import React, { useState } from "react";
import classNames from "classnames";
import "./index.scss";

import CustomersList from "../CustomersList";
import CustomersData from "../CustomersData";
import AddNewCustomerForm from '../AddNewCustomerFofm/AddNewCustomerFofm.jsx';
import {useHistory} from "react-router";
import IconButton from "../IconButton/index.js";

const Customers = () => {
    const history = useHistory();
    const [customerId, setCustomerId] = useState(null);
    const [isAddOpen, setIsAddOpen] = useState(false);

    const handleOnSelectCustomerId = val => {
        setCustomerId(val);
    }

    const onCreateUser = (user) => {
        setIsAddOpen(false);
        setCustomerId(user.id);
        history.push('/adminorders?userId=' + user.id);
    }

    return (
        <div className={classNames("customers")}>
            <CustomersList onClickAdd={() => setIsAddOpen(true)} customerId={customerId} onSelectCustomerId={handleOnSelectCustomerId} />
            <CustomersData customerId={customerId} />

            <div className={classNames('layoutContainer', { open: isAddOpen })}>
                <IconButton isIcon={true} iconName={"cancel"} onClick={() => setIsAddOpen(false)} />
                <AddNewCustomerForm onCreateUser={onCreateUser}/>
            </div>
        </div>
    );
}

export default Customers;