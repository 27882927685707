import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import { getFullName } from '../../../services/userModel';

import FormInfoDataItem from '../FormInfoDataItem';

const CustomersListItem = ({ value, active, onSelect }) => {
  const { t } = useTranslation();

  const handleOnSelectCustomer = e => {
    if (e) e.stopPropagation();
    value && onSelect && onSelect(value.consumerId);
  };

  return (
    <div className={classNames('customersListItem', { active: active })} onClick={handleOnSelectCustomer}>
      <div className={classNames('customersListItemHeader')}>
        <div className={classNames('itemHeader', 'link')}>{getFullName(value)}</div>
      </div>
      <div className={classNames('customersListItemData')}>
        <FormInfoDataItem title={t('customers.EMAIL_LABEL')} value={(value || {}).email} />
        <FormInfoDataItem className={'userPhone'} isNeverRtl={true} title={t('customers.PHONE_LABEL')} value={(value || {}).phone} />
      </div>
    </div>
  );
};

export default CustomersListItem;
