import React from "react";
import classNames from "classnames";
import "./index.scss";

import IconButton from "../IconButton";

const RateItem = ({ rate, value, isInfo = false, onClick }) => {
    const handleOnClick = () => {
        return new Promise(resolve=> {
            onClick && onClick(rate);
            resolve();
        });
    }

    return (
        <div  className={classNames("rateItem", { active: rate === value, isInfo: isInfo })}
            onClick={handleOnClick}
        >
            <div className={classNames("rateTitle")}>{rate || 0}</div>
            <IconButton isIcon={true} iconName="star" onClick={handleOnClick} />
        </div>
    );
}

export default RateItem;