import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { CUSTOMERS_GET } from "../../../store/reducers/customer/customer-actions";

import GlobalFilterDataService from "../../../services/globalFilterDataService";

import { GLOBAL_FILTER_TYPE } from "../../../utils/enums";
import { createAction } from "../../../utils/sagaHelper";

import CustomersListHeader from "../CustomersListHeader";
import CustomersListItems from "../CustomersListItems";
import {useLocation} from "react-router";
import {getHistoryLocationParams} from "../../../utils/routesHelper";
import {currentUserIsOnlySupportOperatorRole} from "../../../services/userModel.js";

const CustomersList = ({ customerId, onSelectCustomerId, getCustomers, onClickAdd }) => {
    const location = useLocation();
    const userId = getHistoryLocationParams(location.search).userId;

    const [filter, setFilter] = useState(GlobalFilterDataService.instance.getFilter(GLOBAL_FILTER_TYPE.customers));

    const handleOnSetFilter = val => {
        setFilter(val);
    }

    useEffect(() => {
        if(userId) {
            setFilter({ ...filter, search: userId });
            // onSelectCustomerId(userId);
        }
        // eslint-disable-next-line
    }, [userId]);

    useEffect(()=> {
        getCustomers(filter).then((data) => {
            if(userId || data?.[0].consumerId) {
                onSelectCustomerId(userId || data?.[0].consumerId);
            }
        });
        // eslint-disable-next-line
    }, [filter, getCustomers]);

    return (
        <div className={classNames("customersList")}>
            <CustomersListHeader addIcon onClickAdd={onClickAdd} filter={filter} onSetFilter={handleOnSetFilter} withExelIcon={!currentUserIsOnlySupportOperatorRole()} />
            <CustomersListItems customerId={customerId} onSelect={onSelectCustomerId} />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        getCustomers: data=> dispatch(createAction(CUSTOMERS_GET, data))
    };
};

export default connect(null, mapDispatchToProps)(CustomersList);