import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import IconButton from "../IconButton";

const PaginationControl = ({
    t,
    onClickPrevious,
    onClickNext
}) => (
    <div className={classNames("paginationControl")}>
        <div className={classNames("paginationControlPrevious")} onClick={onClickPrevious}>
            <IconButton isIcon={true} iconName={"paginationPrevious"}
                onClick={()=> new Promise(resolve=> resolve())}
            />
            <span>{t("basic.PAGINATION_PREVIOUS")}</span>            
        </div>
        <div className={classNames("paginationControlNext")} onClick={onClickNext}>
            <span>{t("basic.PAGINATION_NEXT")}</span>
            <IconButton isIcon={true} iconName={"paginationNext"}
                onClick={()=> new Promise(resolve=> resolve())}
            />
        </div>
    </div>
);

export default withTranslation()(PaginationControl);