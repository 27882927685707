import React, { useEffect, useState } from 'react';
import { createAction } from "../../../utils/sagaHelper";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import "./index.scss";

import { ADMIN_ORDER_CANCEL } from "../../../store/reducers/order/order-actions";
import { MODEL_TYPE } from "../../../utils/enums";
import { getInitModel } from "../../../services/initModels";

import LayoutHeaderControls from "../LayoutHeaderControls";

const CancelAdminOrder = ({ t, id, cancelOrder, onClose, onConfirm }) => {
    const [data, setData] = useState(getInitModel(MODEL_TYPE.cancelOrderModel, { id }));

    useEffect(() => {
        setData(getInitModel(MODEL_TYPE.cancelOrderModel, { id }))
    }, [id])

    const handleOnSave = () => {
        return new Promise((resolve, reject)=> {
            const action = onConfirm || cancelOrder;
            action(data).then(()=> {
                setData(getInitModel(MODEL_TYPE.cancelOrderModel, { id }));
                resolve();
                onClose();
            }, error=> {
                toast(error);
                reject(error);                
            });
        });
    }

    return (
        <div className={classNames("cancelAdminOrder")}>
            <div className={classNames("formControls")}>
                <div className={classNames("formControl")}>
                    <div className={classNames("title")}>{t("admin-orders.REFUND_ORDER_NOTE_LABEL")}</div>
                    <textarea className={classNames("inputArea")} rows="5" value={data.notes}
                        onChange={e=> setData({ ...data, notes: e.target.value })}
                    ></textarea>
                </div>
            </div>

            <LayoutHeaderControls onSave={handleOnSave} onClose={onClose} />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        cancelOrder: data=> dispatch(createAction(ADMIN_ORDER_CANCEL, data))        
    };
};

export default  connect(null, mapDispatchToProps)(withTranslation()(CancelAdminOrder));
