import classnames from "classnames";
import React from "react";
import "./index.scss";

export const CounterButton = ({onClickAdd, onClickRemove, disabled, quantity = 0}) => {
    const handleOnAdd = e => {
        if(!disabled) {
            onClickAdd && onClickAdd();
        }
    }
    const handleOnRemove = e => {
        if(!disabled) {
            onClickRemove && onClickRemove();
        }
    }
    return (
        <div className={classnames("counterButton")}>
            <div className={classnames("minus", {disabled})} onClick={handleOnRemove}>-</div>
            <div className={classnames("quantity")}>{quantity}</div>
            <div className={classnames("plus", {disabled})} onClick={handleOnAdd}> +</div>
        </div>
    )
}