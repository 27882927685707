import React, {useEffect, useRef, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import './style.scss';
import {SupportChatDialog} from '../SupportChatDialog/SupportChatDialog';
import {useDispatch, useSelector} from 'react-redux';
import Waiter from '../Waiter/Waiter';
import {filterStateChat, getFilterStateChat, WAITER_TYPES} from '../../../utils/enums';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {
    AUTH_SUPPORT_CHAT,
    markResolvedSupportChat,
    pullSupportChat
} from '../../../store/reducers/supportChat/supprtChat-actions';
import IconButton from '../IconButton';
import {CreateNewDialog} from '../CreateNewDialog/CreateNewDialog';
import {addUserToChat, startChat} from '../../../api/supportChat-request';
import {getErrorMessage} from '../../../utils/request-util';
import {isRTL} from '../../../services/userModel';
import {createAction} from "../../../utils/sagaHelper.js";

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

export const CustomerServiceChat = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        dispatch(createAction(AUTH_SUPPORT_CHAT, {}));
    }, [dispatch]);

    const inboxRef = useRef();
    const chatRef = useRef();

    const { dialogs, session } = useSelector(state => state.supportChat);

    const [inbox, setInbox] = useState(null);
    const [currentDialog, setCurrentDialog] = useState(null);
    const [filtersInbox, setFiltersInbox] = useState({
        [filterStateChat.active]: true,
        [filterStateChat.closed]: false,
    });
    const [showCreateNewDialog, setShowCreateNewDialog] = useState(false);
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);

    useEffect(
        () => {
            if (!session) {
                return;
            }

            const theme = isRTL() ? 'DelivApp_Business' : 'DelivApp_Business_LTR';

            const types = Object.keys(filtersInbox)
                .filter(x => filtersInbox[x])
                .map(x => x.toString());

            const inboxInstance = session.createInbox({
                theme: theme,
            });

            inboxInstance.setFeedFilter({
                custom: {
                    state: ['oneOf', types.map(x => x.toString())],
                },
            });

            inboxInstance.mount(inboxRef.current);

            setInbox(() => inboxInstance);

            const chatbox = session.createChatbox({
                theme: theme,
            });
            chatbox.mount(chatRef.current);

            inboxInstance.onSelectConversation(ev => {
                ev.preventDefault();
                chatbox.select(ev.conversation.id);
                setCurrentDialog(ev.conversation.id);
            });

            return () => {
                if (inbox && inbox.destroy) {
                    inbox.destroy();
                }
                chatbox.destroy();
            };
        },
        // eslint-disable-next-line
        [session, dimensions.width]
    );

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }, 500);

        window.addEventListener("resize", debouncedHandleResize);

        return _ => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    const handleOnPullChat = chatId => {
        dispatch(pullSupportChat(chatId));
    };

    const handleOnMarkResolved = () => {
        dispatch(markResolvedSupportChat(currentDialog));
    };

    const onSetInboxFilter = types => {
        if (!inbox) {
            return;
        }

        inbox.setFeedFilter({
            custom: {
                state: ['oneOf', types.map(x => x.toString())],
            },
        });
    };

    const handleOnToggleInboxFilter = type => {
        const newFiltersInbox = { ...filtersInbox, [type]: !filtersInbox[type] };
        const hasOneSelected = Object.values(newFiltersInbox).some(Boolean);

        if (hasOneSelected) {
            const types = Object.keys(newFiltersInbox)
                .filter(x => newFiltersInbox[x])
                .map(x => x.toString());
            setFiltersInbox(newFiltersInbox);
            onSetInboxFilter(types);
        }
    };

    const getBodyFromUsers = (users) => {
        const body = {};
        users.forEach(({ courierId, consumerId, depotId }) => {
            if(depotId) {
                body.depotId = depotId;
            }else if(consumerId) {
                body.consumerId = consumerId;
            } else if(courierId) {
                body.courierId = courierId;
            }
        })
        return body;
    }

    const createNewDialog = async data => {
        const body = {
            subject: data.subject,
            ...getBodyFromUsers(data.users || [])
        };
        const resultRequest = await startChat(body);
        if (!resultRequest || resultRequest.status !== 200) {
            throw new Error(getErrorMessage(resultRequest));
        }
        setShowCreateNewDialog(false);
    };

    const addUserToDialog = async (data) => {
        const user = getBodyFromUsers(data.users || []);
        const id = user.depotId || user.courierId || user.consumerId;
        const resultRequest = await addUserToChat(currentDialog, id);
        if (!resultRequest || resultRequest.status !== 200) {
            throw new Error(getErrorMessage(resultRequest));
        }
        setShowAddUserDialog(false);
    }

    const showTabs = dimensions.width < 1500;

    const renderUnassignedTitle = () => {
        return (
            <div className={'header'}>
                <span className={'title'}>{t('support-chat.UNASSIGNED')}</span>
                <div className={'countDialogs'}>{dialogs ? dialogs.length : 0}</div>
            </div>
        );
    };

    const renderUnassigned = () => {
        return (
            <div className={'unassigned'}>
                {renderUnassignedTitle()}
                <SupportChatDialog dialogs={dialogs} onPullChat={handleOnPullChat} />
            </div>
        );
    };

    const renderAssignedToMeTitle = () => {
        return (
            <div className={'header'}>
                <span className={'title'}>{t('support-chat.ASSIGNED_TO_ME')}</span>
                {/*<div className={"countDialogs"}>{unreadMessages}</div>*/}
            </div>
        );
    };

    const renderAssignedToMe = () => {
        return (
            <div className={'assignToMe'}>
                {renderAssignedToMeTitle()}
                <div className={'filters'}>
                    {getFilterStateChat().map(({ key, value }) => (
                        <div
                            key={key}
                            onClick={() => handleOnToggleInboxFilter(key)}
                            className={classNames('filterItem', {
                                active: filtersInbox[key],
                            })}
                        >
                            <span className={'filterType'}>{value}</span>
                        </div>
                    ))}
                </div>
                <div className={'controls'}>
                    <div className={'createNew'} onClick={() => setShowCreateNewDialog(true)}>
                        <IconButton isIcon iconName={'iconPlus'} onClick={() => setShowCreateNewDialog(true)} />
                        <span>{t('support-chat.CREATE_NEW')}</span>
                    </div>
                </div>
                <div className={'inboxContainer'} ref={inboxRef} />
            </div>
        );
    };

    const renderChat = () => {
        return (
            <div className={'chat'}>
                <div className={'chatContainer'} ref={chatRef} />

                <div className={'chatFooter'}>
                    <Waiter
                        type={WAITER_TYPES.buttonSpan}
                        useClass={classNames('markResolve')}
                        spanText={t('support-chat.MARK_AS_RESOLVED')}
                        stopPropagation={true}
                        handleOnClick={handleOnMarkResolved}
                    />

                    <Waiter
                        type={WAITER_TYPES.buttonSpan}
                        useClass={classNames('markResolve')}
                        spanText={t('support-chat.addUser.ADD_BUTTON')}
                        stopPropagation={true}
                        handleOnClick={() => setShowAddUserDialog(true)}
                    />
                </div>
            </div>
        );
    };

    const renderChatControls = () => {
        if (showTabs) {
            return (
                <div className={classNames('customerServiceChatControls', 'customerServiceChatControlsTabs')}>
                    <Tabs
                        className={classNames('chatTabs')}
                        selectedIndex={selectedTab}
                        onSelect={index => setSelectedTab(index)}
                    >
                        <TabList className={classNames('tabList')}>
                            <Tab tabIndex={'0'} className={classNames('tab', { selected: selectedTab === 0 })}>
                                {renderUnassignedTitle()}
                            </Tab>
                            <Tab tabIndex={'1'} className={classNames('tab', { selected: selectedTab === 1 })}>
                                {renderAssignedToMeTitle()}
                            </Tab>
                        </TabList>
                        <TabPanel className={classNames('tabPanel', { selected: selectedTab === 0 })} forceRender>
                            <div className={'unassigned'}>
                                <SupportChatDialog dialogs={dialogs} onPullChat={handleOnPullChat} />
                            </div>
                        </TabPanel>
                        <TabPanel className={classNames('tabPanel', { selected: selectedTab === 1 })} forceRender>
                            <div className={'assignToMe'}>
                                <div className={'filters'}>
                                    {getFilterStateChat().map(({ key, value }) => (
                                        <div
                                            key={key}
                                            onClick={() => handleOnToggleInboxFilter(key)}
                                            className={classNames('filterItem', {
                                                active: filtersInbox[key],
                                            })}
                                        >
                                            <span className={'filterType'}>{value}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className={'controls'}>
                                    <div className={'createNew'} onClick={() => setShowCreateNewDialog(true)}>
                                        <IconButton
                                            isIcon
                                            iconName={'iconPlus'}
                                            onClick={() => setShowCreateNewDialog(true)}
                                        />
                                        <span>{t('support-chat.CREATE_NEW')}</span>
                                    </div>
                                </div>
                                <div className={'inboxContainer'} ref={inboxRef} />
                            </div>
                        </TabPanel>
                    </Tabs>
                    {renderChat()}
                </div>
            );
        } else {
            return (
                <div className={'customerServiceChatControls'}>
                    {renderUnassigned()}
                    {renderAssignedToMe()}
                    {renderChat()}
                </div>
            );
        }
    };

    return (
        <div className={'customerServiceChat'}>
            {renderChatControls(inboxRef)}
            <div
                className={classNames('layoutContainer', {
                    open: showCreateNewDialog || showAddUserDialog,
                })}
            >
                {showCreateNewDialog && (
                    <CreateNewDialog
                        onSave={createNewDialog}
                        onClose={() => setShowCreateNewDialog(false)}
                        title={t("support-chat.createNew.LABEL")}
                        confirmLabel={t("support-chat.createNew.CREATE")}
                    />
                )}
                {showAddUserDialog && (
                    <CreateNewDialog
                        canChooseOneUser
                        onSave={addUserToDialog}
                        onClose={() => setShowAddUserDialog(false)}
                        hideSubject
                        title={t("support-chat.addUser.ADD_USER")}
                        confirmLabel={t("support-chat.addUser.ADD")}
                    />
                )}
            </div>
        </div>
    );
};
