import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import classNames from "classnames";
import "./index.scss";

import CustomerDataItem from "../CustomerDataItem";
import preloader from '../../../assets/images/preloader.GIF';
import {List} from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import {CUSTOMER_WALLET_OPERATION_TYPE} from "../../../utils/constants.js";
import {useTranslation} from "react-i18next";

const CustomerDataItems = ({ customerId, items, getCustomerOrders, onOpenBonus, onBlockUser }) => {
    const {t} = useTranslation();
    const [loading, setIsLoading] = useState(false)
    const [value, setValue] = useState(null)

    useEffect(() => {
        setIsLoading(true)

        const newValue = customerId && items?.find(f => f.consumerId === customerId)

        if(!newValue || (newValue?.consumerId === value?.consumerId && (items && value && items.length === value.length))) return setIsLoading(false)

        if(!newValue?.orders) return setIsLoading(false)

        setIsLoading(false)
        setValue(newValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, items])


    const rowRenderer = ({ index, key, style }) => {
        if (!value?.orders) return
        const item = value?.orders[index];
        if(!item) return

        return (
          <div key={key} style={style}>
              <CustomerDataItem key={`customerDataItem-${index}`} value={item} />
          </div>
        );
    };

    const handleOnAddBonusCard = () => {
        onOpenBonus(CUSTOMER_WALLET_OPERATION_TYPE.bonusCard);
    };

    const handleOnWriteOffBonus = () => {
        onOpenBonus(CUSTOMER_WALLET_OPERATION_TYPE.spend);
    };

    const handleOnAccrue = () => {
        onOpenBonus(CUSTOMER_WALLET_OPERATION_TYPE.accrue);
    };


    return (
        <div className={classNames("customerDataItems")}>
            {loading &&
              <div className={classNames("loaderWrapper")}>
                <img className="preloader" src={preloader} alt="preloader" />
              </div>}
            {!loading &&
              <AutoSizer>
                  {({ height, width }) => {
                      return (
                        <List
                          className={classNames('listContent')}
                          width={width}
                          height={height}
                          rowHeight={170}
                          rowRenderer={rowRenderer}
                          rowCount={value?.orders?.length || 0}
                          overscanRowCount={3}
                        />
                      );
                  }}
              </AutoSizer>
            }
            <div className={classNames('customerDataBottomControls')}>
                {(value?.blockInfo?.isBlocked === false) && (
                    <button className={classNames('btn', 'confirm')} onClick={() => onBlockUser(value)}>
                        {t('customers.BLOCK_USER_BUTTON')}
                    </button>
                )}
                <button className={classNames('btn', 'confirm')} onClick={handleOnAddBonusCard}>
                    {t('customers.ADD_BONUS_CARD')}
                </button>
                <button className={classNames('btn', 'confirm')} onClick={handleOnWriteOffBonus}>
                    {t('customers.WRITE_OFF_BONUSES_LABEL')}
                </button>
                <button className={classNames('btn', 'confirm')} onClick={handleOnAccrue}>
                    {t('customers.ACCRUE_BONUSES_LABEL')}
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    items: state.customer.customers || []
});

export default connect(mapStateToProps)(CustomerDataItems);
