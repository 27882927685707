import React from "react";
import "./style.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const CustomerSalesChart = ({ events, onClickEvent }) => {
    const {t} = useTranslation();
    return (
        <div className={"customerSalesEvents"}>
            <div className={"label"}>{t("sales.EVENTS")}:</div>
            <div className={"events"}>
                {events.map(item => (
                    <div key={item.name + item.quantity} className={classNames("event", {active: item.selected})} onClick={() => onClickEvent(item.id)}>
                        {item.selected && (
                            <div className={"removeIcon"}>+</div>
                        )}
                        {item.name} {item.quantity}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CustomerSalesChart;