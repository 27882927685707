import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import LayoutHeaderControls from "../LayoutHeaderControls";
import SectionInfoTitle from "../SectionInfoTitle/index.js";

const CustomerBonusesRefund = ({ t, onClose, onSave, writeOff }) => {
    const [data, setData] = useState({ amount: 0, notes: "" });

    const handleOnSave = () => {
        return new Promise(resolve=> {
            resolve();
            onSave && onSave(data);
        });
    }

    const handleOnClose = () => {
        return new Promise(resolve=> {
            resolve();
            onClose && onClose();
        });
    }

    const handleOnEdit = (prop, val) => {
        setData({ ...data, [prop]: val });
    }

    return (
        <div className={classNames("customerBonusesRefund")}>
            <div className={classNames("formControls")}>
                <SectionInfoTitle sectionModel={{label: writeOff ? "admin-orders.REFUND_ORDER" : "admin-orders.REFUND_ORDER_ADD"}}/>
                <div className={classNames("formControl")}>
                    <div className={classNames("title")}>{t("admin-orders.REFUND_ORDER_AMOUNT_LABEL")}</div>
                    <input type="number" value={data.amount} step="0.1" 
                        onChange={e=> handleOnEdit("amount", Number(parseFloat(e.target.value).toFixed(2)))} 
                    />
                </div>
                <div className={classNames("formControl")}>
                    <div className={classNames("title")}>{t("admin-orders.REFUND_ORDER_NOTE_LABEL")}</div>
                    <textarea className={classNames("inputArea")} rows="5" value={data.notes}
                        onChange={e=> handleOnEdit("notes", e.target.value)}
                    ></textarea>
                </div>
            </div>
            <LayoutHeaderControls withConfirmDialog onSave={handleOnSave} onClose={handleOnClose} />
        </div>
    );
}

export default withTranslation()(CustomerBonusesRefund);