import React from "react";
import IconButton from "../IconButton";
import "./style.scss";
import TimeBox from "../TimeBox/TimeBox";
import {isAfterDateByCurrent} from "../../../utils/convertTime";

const TimeInfoETA = ({ date, abt, isComa, icon, tooltip, nonRelative, title, onClick }) => {
    const handleOnClick = () => {
        return new Promise(resolve=> {
            resolve();
            onClick && onClick();
        });
    }

    return nonRelative ? (
        <div className={"timeInfoEta"}>
            {icon && (<IconButton isIcon iconName={icon} tooltipText={tooltip} onClick={handleOnClick} />)}
            {date && !isAfterDateByCurrent(date) && abt && ("~")}
            {!isAfterDateByCurrent(date) && (
                <TimeBox shortLabel date={date} />
            )}
            {isAfterDateByCurrent(date) && (<span>{title}</span>)}
            {isComa && ","}
        </div>
    ) : !isAfterDateByCurrent(date) && (
        <div className={"timeInfoEta"}>
            {icon && (<IconButton isIcon iconName={icon} tooltipText={tooltip} onClick={handleOnClick} />)}
            {date && abt && ("~")}
            <TimeBox shortLabel date={date} />
            {isComa && ","}
         </div>
    );
}

export default TimeInfoETA;
