import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import RateControl from "../RateControl";

const CustomerReviewsFilter = ({ t, filter, onSetFilter, isInline }) => {
    const handleOnDeliveryRateClick = deliveryRating => {
        onSetFilter && onSetFilter({ deliveryRating });
    }

    const handleOnProductRateClick = rating => {
        onSetFilter && onSetFilter({ rating });
    }

    return (
        <div className={classNames("customerReviewsFilter")}>
            <div className={classNames("customerReviewsFilterItem")}>
                <RateControl value={filter.rating} title={t("reviews.REVIEW_FILTER_RATE_PRODUCT")}
                             isInline={isInline}
                             onClick={handleOnProductRateClick}
                />
            </div>
            <div className={classNames("customerReviewsFilterItem")}>
                <RateControl value={filter.deliveryRating} title={t("reviews.REVIEW_FILTER_RATE_DELIVERY")} 
                    isInline={isInline} 
                    onClick={handleOnDeliveryRateClick} 
                />
            </div>
        </div>
    );
}

export default withTranslation()(CustomerReviewsFilter);