import React, {useState} from "react";
import "./style.scss";
import classNames from "classnames";
import IconButton from "../IconButton";
import {useSelector} from "react-redux";
import {getMenuTemplates, getRestaurantDepots} from "../../../store/selectors";
import {isRTL} from "../../../services/userModel";
import {SALES_TYPE, defaultCustomerServiceSalesItem} from "../../../utils/constants";
import {useTranslation} from "react-i18next";

const TAB_SIZE = 10;

const getTabStyle = (tabNest) => {
    const margin = TAB_SIZE * tabNest + "px";
    const style = {
        margin: isRTL() ? `0 ${margin} 0 0` : `0 0 0 ${margin}`
    }
    return style;
}

const CustomerSalesMenuList = ({ filterString, onSelect, menu, menus }) => {
    const {t} = useTranslation();
    const [isOpenRoot, setIsOpenRoot] = useState(true);
    const [selectedIds, setSelectedIds] = useState([{ id: "ROOT" }]);
    const depots = useSelector(getRestaurantDepots);
    const menuTemplates = useSelector(getMenuTemplates);

    const isSelectedId = id => {
        return selectedIds.some(m => m.id === id);
    }

    const handleOnClickMarketplace = () => {
        setIsOpenRoot(!isOpenRoot);
    }
    const handleOnSelectMarketplace = () => {
        handleOnSelectId(defaultCustomerServiceSalesItem());
    }

    const handleOnSelectDepot = depot => {
        handleOnSelectId({ id: depot.id, type: SALES_TYPE.business, name: depot.name, businessId: depot.businessId });
    }

    const handleOnSelectTemplate = template => {
        handleOnSelectId({ id: template.id, type: SALES_TYPE.template, name: template.name, businessId: template.business_id });
    }

    const handleOnSelectId = (item) => {
        if(item.type === "business") {
            const selected = selectedIds.filter(m => m.type === "business");
            if(selected.some(m => m.id === item.id)) {
                const filtered = selected.filter(m => m.id !== item.id);
                if(filtered.length) {
                    setSelectedIds(filtered);
                } else {
                    setSelectedIds([defaultCustomerServiceSalesItem()]);
                }
            } else {
                setSelectedIds(selected.concat(item));
            }
        } else {
            setSelectedIds([item]);
        }
        onSelect(item);
    }

    const handleOnOpenDepot = (depot) => {
        if(!isSelectedId(depot.id)) {
            handleOnSelectId({ id: depot.id, type: SALES_TYPE.business, name: depot.name, businessId: depot.businessId });
        }
    }

    const handleOnOpenTemplate = (template) => {
        if(!isSelectedId(template.id)) {
            handleOnSelectId({ id: template.id, type: SALES_TYPE.template, name: template.name, businessId: template.business_id });
        }
    }

    const getMenu = (id) => {
        return menus.find(m => m.id === id);
    }

    return (
        <div className={"customerSalesMenuList"}>
            <div className={classNames("listItem", { open: isOpenRoot, selected: isSelectedId("ROOT") })}>
                <div className={"label"}>
                    <div onClick={handleOnSelectMarketplace} className={classNames("selectedIcon", { selected: isSelectedId("ROOT") })}/>
                    <div className={"name"}>{t("sales.MARKETPLACE")}</div>
                </div>
                <IconButton onClick={handleOnClickMarketplace} isIcon iconName={"smallArrow"}/>
            </div>

            {isOpenRoot && menuTemplates && menuTemplates
                .filter(template => template.name?.toLowerCase().trim().includes(filterString?.toLowerCase().trim()))
                .map(template =>
                    getMenu(template.id)
                        ? (
                            <SelectedItem
                                key={template.id}
                                handleOnSelectDepot={() => handleOnSelectTemplate(template)}
                                handleOnOpenDepot={() => handleOnOpenTemplate(template)}
                                handleOnSelectId={handleOnSelectId}
                                menuId={getMenu(template.id)?.id}
                                isSelectedId={isSelectedId}
                                menu={getMenu(template.id)}
                            />
                        ) : (
                            <div className={classNames("listItem depot")} key={template.id}>
                                <div className={"label"}>
                                    <div onClick={() => handleOnSelectTemplate(template)} className={classNames("selectedIcon", { selected: isSelectedId(template.id) })}/>
                                    <div className={"name"} style={getTabStyle(1)}>{template.name}</div>
                                </div>
                                <IconButton onClick={() => handleOnOpenTemplate(template)} isIcon iconName={"smallArrow"}/>
                            </div>
                        )
                )}

            {isOpenRoot && depots.length && depots
                .filter(depot => !!depot.businessId && depot.name?.toLowerCase()?.trim()?.includes(filterString?.toLowerCase()?.trim()))
                .map(depot => (
                    getMenu(depot.id)
                        ? (
                            <SelectedItem
                                square={true}
                                key={depot.id}
                                handleOnSelectDepot={() => handleOnSelectDepot(depot)}
                                handleOnOpenDepot={() => handleOnOpenDepot(depot)}
                                handleOnSelectId={handleOnSelectId}
                                menuId={getMenu(depot.id)?.id}
                                isSelectedId={isSelectedId}
                                menu={getMenu(depot.id)}
                            />
                        )
                        : (
                            <div className={classNames("listItem depot")} key={depot.id}>
                                <div className={"label"}>
                                    <div onClick={() => handleOnSelectDepot(depot)} className={classNames("selectedIcon", { square: true, selected: isSelectedId(depot.id) })}/>
                                    <div className={"name"} style={getTabStyle(1)}>{depot.name}</div>
                                </div>
                                <IconButton onClick={() => handleOnOpenDepot(depot)} isIcon iconName={"smallArrow"}/>
                            </div>
                        )

                ))
            }
        </div>
    );
}

const SelectedItem = ({handleOnSelectDepot, square, handleOnOpenDepot, isSelectedId, menu, handleOnSelectId}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOnClickOpen = () => {
        setIsOpen(!isOpen);
        handleOnOpenDepot();
    }
    return (
        <div>
            <div className={classNames("listItem depot", { open: isOpen, selected: isSelectedId(menu && menu.id) })}>
                <div className={"label"}>
                    <div onClick={handleOnSelectDepot} className={classNames("selectedIcon", { square, selected: isSelectedId(menu && menu.id) })}/>
                    <div className={"name"} style={getTabStyle(1)}>{menu && menu.name}</div>
                </div>
                <IconButton onClick={handleOnClickOpen} isIcon iconName={"smallArrow"}/>
            </div>

            {isOpen && menu && menu.items.map(item => (
                <ListItem key={item.id} item={item} isSelectedId={isSelectedId} onSelect={handleOnSelectId} />
            ))}
        </div>
    )
}

const ListItem = ({ item, isSelectedId, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <div className={classNames("listItem", { open: isOpen, selected: isSelectedId(item.id), notSelectable: !item.type })}>
                <div className={"label"}>
                    <div onClick={() => onSelect(item)} className={classNames("selectedIcon", { selected: isSelectedId(item.id) })}/>
                    <div className={"name"} style={getTabStyle(item.index)}>{item.name}</div>
                </div>
                <IconButton onClick={() => setIsOpen(!isOpen)} isIcon iconName={"smallArrow"}/>
            </div>

            {isOpen && item.items && item.items.map(item => (
                <ListItem key={item.id} item={item} isSelectedId={isSelectedId} onSelect={onSelect} />
            ))}
        </div>

    )
}

export default CustomerSalesMenuList;