import React, { useEffect, useState } from 'react';
import { chatSource, chatSourceTypes, WAITER_TYPES } from '../../../utils/enums';
import './style.scss';
import classNames from 'classnames';
import { getRelativeTime } from '../../../utils/convertTime';
import Waiter from '../Waiter/Waiter';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../Avatar/Avatar';
import {posrUserDataByParticipant} from '../../../api/supportChat-request';

export const SupportChatDialogItem = ({
    id,
    state,
    source,
    createdAt,
    lastMessage,
    participants,
    subject,
    onPullChat,
}) => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState(null);

    // useEffect(
    //     () => {
    //         async function getUserData() {
    //             const userDataRequest = await getUserDataByParticipantId(participants[0]);
    //             if (userDataRequest.data) {
    //                 setUserData(userDataRequest.data);
    //             }
    //         }
    //         getUserData();
    //     },
    //     [participants]
    // );
    useEffect(() => {
        async function getUserData() {
            const body = {
                UserId: participants,
              };
            const userDataRequest = await posrUserDataByParticipant(body);
            if (userDataRequest.data) {
                setUserData(userDataRequest.data[0]);
            }
        }
        getUserData();
    }, [participants]);

    if (!userData) {
        return null;
    }

    return (
        <div className={'supportChatDialogItem'}>
            {source && (
                <div
                    className={classNames('sourceType', {
                        depot: source === chatSource.depot,
                        courier: source === chatSource.courier,
                        consumer: source === chatSource.consumer,
                    })}
                >
                    {chatSourceTypes[source]}
                </div>
            )}
            <div className="chatRow">
                <div className={'user'}>
                    <Avatar avatar={userData.PhotoUrl} />
                    <div className={'userName'}>{userData.Name}</div>
                </div>
                <div className={'timeAgo'}>{getRelativeTime(createdAt)}</div>
            </div>
            <div className={classNames("chatRow", "chatRowBottom")}>
                <div className={'subject'}>{subject}</div>
                <Waiter
                    type={WAITER_TYPES.buttonSpan}
                    useClass={classNames('pull-btn')}
                    spanText={t('support-chat.pull')}
                    stopPropagation={true}
                    handleOnClick={() => onPullChat(id)}
                />
            </div>
        </div>
    );
};
