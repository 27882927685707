import React from 'react';
import classnames from 'classnames';
import './style.scss';
import CounterButton from "../CounterButton/CounterButton";
import {getCurrencyIcons} from "../../../../utils/enums";

const ProductAddDish = ({product, onChange, currency, useClass, allProductsCount, currentIndex}) => {
    const filteredSuboptions = ((product || {}).suboptions || []).filter(s => s.enabled);
    const handleOnAdd = (data, operation) => {
        let suboptions = [];
        if(product.min === 1 && product.max === 1) {
            suboptions = filteredSuboptions.map(el => (
                el.id === data.id
                    ? {...el, quantity: (el.quantity ? el.quantity : 0)  + operation}
                    : {...el, quantity: 0}
            ))
        } else {
            suboptions = filteredSuboptions.map(el => {
                const newQuantity = (el.quantity ? el.quantity : 0)  + operation;
                return  el.id === data.id && (data.max ? data.max >= newQuantity : true)
                    ? {...el, quantity: newQuantity}
                    : {...el}
            });
        }

        const suboptionQuantity = suboptions.reduce((acc, cv) => acc + (cv.quantity ? cv.quantity : 0), 0) || 0;

        if(suboptionQuantity <= product.max || product.max === 0) {
            onChange({...product, suboptions});
        }
    }
    const handleOnRemove = (data, operation) => {
        const suboptions = filteredSuboptions.map(el => (
            el.id === data.id
                ? {...el, quantity: (el.quantity ? el.quantity : 0)  + operation}
                : {...el}
        ))
        const suboptionQuantity = suboptions.reduce((acc, cv) => acc + (cv.quantity ? cv.quantity : 0), 0);
        if(suboptionQuantity >= 0) {
            onChange({...product, suboptions: suboptions});
        }
    }
    const handleOnSelect = (data, operation) => {
        const suboptions = filteredSuboptions.map(el => (
            el.id === data.id
                ? {...el, quantity: 1}
                : {...el, quantity: 0}
        ));
        onChange({...product, suboptions: suboptions});
    }

    const isRadio = product.max === 1 && product.min === 1
    const isOneOptional = product.min === 0 && product.max === 1;

    return (
        <div className={classnames("productcard-add-dish", useClass)}>
            <div className={classnames("header")}>
                {product.name}
                {isRadio && (
                    ` (min 1 - max 1) `
                )}
                {isOneOptional && (
                    ` (max 1) `
                )}
                {!isOneOptional && !isRadio && (
                    ` ${product.min ? '(min ' + product.min + ' - ' : '('}${product.max ? 'max ' +  product.max + ')' : ')'} `
                )}
            </div>
            <div className={"steps"}>
                {allProductsCount > 0 && Array(allProductsCount)
                    .fill(0)
                    .map((_, index) => (
                        <React.Fragment key={`${index}-step-${_}`}>
                            <div className={classnames("step", {active: index === currentIndex})}/>
                            <div className={"step-line"}/>
                        </React.Fragment>
                    ))}

            </div>
            <div className={classnames("oprions", {
                radioButtons: isRadio,
                fullSizeCheckbox: filteredSuboptions.some(s => s.max === 1) || !product.allow_suboption_quantity,
                fixMargin: !product.allow_suboption_quantity,
                withButton: (product.max === 0 || product.max > 1) && product.allow_suboption_quantity,
            })}>
                {filteredSuboptions.map(subopt => (
                    (isRadio)
                        ? (<RadioButton option={subopt}
                                        currency={currency}
                                        key={"subpotion" + subopt.id}
                                        onSelect={handleOnSelect}
                        />)
                        : (<CheckBox  option={subopt}
                                      onClickAdd={handleOnAdd}
                                      onClickRemove={handleOnRemove}
                                      showButton={(product.max === 0 || product.max > 1) && (product.allow_suboption_quantity )}
                                      currency={currency}
                                      key={"subpotion" + subopt.id}
                        />)
                ))}
            </div>
        </div>
    )
}

const CheckBox = ({option, currency, showButton, onClickAdd, onClickRemove}) => {
    const handleOnChange = () => {
        !!option.quantity ? onClickRemove(option, -option.quantity) : onClickAdd(option, +1);
    }
    const handleOnAdd = () => {
        onClickAdd(option, +1);
    }
    const handleOnRemove = () => {
        if(option.quantity -1 >= 0) {
            onClickRemove(option, -1);
        }
    }

    return (
        <div className={classnames("checkbox", {selected: option.quantity, withCounter: showButton})} onClick={handleOnChange}>
            <label className={classnames("input")}>
                <input onChange={() => {}} type="checkbox" checked={!!option.quantity}/>
                <span onClick={e => e.preventDefault()} className={classnames("text")}>{option.name} <span className={classnames("option-description")}>{option.description}</span></span>
            </label>
            <div className={"counterControl"} onClick={e => e.stopPropagation()}>
                {!!option.price ? (<div className={classnames("price")}>+{getCurrencyIcons(currency)}{option.price}</div>) : (<div/>)}
                {showButton && (<CounterButton quantity={option.quantity} onClickAdd={handleOnAdd}  onClickRemove={handleOnRemove}/>)}
            </div>
        </div>
    )
};

const RadioButton = ({currency, option, onSelect}) => {
    const handleOnChange = (e) => {
        e.preventDefault();
        onSelect(option, !!option.quantity ? -1 : 1);
    }
    return (
        <label className={classnames("radio-button")} onClick={handleOnChange}>
            <div className={classnames("input")}>
                <input type="radio" checked={!!option.quantity} onChange={handleOnChange}/>
                <span className={classnames("text")}>{option.name}</span>
            </div>
            {!!option.price && (<span className={classnames("price")}>+{getCurrencyIcons(currency)}{option.price}</span>)}
        </label>
    )
};
export default ProductAddDish;
