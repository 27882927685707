import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './index.scss';

import {
    actionResetTooltipData,
    actionUpdateTooltipData,
} from '../../../store/reducers/tooltipPortal/tooltipPortal-actions';
import { columnIsRequired } from '../../../services/filter';

import NumberInput from '../NumberInput';
import ColumnTitleWithIcon from '../ColumnTitleWithIcon';
import { getTranslateTitle } from '../../../utils/objects-util.js';
import { getRestaurantConfigPropValue } from '../../../services/restaurant.js';
import i18n from 'i18next';

const getColumnTitle = (isUSMetric, column) => {
  const metric = isUSMetric ? '_US' : '';
  return i18n.exists(column.title + metric) ? column.title + metric : column.title
}

const SectionItemNumber = ({ t, column, item, value, onPropChange, updateTooltipData, resetTooltipData }) => {
  const isUSMetric = getRestaurantConfigPropValue('uSBasedMetricSystem');
  const hoverRef = useRef();
  const measure = getTranslateTitle(column.measure)

  return (
    <div
      key={`sectionItemNumber-${column.accessor}`}
      className={classNames('sectionItemNumber', column.inlineType, {isReadonly: column.isReadonly})}
      style={{ width: column?.width }}
    >
      <ColumnTitleWithIcon item={item} column={{ ...column, title: getColumnTitle(isUSMetric, column) }} onPropChange={onPropChange} />
      <div
        ref={hoverRef}
        className={classNames('inputItem', {
          required: columnIsRequired(column, item),
          colorRed: column.useColorRed && column.useColorRed(item),
        })}
        onMouseEnter={() =>
          !!column.tooltipText &&
          updateTooltipData({ tooltipText: column.tooltipText, ref: hoverRef.current })
        }
        onMouseLeave={e => !!column.tooltipText && resetTooltipData({ event: e, context: this })}
      >
        <NumberInput
          isInteger={column.isInteger}
          value={value}
          isReadonly={column.isReadonly}
          debounce={column.debounce}
          max={column.max && column.max(item)}
          maxError={t('basic.error.NUMBER_MAX_VALUE_ERROR')
            .replace('{prop}', getTranslateTitle(column.title))
            .replace('{max}', (column.max && column.max(item)) ||
              (column.accessor === 'max' && item.max))
          }
          placeholder={column.placeholder}
          minLength={column.minlength}
          maxLength={column.maxLength}
          min={column.min && column.min(item)}
          minError={t('basic.error.NUMBER_MIN_VALUE_LESS_MAX')
            .replace('{prop}', getTranslateTitle(column.title))
            .replace('{max}', 'Maximum value')
          }
          onChange={val => onPropChange({ [column.accessor]: val })}
        />
        {measure && <div className={classNames('measure')}>{measure}</div>}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
    return {
        updateTooltipData: data => dispatch(actionUpdateTooltipData(data)),
        resetTooltipData: data => dispatch(actionResetTooltipData(data)),
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(SectionItemNumber));
