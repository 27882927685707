import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import RateItem from "../RateItem";

const RateSelect = ({ items, value, onSelect }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    
    const handleOnSelect = val => {
        return new Promise(resolve=> {
            onSelect && onSelect(value === val ? null : val);
            setIsOpen(false);
            resolve();
        });
    }

    const handleOnOpen = () => {
        return new Promise(resolve=> {
            setIsOpen(!isOpen);
            resolve();
        });
    }

    return (
        <div className={classNames("rateSelect")}>
            <div className={classNames("rateCurrent")} onClick={handleOnOpen}>
                {!!value && (<RateItem rate={value} value={value} isInfo={true} onClick={handleOnOpen} />)}
                {!value && (t("basic.CHOOSE"))}
            </div>
            {isOpen && (
                <div className={classNames("rateSelectItems")}>
                    {(items || []).map(item=> (
                        <div key={`rateSelectItem-${item}`} className={classNames("rateSelectItem")}>
                            <RateItem rate={item} value={value} isInfo={true} onClick={handleOnSelect} />
                        </div>
                    ))}
                </div>
            )}            
        </div>
    );
}

export default RateSelect;
