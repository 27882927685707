import React from 'react';
import './style.scss';
import classnames from "classnames";
import {useDispatch} from "react-redux";
import {createAction} from "../../../utils/sagaHelper.js";
import {ORDERING_MENU_DELIVERY_ZONES_GLOBAL_EDIT_RESET} from "../../../store/reducers/restaurant/restaurant-actions.js";
import IconButton from "../IconButton/index.js";
import {useTranslation} from "react-i18next";
import {currentUserIsDepotRole} from "../../../services/userModel.js";

export const ResetDepotGlobalPriceValue = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handleOnClick = () => {
        dispatch(createAction(ORDERING_MENU_DELIVERY_ZONES_GLOBAL_EDIT_RESET, props));
    }

    return (
        <div className={classnames('resetDepotGlobalPriceValue', {changedValue: !currentUserIsDepotRole() && props.data.override_delivery_zone_reference_price})}
        >
            <span>{props.price}</span>
            {props.data.override_delivery_zone_reference_price && !currentUserIsDepotRole() && (
                <IconButton isIcon onClick={handleOnClick} iconName={'resetIcon'} tooltipText={t("settings.orderingmenu.DELIVERY_ZONE_PRICE_RESET_LABEL")}/>
            )}
        </div>
    )
}