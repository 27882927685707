import React from "react";
import "./style.scss";
import CustomerReviewSearchPeriod from "../CustomerReviewSearchPeriod";
import classNames from "classnames";
import IconButton from "../IconButton/IconButton";
import {getCurrencyIcons} from "../../../utils/enums";
import FilterTriggerControl from "../FilterTriggerControl/index.js";

const CustomerSalesChartHeader = ({ filter, setFilterOpen, onSetFilter, title, onSave, currency, filterQuantity }) => {
    return (
        <div className={"customerSalesChartHeader"}>
            <div className={"title"}>
                {title || "N/A"}
                <div className={'salesFilters'}>
                    <FilterTriggerControl filterCounter={filterQuantity} onClick={() => setFilterOpen(true)}/>
                </div>
            </div>
            <div className={"controls"}>
                <CustomerReviewSearchPeriod filter={filter} onSetFilter={onSetFilter} />

                {false && currency && !!currency.length && (<div className={"currency"}>
                    {currency.map(c => (
                        <div key={c} className={"currencyItem"}>{getCurrencyIcons(c)}</div>
                    ))}
                </div>)}
                {false && (
                    <div className={classNames("reportButton")}>
                        <IconButton
                            isIcon={true}
                            iconName={"excelIcon"}
                            onClick={onSave}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default CustomerSalesChartHeader;