import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import SectionItemSelect from '../SectionItemSelect/SectionItemSelect';
import Waiter from '../Waiter/Waiter';
import {getControlCenters} from '../../../services/restaurant';
import {SHIFT_TYPE, WAITER_TYPES} from '../../../utils/enums';
import {ModalPopup} from '../ModalPopup/ModalPopup';
import './style.scss';
import {useTranslation} from 'react-i18next';
import SwitchControl from '../SwitchControl';
import ShiftCourierControlTimeSelect from '../ShiftCourierControlTimeSelect';
import ShiftCourierControlDepotsList from '../ShiftCourierControlDepotsSelect';
import ShiftCourierControlDepot from '../ShiftCourierControlDepot';
import {useSelector} from 'react-redux';
import {getRestaurantDepots} from '../../../store/selectors';
import {isRTL} from "../../../services/userModel.js";

const ShiftCourierControlPopup = ({
  popupOpen,
  hanldeOnPopupClose,
  onClose,
  controlCenterId,
  setControlCenterId,
  selectedControlCenter,
  availableControlCenters,
  setSelectedFleet,
  fleet,
  courier,
}) => {
  const { t } = useTranslation();
  const depots = useSelector(getRestaurantDepots);
  const [viewAllMode, setViewAllMode] = useState(false);

  const [selectedDepots, setSelectedDepots] = useState([]);
  const [isBackup, setIsBackup] = useState(() => courier?.cycle?.shiftType === SHIFT_TYPE.Backup);
  const [timeSettings, setTimeSettings] = useState(() => ({
    startTime: courier?.cycle?.startTime || null,
    endTime: courier?.cycle?.endTime || null
  }));

  const hasControlCenters = !!getControlCenters(true)?.length;
  let [filteredCC, setFilteredCC] = useState(null);

  useEffect(() => {
    if (!selectedControlCenter) return;
    setControlCenterId(selectedControlCenter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedControlCenter]);

  useEffect(() => {
    if(filteredCC && filteredCC.length === 1 && !controlCenterId) {
      setControlCenterId(filteredCC[0].controlCenterId);
    }
    //eslint-disable-next-line
  }, [filteredCC]);

  const handleUpdateSelectedDepots = depots => {
    setSelectedDepots(depots);
    setViewAllMode(false);
  };

  const handleOnRemoveDepot = removeId => {
    setSelectedDepots(depots => depots.filter(d => d.id !== removeId));
  };

  const onCloseModal = () => {
    return hanldeOnPopupClose({
      shiftType: isBackup ? 1 : 0,
      controlCenterId,
      ...timeSettings,
      allowedDepots: selectedDepots.map(d => d.id),
    });
  };

  const setTimeSettingsCallBack = useCallback(setTimeSettings, []);

  useEffect(() => {
    if (isBackup && availableControlCenters && availableControlCenters[0] && availableControlCenters[0]?.controlCenterId !== null) {
      setFilteredCC([{controlCenterId: null, name: '-'}, ...availableControlCenters]);
    } else if (!isBackup && filteredCC && filteredCC[0] && filteredCC[0]?.controlCenterId === null) {
      setFilteredCC([...availableControlCenters]);
      if (controlCenterId === null) {
        setControlCenterId(availableControlCenters[0] ? availableControlCenters[0].controlCenterId : null);
      }
    } else {
      setFilteredCC(availableControlCenters?.filter(cc => cc?.name));
    }

    // eslint-disable-next-line
  }, [isBackup, availableControlCenters]);


  return (
    <ModalPopup
      isOpen={popupOpen}
      width={'450px'}
      onClose={onClose}
      content={
        <div className={classNames('shiftCourierControlPopup', {shiftCourierControlPopupRtl: isRTL()})}>
          {viewAllMode && (
            <ShiftCourierControlDepotsList
              selectedDepots={selectedDepots}
              depots={depots}
              onConfirm={handleUpdateSelectedDepots}
              onCancel={() => setViewAllMode(false)}
            />
          )}
          {!viewAllMode && (
            <>
              <div className={classNames('dataWrapper')}>
                <div className={classNames('backupShiftControl')}>
                  <span className={classNames('labelControl')}>{t('couriers.BACKUP_SHIFT_TOOLTIP')}</span>
                  <SwitchControl
                    value={isBackup}
                    onClick={setIsBackup}
                    title={{ on: t('dashboard.orders.ON_LABEL'), off: t('dashboard.orders.OFF_LABEL') }}
                  />
                </div>
                <ShiftCourierControlTimeSelect
                  onChange={setTimeSettingsCallBack}
                  startTime={timeSettings.startTime}
                  endTime={timeSettings.endTime}
                />
                <ShiftCourierControlDepot
                  selectedDepots={selectedDepots}
                  removeDepot={handleOnRemoveDepot}
                  onViewAll={() => setViewAllMode(true)}
                />
                {!!filteredCC?.length && (
                    <div className={classNames('shiftCourierControlPopupContent')}>
                      <SectionItemSelect
                          isDisabled={false}
                          value={controlCenterId || selectedControlCenter}
                          column={{
                            accessor: 'controlCenterId',
                            title: t('shifts.SELECT_CONTROL_CENTER'),
                            values: filteredCC?.map(controlCenter => ({
                              key: controlCenter.controlCenterId,
                              value: controlCenter.name,
                            })),
                          }}
                          onPropChange={val => setControlCenterId(val.controlCenterId)}
                      />
                    </div>
                )}
                {(fleet && fleet.length > 0) && (
                    <div className={classNames('shiftCourierControlPopupContentVehicles')}>
                      <SectionItemSelect
                          isDisabled={false}
                          value={controlCenterId || selectedControlCenter}
                          column={{
                            accessor: 'vehicleId',
                            title: t('shifts.SELECT_FLEET'),
                            values: fleet?.map(e => ({
                              key: e.vehicleId,
                              value: e.plateNumber ? e.plateNumber : e.vehicleId,
                            })),
                          }}
                          onPropChange={val => setSelectedFleet(val.vehicleId)}
                      />
                    </div>
                )}
              </div>
              <div className={classNames('shiftCourierControlPopupFooter')}>
                <Waiter
                  type={WAITER_TYPES.button}
                  stopPropagation={true}
                  disabled={hasControlCenters && !isBackup && !controlCenterId}
                  useClass={classNames('btn', 'confirm')}
                  spanText={t('basic.SAVE_BUTTON')}
                  handleOnClick={onCloseModal}
                />
                <div className={classNames('btn', 'denied')} onClick={onClose}>
                  {t('basic.CLOSE_BUTTON')}
                </div>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default ShiftCourierControlPopup;
