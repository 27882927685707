import {getDefaultCustomersFilter, getDefaultReviewsFilter, getDefaultSalesFilter} from "../utils/constants";
import { GLOBAL_FILTER_TYPE } from "../utils/enums";

let singleton = Symbol();
let singletonEnforcer = Symbol();

export default class GlobalFilterDataService {
    constructor(enforcer) {
        if (enforcer !== singletonEnforcer)
            throw new Error("Instantiation failed: use GlobalFilterDataService.instance() instead of new.");
        
        //Init data
        this.filters = {
            [GLOBAL_FILTER_TYPE.reviews]: getDefaultReviewsFilter(),
            [GLOBAL_FILTER_TYPE.customers]: getDefaultCustomersFilter(),
            [GLOBAL_FILTER_TYPE.sales]: getDefaultSalesFilter(),
        };
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new GlobalFilterDataService(singletonEnforcer);
        }

        return this[singleton];
    }

    static set instance(v) {
        throw new Error("Can't change constant property!");
    }

    setFilter(type, data) {
        this.filters[type] = data;
    }

    getFilter(type) {
        return this.filters[type];
    }
}
