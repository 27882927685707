import MenuModel from "./menuModel.js";
import {TYPE_SECTION_MODEL} from "../utils/enums.js";

export function createMenuGeoSettings() {
    return [
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.regions,
            title: "settings.TITLE_REGIONS",
            active: location => getIsActive(location, TYPE_SECTION_MODEL.regions)
        }),
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.serviceCities,
            title: "settings.SERVICE_CITIES_TITLE",
            active: location =>
                getIsActive(location, TYPE_SECTION_MODEL.serviceCities)
        }),
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.namedPlaces,
            title: "settings.NAMED_PLACES_TITLE",
            active: location => getIsActive(location, TYPE_SECTION_MODEL.namedPlaces)
        }),
    ]
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3]
    );
}
