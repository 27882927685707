import {ADMIN_ORDER_FILTER_TYPE} from "../../../utils/enums.js";
import moment from "moment/moment.js";
import {
    DATE_FORMAT,
    getFormattedDate,
    prepareEndDate,
    prepareStartDate, setStartTime
} from "../../../utils/convertTime.js";
import IconButton from "../IconButton/IconButton.jsx";
import DatePicker from "../DatePicker/DatePicker.jsx";
import React from "react";
import {useTranslation} from "react-i18next";
import './index.scss';

const AdminOrderDateSearchFilter = ({type, onClose, onClear, value}) => {
    const {t} = useTranslation();

    const handleOnDateChange = newValue => {
        let date = newValue;

        if(!date) {
            return new Promise(resolve=> {
                resolve();
                onClose();
            });
        }

        switch (type) {
            case ADMIN_ORDER_FILTER_TYPE.dateStart:
                date = prepareStartDate(newValue);
                break;
            case ADMIN_ORDER_FILTER_TYPE.dateEnd:
                date = prepareEndDate(newValue);
                break;
            default:
                break;
        }

        return new Promise(resolve=> {
            resolve();
            onClose(type, date);
        });
    }

    return (
        <DatePicker
            classNames={'adminOrderDateSearchFilter'}
            value={value || (type === ADMIN_ORDER_FILTER_TYPE.dateStart ? setStartTime(moment()) : prepareEndDate(moment()))}
            isIcon
            onSave={handleOnDateChange}
            iconClassNames={'adminOrderDateSearchFilterIcon'}
            iconRender={(
                <div>
                    {type === ADMIN_ORDER_FILTER_TYPE.dateStart && (<div className={'timeData'}>
                        {value
                            ? getFormattedDate(value, DATE_FORMAT)
                            : t("admin-orders.ADMIN_ORDERS_SEARCH_DATE_START_LABEL")
                        }
                        {!!value && (
                            <IconButton isIcon={true} iconName={"iconDelet"} onClick={onClear} />
                        )}
                    </div>)}
                    {type === ADMIN_ORDER_FILTER_TYPE.dateEnd && (<div className={'timeData'}>
                        {value
                            ? getFormattedDate(value, DATE_FORMAT)
                            : t("admin-orders.ADMIN_ORDERS_SEARCH_DATE_END_LABEL")
                        }
                        {!!value && (
                            <IconButton isIcon={true} iconName={"iconDelet"} onClick={onClear} />
                        )}
                    </div>)}
                </div>
            )}
        />
    )
}

export default AdminOrderDateSearchFilter;