import React from "react";
import "./style.scss";
import {getDepotNameByBusinessId, getDepotNameById} from "../../../services/restaurant";
import {getOrderSource} from "../../../utils/order.js";
import {fixedNumber} from "../../../utils/math-util.js";

const CustomerSalesTopTenList = ({ statistics, menu }) => {
    const getName = (s) => {
        if(s.name) {
            return s.name;
        }
        if(menu) {
            const find = menu && menu.flatMap(item => item.items.flatMap(el => el.items)).find(el => el.id === s.id);
            return (find ? find.name : getDepotNameByBusinessId(s.id)) || getDepotNameById(s.depot_id);
        }

        return getDepotNameByBusinessId(s.id) || getDepotNameById(s.depot_id);
    }
    return (
        <tbody className={"customerSalesTopTenList"}>
            {statistics.map(s => (
                <tr key={`${s.id}-${s.order_source ?? 'empt'}-${s.order_source_id ?? 'empt'}-${s.count ?? 'empt'}-${s.total ?? 'empt'}-${s.order_channel ?? 'empt'}`} className={"listItem"}>
                    <td className={"leftSide"}>
                        <div className={"name"}>{getName(s)}</div>
                    </td>
                    {false && (<div className={"item"}>{s.items.toFixed(2)}</div>)}
                    <td className={"source"}>{getOrderSource(s.order_source, s.order_source_id)}</td>
                    <td className={"quantity"}>{fixedNumber(s.count, 2)}</td>
                    <td className={"income"}>{fixedNumber(s.total, 2)}</td>
                </tr>
            ))}
        </tbody>
    )
};

export default CustomerSalesTopTenList;