import React, {useEffect, useState} from "react";
import classNames from "classnames";
import SearchBox from "../SearchBox/SearchBox";
import {useHistory, withRouter} from "react-router";
import {connect} from "react-redux";
import AdminOrderSearchFilterControl from "../AdminOrderSearchFilterControl/AdminOrderSearchFilterControl";
import AdminOrderItem from "../AdminOrderItem/AdminOrderItem";
import AdminOrderInfo from "../AdminOrderInfo/AdminOrderInfo";
import RefundOrder from "../RefundOrder/RefundOrder";
import CancelAdminOrder from "../CancelAdminOrder/CancelAdminOrder";
import {RefundDetails} from "../RefundsDetails/RefundDetails";
import {createAction} from "../../../utils/sagaHelper";
import {
    actionAdminOrderGetOrderInfo,
    ADMIN_ORDER_GET,
    ADMIN_ORDER_RESHIPMENT,
    setAdminOrderSelectedOrderId,
    setFilterAdminOrders
} from "../../../store/reducers/order/order-actions";
import {BUSINESS_MENU_GET, GIFT_CARD_GET} from "../../../store/reducers/restaurant/restaurant-actions";
import {getOrderMenuProducts} from "../../../services/restaurant";
import {
    ADMIN_ORDER_FILTER_TYPE,
    BASIC_PATH, CONTROL_TYPE, CUSTOMER_SERVICE_MODEL,
    EXTERNAL_COURIER_PROVIDER,
    MODEL_TYPE,
    PAY_METHOD,
    WAITER_TYPES
} from '../../../utils/enums';
import {getISOTime, prepareEndDate, prepareStartDate} from "../../../utils/convertTime";
import {getHistoryLocationParams} from "../../../utils/routesHelper";
import {useTranslation} from "react-i18next";
import "./style.scss";
import CapturePaymentOrder from "../CapturePaymentOrder";
import AddDish from '../AddDish/index.js';
import {getInitModel} from '../../../services/initModels.js';
import {toast} from 'react-toastify';
import AutoSizer from "react-virtualized-auto-sizer";
import {List} from "react-virtualized";
import Waiter from "../Waiter/Waiter.jsx";
import {editCustomerOrderModel} from "../../../models/editCustomerOrderModel.js";
import {
    REPEAT_ORDER,
    resetCustomerOrderEditInfo
} from "../../../store/reducers/editCustomerOrder/editCustomerOrder-action.js";
import {useInterval} from "../../../services/hooks.js";
import environment from 'environment';
import FilterTriggerControl from "../FilterTriggerControl/index.js";
import OrdersFilter from "../OrdersFilter/OrdersFilter.jsx";
import {adminOrderFilterModel, getQuantityFilters} from "../../../services/filter.js";

const rowHeightDefault = 130;

const initFilter = {
    businessId: null, end: null, deliveryStrategy: null,
    start: null, status: null, territory: null, search: null
}

const CustomerServiceOrders = ({
    location,
    isMobile,
    adminOrders,
    territories,
    setFilter,
    filter,
    selectedOrderId, adminOrdersLoading,
    setSelectedOrderId, reshipmentOrder, onRepeatOrder,
    getAdminOrders, getOrderInfo, getGiftCardInfo, enableCallCenterMode, getBusinessMenu, saveOrderToEdit,
}) => {
    const history = useHistory();
    const {t} = useTranslation();
    const [orderRefundId, setOrderRefundId] = useState(null);
    const [isOpenRefund, setIsOpenRefund] = useState(false);
    const [refundId, setRefundId] = useState(null);
    const [reshipmentOpen, setReshipmentOpen] = useState(false);
    const [refundOpen, setRefundOpen] = useState(false);
    const [cancelOrderOpen, setCancelOrderOpen] = useState(false);
    const [searchId, setSearchId] = useState("");
    const [filterType, setFilterType] = useState(null);
    const [capturePaymentId, setCapturePaymentId] = useState(null);

    const order = selectedOrderId && adminOrders.find(f=> f.id === selectedOrderId);
    const orderMenuProduct = getOrderMenuProducts(order);
    const reshipmentOrderValue = (orderMenuProduct && getInitModel(MODEL_TYPE.reshipmentOrder, {
        currency: orderMenuProduct.currency,
        products: orderMenuProduct.items
    })) || {};

    const [isFilterOpen, setFilterOpen] = useState(false);
    const [filterModel, setFilterModel] = useState(filter || adminOrderFilterModel());

    useInterval(() => {
        getAdminOrders(getFiltersValue(filterModel, {search: searchId, rememberOrder: order})).then((orders) =>  {
            const order = orders.items?.[0];
            const find = orders.items.find(o => o.id === selectedOrderId);
            if(find) {
                handleOnSelect(find.id);
            } else {
                handleOnSelect(order.id);
            }
        });
    }, environment.requestCustomerServiceOrders);

    useEffect(()=> {
        getGiftCardInfo();

        // eslint-disable-next-line
    }, [getGiftCardInfo]);

    useEffect(()=> {
        const searchStr = getHistoryLocationParams(location.search).searchStr;
        if(searchStr) {
            let newFilter = {};
            for(let key in filterModel.options) {
                if(![CONTROL_TYPE.dateLink, CONTROL_TYPE.link, CONTROL_TYPE.groupCheckbox].includes(filterModel.options[key].type)) {
                    newFilter = onSalesFilterChange(key, "selected", null);
                }
            }
            setSearchId(searchStr);
            getAdminOrders(getFiltersValue(newFilter, {search: searchStr})).then(orders=> {
                if(((orders || {}).items || [])[0]) {
                    setSelectedOrderId(orders.items[0].id);
                    getOrderInfo(orders.items[0].id);
                }
            });
        } else {
            getAdminOrders(getFiltersValue({...filterModel})).then(orders=> {
                if(((orders || {}).items || [])[0]) {
                    setSelectedOrderId(orders.items[0].id);
                    getOrderInfo(orders.items[0].id);
                }
            });
        }

        // eslint-disable-next-line
    }, []);

    const [init, setInit] = useState(true);
    useEffect(() => {
        if(init) {
            return setInit(false);
        }
        if(!isFilterOpen) {
            updateAdminOrders(filterModel);
            setFilter(filterModel);
        }
        // eslint-disable-next-line
    }, [isFilterOpen]);

    const handleOnSelect = id => {
        setSelectedOrderId(id);
        getOrderInfo(id);
    }

    const handleOnClickPrevoius = () => {
        const findOrder = adminOrders[adminOrders.findIndex(f=> f.id === selectedOrderId) - 1];
        const id = !findOrder ? adminOrders.length && adminOrders[adminOrders.length - 1].id : findOrder.id;

        handleOnSelect(id);
    }

    const handleOnClickNext = () => {
        const findOrder = adminOrders[adminOrders.findIndex(o=> o.id === selectedOrderId) + 1];
        const id = !findOrder ? adminOrders.length && adminOrders[0].id : findOrder.id;

        handleOnSelect(id);
    }

    const handleOnSearch = value => {
        handleOnFilterSearch(ADMIN_ORDER_FILTER_TYPE.search, value);
        setSearchId(value);
    }

    const handleOnFilterSearch = (type, value) => {
        if(type) {
            let newFilter = { ...filter };
            let updatedFilter = {...filterModel};
            switch (type) {
                case ADMIN_ORDER_FILTER_TYPE.search:
                    newFilter.search = value;
                    break;
                case ADMIN_ORDER_FILTER_TYPE.dateEnd:
                    updatedFilter = onSalesFilterChange('end', 'selected', value);
                    break;
                case ADMIN_ORDER_FILTER_TYPE.dateStart:
                    updatedFilter = onSalesFilterChange('start', 'selected', value);
                    break;
                default:
                    break;
            }

            setFilter(updatedFilter);
            updateAdminOrders(updatedFilter, newFilter.search);
        } else {
            setFilterType(null);
        }
    }

    const getFiltersValue = (filter, additionalOptions = {}) => {
        const updatedFilter = {...initFilter};
        for(const key in filter.options) {
            updatedFilter[key] = filter.options[key].selected;
        }
        return {...updatedFilter, ...additionalOptions}
    }
    const updateAdminOrders = (filter, search) => {
        const updatedFilter = getFiltersValue(filter || filterModel);
        getAdminOrders({
            ...updatedFilter,
            search: search ?? updatedFilter.search ?? searchId,
            start: updatedFilter.start && getISOTime(updatedFilter.start),
            end: updatedFilter.end && getISOTime(updatedFilter.end),
        }).then(orders=> {
            if(orders && orders.items && orders.items[0]) {
                setSelectedOrderId(orders.items[0].id);
                getOrderInfo(orders.items[0].id);
            }
        });
    }

    const handleOnClearSearch = type => {
        return new Promise(resolve=> {
            resolve();
            switch (type) {
                case ADMIN_ORDER_FILTER_TYPE.dateStart:
                case ADMIN_ORDER_FILTER_TYPE.dateEnd:
                case ADMIN_ORDER_FILTER_TYPE.search:
                    handleOnFilterSearch(type, "");
                    break;
                default:
                    break;
            }
            setFilterType(null);
        });
    }

    const handleOnReshipment = () => {
        setReshipmentOpen(true);
        getBusinessMenu(order.business_id);
    }

    const handleOnReshipmentClose = () => {
        return new Promise(resolve=> {
            resolve();
            setReshipmentOpen(false);
        });
    }

    const handleOnSave = (data) => {
        return new Promise((resolve, reject) => {
            resolve();
            reshipmentOrder(data).then(() => {
                resolve();
                handleOnReshipmentClose();
            }, error => {
                reject(error);
                toast(error);
            });
        })
    }

    const handleOnRefund = (id) => {
        setRefundOpen(true);
        setRefundId(id);
    }

    const handleOnRefundClose = () => {
        return new Promise(resolve=> {
            resolve();
            setRefundOpen(false);
        });
    }

    const handleOnCancelOrder = () => {
        setCancelOrderOpen(true);
    }

    const handleOnCancelOrderClose = () => {
        return new Promise(resolve=> {
            resolve();
            setCancelOrderOpen(false);
        });
    }

    const handleOnClose = () => {
        setSelectedOrderId(null);
    }

    const handleOnSetEditOrder = (orderId) => {
        return new Promise((resolve, reject) => {
            saveOrderToEdit({
                ...editCustomerOrderModel({
                    ...order,
                    // products: order.items
                }),
                orderInfo: order,
            })
            history.push(BASIC_PATH.editCustomerOrder + "?orderId=" + orderId);
            resolve();
        })
    }
    const handleOnRepeatOrder = (orderId) => {
        return new Promise((resolve, reject) => {
            onRepeatOrder({ ...order, saveUserData: true });
            history.push("/create-order");
            resolve();
        })
    }

    const handleOnSetType = type => setFilterType(type);

    const handleOnSearchId = () => {
        if(order.customer_id) {
            history.push(CUSTOMER_SERVICE_MODEL.customers + "?userId=" + order.customer_id);
        }
    }

    const getRefundsById = refundId => {
        return (refundId &&
            ((order || {}).refunds || []).filter(r => r.payment_id === refundId)
        ) || [];
    }

    const closeRefundLayout = () => {
        setOrderRefundId(null);
        setIsOpenRefund(false);
    }

    const onOpenRefunds = id => {
        setOrderRefundId(id);
        setIsOpenRefund(true);
    }

    const getMaxAmount = paymentId => {
        return (orderMenuProduct.payments || []).find(p => p.id === paymentId).refund_remainder || order.refund_remainder;
    }

    const handleOnCapture = paymentId => {
        setCapturePaymentId(paymentId);
    }

    const getPayment = paymentId => {
        return (orderMenuProduct.payments || []).find(p => p.id === paymentId);
    }

    const isBonusRefund = () => {
        const payment = orderMenuProduct.payments.find(p => p.id === refundId);
        if(payment) {
            return payment.paymethod_id === PAY_METHOD.cash
                || (payment.paymethod_id === PAY_METHOD.bonus && !payment.reference_Id)
                || (payment.paymethod_id === PAY_METHOD.creditCard && payment.provider === EXTERNAL_COURIER_PROVIDER.external);
        } else {
            return false;
        }
    }

    const onSalesFilterChange = (key, prop, value) => {
        const filter = filterModel;
        if(filter.options[key]) {
            filter.options[key][prop] = key === 'start'
                ? (value ? prepareStartDate(value) : null)
                : key === 'end'
                    ? (value ? prepareEndDate(value) : null)
                    : value;
        }
        setFilterModel(filter);
        return filter;
    }

    return (
        <div className={"adminOrders"}>
            <div className={classNames("adminOrdersItemsContainer")}>
                <div className={classNames("adminOrdersFilterWrapper", {
                    isOpen: isFilterOpen,
                })}>
                    <OrdersFilter
                        filterOptionsModel={filterModel}
                        onChangeOption={onSalesFilterChange}
                        onClose={() => setFilterOpen(false)}
                    />
                </div>
                <div className={classNames("adminOrdersItemsheader")}>
                    <div className={classNames("adminOrdersHeader")}>
                        {enableCallCenterMode ? (
                            <div className={"createNewOrder"} onClick={() => history.push("/create-order")}>
                                <Waiter type={WAITER_TYPES.span}
                                        spanText={"+"}
                                        tooltipText={t("orders.CREATE_ORDER_OR_BULK")}
                                />
                            </div>
                        ) : <div/>}
                        <span className={"adminOrdersItemsheaderCenter"}>
                            <h4>{t("admin-orders.ADMIN_ORDERS_ITEMS_LABEL")}</h4>
                            <FilterTriggerControl filterCounter={getQuantityFilters(filterModel?.options || [])} onClick={() => setFilterOpen(true)}/>
                        </span>
                        <div className={"listLengthContainer"}>
                            <div className={"listLength"}>{adminOrders.length}</div>
                            {adminOrdersLoading && (
                                <div className={classNames("loaderContainer")}>
                                    <div className={classNames("loader")} />
                                </div>
                            )}
                        </div>
                    </div>
                    <SearchBox placeholder={t("orders.SEARCH_PLACEHOLDER")} useValue={searchId} onChange={handleOnSearch} />
                    <AdminOrderSearchFilterControl
                        filter={getFiltersValue(filterModel)}
                        filterType={filterType}
                        onSetType={handleOnSetType}
                        onClearSearch={handleOnClearSearch}
                        onFilterSearch={handleOnFilterSearch}
                    />
                </div>

                <div className={classNames("adminOrdersItems")}>
                    {adminOrders && !!adminOrders.length && (
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                        className={classNames('listContent')}
                                        width={width}
                                        height={height}
                                        rowHeight={rowHeightDefault}
                                        rowRenderer={({ index, key, style }) => {
                                            const adminOrder = adminOrders[index];
                                            return (
                                                <div key={key} style={style}>
                                                    <AdminOrderItem value={adminOrder}
                                                                    selected={selectedOrderId && adminOrder.id === selectedOrderId}
                                                                    isMobile={isMobile}
                                                                    isAdminOrder={true}
                                                                    filterStatus={filterModel.options.status.selected}
                                                                    onClick={handleOnSelect}
                                                    />
                                                </div>
                                            )
                                        }}
                                        rowCount={adminOrders.length}
                                        overscanRowCount={3}
                                    />
                                );
                            }}
                        </AutoSizer>
                    )}
                </div>
            </div>
            {selectedOrderId && order && (
                <AdminOrderInfo isMobile={isMobile} order={orderMenuProduct}
                                territories={territories}
                                openRefundsById={onOpenRefunds}
                                onClickPrevious={handleOnClickPrevoius}
                                onClickNext={handleOnClickNext}
                                onReshipment={handleOnReshipment}
                                onRefund={handleOnRefund}
                                onCancelOrder={handleOnCancelOrder}
                                onClose={handleOnClose}
                                onSearchId={handleOnSearchId}
                                setEditOrder={handleOnSetEditOrder}
                                onRepeatOrder={handleOnRepeatOrder}
                                onCapture={handleOnCapture}
                />
            )}
            {(!adminOrders || !adminOrders.length) && (
                <div className={classNames("emptyContainer")}>
                    {t("admin-orders.ADMIN_ORDERS_EMPTY_ITEMS_LABEL")}
                </div>
            )}
            <div className={classNames("layoutContainer", { open: refundOpen || cancelOrderOpen || isOpenRefund || !!capturePaymentId || reshipmentOpen })}>
                {reshipmentOpen && (
                  <AddDish fromEditMode={true}
                           defOrder={order}
                           value={reshipmentOrderValue}
                           onClose={handleOnReshipmentClose}
                           quantityFromZero={true}
                           withReshipments={true}
                           headerLabelEditing={t("admin-orders.ADD_DISH_RESHIPMENT_ORDER")}
                           onSave={handleOnSave}
                  />
                )}
                {refundOpen && (
                    <RefundOrder
                        id={orderMenuProduct.id}
                        maxAmount={getMaxAmount(refundId)}
                        onClose={handleOnRefundClose}
                        paymentId={refundId}
                        bonusRefund={isBonusRefund()}
                    />
                )}
                {cancelOrderOpen && (
                    <CancelAdminOrder id={orderMenuProduct.id} onClose={handleOnCancelOrderClose} />
                )}
                {isOpenRefund && (
                    <RefundDetails
                        refunds={getRefundsById(orderRefundId)}
                        onClose={closeRefundLayout}
                        currency={order.currency}
                    />
                )}
                {!!capturePaymentId && (
                    <CapturePaymentOrder orderId={orderMenuProduct.id} onClose={() => setCapturePaymentId(null)} payment={getPayment(capturePaymentId)}/>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        adminOrders: (state.order.data.adminOrders && state.order.data.adminOrders.items) || [],
        adminOrdersLoading: state.order.data.adminOrdersLoading,
        enableCallCenterMode: state.restaurant.data.configuration && state.restaurant.data.configuration.enableCallCenterMode,
        territories: state.restaurant.data.territories,
        filter: state.order.data.filter,
        selectedOrderId: state.order.data.selectedOrderId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSelectedOrderId: data=> dispatch(setAdminOrderSelectedOrderId(data)),
        getAdminOrders: data=> dispatch(createAction(ADMIN_ORDER_GET, data)),
        getGiftCardInfo: ()=> dispatch(createAction(GIFT_CARD_GET)),
        getOrderInfo: data=> dispatch(actionAdminOrderGetOrderInfo(data)),
        reshipmentOrder: data=> dispatch(createAction(ADMIN_ORDER_RESHIPMENT, data)),
        getBusinessMenu: data => dispatch(createAction(BUSINESS_MENU_GET, data)),
        setFilter: data => dispatch(setFilterAdminOrders(data)),
        saveOrderToEdit: data => dispatch(resetCustomerOrderEditInfo(data)),
        onRepeatOrder: data => dispatch(createAction(REPEAT_ORDER, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerServiceOrders));
