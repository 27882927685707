import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { getExternalCycleDriverPhone } from "../../../api/user-requests";
import { getExternalCycleDriverInfo } from "../../../services/restaurant";
import { useOnClickOutside } from "../../../services/hooks";
import { requestResultIsOk } from "../../../utils/request-util";
import { objectKeysToUpperLowerCase } from "../../../utils/objects-util";

import TimeValidControl from "../TimeValidControl";

const ExternalCycleDriverInfo = ({ t, id, externalCycles, isHideTimeValidCheck = false, onClose }) => {
    const targetRef = useRef();
    const [info, setInfo] = useState(null);
    const group = id && externalCycles.find(externalCycle=> externalCycle.id === id);

    useOnClickOutside(targetRef, () => {
        onClose && onClose();
    });

    useEffect(()=> {
        if(id) {
            getExternalCycleDriverPhone(id).then(requestResult=> {
                if(requestResultIsOk(requestResult, true)) {
                    setInfo(objectKeysToUpperLowerCase(requestResult.data));
                }
            });
        }        
    }, [id]);
    
    return (
        <div ref={targetRef} className={classNames("externalCycleDriverInfo", { hide: !id || !group })}>
            <div className={classNames("title")}>{getExternalCycleDriverInfo(group)}</div>
            {info && (
                <div className={classNames("info")}>
                    <a href={`tel:${info.phone}`} className={classNames("btn", "confirm")}>{info.phone}</a>
                    <div className={classNames("ext")}>{t("externalCycles.DRIVER_INFO_EXT_LABEL")}:&nbsp;{info.ext}</div>
                </div>
            )}
            {info && !isHideTimeValidCheck && (<TimeValidControl value={info.ttlSeconds} onFinish={onClose} />)}
        </div>
    );
}

const mapStateToProps = state => {
    return {
      externalCycles: state.externalCycles.data || []
    };
};

export default connect(mapStateToProps)(withTranslation()(ExternalCycleDriverInfo));