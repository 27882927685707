import React, {useState} from "react";
import "./style.scss";
import {SelectorUsersForChat} from "../SelectorUsersForChat/SelectorUsersForChat";
import classnames from "classnames";
import {useTranslation} from "react-i18next";

export const CreateNewDialog = ({onClose, onSave, hideSubject, confirmLabel, title, canChooseOneUser}) => {
    const {t} = useTranslation();
    const [subject, setSubject] = useState("");
    const [users, setUsers] = useState([]);

    const handleOnSave = () => {
        if(!!users.length) {
            onSave({subject, users});
        }
    }

    return (
        <div className={"createNewDialog"}>
            <div className={"createNewDialogBody"}>
                <span className={"title"}>{title}</span>
                {!hideSubject && (
                    <input
                        className={"subjectInput"}
                        placeholder={t("support-chat.createNew.THEME")}
                        type="text"
                        value={subject}
                        onChange={({target}) => setSubject(target.value)}
                    />
                )}
                <SelectorUsersForChat
                    canChooseOneUser={canChooseOneUser}
                    disableConsumers
                    selectedUsersList={users}
                    onChange={setUsers}
                />
            </div>

            <div className={"footer"}>
                <div className={"footerControl reject"} onClick={onClose}>{t("support-chat.createNew.CLOSE")}</div>
                <div
                    onClick={handleOnSave}
                    className={classnames("footerControl", {disable: !users.length})}
                >{confirmLabel}</div>
            </div>
        </div>
    )
}