import React, { useState } from "react";
import classNames from "classnames";
import "./index.scss";

import { getDefaultReviewsFilter, DEFAULT_PERIOD_STATE } from "../../../utils/constants";
import { ADMIN_ORDER_FILTER_TYPE,  PERIOD_TYPE, getPeriodTypeTranslate } from "../../../utils/enums";
import { getPeriodByType, prepareEndDate } from "../../../utils/convertTime";

import {AdminOrderDateSearchFilter} from "../AdminOrderDateSearchFilter/index.js";

const CustomerReviewSearchPeriod = ({ filter, onSetFilter }) => {
    const [filterStart, setFilterStart] = useState(false);
    const [filterEnd, setFilterEnd] = useState(false);

    const handleOnSelectPeriod = type => {
        const newValue = !filter.periodFilter[type];
        onSetFilter(newValue 
            ? { 
                ...filter, 
                ...getPeriodByType(type),
                periodFilter: type
                    ? { ...DEFAULT_PERIOD_STATE, [type]: newValue }
                    : DEFAULT_PERIOD_STATE
            } 
            : { ...getDefaultReviewsFilter(), rating: filter.rating, deliveryRating: filter.deliveryRating }
        );
    }

    const handleOnSelectToday = () => {
        handleOnSelectPeriod(PERIOD_TYPE.today);
    }

    const handleOnSelectYesterday = () => {
        handleOnSelectPeriod(PERIOD_TYPE.yesterday);
    }

    const handleOnSelectWeek = () => {
        handleOnSelectPeriod(PERIOD_TYPE.week);
    }

    const handleOnSelectMonth = () => {
        handleOnSelectPeriod(PERIOD_TYPE.month);
    }

    const handleOnSelectPreviousMonth = () => {
        handleOnSelectPeriod(PERIOD_TYPE.prevMonth);
    }

    const handleOnSelectYear = () => {
        handleOnSelectPeriod(PERIOD_TYPE.year);
    }

    const handleOnFilterStart = () => {
        if(!filterStart) setFilterStart(true);
    }

    const handleOnFilterEnd = () => {
        if(!filterEnd) setFilterEnd(true);
    }
    
    const handleOnFilterDateChange = (type, value) => {
        switch (type) {
            case ADMIN_ORDER_FILTER_TYPE.dateStart:
                onSetFilter({
                    ...filter, 
                    start: value,
                    periodFilter: DEFAULT_PERIOD_STATE
                });
                setFilterStart(false);
                break;
            case ADMIN_ORDER_FILTER_TYPE.dateEnd:
                onSetFilter({ 
                    ...filter, 
                    end: prepareEndDate(value),
                    periodFilter: DEFAULT_PERIOD_STATE
                });
                setFilterEnd(false);
                break;
            default:
                setFilterStart(false);
                setFilterEnd(false);
                break;
        }
    }

    const handleOnClearStart = () => {
        return new Promise(resolve=> {
            onSetFilter({ ...filter, start: null });
            resolve();
        });
    }

    const handleOnClearEnd = () => {
        return new Promise(resolve=> {
            onSetFilter({ ...filter, end: null });
            resolve();
        });
    }
    
    return (
        <div className={classNames("customerReviewSearchPeriod")}>
            <div className={classNames("periodButtons")}>
                <button className={classNames("btn", "denied", "periodItem", { active: !!filter.start})}
                    onClick={handleOnFilterStart}
                >
                    <AdminOrderDateSearchFilter
                        value={filter.start}
                        onClose={handleOnFilterDateChange}
                        onClear={handleOnClearStart}
                        type={ADMIN_ORDER_FILTER_TYPE.dateStart}
                    />
                </button>
                <button className={classNames("btn", "denied", "periodItem", { active: !!filter.end})}
                    onClick={handleOnFilterEnd}
                >
                    <AdminOrderDateSearchFilter
                        value={filter.end}
                        onClose={handleOnFilterDateChange}
                        onClear={handleOnClearEnd}
                        type={ADMIN_ORDER_FILTER_TYPE.dateEnd}
                    />
                </button>
            </div>
            <div className={classNames("periodItems")}>
                <div className={classNames("periodItem", { active: filter.periodFilter[PERIOD_TYPE.today] })} onClick={handleOnSelectToday}>
                    {getPeriodTypeTranslate(PERIOD_TYPE.today)}
                </div>
                <div className={classNames("periodItem", { active: filter.periodFilter[PERIOD_TYPE.yesterday] })} onClick={handleOnSelectYesterday}>
                    {getPeriodTypeTranslate(PERIOD_TYPE.yesterday)}
                </div>
                <div className={classNames("periodItem", { active: filter.periodFilter[PERIOD_TYPE.week] })} onClick={handleOnSelectWeek}>
                    {getPeriodTypeTranslate(PERIOD_TYPE.week)}
                </div>
                <div className={classNames("periodItem", { active: filter.periodFilter[PERIOD_TYPE.month] })} onClick={handleOnSelectMonth}>
                    {getPeriodTypeTranslate(PERIOD_TYPE.month)}
                </div>
                <div className={classNames("periodItem", { active: filter.periodFilter[PERIOD_TYPE.prevMonth] })} onClick={handleOnSelectPreviousMonth}>
                    {getPeriodTypeTranslate(PERIOD_TYPE.prevMonth)}
                </div>
                <div className={classNames("periodItem", { active: filter.periodFilter[PERIOD_TYPE.year] })} onClick={handleOnSelectYear}>
                    {getPeriodTypeTranslate(PERIOD_TYPE.year)}
                </div>
            </div>
        </div>
    );
}

export default CustomerReviewSearchPeriod;