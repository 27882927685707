import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { PENDING_ORDER_TYPE } from "../../../utils/enums";
import { defaultPromiseResolve } from "../../../utils/objects-util";

import IconButton from "../IconButton";

const OrderTypeInfo = ({ t, type }) => {
    return (
        <div className={classNames("orderTypeInfo")}>
            {type === PENDING_ORDER_TYPE.delivery && (
                <IconButton onClick={defaultPromiseResolve} isIcon={true} iconName="carbonScooter" 
                    tooltipText={t("create-order.DELIVERY")} 
                />
            )}
            {type === PENDING_ORDER_TYPE.takeAway && (
                <IconButton onClick={defaultPromiseResolve} isIcon={true} iconName="carbonTakeaway" 
                    tooltipText={t("create-order.TAKEAWAY")} 
                />
            )}
            {type === PENDING_ORDER_TYPE.dinein && (
                <IconButton onClick={defaultPromiseResolve} isIcon={true} iconName="dinein"
                    tooltipText={t("create-order.DINEIN")}
                />
            )}
            {type === PENDING_ORDER_TYPE.delivery && (
                <div className={classNames("title")}>&nbsp;&nbsp;{t("create-order.DELIVERY")}</div>
            )}
            {type === PENDING_ORDER_TYPE.takeAway && (
                <div className={classNames("title")}>&nbsp;&nbsp;{t("create-order.TAKEAWAY")}</div>
            )}
            {type === PENDING_ORDER_TYPE.dinein && (
                <div className={classNames("title")}>&nbsp;&nbsp;{t("create-order.DINEIN")}</div>
            )}
        </div>
    );
}

export default withTranslation()(OrderTypeInfo);