
import SectionModel from "../../models/sectionModel";
import FilterOptionsModel from "../../models/filterOptionsModel";

import { SEARCH_SHORTCUT_SETTING } from "../../store/reducers/restaurant/restaurant-actions";
import { CONTROL_TYPE, TYPE_SECTION_MODEL, MODEL_CONTROL_TYPE } from "../../utils/enums";
import { getSearchShortcutFilter } from "../filter";
import { createControlModel } from "../controlModelFactory";
import {isMobileWidth} from "../restaurant";
import {createBreadcrumbModel} from "../createBreadcrumbModel";

export function createSearchShortcutsSectionModel() {
  return new SectionModel({
    filterOptions: createSearchShortcutsSectionFilter(),
    columns: [
      {
        title: "settings.searchShortcutsSettings.NAME_LABEL",
        accessor: "name"
      }
    ],
    actionName: SEARCH_SHORTCUT_SETTING,
    typeModel: TYPE_SECTION_MODEL.searchShortcuts,
    controls: [
      createControlModel({ type: MODEL_CONTROL_TYPE.add, url: TYPE_SECTION_MODEL.searchShortcutsNew }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    label: "settings.searchShortcutsSettings.LABEL",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
      createBreadcrumbModel({ label: "settings.OPERATIONS_MANAGEMENT_LABEL" }),
    ],
    mappingLambda: state => ((state.restaurant.data.configuration && state.restaurant.data.configuration.searchShortcuts) || [])
        .map((searchShortcut, index)=> ({ id: index, name: searchShortcut }))
  });
}

export function createNewSearchShortcutSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "settings.searchShortcutsSettings.NAME_LABEL",
        accessor: "name",
        isEditable: true,
        type: CONTROL_TYPE.text
      }
    ],
    actionName: SEARCH_SHORTCUT_SETTING,
    initNewItemModel: () => ({ id: "", name: "" }),
    typeAction: args.typeAction,
    typeModel: TYPE_SECTION_MODEL.searchShortcutsNew,
    parentUrl: TYPE_SECTION_MODEL.searchShortcuts,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    label: "settings.searchShortcutsSettings.LABEL",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
      createBreadcrumbModel({ label: "settings.OPERATIONS_MANAGEMENT_LABEL" }),
    ],
    mappingLambda: state => ((state.restaurant.data.configuration && state.restaurant.data.configuration.searchShortcuts) || [])
        .map((searchShortcut, index)=> ({ id: index, name: searchShortcut }))
  });
}

function createSearchShortcutsSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(shortcut) {
    return getSearchShortcutFilter(this.selected, shortcut);
  });

  return filterOptionsModel;
}
