import SectionModel from "../../models/sectionModel.js";
import {
    CUSTOMER_SERVICE_MODEL,
    TYPE_ACTION,
} from "../../utils/enums.js";
import React from "react";
import CustomerServiceOrders from "../../app/components/CustomerServiceOrders/CustromerServiceOrders.jsx";
import CustomerReviews from "../../app/components/CustomerReviews/index.js";
import Customers from "../../app/components/Customers/index.js";
import CustomerSales from "../../app/components/CustomerSales/index.js";
import {CustomerServiceChat} from "../../app/components/CustomerServiceChat/CustomerServiceChat.jsx";

export function createCustomerServiceOrderSection() {
    return new SectionModel({
        columns: [
            {
                component: function() {
                    return <CustomerServiceOrders />;
                },
            },
        ],
        typeAction: TYPE_ACTION.edit,
        customSettings: 'adminOrderFixed',
        initNewItemModel: () => ({}),
        typeModel: CUSTOMER_SERVICE_MODEL.order,
        isHideControls: true,
        mappingLambda: state => ({}),
    });
}

export function createCustomerServiceReviewsSection() {
    return new SectionModel({
        columns: [
            {
                component: function() {
                    return <CustomerReviews />;
                },
            },
        ],
        typeAction: TYPE_ACTION.edit,
        customSettings: 'adminOrderFixed',
        initNewItemModel: () => ({}),
        typeModel: CUSTOMER_SERVICE_MODEL.order,
        isHideControls: true,
        mappingLambda: state => ({}),
    });
}

export function createCustomerServiceCustomersSection() {
    return new SectionModel({
        columns: [
            {
                component: function() {
                    return <Customers />;
                },
            },
        ],
        typeAction: TYPE_ACTION.edit,
        customSettings: 'adminOrderFixed',
        initNewItemModel: () => ({}),
        typeModel: CUSTOMER_SERVICE_MODEL.order,
        isHideControls: true,
        mappingLambda: state => ({}),
    });
}

export function createCustomerServiceSalesSection() {
    return new SectionModel({
        columns: [
            {
                component: function() {
                    return <CustomerSales />;
                },
            },
        ],
        typeAction: TYPE_ACTION.edit,
        customSettings: 'adminOrderFixed',
        initNewItemModel: () => ({}),
        typeModel: CUSTOMER_SERVICE_MODEL.sales,
        isHideControls: true,
        mappingLambda: state => ({}),
    });
}

export function createCustomerServiceSupportChatSection() {
    return new SectionModel({
        columns: [
            {
                component: function() {
                    return <CustomerServiceChat />;
                },
            },
        ],
        typeAction: TYPE_ACTION.edit,
        customSettings: 'adminOrderFixed',
        initNewItemModel: () => ({}),
        typeModel: CUSTOMER_SERVICE_MODEL.sales,
        isHideControls: true,
        mappingLambda: state => ({}),
    });
}