import i18n from "../../i18n";

import SectionModel from "../../models/sectionModel";
import FilterOptionsModel from "../../models/filterOptionsModel";
import {REGION_SETTING} from "../../store/reducers/restaurant/restaurant-actions";

import {CONTROL_TYPE, MODEL_CONTROL_TYPE, MODEL_TYPE, TYPE_ACTION, TYPE_SECTION_MODEL} from "../../utils/enums";
import {timeToMinutes} from "../../utils/convertTime";
import {getControlCenters, isMobileWidth} from '../restaurant';
import {getRegionFilter, sortItemsByProp} from "../filter";
import {getInitModel} from "../initModels";
import {createControlModel} from "../controlModelFactory";
import {createBreadcrumbModel} from "../createBreadcrumbModel";

export function createRegionsSectionModel() {
  return new SectionModel({
    filterOptions: createRegionsSectionFilter(),
    columns: [
      {
        title: "placeEditor.REGION_LABEL",
        accessor: "name"
      },
      {
        title: "settings.map.DEPOT_EDIT_DEFAULT_CONTROL_CENTER",
        accessor: "defaultControlCenterId",
        component: region => {
          const controlCenters = getControlCenters();
          const controlCenter =
            controlCenters.find(
              center => center.controlCenterId === region.defaultControlCenterId
            ) || {};
          return controlCenter.name || "";
        },
      },
      {
        title:
          i18n.t(
            "settings.settings.numericalSettings.MAX_DELIVERY_TIME_LABEL"
          ) + `, ${i18n.t("settings.map.TIME_MEASURE")}`,
        centered: true,
        accessor: "maxDeliveryTime",
        component: region => timeToMinutes(region.maxDeliveryTime),
      },
      {
        title: 'basic.EDIT_BUTTON',
        width: "90px",
        centered: true,
        type: CONTROL_TYPE.link,
        link: TYPE_SECTION_MODEL.regionsEdit,
        iconName: "editItem",
        tooltipText: i18n.t("settings.users.USER_TOOLS_EDIT_TOOLTIP")
      }
    ],
    actionName: REGION_SETTING,
    typeModel: TYPE_SECTION_MODEL.regions,
    tableMinWidth: 800,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.add,
        url: TYPE_SECTION_MODEL.regionsNew
      }),
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    label: "settings.TITLE_REGIONS",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
      createBreadcrumbModel({ label: "settings.GEO_SETTINGS_LABEL" }),
    ],
    mappingLambda: state => {
      const data = (state.restaurant.data.configuration && state.restaurant.data.configuration.regions) || [];
      return sortItemsByProp(data, "name");
    }
  });
}

export function createRegionsEditSectionModel({ ...args }) {
  return new SectionModel({
    columns: [
      {
        title: "placeEditor.REGION_LABEL",
        accessor: "name",
        isEditable: true,
        type: CONTROL_TYPE.text
      },
      {
        title: "settings.map.DEPOT_EDIT_DEFAULT_CONTROL_CENTER",
        accessor: "defaultControlCenterId",
        connect: state => ({
          values: [{ key: null, value: "-" }]
            .concat(state.restaurant.data.controlCenters || [])
            .map(center => ({
              key: center.controlCenterId,
              value: center.name
            }))
        }),
        isEditable: true,
        type: CONTROL_TYPE.select
      },
      {
        title: "settings.settings.numericalSettings.MAX_DELIVERY_TIME_LABEL",
        accessor: "maxDeliveryTime",
        measure: i18n.t("settings.map.TIME_MEASURE"),
        isEditable: true,
        type: CONTROL_TYPE.number
      },
      {
        title: "",
        accessor: "region",
        isEditable: true,
        isFillContent: true,
        boundsByRegion: true,
        type: CONTROL_TYPE.mapRegion
      }
    ],
    actionName: REGION_SETTING,
    initNewItemModel: item =>
        getInitModel(MODEL_TYPE.region, {
          ...item,
          maxDeliveryTime: timeToMinutes(
              item && item.maxDeliveryTime ? item.maxDeliveryTime : "00:00:00"
          ),
          pendingOrdersGroupingTimeDelta: timeToMinutes(
              item && item.pendingOrdersGroupingTimeDelta
                  ? item.pendingOrdersGroupingTimeDelta
                  : "00:00:00"
          ),
          setDefaultRegion: !item
        }),
    typeAction: args.typeAction,
    typeModel:
      args.typeAction === TYPE_ACTION.edit
        ? TYPE_SECTION_MODEL.regionsEdit
        : TYPE_SECTION_MODEL.regionsNew,
    parentUrl: TYPE_SECTION_MODEL.regions,
    controls: [
      createControlModel({
        type: MODEL_CONTROL_TYPE.back,
        isShow: isMobileWidth,
      }),
    ],
    label: "settings.TITLE_REGIONS",
    breadcrumbs: [
      createBreadcrumbModel({ label: "header.menu.SETTINGS_LABEL" }),
      createBreadcrumbModel({ label: "settings.GEO_SETTINGS_LABEL" }),
    ],
    mappingLambda: state => (state.restaurant.data.configuration && state.restaurant.data.configuration.regions) || []
  });
}

function createRegionsSectionFilter() {
  let filterOptionsModel = new FilterOptionsModel();

  filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function(
    region
  ) {
    return getRegionFilter(this.selected, region);
  });

  return filterOptionsModel;
}
