import React from 'react';
import './index.scss';
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const AdminOrderInfoGift = ({giftOption}) => {
    const {t} = useTranslation();
    return (
        <div className={classNames("adminOrderInfoItem adminOrderInfoGift")}>
            <div className={classNames("title")}>{t("uilib.gift.GIFT")}</div>
            <div className={"data"}>
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("uilib.gift.NAME")}</div>
                    <div>{giftOption.full_name || "-"}</div>
                </div>
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("uilib.gift.PHONE")}</div>
                    <div>{giftOption.phone || "-"}</div>
                </div>
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("uilib.gift.GREETING")}</div>
                    <div>{giftOption.greeting || "-"}</div>
                </div>
            </div>
        </div>
    )
}

export default AdminOrderInfoGift;