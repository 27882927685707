import React, { useState } from "react";
import RcSlider from "rc-slider";
import classNames from "classnames";
import "rc-slider/assets/index.css";
import "./index.scss";
import { isRTL } from "../../../services/userModel";

const Handle = RcSlider.Handle;
const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  const style = isRTL()
    ? {
      right: `calc(${restProps.offset}% - ${value > 9 ? 6 : 10}px)`,
      left: "auto"
    } 
    : {
      right: "auto",
      left: `calc(${restProps.offset}% - ${value > 9 ? 9 : 5}px)`
    }

  return (
    <div className={classNames("sliderPointer")}>
      <Handle value={value} {...restProps} />
      <div className={classNames("pointer")} style={style}>
        {value}
      </div>
    </div>
  );
};

const Slider = ({ min, max, value, title, onChange }) => {
  const [sliderValue, setSliderValue] = useState(value);

  const handleOnChange = value => {
    setSliderValue(value);
    onChange(value);
  };

  const marks = () => {
    if (value < min + 3) {
      return {
        [min]: "",
        [max]: max
      };
    }

    if (value > max - 3) {
      return {
        [min]: min,
        [max]: ""
      };
    }

    return {
      [min]: min,
      [max]: max
    };
  };

  return (
    <div className={classNames("sliderControl")}>
      {title && (<div className={classNames("title")}>{title}</div>)}
      <RcSlider
        reverse={isRTL()}
        min={min}
        max={max}
        marks={marks()}
        value={sliderValue}
        tipFormatter={value => `${value}`}
        handle={handle}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default Slider;
