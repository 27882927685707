import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { useOnClickOutside } from "../../../services/hooks";

const CourierInfoControl = ({ 
    t, 
    value, 
    component, 
    isShowCancel = true,
    onClose, onCancel 
}) => {
    const targetRef = useRef();
    const { phone, ext } = value || {};

    const handleOnCancelClick = () => onCancel && onCancel();

    const handleOnClose = () => onClose && onClose();

    useOnClickOutside(targetRef, () => handleOnClose());

    return (
        <div ref={targetRef} className={classNames("courierInfoControl", { isComponent: !!component })}>
            <div className={classNames("courierInfoControlItems")}>
                {!!component && component}
                {phone && !component && (
                    <div className={classNames("courierInfoControlItem")}>
                        <div className={classNames("title")}>{t("externalCycles.DRIVER_INFO_PHONE_LABEL")}:</div>
                        <div className={classNames("data")}>
                            {phone}
                            {ext && (
                                <div className={classNames("ext")}>{t("externalCycles.DRIVER_INFO_EXT_LABEL")}:&nbsp;{ext}</div>
                            )}
                        </div>
                    </div>
                )}
            </div>            
            <div className={classNames("controls")}>
                {!component && (
                    <a href={`tel:${phone}`} className={classNames("btn", "confirm")}>
                        {t("dialogs.couriers.CALL_COURIER_LABEL")}
                    </a>
                )}
                {isShowCancel && (
                    <button className={classNames("btn", "confirm")} onClick={handleOnCancelClick}>
                        {t("dialogs.couriers.CANCEL_COURIER_CYCLE")}
                    </button>
                )}
                <button className={classNames("btn", "denied", "close")} onClick={handleOnClose}>
                    {t("basic.CLOSE_BUTTON")}
                </button>
            </div>            
        </div>
    );
}

export default withTranslation()(CourierInfoControl);