import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import './index.scss';

import {getFullName, isRTL} from '../../../services/userModel';

import { ModalPopup } from '../ModalPopup/ModalPopup';
import { Tooltip } from '../Tooltip/Tooltip';
import InvitationPopup from '../InvitationPopup/InvitationPopup';

import search_blue_small from '../../../assets/images/icons/search_blue_small.png';
import { List } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import preloader from '../../../assets/images/preloader.GIF';
import {
  COURIER_PAGINATION,
  START_SHIFTS
} from '../../../store/reducers/userModel/userModel-actions.js';
import CourierShiftItem from './CourierShiftItem.jsx';
import usePagination from '../../hooks/usePagination.js';
import {POPUP_ITEM_TYPE} from '../../../utils/enums.js';
import PopupManagerItem from './CourierManagerItem.jsx';
import CourierShiftsFilterItem from './CourierShiftsFilterItem.jsx';
import {useTranslation} from "react-i18next";
import IconButton from "../IconButton/IconButton.jsx";
import ShiftCourierControlPopup from "../ShiftCourierControlPopup/index.js";
import {connect, useSelector} from "react-redux";
import {createAction} from "../../../utils/sagaHelper.js";

const CouriersShiftPopup = ({
  isOpen,
  onClose,
  selectedControlCenter, // Filtered users in popup by selected control center in dashboard
  controlCenterFilter, // Shifts -> history -> filter. Filtered users in popup by selected control center from filter
  activeControlCenter, // If the user role is not “Control Center Manager”, use the active control center everywhere.
  roles,
  onUserClick,
  itemType,
  startShifts,
  enableMultiMode
}) => {
  const {t} = useTranslation();
  const controlCenters = useSelector(state => state.restaurant.data.controlCenters);

  const [invitationPopupIsOpen, setInvitationPopupIsOpen] = useState(null);
  const [courier, setCourier] = useState(null);
  const [multiSelectMode, setMultiSelectMode] = useState(false);
  const [multiSelectedCourier, setMultiSelectedCourier] = useState([]);
  const [startShiftIsOpen, setStartShiftIsOpen] = useState(false);
  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const [code, setCode] = useState(null);

  const controlCenterId = controlCenterFilter !== undefined ? controlCenterFilter : selectedControlCenter || activeControlCenter;

  // pagination
  const wrapperRef = useRef(null);
  const itemHeight = 50;
  const itemsPerPage = +(wrapperRef?.current?.props?.height / itemHeight).toFixed(0) || 0;
  const { handleScroll, handleSearch, newItems: newUsers, searchString, isLoading, resetPagination } = usePagination({
    itemsPerPage,
    roles: roles,
    actionPagination: COURIER_PAGINATION,
    controlCenterId: controlCenterId,
  });
  // pagination end

  useEffect(() => {
    resetPagination()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAvailableControlCenters = () => {
    const find = (controlCenters || []).find(cc => cc.controlCenterId === controlCenterId);
    if(find) {
      return [find];
    }
    return [];
  }

  const handleInvitationPopup = () => {
    setInvitationPopupIsOpen(null);
    setCourier(null);
    setQrCodeSrc(null);
    setCode(null);
  };

  const popupItem = () => {
    switch (itemType) {
      case POPUP_ITEM_TYPE.managerItem:
        return PopupManagerItem;
      case POPUP_ITEM_TYPE.shiftsFilterItem:
        return CourierShiftsFilterItem;
      case POPUP_ITEM_TYPE.courierShiftItem:
        return CourierShiftItem;
      default:
        return CourierShiftItem;
    }
  };

  const toggleMultiMode = () => {
    if(multiSelectMode) {
      setMultiSelectMode(false);
      setMultiSelectedCourier([]);
    } else {
      setMultiSelectMode(true);
      setMultiSelectedCourier([]);
    }
  }

  const onSelectMultiUser = (user) => {
    const selectedUser = multiSelectedCourier.find(c => c.userId === user.userId);
    if(selectedUser) {
      setMultiSelectedCourier(couriers => couriers.filter(c => c.userId !== user.userId));
    } else {
      setMultiSelectedCourier(couriers => couriers.concat(user));
    }
  }

  const rowRenderer = ({ index, key, style }) => {
    const ItemComponent = popupItem();
    const user = newUsers[index];
    const selectedUser = multiSelectedCourier.find(c => c.userId === user.userId);

    return (
      <div key={`courier-container-item-${user}-${key}`} style={style}>
        <ItemComponent
          key={`courier-container-item-${user}`}
          onUserClick={onUserClick}
          user={user}
          controlCenter={selectedControlCenter}
          //for courier shift item multi start
          enableMultiMode={enableMultiMode}
          multiMode={multiSelectMode}
          onSelectMulti={() => onSelectMultiUser(user)}
          selected={!!selectedUser}
          shouldCheckMultiDisable
        />
      </div>
    );
  };

  const handleOnStartShift = data => {
    return startShifts({...data, courierIds: multiSelectedCourier.map(c => c.userId) })
        .then(() => {
          setStartShiftIsOpen(false);
          toggleMultiMode();
        });
  }

  return (
    <ModalPopup
      isOpen={isOpen}
      onClose={onClose}
      content={
        <div className={classNames('popupContainer', { rtl: isRTL(), multiStart: enableMultiMode, popupStart: true })}>
          <div className='searchCourier'>
            <div className='searchCourierContainer'>
              <input
                type='search'
                placeholder={t('couriers.SEARCH_PLACEHOLDER')}
                className='searchInput'
                value={searchString}
                onChange={e => handleSearch(e.target.value)}
              />
              <img src={search_blue_small} alt='search' className='searchIcon' />
            </div>
            {enableMultiMode && (
                <IconButton
                    isIcon
                    iconName={multiSelectMode ? "multiSelectIcon" : "multiSelectIconDisabled"}
                    tooltipText={t("dashboard.orders.MULTI_SELECT_MODE_TOOLTIP")}
                    onClick={toggleMultiMode}
                />
            )}
          </div>
          {enableMultiMode && (<div className={'selectedCouriers'}>
            {multiSelectedCourier.map(c =>
              <div key={`${c.userId}-selected-courier`} className={'selectedCourier'} onClick={() => onSelectMultiUser(c)}>
                {getFullName(c)}
                &nbsp;&nbsp;
                <IconButton isIcon iconName={'crossClose'}/>
              </div>
            )}
          </div>)}
          <div className={classNames('couriersContainer', 'verticalScroll')}>
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <List
                    ref={wrapperRef}
                    className={classNames('listContent')}
                    onScroll={event => handleScroll(event)}
                    width={width}
                    height={height}
                    rowHeight={itemHeight}
                    rowRenderer={rowRenderer}
                    rowCount={newUsers?.length}
                    overscanRowCount={3}
                  />
                );
              }}
            </AutoSizer>
          </div>
          <div className={classNames('couriersContainerFooter')}>
            <div className={classNames('btn', 'denied')} onClick={onClose}>
              {t('basic.CLOSE_BUTTON')}
            </div>
            {multiSelectMode && (
              <div className={classNames('btn', 'confirm', { disabled: !multiSelectedCourier.length })} onClick={() => setStartShiftIsOpen(true)}>
                {t('basic.APPLY_BUTTON')}
              </div>
            )}
          </div>
          <Tooltip />
          {invitationPopupIsOpen && (
            <InvitationPopup
              isOpen={invitationPopupIsOpen}
              courier={courier}
              qrCodeSrc={qrCodeSrc}
              code={code}
              onClose={handleInvitationPopup}
              isOverModal={true}
            />
          )}
          {isLoading && (
            <div className={classNames('loaderWrapper')}>
              <img className='preloader' src={preloader} alt='preloader' />
            </div>
          )}
          {startShiftIsOpen && (
              <ShiftCourierControlPopup
                  courier={{}}
                  selectedControlCenter={[]}
                  hanldeOnPopupClose={handleOnStartShift}
                  setControlCenterId={() => {}}
                  popupOpen={startShiftIsOpen}
                  controlCenterId={controlCenterId}
                  availableControlCenters={getAvailableControlCenters()}
                  onClose={() => setStartShiftIsOpen(false)}
              />
          )}
        </div>
      }
    />
  );
};

const mdtp = dispatch => {
  return {
    startShifts: data => dispatch(createAction(START_SHIFTS, data)),
  }
}

export default connect(null, mdtp)(CouriersShiftPopup);
