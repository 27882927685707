import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { isShowControlCenterSearch, getFullName, isRTL } from "../../../services/userModel";
import { getGroupOrders } from "../../../services/order";
import { COURIER_STATE, CYCLE_STATE, SHIFT_TYPE } from "../../../utils/enums";
import { getConvertedCloudUrl } from "../../../utils/image-util";

import defaultAvatar from "../../../assets/images/avatar.png";
import GrCode from "../GrCode";
import ShiftCourierControl from "../ShiftCourierControl";
import CheckBox from "../CheckBox/index.js";

const CourierShiftItem = ({
  t,
  user,
  users,
  orderModel,
  controlCenter,
  enableMultiMode,
  multiMode,
  selected,
  onSelectMulti,
  shouldCheckMultiDisable
}) => {
  if(!user) return null
  const userFromState = users.find(item => item.userId === user.userId);
  const courier = userFromState || user;
  const courierOrders = getGroupOrders(orderModel, courier.userId, true);

  const multiDisabled = enableMultiMode && shouldCheckMultiDisable && (courier.roles.active || courier?.cycle?.shiftType === SHIFT_TYPE.Normal);

  const getButtonsConfig = () => {
    const isSupervisorRole = isShowControlCenterSearch();
    let buttonsConfig = {};

    Object.keys(COURIER_STATE).forEach(key=> {
      buttonsConfig[key] = false;
    });

    if (courier.roles.active) {
      if (
        courier.cycle.state !== CYCLE_STATE.started &&
        courier.cycle.state !== CYCLE_STATE.away &&
        courier.cycle.state !== CYCLE_STATE.comingBack
      ) {
        buttonsConfig[COURIER_STATE.finishShift] = true;
        buttonsConfig[COURIER_STATE.cancelShift] = true;
        buttonsConfig[COURIER_STATE.goAwayShift] = !isSupervisorRole;
      } else if (courier.cycle.state === CYCLE_STATE.started) {
        buttonsConfig[COURIER_STATE.goAwayShift] = courierOrders && !!courierOrders.length;
      }
      if (courier.cycle.shiftType === SHIFT_TYPE.Normal) {
        buttonsConfig[COURIER_STATE.endShift] = true;
      } else {
        buttonsConfig[COURIER_STATE.startShift] = true;
        buttonsConfig[COURIER_STATE.endBackup] = true;
      }
    } else {
      buttonsConfig[COURIER_STATE.startShift] = true;
    }

    return buttonsConfig;
  };

  const buttonsConfig = getButtonsConfig();

  const getStateName = () => {
    if (!courier.cycle) {
      return t("couriers.STATE_CLOSED");
    }
    switch (courier.cycle.state) {
      case CYCLE_STATE.started:
        return courierOrders.length
          ? t("couriers.STATE_APPROVED")
          : t("couriers.STATE_STARTED");
      case CYCLE_STATE.approved:
        return courierOrders.length
          ? t("couriers.STATE_APPROVED")
          : t("couriers.STATE_STARTED");
      case CYCLE_STATE.deliveryStart:
        return t("couriers.STATE_DELIVERY_START");
      case CYCLE_STATE.comingBack:
        return t("couriers.STATE_COMING_BACK");
      case CYCLE_STATE.away:
        return t("couriers.STATE_AWAY");
      case CYCLE_STATE.closed:
        return t("couriers.STATE_CLOSED");
      default:
        return "";
    }
  };

  const handleOnClickSelect = (e) => {
    if(enableMultiMode && (!multiMode || multiDisabled)) {
      return;
    }
    e.stopPropagation();
    onSelectMulti();
  }

  return (
    <div className={classNames("courierItem", { rtl: isRTL(), multiDisabled: enableMultiMode && multiMode && multiDisabled })} onClick={handleOnClickSelect}>
      <div className="leftSide">
        <img src={getConvertedCloudUrl(courier.avatarUrl) || defaultAvatar} alt={`img-${courier.userId}`} />
      </div>
      <div className="centerSide overflowHiddenText">
        <div style={{width: '88%'}}>
          <div className="title overflowText">{getFullName(courier)}</div>
          {!courier.isUnverified && <div>{getStateName()} {courier.phoneNumber && `| ${courier.phoneNumber}`}</div>}
        </div>
        {!multiMode && (
            <GrCode courier={courier} />
        )}
      </div>
      <div className="rightSide">
        {multiMode && !multiDisabled && (
            <CheckBox onChange={onSelectMulti} id={'start-multi-shift'} value={selected} stopPropagation/>
        )}
        {!multiMode && (
            <table className="table">
              <tbody>
              <tr>
                {Object.keys(buttonsConfig).filter(key=> buttonsConfig[key]).map((key, i)=> (
                    <td key={`td-shiftitems-${i}`}><ShiftCourierControl type={key} courier={courier} parentControlCenter={controlCenter} /></td>
                ))}
              </tr>
              </tbody>
            </table>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    disableBackupCouriers: state.restaurant.data.configuration && state.restaurant.data.configuration.disableBackupCouriers,
    users: state.userModel.data || [],
    orderModel: state.order.data,
  };
};

export default connect(mapStateToProps)(withTranslation()(CourierShiftItem));
