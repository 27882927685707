import React from 'react';
import classNames from "classnames";
import {DATE_TIME_FORMAT_DAYTIME, getFormattedDate} from "../../../utils/convertTime";
import TimeBox from "../TimeBox/TimeBox";
import {getFullName} from "../../../services/userModel";
import OrderInfoStatus from "../OrderInfoStatus";
import {ACCOUNT_ORDER_STATUS, orderChannelNames} from "../../../utils/enums";
import {useTranslation} from "react-i18next";
import './style.scss';

const AdminOrderInfoStatus = ({order, territories}) => {
    const {t} = useTranslation();
    const getTerritoryName = () => {
        return ((territories || []).find((t) => t.entityId === order.entity_id) || {}).name || "";
    }
    return (
        <div className={classNames("adminOrderInfoStatus adminOrderInfoItem")}>
            <div className={classNames("title")}>{t("admin-orders.ORDER_INFO_LABEL")}</div>
            <div className={classNames("data")}>
                {territories && territories.length > 0 && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.ORDER_INFO_ENTITY_LABEL")}</div>
                        <div>{getTerritoryName()}</div>
                    </div>
                )}
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("admin-orders.ORDER_INFO_NUMBER_LABEL")}</div>
                    <div>#{order.sequenceId || order.sequence_id}</div>
                </div>
                {!!order.order_channel && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.ORDER_CHANNEL")}</div>
                        <div>{orderChannelNames()[order.order_channel]}</div>
                    </div>
                )}
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_CREATION_DATE_TIME_LABEL")}</div>
                    <div>{getFormattedDate(order.creation_datetime, DATE_TIME_FORMAT_DAYTIME)}</div>
                </div>
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_CREATION_DATE_TIME_TILL_LABEL")}</div>
                    <TimeBox date={order.creation_datetime} />
                </div>
                {order.readiness_time && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_READINESS_DATE_TIME_LABEL")}</div>
                        <div>{getFormattedDate(order.readiness_time, DATE_TIME_FORMAT_DAYTIME)}</div>
                    </div>
                )}
                {order.cancellation_datetime && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.ORDER_CANCELLATION_DATE")}</div>
                        <div>{getFormattedDate(order.cancellation_datetime, DATE_TIME_FORMAT_DAYTIME)}</div>
                    </div>

                )}
                {order.cancellation_datetime && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.ORDER_CANCELER_INFO")}</div>
                        <div>{getFullName(order.cancelerInfo) || "-"}</div>
                    </div>

                )}
                {order.canc && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_READINESS_DATE_TIME_LABEL")}</div>
                        <div>{getFormattedDate(order.readiness_time, DATE_TIME_FORMAT_DAYTIME)}</div>
                    </div>
                )}
                <div className={classNames("dataItem", {canceledOrder: order.status === ACCOUNT_ORDER_STATUS.canceled})}>
                    <div className={classNames("title")}>{t("admin-orders.ORDER_INFO_STATUS")}</div>
                    <div><OrderInfoStatus value={order.status} /></div>
                </div>
            </div>
            <div className={classNames("data")}>
                {!!order?.consumerInfo?.serviceNotes && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.PENDING_ORDER_INFO_STAFFONLY_NOTES")}</div>
                        <div>{order.consumerInfo.serviceNotes || "-"}</div>
                    </div>
                )}
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("admin-orders.PENDING_ORDER_INFO_NOTES")}</div>
                    <div>{order.comment || "-"}</div>
                </div>
                {!!order.discount && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.ORDER_SUMMARY_DISCOUNT_LABEL")}</div>
                        <div>{order.discount}</div>
                    </div>
                )}
                {!!order.invoice_url && (
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_INVOICE_LABEL")}</div>
                        <div>
                            <a href={order.invoice_url}>
                                {t("admin-orders.ADMIN_ORDER_INFO_INVOICE_LINK_LABEL")}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AdminOrderInfoStatus;