import React, {useState} from "react";
import classNames from "classnames";
import "./style.scss";
import {SupportChatDialogItem} from "./SupportChatDialogItem";
import {chatSource} from "../../../utils/enums";
import {useTranslation} from "react-i18next";

export const SupportChatDialog = ({dialogs, onPullChat}) => {
    const {t} = useTranslation();

    const [selectedTypes, setSelectedTypes] = useState({
        [chatSource.consumer]: true,
        [chatSource.courier]: true,
        [chatSource.depot]: true,
    });

    const handleOnToggleFilter = filterType => {
        setSelectedTypes({
            ...selectedTypes,
            [filterType]: !selectedTypes[filterType],
        })
    }

    const getCountDialogByType = type => {
        return (dialogs || []).filter(d => d.source === type).length;
    }

    return (
        <div className={"dialogsContainer"}>
            <div className={"filters"}>
                <div
                    onClick={() => handleOnToggleFilter(chatSource.courier)}
                    className={classNames("filterItem", {active: selectedTypes[chatSource.courier]})}
                >
                    <span className={"count"}>{getCountDialogByType(chatSource.courier)}</span>
                    <span className={"filterType"}>{t("support-chat.chatSource.COURIER")}</span>
                </div>
                <div
                    onClick={() => handleOnToggleFilter(chatSource.depot)}
                    className={classNames("filterItem", {active: selectedTypes[chatSource.depot]})}
                >
                    <span className={"count"}>{getCountDialogByType(chatSource.depot)}</span>
                    <span className={"filterType"}>{t("support-chat.chatSource.BUSINESS")}</span>
                </div>
                <div
                    onClick={() => handleOnToggleFilter(chatSource.consumer)}
                    className={classNames("filterItem", {active: selectedTypes[chatSource.consumer]})}
                >
                    <span className={"count"}>{getCountDialogByType(chatSource.consumer)}</span>
                    <span className={"filterType"}>{t("support-chat.chatSource.CONSUMER")}</span>
                </div>
            </div>

            <div className={"dialogItemsContainer"}>
                {dialogs && dialogs
                    .filter(d => selectedTypes[d.source])
                    .map(d => (
                    <SupportChatDialogItem
                        {...d}
                        key={d.id}
                        onPullChat={onPullChat}
                    />
                ))}
            </div>
        </div>
    )
}