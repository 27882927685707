import React, {useState} from 'react';
import classNames from "classnames";
import "./style.scss";

import LayoutHeaderControls from "../LayoutHeaderControls";
import {toast} from "react-toastify";
import SwitchControl from "../SwitchControl";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {createAction} from "../../../utils/sagaHelper";
import {ADMIN_ORDER_CAPTURE} from "../../../store/reducers/order/order-actions";

const CapturePaymentOrder = ({ payment, onClose, orderId, onSave }) => {
    const {t} = useTranslation();
    const [capture, setCapture] = useState("");
    const [isPartial, setIsPartial] = useState(false);
    const max = payment.price;

    const handleOnCaptureSave = () => {
        return new Promise((resolve, reject) => {
            if(parseFloat(capture) > max) {
                return;
            }
            onSave({
                id: orderId,
                body: {
                    payment_id: payment.id,
                    partial: isPartial,
                    amount: isPartial ? parseFloat(capture) : undefined
                }
            }).then(()=> {
                resolve();
                onClose();
            }, error=> {
                reject(error);
                toast(error);
            })
        })
    }

    return (
        <div className={classNames("capturePaymentOrder")}>
            <div className={classNames("formControls")}>
                <div className={classNames("formControl")}>
                    <div className={classNames("title")}>Partial</div>
                    <SwitchControl
                        title={{ on: t("dashboard.orders.ON_LABEL"), off: t("dashboard.orders.OFF_LABEL") }}
                        value={isPartial} onClick={setIsPartial}/>
                </div>
                {isPartial && (
                    <div className={classNames("formControl")}>
                        <div className={classNames("title")}>Capture{capture > max && (<span>, max: {max}</span>)}</div>
                        <input className={classNames("inputArea", {error: capture > max})} type="number" value={capture} onChange={({target}) => setCapture(target.value)}/>
                    </div>
                )}
            </div>

            <LayoutHeaderControls withConfirmDialog disabledSaveButton={capture > max} onSave={handleOnCaptureSave} onClose={onClose} />
        </div>
    );
}

const mdtp = dispatch => {
    return {
        onSave: (data) => dispatch(createAction(ADMIN_ORDER_CAPTURE, data))
    }
}

export default connect(null, mdtp)(CapturePaymentOrder);