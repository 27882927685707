import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import LayoutHeaderControls from '../LayoutHeaderControls';
import { DATECONTROL_TYPE } from '../../../utils/enums.js';
import OrderDatetimeControl from '../OrderDatetimeControl/OrderDatetimeControl.jsx';
import moment from "moment";
import SwitchControl from "../SwitchControl/index.js";
import SectionInfoTitle from "../SectionInfoTitle/index.js";
import {SimpleItemSelect} from "../SectionItemAudienceFilter/audienceFilterHelpers.js";
import i18n from "i18next";
import {getDeliveryTypes} from "../../../utils/constants.js";

const CustomersBonusCard = ({ t, onClose, onSave }) => {
  const [data, setData] = useState({
    bonus: 0.0,
    name: '',
    checkoutLimitPercent: 10,
    expiration: new Date().setDate(new Date().getDate() + 1), //set current date + 1 day
    multiUse: false,
  });

  const handleOnSave = () => {
    const newData = {
      ...data,
      checkoutLimitPercent: data.checkoutLimitPercent / 100,
      expiration: new Date(data.expiration),
    };

    return new Promise(resolve => {
      resolve();
      onSave && onSave(newData);
    });
  };

  const handleOnClose = () =>
    new Promise(resolve => {
      resolve();
      onClose && onClose();
    });

  const handleOnEdit = (prop, val) => {
    setData({ ...data, [prop]: val });
  };

  const isDisabledSave = () => {
    return (
        !data?.name
        || !data?.bonus
        || !(data.checkoutLimitPercent > 0 && data.checkoutLimitPercent <= 100)
        || moment(data.expiration).isSameOrBefore()
    )
  }

  return (
    <div className={classNames('customersBonusCard')}>
      <div className={classNames('formControls')}>
        <SectionInfoTitle sectionModel={{label: "admin-orders.CUSTOMER_BONUS_CARD"}}/>
        <div className={classNames('formControl')}>
          <div className={classNames('formControlItem')}>
            <div className={classNames('title')}>{t('admin-orders.CUSTOMER_BONUS_CARD_NAME_LABEL')}</div>
            <form autoComplete='off' className={classNames('inputItem required')}>
              <input
                required={true}
                type='text'
                value={data?.name || ''}
                onChange={e => handleOnEdit('name', e.target.value)}
              />
            </form>
          </div>

          <div className={classNames('formControlItem')}>
            <div className={classNames('title')}>{t('admin-orders.CUSTOMER_BONUS_CARD_BONUS_LABEL')}</div>
            <form autoComplete='off' className={classNames('inputItem required')}>
              <input
                type='number'
                value={data?.bonus || ''}
                onChange={e => handleOnEdit('bonus', Number(parseFloat(e.target.value).toFixed(2)))}
              />
            </form>
          </div>
        </div>

        <div className={classNames('formControl')}>
          <div className={classNames('formControlItem')}>
            <div className={classNames('title')}>{t('admin-orders.CUSTOMER_BONUS_CARD_PERCENT_LABEL')}</div>
            <div className={classNames('percentField')}>
              <input
                type='number'
                value={data?.checkoutLimitPercent || ''}
                step={5}
                onChange={e => handleOnEdit('checkoutLimitPercent', e.target.value)}
              />
              <div className={classNames('percent')}>%</div>
            </div>
          </div>

          <div className={classNames('formControlItem')}>
            <div className={classNames('title')}>{t('admin-orders.CUSTOMER_BONUS_CARD_EXPIRATION_LABEL')}</div>
            <OrderDatetimeControl
              tab={1}
              accessor={'voucherExpiration'}
              time={data?.expiration || new Date()}
              type={DATECONTROL_TYPE.datetime}
              isEditable={false}
              hideLabel={true}
              onSelect={date => handleOnEdit('expiration', date)}
            />
          </div>
        </div>

        <div className={classNames('formControl')}>
          <div className={classNames('formControlItem')}>
            <div className={classNames('title')}>{t('admin-orders.CUSTOMER_BONUS_CARD_MULTI_USE_LABEL')}</div>
            <SwitchControl value={data?.multiUse} onClick={value => handleOnEdit('multiUse', value)} />
          </div>

          <div className={classNames('formControlItem')}>
            <div className={classNames('title')}>{t('settings.campaigns.BONUS_VOUCHER_TITLE')}</div>
            <SimpleItemSelect
                width={'100%'}
                value={data?.deliveryType}
                values={[{key: null, value: i18n.t('settings.campaigns.BONUS_VOUCHER_ALL_DELIVERY_TYPES')}].concat(getDeliveryTypes())}
                handleChange={value => handleOnEdit('deliveryType', value.key)}
            />
          </div>
        </div>
      </div>
      <LayoutHeaderControls
        disabledSaveButton={isDisabledSave()}
        onSave={handleOnSave}
        onClose={handleOnClose}
      />
    </div>
  );
};

export default withTranslation()(CustomersBonusCard);
