import { useEffect, useState } from 'react';
import { createAction } from '../../utils/sagaHelper.js';
import { useDispatch } from 'react-redux';
import { useDebounce } from './useDebounce.js';

const usePagination = ({
  actionPagination,
  itemsPerPage,
  controlCenterId,
  roles,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [items, setItems] = useState([]);

  const [searchString, setSearchString] = useState('');
  const debounceSearch = useDebounce(searchString, 400);

  const [isLoading, setIsLoading] = useState(false)

  // const handleFilterByRoles = (roles) => {
  // }

  const resetPagination = () => {
    setPage( 0);
    setIsUpdate(false);
    setTotalItems(0);
    setItems([]);
  }

  const handleScroll = event => {
    const { clientHeight, scrollHeight, scrollTop } = event || {};

    setIsUpdate(scrollHeight - clientHeight - 20 < scrollTop);
  };

  const handleSearch = value => {
    if (value === searchString) return;

    setSearchString(value);
  };

  const handlePagination = () => {
    if (items?.length && totalItems && totalItems <= items.length) {
      setIsLastPage(true);
      setIsUpdate(false);
      setIsLoading(false);
      return;
    }

    const handleData = data => {
      if (!data) return;
      setPage(page + 1);
      setIsUpdate(false);
      setTotalItems(data?.totalCount || 0);
      setItems([...items, ...data?.users]);
    };

    const paginationParams = {
      num: page,
      count: itemsPerPage + 6,
      ...(controlCenterId && { controlCenterId: controlCenterId }),
      ...(debounceSearch && { query: debounceSearch }),
      ...(roles && { roles: roles }),
    };

    dispatch(createAction(actionPagination, paginationParams)).then(data => {
      handleData(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsUpdate(true);
    setItems([]);
    setPage(0);
    setIsLastPage(false)
  }, [debounceSearch]);

  useEffect(() => {
    if (!isUpdate || isLoading || isLastPage || !actionPagination || !itemsPerPage) return;

    setIsLoading(true)
    handlePagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemsPerPage, isUpdate, isLoading, roles]);

  return {
    handleScroll,
    handleSearch,
    searchString,
    isLoading,
    newItems: items,
    resetPagination,
  };
};

export default usePagination;
