import React, {useState} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import {ADMIN_ORDER_DELIVERED} from "../../../store/reducers/order/order-actions";
import {
    ACCOUNT_ORDER_STATUS,
    DELIVERY_TYPE,
    isMarketplaceOrder,
    isValidForRepeat,
    USER_FILTER_ROLES
} from "../../../utils/enums";
import {createAction} from "../../../utils/sagaHelper";

import PaginationControl from "../PaginationControl";
import ConfirmDialog from "../ConfirmDialog";
import FullScreenConfirmDialog from "../FullScreenConfirmDialog/index.js";

const AdminOrderInfoFooter = ({
    t,
    order, isHidePagination,
    onClickPrevious, onClickNext, onReshipment, onRefund, onCancelOrder,
    setOrderDelivered, setEditOrder, onRepeatOrder
}) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isOpenDeliveryConfirm, setIsOpenDeliveryConfirm] = useState(false);

    const isReshipmentActive = () => {
        if(order && order?.status_history[0] && order?.status_history[0]?.role === USER_FILTER_ROLES.depot && order.status === ACCOUNT_ORDER_STATUS.canceled) {
            return false;
        }
        return [
            ACCOUNT_ORDER_STATUS.finished,
            ACCOUNT_ORDER_STATUS.canceled
        ].includes(order.status) && order.delivery_type === DELIVERY_TYPE.delivery;
    };

    const handleOnConfirmDialog = () => {
        setConfirmOpen(false);
        setOrderDelivered({ id: order.id });
    }

    const handleOnCancel = () => {
        setConfirmOpen(false);
    }

    const handleOnDelivered = () => {
        if(order.payment_balance !== 0) {
            setIsOpenDeliveryConfirm(true);
            return;
        }
        if(!confirmOpen) {
            setConfirmOpen(true);
        }
    }

    return (
        <div className={classNames("adminOrderInfoFooter")}>
            <div className={classNames("actions")}>
                <button className={classNames("btn", "confirm")}
                    disabled={!isReshipmentActive()}
                    onClick={onReshipment}>
                    {t("admin-orders.RESHIPMENT_BUTTON_LABEL")}
                </button>
                <button className={classNames("btn", "confirm")}
                    disabled={order.status > ACCOUNT_ORDER_STATUS.delivering}
                    onClick={onCancelOrder}
                >
                    {t("admin-orders.CANCEL_ORDER_BUTTON_LABEL")}
                </button>
                <button className={classNames("btn", "confirm", { confirmOpen: confirmOpen })}
                    disabled={order.status === ACCOUNT_ORDER_STATUS.pending || order.status > ACCOUNT_ORDER_STATUS.delivering}
                    onClick={handleOnDelivered}
                >
                    {t("admin-orders.DELIVERED_ORDER_BUTTON_LABEL")}
                    {confirmOpen && (
                        <ConfirmDialog
                            labelText={t("admin-orders.CONFIRM_DELIVERED_ORDER_LABEL")}
                            handleOnConfirm={handleOnConfirmDialog}
                            handleOnCancel={handleOnCancel}
                        />
                    )}
                </button>
                <button className={classNames("btn", "confirm")}
                        onClick={()=> setEditOrder(order.id)}
                        disabled={!(isMarketplaceOrder(order.order_source) && ![ACCOUNT_ORDER_STATUS.finished, ACCOUNT_ORDER_STATUS.canceled].includes(order?.status))}
                >
                    {t("admin-orders.EDIT_CUSTOMER_ORDER")}
                </button>
                <button className={classNames("btn", "confirm")}
                        onClick={()=> onRepeatOrder(order.id)}
                        disabled={!isValidForRepeat(order)}
                >
                    {t("admin-orders.REPEAT")}
                </button>
            </div>
            <div className={classNames("controls")}>
                {!isHidePagination && (<PaginationControl onClickPrevious={onClickPrevious} onClickNext={onClickNext} />)}
            </div>

            {isOpenDeliveryConfirm && (
                <FullScreenConfirmDialog
                    useWrapperClass={'deliveryConfirm'}
                    t={t}
                    description={t('pending-orders.CONFIRM_DELIVERY')}
                    handleOnConfirm={() => setEditOrder(order.id)}
                    handleOnCancel={() => setIsOpenDeliveryConfirm(false)}
                />
            )}
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setOrderDelivered: data=> dispatch(createAction(ADMIN_ORDER_DELIVERED, data))
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(AdminOrderInfoFooter));
