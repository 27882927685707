import React from "react";
import classNames from "classnames";
import "./index.scss";

import { ACCOUNT_ORDER_STATUS, getAccountOrderStatusTitle } from "../../../utils/enums";

const OrderInfoStatus = ({ value }) => {
    return (
        <div className={classNames("orderInfoStatus")}>
            <div className={classNames("title")}>{getAccountOrderStatusTitle(value)}</div>
            <div className={classNames("icons")}>
                <div className={classNames("iconLogo", { active: value === ACCOUNT_ORDER_STATUS.accepted })}>
                    <div className={classNames("icon", "accepted")}></div>
                </div>
                <div className={classNames("hyphen")}></div>
                <div className={classNames("iconLogo", { active: value === ACCOUNT_ORDER_STATUS.ready })}>
                    <div className={classNames("icon", "ready")}></div>
                </div>
                <div className={classNames("hyphen")}></div>
                <div className={classNames("iconLogo", { active: value === ACCOUNT_ORDER_STATUS.delivering })}>
                    <div className={classNames("icon", "delivering")}></div>
                </div>
                <div className={classNames("hyphen")}></div>
                <div className={classNames("iconLogo", { active: value === ACCOUNT_ORDER_STATUS.finished || value === ACCOUNT_ORDER_STATUS.canceled })}>
                    <div className={classNames("icon", value === ACCOUNT_ORDER_STATUS.canceled ? "canceled" : "finished")}></div>
                </div>
            </div>            
        </div>
    );
}

export default OrderInfoStatus;