import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import './index.scss';

import { getCustomersReport } from '../../../api/customer-requests';

import { fileFromRequestResult } from '../../../utils/objects-util';
import { getErrorMessage, requestResultIsOk } from '../../../utils/request-util';

import SearchBox from '../SearchBox';
import IconButton from '../IconButton';
import AmountControl from '../AmountControl';
import Waiter from "../Waiter/Waiter.jsx";
import {WAITER_TYPES} from "../../../utils/enums.js";

const CustomersListHeader = ({ filter, onSetFilter, customers, withLabel = true, withExelIcon = true, addIcon = false, onClickAdd }) => {
  const { t } = useTranslation();

  const handleOnSearch = search => {
    onSetFilter({ ...filter, search });
  };

  const handleOnReportSave = () => {
    return new Promise((resolve, reject) => {
      getCustomersReport(filter).then(
        requestResult => {
          if (requestResultIsOk(requestResult)) {
            const dataBlob = fileFromRequestResult(requestResult);
            if (dataBlob && dataBlob.file && dataBlob.fileName) {
              saveAs(new Blob([dataBlob.file], { type: dataBlob.contentType }), dataBlob.fileName);
              resolve();
            } else {
              reject("Can't convert file");
            }
          } else {
            reject(getErrorMessage(requestResult));
          }
        },
        err => reject(err)
      );
    });
  };

  return (
    <div className={classNames('customersListHeader')}>
      {withLabel && (
        <div className={classNames('customersListHeaderTitle')}>
          {addIcon ? (
              <div className={"createNewUser"} onClick={onClickAdd}>
                <Waiter type={WAITER_TYPES.span}
                        spanText={"+"}
                        tooltipText={t("edit-customer-order.aboutClient.CREATE_USER")}
                />
              </div>
          ): <div/>}

          <span className={classNames('headerTitle')}>
            {t('customers.LABEL')}
          </span>

          <AmountControl value={customers.length} />
        </div>
      )}
      <div className={classNames('customersListHeaderData')}>
        <div className={classNames('customersListHeaderSearch')}>
          <SearchBox
            placeholder={t('orders.SEARCH_PLACEHOLDER')}
            useValue={(filter || {}).search}
            onChange={handleOnSearch}
          />
        </div>
        {withExelIcon && (
          <div className={classNames('reportButton')}>
            <IconButton
              isIcon={true}
              iconName='excelIcon'
              tooltipText={t('pending-orders.PENDING_ORDERS_REPORT_SAVE_TOOLTIP')}
              onClick={handleOnReportSave}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  customers: state.customer.customers || [],
});

export default connect(mapStateToProps)(CustomersListHeader);
