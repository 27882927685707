import React from "react";

import MenuModel from "./menuModel";

import {TYPE_MENU, TYPE_SECTION_DEPOT_MODEL} from "../utils/enums";
import {getCurrentDepotByBusiness, getRestaurantPaymentEnabled, hasDepotBusinessMenu} from "../services/restaurant";
import IconButton from "../app/components/IconButton";
import {defaultPromiseResolve} from "../utils/objects-util";
import {ORDERING_MENU_GET} from "../store/reducers/restaurant/restaurant-actions";
import {getHistoryLocationParams} from "../utils/routesHelper";

export function createDepotMenu() {
    return [
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.depotsEdit,
            title: "settings.depotSettingsLabel.INFO",
            icon: "depotInfo",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.depotsEdit)
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.depotsEditPrice,
            title: "settings.depotSettingsLabel.DELIVERY_COST",
            icon: "deliveryCost",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.depotsEditPrice),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.depotMenu,
            nestedMenuType: TYPE_MENU.orderingMenu,
            actionLoad: ORDERING_MENU_GET,
            title: "settings.depotSettingsLabel.MENU",

            icon: "parentMenu",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            isShow: () => getRestaurantPaymentEnabled() && hasDepotBusinessMenu(),
            active: location => location.pathname.includes("settings/orderingmenu/"),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.depotsMenuCopy,
            title: "settings.depotSettingsLabel.CREATE_MENU",
            icon: "parentMenu",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            isShow: () => getRestaurantPaymentEnabled() && !hasDepotBusinessMenu(),
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.depotsMenuCopy),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.depotusers,
            title: "settings.depotSettingsLabel.USERS",
            icon: "user",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.depotusers),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.depotIntegrations,
            title: "settings.depotSettingsLabel.INTEGRATIONS",
            icon: "integrations",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.depotIntegrations),
            isWithIcon: function(state) {
                const { parentId, id } = getHistoryLocationParams(window.location.search);
                const depot = ((state.restaurant.data && state.restaurant.data.depots) || []).find(depot => depot.id === (parentId || id));
                return depot && depot.squareUpAuthorizationRequired
                    ? (<IconButton isIcon={true} iconName={"warn"} onClick={defaultPromiseResolve} />)
                    : "";
            }
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters,
            title: "settings.depotSettingsLabel.REGIONS",
            icon: "deliveryZone",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.depotDefaultRegionControlCenters),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.provider,
            title: "settings.depotSettingsLabel.PROVIDER",
            icon: "externalProvider",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.provider),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfoEdit,
            title: "settings.depotSettingsLabel.PAYMENTS",
            icon: "paymentsCard",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            isShow: () => getRestaurantPaymentEnabled(),
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.depotPaymeCreditInfoEdit),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.telegram,
            title: "settings.depotSettingsLabel.TELEGRAM",
            icon: "telegramSettings",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.telegram),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.operationMode,
            title: "settings.operationTiming.LABEL",
            icon: "operationTiming",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.operationMode) || getIsActive(location, TYPE_SECTION_DEPOT_MODEL.operationModeActive),
        }),
        new MenuModel({
            pathname: TYPE_SECTION_DEPOT_MODEL.reconciliation,
            title: "settings.depotSettingsLabel.RECONCILIATION",
            icon: "reconciliation",
            searchParams: state => `?id=${getCurrentDepotByBusiness(state)}`,
            active: location => getIsActive(location, TYPE_SECTION_DEPOT_MODEL.reconciliation),
        })
    ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split("/");
    const splitType = type.split("/");
    return (splitCurrentLocation[1] === splitType[1] && splitCurrentLocation[2] === splitType[2] && splitCurrentLocation[3] === splitType[3]);
}
