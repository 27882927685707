import React from "react";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import {
    ADMIN_ORDER_FILTER_TYPE,
} from "../../../utils/enums";
import {AdminOrderDateSearchFilter} from '../AdminOrderDateSearchFilter';

const AdminOrderSearchFilterControl = ({
    t,
    filter,
    onSetType, onClearSearch, onFilterSearch
}) => {
    return (
        <div className={classNames("adminOrderSearchFilterControl")}>
            <button className={classNames("btn", "denied", { selected: !!filter.start })}
                onClick={()=> onSetType(ADMIN_ORDER_FILTER_TYPE.dateStart)}
            >
                <AdminOrderDateSearchFilter
                    value={filter.start}
                    onClose={onFilterSearch}
                    onClear={() => onClearSearch(ADMIN_ORDER_FILTER_TYPE.dateStart)}
                    type={ADMIN_ORDER_FILTER_TYPE.dateStart}
                />
            </button>
            <button className={classNames("btn", "denied", { selected: !!filter.end})}
                onClick={()=> onSetType(ADMIN_ORDER_FILTER_TYPE.dateEnd)}
            >
                <AdminOrderDateSearchFilter
                    value={filter.end}
                    onClose={onFilterSearch}
                    onClear={() => onClearSearch(ADMIN_ORDER_FILTER_TYPE.dateEnd)}
                    type={ADMIN_ORDER_FILTER_TYPE.dateEnd}
                />
            </button>
            {/*<button className={classNames("btn", "denied", { selected: filterType === ADMIN_ORDER_FILTER_TYPE.deviceType || (!!filter.deviceType || filter.deviceType === 0)})}*/}
            {/*        onClick={()=> onSetType(ADMIN_ORDER_FILTER_TYPE.deviceType)}*/}
            {/*>*/}
            {/*    {orderChannelNames[filter.deviceType] || t("admin-orders.ADMIN_ORDERS_SEARCH_DEVICE_TYPE_LABEL")}*/}
            {/*    {filterType === ADMIN_ORDER_FILTER_TYPE.deviceType && (*/}
            {/*        <AdminOrderSearchFilter type={filterType} value={filter.deviceType} onClose={onFilterSearch} />*/}
            {/*    )}*/}
            {/*    {!isEmptyMode(filter.deviceType) && (*/}
            {/*        <IconButton isIcon={true} iconName={"iconDelet"} onClick={()=> onClearSearch(ADMIN_ORDER_FILTER_TYPE.deviceType)} />*/}
            {/*    )}*/}
            {/*</button>*/}
            {/*<button className={classNames("btn", "denied", { selected: filterType === ADMIN_ORDER_FILTER_TYPE.paymentMethod || (!!filter.paymentMethod || filter.paymentMethod === 0)})}*/}
            {/*        onClick={()=> onSetType(ADMIN_ORDER_FILTER_TYPE.paymentMethod)}*/}
            {/*>*/}
            {/*    {getPaymethodNames[filter.paymentMethod] || t("admin-orders.ADMIN_ORDERS_SEARCH_PAYMENT_METHOD_LABEL")}*/}
            {/*    {filterType === ADMIN_ORDER_FILTER_TYPE.paymentMethod && (*/}
            {/*        <AdminOrderSearchFilter type={filterType} value={filter.paymentMethod} onClose={onFilterSearch} />*/}
            {/*    )}*/}
            {/*    {!isEmptyMode(filter.paymentMethod) && (*/}
            {/*        <IconButton isIcon={true} iconName={"iconDelet"} onClick={()=> onClearSearch(ADMIN_ORDER_FILTER_TYPE.paymentMethod)} />*/}
            {/*    )}*/}
            {/*</button>*/}
            {/*<button className={classNames("btn", "denied", { selected: filterType === ADMIN_ORDER_FILTER_TYPE.orderSource || (!!filter.orderSource || filter.orderSource === 0)})}*/}
            {/*        onClick={()=> onSetType(ADMIN_ORDER_FILTER_TYPE.orderSource)}*/}
            {/*>*/}
            {/*    {orderSourceType()[filter.orderSource] || t("admin-orders.ADMIN_ORDERS_SEARCH_ORDER_SOURCE_LABEL")}*/}
            {/*    {filterType === ADMIN_ORDER_FILTER_TYPE.orderSource && (*/}
            {/*        <AdminOrderSearchFilter type={filterType} value={filter.orderSource} onClose={onFilterSearch} />*/}
            {/*    )}*/}
            {/*    {!isEmptyMode(filter.orderSource) && (*/}
            {/*        <IconButton isIcon={true} iconName={"iconDelet"} onClick={()=> onClearSearch(ADMIN_ORDER_FILTER_TYPE.orderSource)} />*/}
            {/*    )}*/}
            {/*</button>*/}
        </div>
    );
}

export default withTranslation()(AdminOrderSearchFilterControl);
