import React from "react";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import {getConvertedCloudUrl} from "../../../utils/image-util";
import {defaultPromiseResolve, formatNumber} from "../../../utils/objects-util";
import {getCurrencyIcons} from "../../../utils/enums";

import OrderMenuOption from "../OrderMenuOption";
import IconButton from "../IconButton";
import {OrderOptionMenuComments} from "../OrderMenuOption/OrderOptionMenuComments";
import {CounterButton} from "delivapp-ordering";
import {fixedNumber} from "../../../utils/math-util.js";
import { DebounceInput } from "react-debounce-input";

const PendingOrdersInfoData = ({
                                   t,
                                   item,
                                   order,
                                   isMobile,
                                   isEditable,
                                   handleOnQuantity,
                                   onDelete,
                                   onClickProduct,
                                   onChangeWeight,
                                   editableWeight,
                               }) => {

    const getOrderWeight = product => {
        const { weight, buffer } = getWeightAnfBuffer(product);
        if(product.weighed) {
            return `${parseInt(weight - buffer)}-${parseInt(weight + buffer)} ${t('uilib.productConstructor.GR')}`;
        }
        return '';
    }

    const getWeightAnfBuffer = product => {
        const weight = fixedNumber(product.quantity * product.unit_weight, 2);
        const buffer = fixedNumber(weight * product.weight_range, 2);
        return { weight, buffer };
    }

    const checkValidWeight = product => {
        const { weight, buffer } = getWeightAnfBuffer(product);
        const [min, max] = [weight - buffer, weight + buffer];
        if(product.actual_weight === 0) {
            return true;
        }
        return product.actual_weight >= min && product.actual_weight <= max;
    }

    const isEditWeight = isEditable || editableWeight;

    return (
        <table className={classNames("pendingOrdersInfoData", { isMobile: isMobile })}>
            <thead>
            <tr>
                <th>{t("pending-orders.PENDING_ORDERS_PICTURE")}</th>
                <th>{t("pending-orders.PENDING_ORDERS_ITEM")}</th>
                <th>{t("pending-orders.PENDING_ORDERS_QUANTITY")}</th>
                <th>{t("pending-orders.PENDING_ORDERS_ORDERED_WEIGHT")}</th>
                <th>{t("pending-orders.PENDING_ORDERS_ACTUAL_WEIGHT")}</th>
                {!isMobile && order && (
                    <th>{t("pending-orders.PENDING_ORDERS_ADD_ONS")}</th>
                )}
                <th>{t("pending-orders.PENDING_ORDERS_PRICE")}</th>
                {isEditable && (
                    <th>{t("pending-orders.PENDING_ORDERS_ACTIONS")}</th>
                )}
            </tr>
            </thead>
            <tbody>
            {item && item.items.map((orderItem, orderItemIndex)=> (
                <tr key={`orderItem-${orderItemIndex}`}>
                    <td>
                        {orderItem.image && (<img src={getConvertedCloudUrl(orderItem.image, 85, true)} alt={orderItem.name} />)}
                    </td>
                    <td className={classNames("name")}>
                        <div className={classNames("name")}>
                            {orderItem.name || t("pending-orders.PENDING_ORDER_EMPTY_NAME_LABEL")}
                            {` (${formatNumber(orderItem.price)} ${getCurrencyIcons(item.currency)})`}
                            {orderItem.description && (
                                <IconButton tooltipIsHTML tooltipText={orderItem.description} iconName={'info'} isIcon/>
                            )}

                            {orderItem.ingredientsNames?.map((ingredient, ingredientIndex)=> (
                                <div key={"ingredient-" + ingredientIndex} className={classNames("ingredientContainer")}>
                                    <IconButton isIcon={true}
                                                iconName={"iconDelet"}
                                                onClick={()=> new Promise(resolve=> resolve())}
                                    />
                                    <span className={classNames("ingredientText")}>{ingredient || t("pending-orders.PENDING_ORDER_EMPTY_INGREDIENT_LABEL")}&nbsp;</span>
                                </div>
                            ))}
                        </div>
                        {isMobile && (
                            <div className={classNames("ingredients")}>
                                {orderItem.ingredientsNames?.map((ingredient, ingredientIndex)=> (
                                    <div key={"ingredient-" + ingredientIndex} className={classNames("ingredientContainer")}>
                                        <IconButton isIcon={true}
                                                    iconName={"iconDelet"}
                                                    onClick={defaultPromiseResolve}
                                        />
                                        <span className={classNames("ingredientText")}>{ingredient || t("pending-orders.PENDING_ORDER_EMPTY_INGREDIENT_LABEL")}&nbsp;</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </td>
                    <td className={classNames("count")} onClick={e => e.stopPropagation()}>
                        {!isEditWeight && (<>x{orderItem.quantity}</>)}
                        {isEditWeight && (
                            <CounterButton
                                count={orderItem.quantity}
                                isAddDisable={orderItem.max && orderItem.max === orderItem.quantity}
                                isRemoveDisable={orderItem.quantity === 1}
                                onChangeQuantity={(value) => handleOnQuantity(orderItemIndex, {...orderItem, quantity: value <= 0 ? 0 : value})}
                                onAdd={() => handleOnQuantity(orderItemIndex, {...orderItem, quantity: orderItem.quantity +1})}
                                onRemove={() => handleOnQuantity(orderItemIndex, {...orderItem, quantity: orderItem.quantity -1})}
                            />
                        )}
                    </td>
                    <td className={"orderWeight"}>
                        {getOrderWeight(orderItem)}
                    </td>
                    <td className={classNames("actualWeight", {weightError: !checkValidWeight(orderItem)})}>
                        {!!orderItem.weighed && isEditWeight && (
                            <>
                                <DebounceInput
                                    debounceTimeout={600}
                                    type={'number'}
                                    value={orderItem.actual_weight}
                                    onChange={({target}) => onChangeWeight(orderItemIndex, {...orderItem, actual_weight: fixedNumber(target.value, 2)})}
                                />
                                &nbsp;<span>{t("uilib.productConstructor.GR")}</span>
                            </>
                        )}
                        {!isEditWeight && !!orderItem.weighed && (<>
                            {orderItem.actual_weight}&nbsp;<span>{t("uilib.productConstructor.GR")}</span>
                        </>)}
                    </td>
                    {!isMobile && order && (
                        <td>
                            <div className={classNames("options")}>
                                {orderItem.options.map((option, optionIndex)=> (
                                    <OrderMenuOption key={`option-${optionIndex}`} option={option} order={order} />
                                ))}
                                {orderItem.comment && (<OrderOptionMenuComments title={t("create-order.product.COMMENTS")} comment={orderItem.comment} />)}
                                {orderItem.name_on_box && (<OrderOptionMenuComments title={t("create-order.product.BOX_NAME")} comment={orderItem.name_on_box} />)}
                            </div>
                        </td>
                    )}
                    <td>
                        <div className={classNames("price")} onClick={e => e.stopPropagation()}>
                            {orderItem.comment3}&nbsp;
                            {`${formatNumber(orderItem.total)} ${getCurrencyIcons(item.currency)}`}
                        </div>
                    </td>
                    {isEditable && (
                        <td>
                            <div className={'controls'}>
                                <IconButton isIcon iconName={'editItem'} onClick={() => onClickProduct({product: orderItem, index: orderItemIndex})}/>
                                <IconButton isIcon iconName={'deleteItem'} onClick={() => onDelete(orderItemIndex)}/>
                            </div>
                        </td>
                    )}
                </tr>
            ))}
            </tbody>
        </table>
    )
}

export default withTranslation()(PendingOrdersInfoData);
