export const getCourierControlSettingsModel = (data = {}) => {
    return {
        DoNotAllowCycleRejectByCourier: data.doNotAllowCycleRejectByCourier,
        DoNotAllowPullWithoutLocation: data.doNotAllowPullWithoutLocation,
        EnableAwayControlByCourier: data.enableAwayControlByCourier,
        ManagerOnlyShiftControl: data.managerOnlyShiftControl,
        RunCourierAppInPointByPointMode: data.runCourierAppInPointByPointMode,
        LockTasksOrderInCourierApp: data.lockTasksOrderInCourierApp,
        ArrivalDistanceThresholdInMeters: data.arrivalDistanceThresholdInMeters,
    };
}