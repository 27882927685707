import MenuModel from "./menuModel.js";
import {TYPE_SECTION_MODEL} from "../utils/enums.js";

export function createMenuOperationsManagementSettings() {
    return [
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.operationTiming,
            title: "settings.operationTiming.LABEL",
            active: location => getIsActive(location, TYPE_SECTION_MODEL.operationTiming)
        }),
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.controlCenters,
            title: "header.menu.CONTROL_CENTERS",
            active: location => getIsActive(location, TYPE_SECTION_MODEL.controlCenters)
        }),
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.fleet,
            title: "settings.TITLE_FLEET",
            active: location => getIsActive(location, TYPE_SECTION_MODEL.fleet)
        }),
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.searchShortcuts,
            title: "settings.searchShortcutsSettings.LABEL",
            active: location => getIsActive(location, TYPE_SECTION_MODEL.searchShortcuts)
        }),
    ]
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3]
    );
}
