import React, {useRef} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import './index.scss';

import {
  actionResetTooltipData,
  actionUpdateTooltipData,
} from '../../../store/reducers/tooltipPortal/tooltipPortal-actions';

import {isContainsHeb} from '../../../utils/objects-util';

const InlineTooltipText = ({ value, onClick, updateTooltipData, resetTooltipData, isNeverRtl = false }) => {
  const hoverRef = useRef();

  const handleOnClick = e => {
    onClick && onClick(e);
  };

  const handleOnMouseEnter = e => {
    value && updateTooltipData({ tooltipText: value, ref: hoverRef.current , isNeverRtl: isNeverRtl});
  };

  const handleOnMouseLeave = e => {
    value && resetTooltipData({ event: e, context: this });
  };

  return (
    <p
      ref={hoverRef}
      className={classNames('inlineTooltipText', { rtl: isContainsHeb(value), neverRtl: isNeverRtl })}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleOnClick}
    >
      {value}
    </p>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateTooltipData: data => dispatch(actionUpdateTooltipData(data)),
    resetTooltipData: data => dispatch(actionResetTooltipData(data)),
  };
};

export default connect(null, mapDispatchToProps)(InlineTooltipText);
