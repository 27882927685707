import {ADD, DELETE, EDIT, EXPORT, GET} from '../common-actions';

export const LOAD_RECONCILIATIONS_PERIODS = "LOAD_RECONCILIATIONS_PERIODS";
export const LOAD_RECONCILIATIONS_PERIOD = "LOAD_RECONCILIATIONS_PERIOD";
export const ADD_RECONCILIATIONS_PERIODS = "ADD_RECONCILIATIONS_PERIODS";
export const RECONCILIATIONS_PERIODS = "RECONCILIATIONS_PERIODS"
export const RECONCILIATIONS_PERIODS_GET = "RECONCILIATIONS_PERIODS" + GET
export const RECONCILIATIONS_PERIODS_EDIT = "RECONCILIATIONS_PERIODS" + EDIT
export const RECONCILIATIONS_PERIOD_GET = "RECONCILIATIONS_PERIOD" + GET

export const RECONCILIATIONS_PERIODS_XLS = "RECONCILIATIONS_PERIODS_XLS";
export const RECONCILIATIONS_PERIODS_INVOICE = "RECONCILIATIONS_PERIODS_INVOICE";
export const RECONCILIATIONS_PERIODS_TABLE_XLS_EXPORT = "RECONCILIATIONS_PERIODS_TABLE_XLS" + EXPORT;
export const RECONCILIATIONS_PERIODS_TABLE_INVOICE_EXPORT = "RECONCILIATIONS_PERIODS_TABLE_INVOICE" + EXPORT;
export const RECONCILIATIONS_PERIODS_ADD = "RECONCILIATIONS_PERIODS" + ADD
export const RECONCILIATIONS_PERIODS_EXPORT = 'RECONCILIATIONS_PERIODS' + EXPORT;
export const RECONCILIATIONS_PERIODS_DELETE = "RECONCILIATIONS_PERIODS" + DELETE
export const RECONCILIATIONS_PERIODS_RECALCULATE = "RECONCILIATIONS_PERIODS_RECALCULATE";
export const RECONCILIATIONS_PERIODS_RELOAD = "RECONCILIATIONS_PERIODS_RELOAD";
export const RECONCILIATIONS_PERIODS_COLLAPSE_TABLE = "RECONCILIATIONS_PERIODS_COLLAPSE_TABLE";
export const RECONCILIATIONS_PERIODS_UNMOUNT_TABLE = "RECONCILIATIONS_PERIODS_UNMOUNT_TABLE";
export const RECONCILIATIONS_PERIODS_APPROVE = "RECONCILIATIONS_PERIODS_APPROVE";

export const LOAD_RECONCILIATIONS_CONFIGURATIONS = "LOAD_RECONCILIATIONS_CONFIGURATIONS";
export const ADD_RECONCILIATIONS_CONFIGURATIONS = "ADD_RECONCILIATIONS_CONFIGURATIONS";
export const UPDATE_RECONCILIATIONS_CONFIGURATIONS = "UPDATE_RECONCILIATIONS_CONFIGURATIONS";
export const UPDATE_RECONCILIATIONS_CONFIGURATIONS_MERGE = "UPDATE_RECONCILIATIONS_CONFIGURATIONS_MERGE";
export const UPDATE_RECONCILIATIONS_CONFIGURATIONS_SETTINGS = "UPDATE_RECONCILIATIONS_CONFIGURATIONS_SETTINGS";

export const RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET = "RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET";
export const RECONCILIATIONS_CONFIGURATIONS_WITHOUT_TERRITORY_DEPOTS_GET = "RECONCILIATIONS_CONFIGURATIONS_WITHOUT_TERRITORY_DEPOTS_GET";
export const RECONCILIATIONS_CONFIGURATIONS = "RECONCILIATIONS_CONFIGURATIONS"
export const RECONCILIATIONS_CONFIGURATIONS_IMPORT = RECONCILIATIONS_CONFIGURATIONS + "_IMPORT";
export const RECONCILIATIONS_CONFIGURATIONS_EXPORT = RECONCILIATIONS_CONFIGURATIONS + "_EXPORT";
export const RECONCILIATIONS_CONFIGURATIONS_PARENT_MERGED_GET = "RECONCILIATIONS_CONFIGURATIONS_PARENT_MERGED_GET"
export const RECONCILIATIONS_CONFIGURATIONS_GET = "RECONCILIATIONS_CONFIGURATIONS" + GET
export const RECONCILIATIONS_CONFIGURATIONS_ADD = "RECONCILIATIONS_CONFIGURATIONS" + ADD
export const RECONCILIATIONS_CONFIGURATIONS_EDIT = "RECONCILIATIONS_CONFIGURATIONS" + EDIT
export const RECONCILIATIONS_CONFIGURATIONS_DELETE = "RECONCILIATIONS_CONFIGURATIONS" + DELETE

export const DEPOT_RECONCILIATIONS_CONFIGURATIONS = "DEPOT_RECONCILIATIONS_CONFIGURATIONS";
export const DEPOT_RECONCILIATIONS_CONFIGURATIONS_GET = "DEPOT_RECONCILIATIONS_CONFIGURATIONS" + GET;
export const DEPOT_RECONCILIATIONS_CONFIGURATIONS_EDIT = "DEPOT_RECONCILIATIONS_CONFIGURATIONS" + EDIT;

export const RECONCILIATIONS_CONFIGURATIONS_CONFIG = "RECONCILIATIONS_CONFIGURATIONS_CONFIG";
export const RECONCILIATIONS_CONFIGURATIONS_CONFIG_GET = RECONCILIATIONS_CONFIGURATIONS_CONFIG + GET;
export const RECONCILIATIONS_CONFIGURATIONS_CONFIG_EDIT = RECONCILIATIONS_CONFIGURATIONS_CONFIG + EDIT;

export function actionLoadReconciliationsConfigurations(data) {
  return { type: LOAD_RECONCILIATIONS_CONFIGURATIONS, data };
}
export function actionAddReconciliationsConfigurations(data) {
  return { type: ADD_RECONCILIATIONS_CONFIGURATIONS, data };
}

export function actionUpdateReconciliationsConfigurations(data) {
  return { type: UPDATE_RECONCILIATIONS_CONFIGURATIONS, data };
}

export function actionUpdateReconciliationsConfigurationsMERGE(data) {
  return { type: UPDATE_RECONCILIATIONS_CONFIGURATIONS_MERGE, data };
}
export function actionUpdateReconciliationsConfigurationSettings(data) {
  return { type: UPDATE_RECONCILIATIONS_CONFIGURATIONS_SETTINGS, data };
}
export function actionUpdateReconciliationsConfigurationsDepots(data) {
  return { type: RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET, data };
}

export function actionUpdateReconciliationsCollapseTable(data) {
  return { type: RECONCILIATIONS_PERIODS_COLLAPSE_TABLE, data };
}

export function actionLoadReconciliationsPeriods(data) {
  return { type: LOAD_RECONCILIATIONS_PERIODS, data };
}
export function actionLoadReconciliationsPeriod(data) {
  return { type: LOAD_RECONCILIATIONS_PERIOD, data };
}
export function actionAddReconciliationsPeriods(data) {
  return { type: ADD_RECONCILIATIONS_PERIODS, data };
}
