import {
    ADD_NEW_SINGLE_TABLE_ROW_BATCH_CUSTOMERS,
    ADD_TEMPLATE_BATCH_DRAFT, CHANGE_TEMPORARY_CONSUMER,
    DELET_BATCH_CUSTOMERS_TABLE_ROW,
    DELETE_TEMPLATE_DRAFT,
    RESET_CUSTOMER_ORDER_EDIT_INFO,
    SET_BATCH_CUSTOMERS_TABLE, SET_BULK_EMAIL,
    SET_BUSINESS_BY_FILTERS, SET_CANCEL_PAYMENT_IDS,
    SET_CONFIRM_EDIT_CUSTOMER_ORDER_LOADING,
    SET_EDIT_CUSTOMER_ENABLED_PAYMENTS,
    SET_EDIT_CUSTOMER_GIFT_DATA,
    SET_EDIT_CUSTOMER_ORDER_ADDRESS,
    SET_EDIT_CUSTOMER_ORDER_COURIER_TIP,
    SET_EDIT_CUSTOMER_ORDER_CUTLERY,
    SET_EDIT_CUSTOMER_ORDER_DELIVERY_STRATEGY,
    SET_EDIT_CUSTOMER_ORDER_DELIVERY_TIME,
    SET_EDIT_CUSTOMER_ORDER_DELIVERY_TYPE,
    SET_EDIT_CUSTOMER_ORDER_INFO,
    SET_EDIT_CUSTOMER_ORDER_LOADING,
    SET_EDIT_CUSTOMER_ORDER_MENU,
    SET_EDIT_CUSTOMER_ORDER_PAYMENTS,
    SET_EDIT_CUSTOMER_ORDER_PRODUCTS,
    SET_EDIT_CUSTOMER_ORDER_SUMMARY,
    SET_EDIT_ORDER_COMMENT,
    SET_EDIT_ORDER_TERRITORY,
    SET_IS_SINGLE_ORDER, SET_ORDER_TIME_SLOTS,
    SET_PREVIOUS_ORDERS,
    SET_PREVIOUS_ORDERS_ADDRESS,
    SET_TEMPLATE_BATCH_DRAFT,
    UPDATE_BATCH_CUSTOMERS_TABLE_ROW,
    UPDATE_TEMPLATE_BATCH_DRAFT
} from "./editCustomerOrder-action";
import uuid from "draft-js/lib/uuid.js";
import {REMOVE_ALL_DATA} from "../common-actions.js";
import {
    CHANGE_ORDER_COUPON,
    CHANGE_RECALCULATED_DISCOUNTS,
    ORDEREDIT_SET_CANCEL_OFFER_IDS,
    ORDEREDIT_SET_PAYMENT_INTENT
} from "../orderEdit/orderEdit-actions.js";

const initState = {
    confirmLoading: false,
    coupon: '',
    loading: false,
    orderInfo: null,
    menu: null,
    products: [],
    deliveryType: null,
    courierTip: null,
    deliveryStrategy: null,
    deliveryTime: null,
    cancelPaymentIds: null,
    canceledOffer: [],
    recalculateDiscounts: false,
    cutlery: null,
    payments: [],
    enabledPayments: [],
    address: null,
    territory: null,
    giftOption: {
        name: '',
        phone: '',
        greeting: '',
        isGift: false,
    },
    comment: '',
    cartId: uuid(),
    previousOrders: null,
    previousAddress: null,
    isSingleOrder: true,
    batchDraft: null,
    batchTable: null,
    bulkEmail: '',
};

export default function editCustomerOrderReducer(state = initState, action) {
    switch (action.type) {
        case REMOVE_ALL_DATA: {
            return initState;
        }
        case RESET_CUSTOMER_ORDER_EDIT_INFO: {
            return { ...initState, cartId: uuid(), ...action.payload };
        }
        case SET_EDIT_CUSTOMER_ORDER_INFO: {
            return {
                ...state, orderInfo: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_MENU: {
            return {
                ...state,
                menu: action.payload,
                summary: null,
                products: state.menu?.id === action.payload?.id ? state.products : [],
            }
        }
        case SET_BULK_EMAIL: {
            return {
                ...state,
                bulkEmail: action.payload
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_SUMMARY: {
            return {
                ...state, summary: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_PRODUCTS: {
            return {
                ...state, products: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_DELIVERY_TYPE: {
            return {
                ...state, deliveryType: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_LOADING: {
            return {
                ...state, loading: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_COURIER_TIP: {
            return {
                ...state, courierTip: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_PAYMENTS: {
            return {
                ...state, payments: action.payload.map(p => ({ ...p, saleAmount: parseFloat(p.saleAmount.toFixed(2)) })),
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_DELIVERY_STRATEGY: {
            return {
                ...state, deliveryStrategy: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_DELIVERY_TIME: {
            return {
                ...state, deliveryTime: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_CUTLERY: {
            return {
                ...state, cutlery: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_ORDER_ADDRESS: {
            const hasAddress = (state.previousAddress || []).find(a => a.id === action.payload.id);
            return {
                ...state,
                previousAddress: hasAddress
                    ? state.previousAddress.map(p => p.id === action.payload.id ? action.payload : p)
                    : state.previousAddress
                        ? [action.payload, ...state.previousAddress]
                        : null,
                address: action.payload,
            }
        }
        case SET_EDIT_CUSTOMER_GIFT_DATA: {
            return {
                ...state, giftOption: { ...state.giftOption, ...action.payload }
            }
        }
        case SET_EDIT_CUSTOMER_ENABLED_PAYMENTS: {
            return {
                ...state, enabledPayments: action.payload
            }
        }
        case SET_ORDER_TIME_SLOTS: {
            return {
                ...state, timeSlots: action.payload
            }
        }
        case SET_CONFIRM_EDIT_CUSTOMER_ORDER_LOADING: {
            return {
                ...state, confirmLoading: action.payload
            }
        }
        case SET_PREVIOUS_ORDERS: {
            return {
                ...state, previousOrders: action.payload
            }
        }
        case SET_IS_SINGLE_ORDER: {
            return {
                ...state, isSingleOrder: action.payload
            }
        }
        case SET_PREVIOUS_ORDERS_ADDRESS: {
            return {
                ...state, previousAddress: action.payload
            }
        }
        case SET_BUSINESS_BY_FILTERS: {
            return {
                ...state, business: action.payload
            }
        }
        case SET_EDIT_ORDER_COMMENT: {
            return {
                ...state, comment: action.payload
            }
        }
        case SET_EDIT_ORDER_TERRITORY: {
            return {
                ...state, territory: action.payload
            }
        }
        case SET_BATCH_CUSTOMERS_TABLE: {
            return {
                ...state, batchTable: action.payload
            }
        }
        case SET_CANCEL_PAYMENT_IDS: {
            return {
                ...state, cancelPaymentIds: action.payload,
            }
        }
        case UPDATE_BATCH_CUSTOMERS_TABLE_ROW: {
            return {
                ...state,
                batchTable: state.batchTable
                    ? {
                    ...state.batchTable,
                        consumers_data:
                            state.batchTable.consumers_data.map(row => (row[action.payload.sourceKey] || row.id) === (action.payload.row[action.payload.updateKey] || action.payload.row.id)
                                ? ({...action.payload.row })
                                : row)
                } : null,
            }
        }
        case SET_TEMPLATE_BATCH_DRAFT: {
            return {
                ...state, batchDraft: action.payload,
            }
        }
        case ADD_TEMPLATE_BATCH_DRAFT: {
            return {
                ...state, batchDraft: state.batchDraft ? state.batchDraft.concat(action.payload) : action.payload,
            }
        }
        case UPDATE_TEMPLATE_BATCH_DRAFT: {
            return {
                ...state,
                batchDraft:
                    state.batchDraft
                        ? state.batchDraft.map(draft => draft.id === action.payload.id ? action.payload : draft)
                        : state.batchDraft,
            }
        }
        case DELETE_TEMPLATE_DRAFT: {
            return {
                ...state, batchDraft: state.batchDraft ? state.batchDraft.filter(d => d.id !== action.payload) : null,
            }
        }
        case ADD_NEW_SINGLE_TABLE_ROW_BATCH_CUSTOMERS: {
            return {
                ...state, batchTable: state.batchTable ? {...state.batchTable, consumers_data: [...state.batchTable.consumers_data, action.payload]} : null,
            }
        }
        case DELET_BATCH_CUSTOMERS_TABLE_ROW: {
            return {
                ...state, batchTable: state.batchTable ? {
                    ...state.batchTable,
                    consumers_data: state.batchTable.consumers_data.filter(row => !(row.id === action.payload || row.temporaryId === action.payload))
                } : null,
            }
        }
        case CHANGE_TEMPORARY_CONSUMER: {
            return {
                ...state, batchTable: state.batchTable
                    ? {
                        ...state.batchTable,
                        consumers_data: state.batchTable.consumers_data.map(c => c.temporaryId === action.payload.temporaryId ? action.payload.row : c)
                    }
                    : null,
            }
        }
        case ORDEREDIT_SET_PAYMENT_INTENT: {
            return {
                ...state, paymentIntentData: action.data,
            }
        }
        case ORDEREDIT_SET_CANCEL_OFFER_IDS: {
            return {
                ...state, canceledOffer: action.data,
            }
        }
        case CHANGE_RECALCULATED_DISCOUNTS: {
            return {
                ...state, recalculateDiscounts: action.data,
            }
        }
        case CHANGE_ORDER_COUPON: {
            return {
                ...state, coupon: action.data,
            }
        }
        default:
            return state;
    }
}
