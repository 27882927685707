import React from 'react';

import {TYPE_MENU, TYPE_SECTION_MODEL} from '../utils/enums';
import {defaultPromiseResolve} from '../utils/objects-util';

import MenuModel from './menuModel';

import IconButton from '../app/components/IconButton';
import {currentUserIsDepotRole} from "../services/userModel.js";
import {getFirstDepotId} from "../services/restaurant.js";

export function createSettingsMenu() {
  return [
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.users,
      title: "settings.users.USERS",
      icon: "user",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.users)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.cloudPrinters,
      title: "settings.cloudPrinters.CLOUD_PRINTERS",
      icon: "printIcon",
      isShow: () => currentUserIsDepotRole(),
      active: location => getIsActive(location, TYPE_SECTION_MODEL.cloudPrinters)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.deliveryProviders + '?id=' + getFirstDepotId(),
      title: "settings.depotSettingsLabel.PROVIDER",
      icon: "providersettings",
      isShow: () => currentUserIsDepotRole(),
      active: location => getIsActive(location, TYPE_SECTION_MODEL.deliveryProviders)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.depots,
      title: "settings.map.DEPOT_SETTINGS_LABEL",
      icon: "depot",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.depots) || location.pathname.includes("/settings/orderingmenu"),
      isWithIcon: function(state) {
        return ((state &&
          state.restaurant &&
          state.restaurant.data &&
          state.restaurant.data.depots) || []).some(depot => depot.disable_delivery || depot.disable_takeaway || depot.squareUpAuthorizationRequired)
            ? (<IconButton isIcon={true} iconName={"warn"} onClick={defaultPromiseResolve} />)
            : "";
      }
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.generalSettings,
      title: "settings.settings.LABEL",
      icon: "generalsetting",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.generalSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.geoSettings,
      nestedMenuType: TYPE_MENU.geoSettings,
      title: "settings.GEO_SETTINGS_LABEL",
      icon: "geoSettings",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.geoSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.personalSettings,
      title: "settings.personalSettings.LABEL",
      icon: "personalSettings",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.personalSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.courierManagement,
      nestedMenuType: TYPE_MENU.courierManagement,
      title: "settings.COURIER_MANAGEMENT_LABEL",
      icon: "courierManagement",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.courierManagement)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.businessAppSettings,
      title: "settings.businessAppConfiguration.LABEL",
      icon: "businessAppSettings",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.businessAppSettings)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.operationsManagement,
      title: "settings.OPERATIONS_MANAGEMENT_LABEL",
      nestedMenuType: TYPE_MENU.operationsManagement,
      icon: "operationsManagement",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.operationsManagement)
    }),
    new MenuModel({
      pathname: TYPE_SECTION_MODEL.integrations,
      nestedMenuType: TYPE_MENU.integrations,
      title: "settings.INTEGRATIONS_LABEL",
      icon: "integrations",
      active: location => getIsActive(location, TYPE_SECTION_MODEL.integrations)
    }),
  ];
}

function getIsActive(location, type) {
  const splitCurrentLocation = location.pathname.split("/");
  const splitType = type.split("/");
  return (splitCurrentLocation[1] === splitType[1] && splitCurrentLocation[2] === splitType[2]);
}
