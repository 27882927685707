import Store from "../store";
import { all, call, put, takeEvery, select } from "redux-saga/effects";

import { INIT, INIT_START } from "../reducers/init/init-actions";
import { actionLoadMarkers, actionRemoveMarker } from "../reducers/marker/marker-actions";
import { PUSHER_DEPOT_CYCLE_REFRESH } from '../reducers/userModel/userModel-actions';
import { actionControlCenterRefresh, actionCurrentControlCenterUpdate, actionRestaurantControlCentersStateUpdate, CONTROL_CENTER_REFRESH, setOrdersProvider } from "../reducers/restaurant/restaurant-actions";
import { actionNotificationUpdate, actionAlarmUpdate, actionAccountTopNotification } from "../reducers/notification/notification-actions";
import {
  actionAdminOrderPendingCountUpdate,
  actionPendingOrdersUpdate,
  PUSHER_GROUP_UPDATE
} from "../reducers/order/order-actions";
import { GET_INITIAL_DATA } from "../reducers/common-actions";

import { ordersSaga } from "./order-saga";
import { restaurantSaga } from "./restaurant-saga";
import {
  usersSaga,
  getUserByIdSaga,
  getDefaultUsersState,
} from './userModel-saga';

import { fetchExternalProviders, fetchUsers } from '../../api/user-requests';

import {
  fetchConsumerActiveOrders,
  fetchConsumerOrderMenu,
  fetchConsumerOrders,
  fetchPendingPreOrders, getAdminOrderPengingCount
} from "../../api/order-requests";
import { fetchRestaurant, getNotification, getDeliveryActiveState } from "../../api/restaurant-requests";

import DatabaseStorage from "../../services/databaseStorage";
import Interval from "../../services/interval";
import Pusher from "../../services/pusher";
import AudioService from "../../services/audio";
import BaseNotification from "../../services/baseNotification";
import RoleManager from "../../services/roleManage";
import User from "../../services/user";
import { getStoreDataKeys } from "../../services/dataStorage";

import { objectKeysToUpperLowerCase, mapObject, someObject } from "../../utils/objects-util";
import { createSaga, ErrorData, getCurrentInitConfig } from "../../utils/sagaHelper";
import { controlCenterUpdateConfig, depotCycleRefreshConfig, groupUpdateConfig } from "../../utils/constants";
import {DEFAULT_ORDERS_GROUPS, TYPE_MARKER} from "../../utils/enums";

import { getMarkers } from "../selectors";
import { actionExternalCyclesUpdate } from "../reducers/externalCycles/externalCycles-actions";
import { actionControlCenterRefreshLoading } from "../reducers/loaders/loaders-actions";

import {initSupportChat} from "../reducers/supportChat/supprtChat-actions";
import {fetchAuthSupportChat} from "../../api/supportChat-request";
import { updateBillingInfoData } from "../reducers/billing/billing-actions";
import {getUserConnectionData} from '../../utils/auth-util.js';
import { actionActiveStateCyclesSet } from '../reducers/cycles/cycles-actions.js';
import i18n from "../../i18n.js";
import InfoMessageService from "../../services/infoMessage.js";
import {actionSetEmptyInfoMessage} from "../reducers/emptyInfo/emptyInfo-actions.js";
import {sortByDate} from "../../services/filter.js";

const apiDictionary = {
  activeState: getDeliveryActiveState,
  consumerOrderMenu: fetchConsumerOrderMenu,
  consumerOrders: fetchConsumerOrders,
  notifications: getNotification,
  restaurant: fetchRestaurant,
  users: fetchUsers,
  consumerActiveOrders: fetchConsumerActiveOrders,
  pendingPreOrders: fetchPendingPreOrders,
  authSupportChat: fetchAuthSupportChat,
  externalProviders: fetchExternalProviders,
  pendingOrderCount: getAdminOrderPengingCount,
};

function* initSaga() {
  yield DatabaseStorage.instance;
}

function* initStartSaga(action) {
  yield* createSaga(function*() {
    let result = yield fetchAllDataSaga(getCurrentInitConfig(), true);
    // Finish processing. Report success
    if (result) {
      Interval.instance.start(Store.store);
      Pusher.instance.initPusher();
      BaseNotification.instance.playNotification();
      RoleManager.instance.setRolesManagerConfiguration();
      User.instance.setBaseConstants();

      const currentUser = User.instance.getCurrentUserInfo();
      if(currentUser?.configuration?.language) {
        i18n.changeLanguage(currentUser.configuration.language);
      }
      if(currentUser && currentUser.controlCenterId) {
        yield put(actionControlCenterRefresh({ selectedControlCenterMulti: [currentUser.controlCenterId] }));
      }
    } else {
      return new ErrorData("Error init start");
    }
  }, action.type);
}

function* checkRedirect(action) {
  try {
    const checkRedirect = InfoMessageService.instance.checkRedirect(Store.history.location.pathname);
    if(checkRedirect) {
      yield put(actionSetEmptyInfoMessage({ message: checkRedirect.message }));
      Store.history.push(checkRedirect.pathname);
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchAllDataSaga(configuration) {
  try {
    const apiCalls = {
      keys: call(getStoreDataKeys)
    };
    Object.keys(configuration).forEach(key => {
      if (configuration[key]) {
        apiCalls[key] = call(apiDictionary[key]);
      }
    });

    // Validate responses
    const apiCallResults = yield all(apiCalls);
    if (someObject(apiCallResults, result => result.status && result.status !== 200)) {
      return;
    }

    let result = mapObject(apiCallResults, result => objectKeysToUpperLowerCase(result.data || result.result));

    const activeState = (result && result.activeState) || {};
    
    result = {
      ...result,
      courierLocations: activeState.locations,
      cycles: activeState.courierCycles,
      orders: { groups: activeState.groups, preOrders: activeState.preOrders, takeawayOrders: activeState.takeawayOrders },
      assistanceOrders: activeState.assistance,
      externalCycles: activeState.externalCycles,
      activeOrders: activeState.activeOrders
    }

    if (result.restaurant) {
      yield restaurantSaga(result);
    }

    if(result.pendingPreOrders) {
      yield put(actionPendingOrdersUpdate({
        pendingPreOrders: (objectKeysToUpperLowerCase(result.pendingPreOrders) || [])
            .map(preorder => ({ ...preorder, groupId: DEFAULT_ORDERS_GROUPS.preOrders }))
            ?.sort((a, b) => sortByDate(a, b, 'creation_datetime'))
      }));
    }

    if(result.pendingOrderCount) {
      yield put(actionAdminOrderPendingCountUpdate(result.pendingOrderCount.count));
    }

    const curUserId = getUserConnectionData()?.unique_name || ''

    yield getUserByIdSaga({ data: { id: curUserId }})

    yield getDefaultUsersState({ cycles: result.cycles, courierLocations: result.courierLocations })

    if(result.cycles) {
      yield put(actionActiveStateCyclesSet(result.cycles))
    }

    if(activeState.controlCentersStatus) {
      yield put(actionRestaurantControlCentersStateUpdate(objectKeysToUpperLowerCase(activeState.controlCentersStatus)));
    }
      
    if (result.orders) yield ordersSaga(result);

    if (result.notifications && Array.isArray(result.notifications)) {
      yield put(actionNotificationUpdate(result.notifications));
      yield put(actionAlarmUpdate(result.notifications));
      
      result.notifications.forEach(notification=> {
        AudioService.instance.playSound(notification.type);
      });      
    }

    if(activeState.accountTopNotification) {
      yield put(actionAccountTopNotification(activeState.accountTopNotification))
    }

    if(result.externalCycles) {
      yield put(actionExternalCyclesUpdate(result));
    }

    if(result.authSupportChat) {
      yield put(initSupportChat(result.authSupportChat));
    }

    if(result.externalProviders) {
      yield put(setOrdersProvider(result.externalProviders));
    }

    yield checkRedirect();

    return result;
  } catch (error) {
    console.log(error);
  }
}

export function* groupUpdateDataSaga(configuration, excludedTypes) {
  const groupUpdateCalls = [];
  const apiCalls = { keys: call(getStoreDataKeys) };
  Object.keys(configuration).forEach(key => {
    if (configuration[key]) {
      apiCalls[key] = call(apiDictionary[key]);
    }
  });

  // Validate responses
  const apiCallResults = yield all(apiCalls);
  if (someObject(apiCallResults, result => result.status && result.status !== 200)) {
    return;
  }
  let result = mapObject(apiCallResults, result => objectKeysToUpperLowerCase(result.data || result.result));
  const activeState = (result && result.activeState) || {};

  result = {
    ...result,
    courierLocations: activeState.locations,
    cycles: activeState.courierCycles,
    orders: { groups: activeState.groups, preOrders: activeState.preOrders, takeawayOrders: activeState.takeawayOrders },
    assistanceOrders: activeState.assistance,
    externalCycles: activeState.externalCycles,
    activeOrders: activeState.activeOrders
  }

  if(result.cycles) {
    yield put(actionActiveStateCyclesSet(result.cycles))
  }

  if (result.restaurant) {
    groupUpdateCalls.push(restaurantSaga(result));

    if (result.restaurant.activeControlCenter) {
      const controlCenterId = result.restaurant.activeControlCenter.controlCenterId;
      if(controlCenterId) {
        groupUpdateCalls.push(put(actionControlCenterRefresh({ selectedControlCenterMulti: [controlCenterId], isInit: false })));
      }       
    }
  }

  if(result.pendingPreOrders) {
    groupUpdateCalls.push(put(actionPendingOrdersUpdate({
      pendingPreOrders: (objectKeysToUpperLowerCase(result.pendingPreOrders) || [])
          .map(preorder => ({ ...preorder, groupId: DEFAULT_ORDERS_GROUPS.preOrders }))
          ?.sort((a, b) => sortByDate(a, b, 'creation_datetime'))
    })));
  }

  if(activeState.controlCentersStatus) {
    groupUpdateCalls.push(put(actionRestaurantControlCentersStateUpdate(objectKeysToUpperLowerCase(activeState.controlCentersStatus))));
  }

  if (result.users || result.courierLocations || result.cycles) {
    groupUpdateCalls.push(usersSaga(result));
  }

  // if (result.orders) {
  //   groupUpdateCalls.push(ordersSaga(result));
  // }

  if (result.notifications && Array.isArray(result.notifications)) {
    groupUpdateCalls.push(put(actionNotificationUpdate(result.notifications)));
    groupUpdateCalls.push(actionAlarmUpdate(result.notifications));    
    result.notifications.forEach(notification=> {
      AudioService.instance.playSound(notification.type);
    });      
  }

  if(result.externalCycles) {
    groupUpdateCalls.push(put(actionExternalCyclesUpdate(result)));
  }

  if(activeState.accountTopNotification) {
    groupUpdateCalls.push(put(actionAccountTopNotification(activeState.accountTopNotification)));
  }

  if(result.billingInfo) {
    groupUpdateCalls.push(put(updateBillingInfoData(result.billingInfo)));
  }

  yield all(groupUpdateCalls);
  if (result.orders) {
    yield ordersSaga(result);
  }
  yield put(actionLoadMarkers({ isLoadUserMarkers: true }));
}

function* getInitialDataSaga(action) {
  yield* createSaga(function*() {
    yield put(actionLoadMarkers({ isLoadUserMarkers: true }));
  }, action.type);
}

function* groupUpdateSaga() {
  yield groupUpdateDataSaga(groupUpdateConfig);
}

function* depotCycleRefreshSaga() {
  yield groupUpdateDataSaga(depotCycleRefreshConfig);
}

function* controlCenterRefreshSaga(action) {
  yield* createSaga(function*() {
    const { selectedControlCenterMulti = [] } = action.data;
    const selectedControlCenterIds = selectedControlCenterMulti || [];
    const setedControlCenters = yield select(state => state.restaurant.data.selectedControlCenterMulti);
    const currentSelectedControlCenter = Pusher.instance.getControlCenterMultiChannel() || [];
    const duplicated = selectedControlCenterIds.every(cc => currentSelectedControlCenter.includes(cc));

    if(!duplicated || !setedControlCenters || currentSelectedControlCenter.length !== selectedControlCenterIds.length) {
      yield put(actionControlCenterRefreshLoading(true));
      //Clear current courier markers
      const markers = yield select(getMarkers);
      const markerTypesToRemove = [TYPE_MARKER.courier, TYPE_MARKER.order];
      yield put(actionRemoveMarker(markers
        .filter(marker=> markerTypesToRemove.includes(marker.markerType))
        .map(marker=> marker.id))
      );
      Pusher.instance.setControlCenterMultiChannel(selectedControlCenterIds);
      yield put(actionCurrentControlCenterUpdate({ selectedControlCenterMulti: selectedControlCenterIds }));

      Pusher.instance.resubscribeUpdatedControlCenters();
      yield groupUpdateDataSaga(controlCenterUpdateConfig, { users: false });

      //Subscribe pusher events
      yield put(actionControlCenterRefreshLoading(false));
    }    
  }, action.type);
}

export default function*() {
  yield takeEvery(INIT, initSaga);
  yield takeEvery(INIT_START, initStartSaga);
  yield takeEvery(GET_INITIAL_DATA, getInitialDataSaga); 
  yield takeEvery(PUSHER_GROUP_UPDATE, groupUpdateSaga);
  yield takeEvery(PUSHER_DEPOT_CYCLE_REFRESH, depotCycleRefreshSaga);
  yield takeEvery(CONTROL_CENTER_REFRESH, controlCenterRefreshSaga);  
}
