import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import ReviewItem from "../ReviewItem";

const CustomerReviewsData = ({ items, isLoading }) => {
    return (
        <div className={classNames("customerReviewsData")}>
            {isLoading && (
                <div className={classNames("loaderContainer")}>
                    <div className={classNames("loader")} />
                </div>
            )}
            {!isLoading && (items || []).map((item, i)=> (<ReviewItem key={`reviewItem-${i}`} value={item} />))}
        </div>
    );
}

const mapStateToProps = state => ({
    items: state.customer.reviews || []
});

export default connect(mapStateToProps)(CustomerReviewsData);