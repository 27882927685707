import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { WAITER_TYPES } from "../../../utils/enums";

import IconButton from "../IconButton";
import Waiter from "../Waiter/Waiter";

const FilterTriggerControl = ({ isDefault = false, onClick, filterCounter }) => {
    const { t } = useTranslation();
    const handleOnClick = () => {
        return new Promise(resolve=> {
            onClick && onClick();
            resolve();
        });
    }

    return (
        <button className={classNames("btn", "confirm", "filterTriggerControl", { isDefault: isDefault })} 
            onClick={handleOnClick}
        >
            <span className={"filterTriggerControlTitle"}>{t("orders.FILTERS_LABEL")}</span>
            {isDefault && (
                <Waiter type={WAITER_TYPES.svg} useClass="filterIcon" useSvg="#icon-filter" onClick={handleOnClick} />
            )}
            {!isDefault && (<IconButton isIcon iconName="filters" onClick={handleOnClick} />)}
            {!!filterCounter && (
                <div className={'badge'}>{filterCounter}</div>
            )}
        </button>
    );
}

export default FilterTriggerControl;