import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import Dropdown from "../Dropdown";
import IconButton from "../IconButton";
import classnames from "classnames";

const OrdersFilterDropDownControl = ({ optionKey, option, onOptionChange, rootState }) => {
    const items = (option.mappingLambda ? option.mappingLambda(rootState) : option.items) || [];
    const getOptionComboValue = () => {
        const item = items.find(f=> f.value === option.selected) || {};
        return item.key;
    }

    if(option.disabled) return null;
    
    return (
        <div className={classNames("ordersFilterDropDownControl")}>
            <div className={classNames("title")}>{option.label || ""}</div>
            {items.length > 3
                ? (
                    <Dropdown
                        values={items}
                        labelField="key"
                        valueField="value"
                        searchBy="key"
                        value={getOptionComboValue()}
                        onSelect={item => onOptionChange(optionKey, "selected", item.value)}
                    />
                )
                : (
                    <div className={'selectBlocks'}>
                        {items.map(({key, value}) => (
                            <span
                                className={classnames('selectBlockItem', {selected: option.selected === value})}
                                onClick={() => onOptionChange(optionKey, "selected", option.selected === value ? null : value)}
                            >
                                <span>{key}</span>
                                {option.selected === value && value !== null && (
                                    <IconButton isIcon iconName={'iconDelet'}/>
                                )}
                            </span>
                        ))}
                    </div>
                )
            }
        </div>
    );
}

const mapStateToProps = state => ({
    rootState: state
});

export default connect(mapStateToProps)(OrdersFilterDropDownControl);