import React from "react";
import './index.scss';

export const OrderOptionMenuComments = ({title, comment}) => {
    return (
        <div className={'orderMenuOption'}>
            <div className={"title"}>{title}</div>
            <div className={"group"}>
                <div className={"suboptions"}>
                    &nbsp;{comment}
                </div>
            </div>
        </div>
    )
}