import ChartModel from "../../models/chartModel";
import { defaultControlCenterColors } from "../../utils/constants";
import { TYPE_CHART, CHART_MODEL } from "../../utils/enums";

export function createInProcessOrdersChartModel(data) {
  return new ChartModel({
    typeModel: CHART_MODEL.inProcessOrders,
    chartType: TYPE_CHART.pieChart,
    title: "",
    data: data,
    height: "200px",
    options: {
      chartArea: { left: 5, top: 20, width: "100%", height: "100%" },
      colors: defaultControlCenterColors
    }
  });
}
