import * as Sentry from '@sentry/react';
import environment from 'environment';
import Store from '../../src/store/store';
import i18n from '../i18n';

import { fetchMarkerAvatarBase64 } from '../utils/image-util';
import {
  COURIER_STATE,
  CYCLE_STATE,
  FORMAT_RELATIVE_TIME_ONLY_TYPE,
  ORDER_STATE,
  ORDER_TYPE,
  PICKUP_STATE,
  SEVERITY_NOTIFICATION,
  SHIFT_TYPE,
  TYPE_STORAGE,
  USER_ROLES,
  VEHICLE_TYPE,
} from '../utils/enums';
import { getFormatRelativeTime, getStaticTime } from '../utils/convertTime';
import { getUserConnectionData } from '../utils/auth-util';
import {NEW_ASSOCIATE_GROUP_ID} from '../utils/constants';

import ColorService from './color';
import User from './user';
import { getStoreData, saveStoreData } from './dataStorage';
import { isDefaultGroup } from './order';
import {getInitialControlCenters} from './restaurant';
import { sortItemsByProp } from './filter';

export function getAvatar(id, keys, url) {
  return new Promise((resolve, reject) => {
    if (keys.length > 0 && keys.includes(id)) {
      getStoreData(TYPE_STORAGE.db, id).then(response => resolve(response));
    } else {
      if(url) {
        fetchMarkerAvatarBase64(url).then(image=> {
          if(image) {
            saveStoreData(TYPE_STORAGE.db, id, false, image).then(
              () => resolve({ data: image }),
              ()=> Sentry.captureException("Error saveStoreData DB. Courier id: " + id)
            );
          } else {
            Sentry.captureException("Error fetchMarkerAvatarBase64 DB. Courier id: " + id);
          }
        }, ()=> {
          saveStoreData(TYPE_STORAGE.db, id, false, "").then(
            () => resolve({ data: "" }),
            ()=> Sentry.captureException("Error saveStoreData. Courier id: " + id)
          );
        })
      } else {
        saveStoreData(TYPE_STORAGE.db, id, false, "").then(
          () => resolve({ data: "" }),
          ()=> Sentry.captureException("Error saveStoreData. Courier id: " + id)
        );
      }
    }
  });
}

export function isRTL() {
  const language = User.instance.getCurrentUserConfigurationProp("language", true);
  return language === "he";
}

export function isStartFromMonday() {
  return false;
}

export function getShortName(courier) {
  return (((courier || {}).firstName || "")[0] || "") + 
    (((courier || {}).lastName || "")[0] || "");
}

export function getExternalCycleShortName(externalCycle) {
  return ((externalCycle &&
    externalCycle.driverInfo &&
    externalCycle.driverInfo?.name) || "")[0] || "";
}

export function getExternalCycleName(externalCycle) {
  return ((externalCycle &&
    externalCycle.driverInfo &&
    externalCycle.driverInfo.name) || "");
}

export function getUserName(user) {
  return (
    user.userName ||
    (user.firstName ? user.firstName + " " : "") +
      (user.lastName ? user.lastName : "")
  );
}

export function isValidPasswordData(data) {
  return (
    !!(data.newPassword && data.retypePassword && data.userName) &&
    data.newPassword === data.retypePassword
  );
}

export function getCourierColor(courier) {
  return User.instance.getCurrentUserConfigurationProp("courierColorScheme")
    ? ColorService.instance.getColorForStateLocation(courier)
    : courier.color;
}

export function getNotificationColorCode(order) {
  const colors = User.instance.getCurrentUserConfigurationProp("ordersColors");
  if (colors && order.notificationType) {
    if(order.notificationType.includes(SEVERITY_NOTIFICATION.orderNotDeliveredInTime)) {
      return ColorService.instance.getCodeByColorKey("z");
    }
    if(order.notificationType.includes(SEVERITY_NOTIFICATION.orderCanLate)) {
      return ColorService.instance.getCodeByColorKey("gg");
    }
  }
  return '';
}

export function updateUserColor(user) {
  if (user.shiftType) {
    // end shift
    if (user.color) {
      ColorService.instance.freeColor(user.color);
      user.color = null;
    }
  } else {
    // start shift
    if (!user.color) user.color = ColorService.instance.getColor();
  }
  return user;
}

export function initUserModel(user) {
  if(!user) return null;
  const roles = getRolesFromUser(user);
  return {
    ...user,
    phoneNumber: (user.verifiedPhone || {}).nationalFormatedNumber || user.phoneNumber,
    courierState: user.courierState || COURIER_STATE.default,
    roles,
    authorized: { courier: roles.courier || user.hasDevices, deliveryManager: user.hasPassowrd }
  };
}

export function getRolesFromUser(user) {
  if (!user || !Array.isArray(user.roles)) return (user && user.roles) || {};

  const roleNames = user.roles.map(role => role?.name);
  return {
    courier: roleNames.length && roleNames.includes(environment.USER_ROLES.courier),
    deliveryManager: roleNames.length && roleNames.includes(environment.USER_ROLES.deliveryManager),
    controlCenterManager: roleNames.length && roleNames.includes(environment.USER_ROLES.controlCenterManager),
    supervisor: roleNames.length && roleNames.includes(environment.USER_ROLES.supervisor),
    supportOperator: roleNames.length && roleNames.includes(environment.USER_ROLES.supportOperator),
    depot: roleNames.length && roleNames.includes(environment.USER_ROLES.depot),
    active:
      roleNames.length &&
      roleNames.includes(environment.USER_ROLES.courier) &&
      (user.cycle &&
        user.cycle.courierId &&
        user.cycle.courierId === user.userId)
  };
}

export function currentUserIsDepotRole() {
  const user = getUserConnectionData() || {};
  return Array.isArray(user.role)
    ? user.role.includes(environment.USER_ROLES.depot)
    : user.role === environment.USER_ROLES.depot;
}

export function currentUserIsOnlyDepotRole() {
  const user = getUserConnectionData() || {};
  return Array.isArray(user.role)
    ? user.role.length === 1 && user.role.includes(environment.USER_ROLES.depot)
    : user.role === environment.USER_ROLES.depot;
}

export function currentUserIsSupportOperatorRole() {
  const user = getUserConnectionData();
  return Array.isArray(user.role)
    ? user.role.includes(environment.USER_ROLES.supportOperator)
    : user.role === environment.USER_ROLES.supportOperator;
}

export function currentUserIsOnlySupportOperatorRole() {
  const user = getUserConnectionData() || {};
  return Array.isArray(user.role)
    ? (user.role.length === 1 && user.role.includes(environment.USER_ROLES.supportOperator))
    : user.role === environment.USER_ROLES.supportOperator;
}

export function currentUserIsSupervisorRole() {
  const user = getUserConnectionData();
  return user && user.role && user.role.includes(environment.USER_ROLES.supervisor);
}

export function currentUserIsControlCenterManagerRole() {
  const user = getUserConnectionData();
  return user && user.role && user.role.includes(environment.USER_ROLES.controlCenterManager);
}

export function currentUserIsDeliveryManager() {
  const user = getUserConnectionData();
  return user && user.role && user.role.includes(environment.USER_ROLES.deliveryManager);
}

export function currentUserIsWithoutControlCenter() {
  const currentUser = User.instance.getCurrentUserInfo() || {};
  const controlCenters = getInitialControlCenters();

  return !currentUserIsSupervisorRole() &&
    !currentUserIsControlCenterManagerRole() &&
    !currentUserIsOnlySupportOperatorRole() &&
    currentUserIsDeliveryManager() &&
    controlCenters.length &&
    !currentUser.controlCenterId;
}

export function isShowControlCenterSearch() {
  const currentUser = User.instance.getCurrentUserInfo() || {};
  return currentUserIsControlCenterManagerRole() && !currentUser.controlCenterId;
}

export function getFullName(user) {
  return user ? `${user.firstName || ``} ${user.lastName || ``}`.trim() : "";
}

export function getProviderName(provider) {
  return (provider || {})?.name || (provider || {}).providerName;
}

export function getSwitchedFullName(user) {
  return `${user.lastName || ``} ${user.firstName || ``}`.trim();
}

export function getCourierNameById(userId) {
  if(!userId || isDefaultGroup(userId)) return null;

  const state = Store.store.getState();
  const courier = state.userModel.data.find(f=> f.userId === userId);
  return getFullName(courier);
}

export function isExistStatusDelivery(order) {
  return order.groupId && !isDefaultGroup(order.groupId);
}

export function getTotalCourierEstimation(courier, isReturnTime) {
  let time = 0;
  const travelData = (courier && ((courier.travelData && courier.travelData.progress) || courier.routeEstimation)) || {};

  if (travelData.segments) {
    time = travelData.segments.reduce((a, segment) =>
        a + ((segment.timeToArrivalInSec || 0) + (segment.unloadTime || 0)), 0
    );
  }

  if(isReturnTime) return time * 1000;

  let resultTime = "";
  if (time > 0) {
    resultTime = getFormatRelativeTime(
      time * 1000,
      undefined,
      true,
      isRTL(),
      FORMAT_RELATIVE_TIME_ONLY_TYPE.m
    );
  }

  return resultTime;
}

export function getCourierArrivalEstimation(courier) {
  if (
    !courier.travelData ||
    !courier.travelData.progress ||
    !courier.travelData.progress.segments ||
    courier.cycle.state < CYCLE_STATE.deliveryStart
  )
    return "";

  const time = calculatePreviousTime(
    courier.travelData.progress.segments,
    courier
  );
  let resultTime = "";
  if (time > 0) {
    var mSec = time * 1000;
    if (courier.cycle.state === CYCLE_STATE.comingBack) {
      resultTime = getFormatRelativeTime(mSec, undefined, true, isRTL());
    } else {
      resultTime = getStaticTime(mSec + new Date().getTime(), false, isRTL());
    }
  }

  return resultTime;
}

export function calculateOrderPreviousTime(order, segments, orders) {
  let a = 0;
  const orderIndex = segments
    .map(segment => segment.correspondingId)
    .indexOf(order.id);
  if (orderIndex < 0) return a;

  for (let i = 0; i <= orderIndex; i++) {
    const segmentOrder = orders.find(
      item => item.id === segments[i].correspondingId
    );

    if (segmentOrder && ((segmentOrder.type === ORDER_TYPE.delivery && segmentOrder.state < ORDER_STATE.delivered) || (segmentOrder.type === ORDER_TYPE.pickUp && segmentOrder.state < PICKUP_STATE.arrived))) {
      a += (i > 0
          ? ((segments[i - 1].unloadTime || 0) + (segments[i].timeToArrivalInSec || 0))
          : (segments[i].timeToArrivalInSec || 0));
    }
  }

  return a;
}

export function getProgressSegment(travelData, id) {
  return travelData &&
    travelData.progress &&
    travelData.progress.segments.find(segment => segment.correspondingId === id);
}

export function separateName(name) {
  let arr = name?.trim().split(" ");
  if (arr.length === 1)
    return {
      lastName: "",
      firstName: arr.pop()
    };

  return {
    lastName: arr.pop(),
    firstName: arr.join(" ")
  };
}

export function getPersonalSettings(user) {
  if (!user) return {};
  const visualSettings = (user.configuration || {}).visualSettings || {};
  const {
    isHideTakeawayGroup,
    isHidePreordersGroup,
    pendingOrdersPanelLocation,
    couriersPanelLocation,
    courierColorScheme,
    defaultDashboardMode,
    isHideDeliveryRegions,
    isShowTraffic,
    enableAlarmPopups,
    orderNotDeliveredInTimeNotificationEnabled,
    courierComingBackEnabled,
    courierWrongRouteEnabled,
    courierReadyToDispatchEnabled,
    assistanceExistsEnabled,
    courierStoppedEnabled,
    boxLostEnabled,
    courierLostEnabled,
    orderCanLateEnabled,
    sortOrdersBasedOnReadinessTime,
    visualizeReadinessTime,
    ordersColors,
    newOrderSoundNotification,
    showAllDepotsOnMap,
    consumerOrderCreatedNotificationEnabled,
    isShowExtraSizeGroup,
    isHideOrderId,
    showOrderIdOnMap,
    filterMapObjectsByControlCenter,
    alwaysShowOrderTargetTime
  } = visualSettings;
  return {
    id: user.userId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    userId: user.userId,
    userName: user.userName,
    avatarUrl: user.avatarUrl,
    language: (user.configuration || {}).language,
    roles: user.roles,
    currentPassword: "",
    ...visualSettings,
    isHideTakeawayGroup: !isHideTakeawayGroup,
    isHidePreordersGroup: !isHidePreordersGroup,
    pendingOrdersPanelLocation: !pendingOrdersPanelLocation,
    couriersPanelLocation: !couriersPanelLocation,
    courierColorScheme: !courierColorScheme,
    defaultDashboardMode: !defaultDashboardMode,
    isHideDeliveryRegions: isHideDeliveryRegions,
    isShowTraffic: !!isShowTraffic,
    enableAlarmPopups: !!enableAlarmPopups,
    orderNotDeliveredInTimeNotificationEnabled: !!orderNotDeliveredInTimeNotificationEnabled,
    courierComingBackEnabled: !!courierComingBackEnabled,
    courierWrongRouteEnabled: !!courierWrongRouteEnabled,
    courierReadyToDispatchEnabled: !!courierReadyToDispatchEnabled,
    assistanceExistsEnabled: !!assistanceExistsEnabled,
    courierStoppedEnabled: !!courierStoppedEnabled,
    boxLostEnabled: !!boxLostEnabled,
    courierLostEnabled: !!courierLostEnabled,
    orderCanLateEnabled: !!orderCanLateEnabled,
    sortOrdersBasedOnReadinessTime: !!sortOrdersBasedOnReadinessTime,
    visualizeReadinessTime: !!visualizeReadinessTime,
    ordersColors: !!ordersColors,
    newOrderSoundNotification: !!newOrderSoundNotification,
    showAllDepotsOnMap: !!showAllDepotsOnMap,
    consumerOrderCreatedNotificationEnabled: !!consumerOrderCreatedNotificationEnabled,
    isShowExtraSizeGroup: !!isShowExtraSizeGroup,
    isHideOrderId,
    showOrderIdOnMap: !!showOrderIdOnMap,
    filterMapObjectsByControlCenter: !!filterMapObjectsByControlCenter,
    alwaysShowOrderTargetTime: !!alwaysShowOrderTargetTime
  };
}

export function getUsers() {
  const state = Store.store.getState();
  return state.userModel.data || [];
}

export function getConfirmUsers(users) {
  return sortItemsByProp(users
    .filter(user => user.roles && user.roles.courier)
    .map(user=> ({ ...user, fullName: getFullName(user) })), "fullName");
}

export function getControlCenterUsers(state) {
  state = state || Store.store.getState();
  const users =
    (state.userModel.data || []).filter(
      user =>
        !user.isDeleted &&
        !user.controlCenterId &&
        user.roles.deliveryManager &&
        !user.roles.supervisor
    ) || [];
  return users.map(user => ({
    key: user.userId,
    value: getFullName(user)
  }));
}

export function getUserById(id) {
  if(!id || !Store) return null;
  const state = Store.store.getState();
  return ((state &&
    state.userModel &&
    state.userModel.data) || []).find(user => user.userId === id);
}

export function getControlCenterActive() {
  if(!Store) return null;
  const state = Store.store.getState();
  return state &&
    state.restaurant &&
    state.restaurant.data &&
    state.restaurant.data.activeControlCenter;
}

export function getShiftsTimePerDay(shifts) {
  let result = 0;

  if (shifts) {
    result = shifts.reduce((a, b) => {
      return a + Number(getTimePerDay(b));
    }, 0);
  }

  return result.toFixed(2);
}

export function getTimePerDay(shift) {
  if (shift.startDate) {
    const endDate = shift.endDate ? new Date(shift.endDate) : new Date();
    const startDate = new Date(shift.startDate);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    startDate.setMilliseconds(0);
    startDate.setSeconds(0);
    const difference = endDate - startDate;
    return (difference / 3600000).toFixed(2);
  }
  return "";
}

export function getDifferenceTimeInMinute(time) {
  const delta = new Date(time) - new Date();
  return Math.floor(delta / (1000 * 60));
}

export function calculatePreviousTime(segments, courier) {
  let previusTime = 0;
  let progress;
  let items;

  if (courier) {
    // Coming back
    if (
      courier.travelData &&
      courier.travelData.progress &&
      courier.cycle.state === CYCLE_STATE.comingBack
    ) {
      let timeToArrival = 0;
      courier.travelData.progress.segments.forEach(segment => {
        if (segment.timeToArrivalInSec) timeToArrival = segment.timeToArrivalInSec;
      });
      return timeToArrival;
    }

    if (
      courier.travelData &&
      courier.travelData.progress &&
      courier.travelData.progress.correspondingId
    ) {
      progress = courier.travelData.progress;
    }

    const state = Store.store.getState();
    items = state.order.data.orders
      .filter(order => order.groupId === courier.userId)
      .concat(
        state.order.data.pickUps.filter(
          order => order.groupId === courier.userId
        )
      );
  }

  segments.forEach(segment => {
    let segmentOrder;
    if (items) {
      segmentOrder = items.find(order => order.id === segment.correspondingId);
    }
    if (segmentOrder && segmentOrder.state > 3) return;

    if (progress && segment.correspondingId === progress.correspondingId) {
      if (segment.timeToArrivalInSec > 0) previusTime += segment.timeToArrivalInSec;
    } else if (segment.travelEstimation) {
      previusTime += segment.travelEstimation;
    }

    if (segment.unloadTime) {
      previusTime += segment.unloadTime;
    }
  });
  return previusTime;
}

export function getETATimeForOrder(order) {
  if(!order || isDefaultGroup(order.groupId)) return "";
  const courier = getUserById(order.groupId);
  if(!courier) return "";
  const segment = getProgressSegment(courier.travelData, order.id);
  const time = segment ? segment.timeToArrivalInSec : 0;
  return time > 0 ? getStaticTime(time * 1000 + new Date().getTime(), false, isRTL()) : "";
}

export function getActiveCouriers(users) {
  return (users || [])
    .filter(user=> user.roles && user.roles.courier && user.roles.active)
    ?.sort((a, b)=> a.queueNumber - b.queueNumber);
}

export function futureRouteInfo() {
  return [
    {
      userId: NEW_ASSOCIATE_GROUP_ID,
      firstName: i18n.t("dialogs.couriers.FUTURE_ROUTE_LABEL"),
      avatarUrl: null,
      color: null,
      vehicleType: VEHICLE_TYPE.delivery
    }
  ]
}

export function getDefaultUserRolesValue() {
  let roles = {};
  Object.keys(USER_ROLES).forEach(key=> {
    roles[key] = false;
  });

  return roles;
}

export function isCanStartShift(user) {
  if(!user || !user.roles) return false;
  return (user.roles.courier && !user.roles.active) ||
    (user.roles.courier && user.roles.active && user.cycle && user.cycle.shiftType !== SHIFT_TYPE.Normal);
}

export function isCanEndShift(user) {
  if(!user || !user.roles) return false;
  return (user.roles.courier && user.roles.active && user.cycle && user.cycle.shiftType === SHIFT_TYPE.Normal);
}

export function getUserByOrder(users, order) {
  if(!Array.isArray(users) || !order || !order.groupId || isDefaultGroup(order.groupId)) return null;
  return users.find(user=> user.userId === order.groupId);
}

export function getUserByGroupId(users, groupId) {
  if(!Array.isArray(users) || !groupId || isDefaultGroup(groupId)) return null;
  return users.find(user=> user.userId === groupId);
}

export function getDistinctUsersIdsFromData(data) {
  if(!Array.isArray(data) || !data.length) return [];

  return data.reduce((acc, item)=> {
    const userId = item.userId || item.courierId || item.courier_id;
    if(userId && !acc.includes(userId)) {
      acc.push(userId);
    }
    return acc;
  }, [])
}

export const getDropOffEtaMax = (courier, orders) => {
  const dates = (orders || ((courier || {}).cycle || {}).orders || [])
    .filter(f=> f.dropoff_eta)
    .map(m=>  new Date(m.dropoff_eta).getTime());
  if(!dates.length) return null;
  return Math.max(...dates);
}

export default {
  updateCourierLocations: (state, data) => {
    return {
      ...state,
      data: state.data.map(user => {
        let travelData = data.find(f => f.courierId === user.userId);
        return travelData ? { ...user, travelData } : user;
      })
    };
  },
  cycleStateUpdate: (state, data) => {
    if (User.instance.skipObjectWithControlCenter(data)) return state;
    const isCleanOrders = data.cycleState === CYCLE_STATE.started && data.cleanOrders;
    return {
      ...state,
      data: state.data.map(user => {
        return user.userId === data.courierId
          ? {
              ...user,
              cycle: data.cycleState === CYCLE_STATE.closed
                  ? {...user.cycle }
                  : {
                      ...user.cycle,
                      controlCenterId: data.controlCenterId,
                      queueNumber: data.queueNumber,
                      state: data.cycleState,
                      retrieved: data.retrieved || (user.cycle && user.cycle.retrieved),
                      routeEstimation: isCleanOrders
                        ? {}
                        : (user.cycle && user.cycle.routeEstimation),
                      optimalData: isCleanOrders
                        ? { totalDistance: 0, totalTime: 0 }
                        : (user.cycle && user.cycle.optimalData)
                    },
              roles: data.cycleState === CYCLE_STATE.closed
                  ? { ...user.roles, active: false }
                  : user.roles
            }
          : user;
      })
    };
  }
};
