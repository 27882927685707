import React from 'react';
import './style.scss';
import classNames from "classnames";
import IconButton from "../IconButton/IconButton.jsx";
import {getFullName} from "../../../services/userModel.js";
import {getOrderInfoAddress} from "../../../services/order.js";
import {
    DELIVERY_STRATEGY,
    getExternalCourierProviderNameByProvider,
    getLoyaltyLevel,
    PENDING_ORDER_TYPE
} from "../../../utils/enums.js";
import {DATE_TIME_FORMAT_DAYTIME, getFormattedDate} from "../../../utils/convertTime.js";
import {useTranslation} from "react-i18next";
import {getExternalCycleLogoByProvider} from "../../../services/restaurant.js";
import {Avatar} from "../Avatar/Avatar.jsx";
import {deliveryIconNames} from "../../../utils/constants.js";

const PhoneIcon = () => (
    <div className={classNames("phoneIconLogo")}>
        <div className={classNames("phoneIcon")}></div>
    </div>
);


const AdminOrderInfoClientAndDelivery = ({order, handleOnSearchId}) => {
    const {t} = useTranslation();
    const deliveryLabels = {
        [PENDING_ORDER_TYPE.delivery]: t("dashboard.orders.DELIVERY_ORDERS_MODE_LABEL"),
        [PENDING_ORDER_TYPE.takeAway]: t("dashboard.orders.TAKEAWAY_ORDERS_MODE_LABEL"),
        [PENDING_ORDER_TYPE.dinein]: t("dashboard.orders.DINEIN_ORDERS_MODE_LABEL"),
    }
    return (
        <div className={classNames("adminOrderInfoClientAndDelivery adminOrderInfoItem", "full")}>
            <div className={classNames("data")}>
                <div className={classNames("adminOrderInfoItem")}>
                    <div className={classNames("title")}>
                        {t("admin-orders.CLIENT_INFO_LABEL")}
                        <IconButton isIcon={true} iconName={"search"} onClick={handleOnSearchId}/>
                    </div>
                    <div className={classNames("data")}>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.CLIENT_INFO_NAME_LABEL")}</div>
                            <div className={classNames("inline customerName")}>
                                {order.customer_name ||  getFullName(order.consumerInfo) || order.customerName || "-"}
                                {order.new_customer && (<div className={'customerNew'}>{t("pending-orders.PENDING_ORDER_INFO_NEW_CUSTOMER")}</div>)}
                                {!!order.loyalty_level && (<div className={'loyaltyLevel'}>{getLoyaltyLevel(order.loyalty_level) || "-"}</div>)}
                            </div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.CLIENT_INFO_EMAIL_LABEL")}</div>
                            <div className={classNames("inline")}>
                                {order.email || (order.consumerInfo && order.consumerInfo.email) || "-"}
                            </div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.CLIENT_INFO_PHONE_LABEL")}</div>
                            <div className={classNames("inlineFlex")}>
                                <span className={classNames({neverRtl: true })}>{ order.phone || (order.consumerInfo && order.consumerInfo.phone) || "-"}</span>
                                <PhoneIcon />
                            </div>
                        </div>
                        <div className={classNames("dataItem", "full")}>
                            <div className={classNames("title")}>{t("admin-orders.CLIENT_INFO_ADDRESS_LABEL")}</div>
                            <div>
                                {getOrderInfoAddress(order)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames("adminOrderInfoItem")}>
                    <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_LABEL")}</div>
                    <div className={classNames("data")}>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>&nbsp;</div>
                            <div className={classNames("inlineFlex")}>
                                <IconButton isIcon={true} iconName={deliveryIconNames[order.delivery_type]}
                                            onClick={()=> new Promise(resolve=> resolve())}
                                />
                                <span className={classNames("blueColor")}>
                                    {deliveryLabels[order.delivery_type]}
                                </span>
                            </div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_COURIER_LABEL")}</div>
                            <div>{[PENDING_ORDER_TYPE.takeAway, PENDING_ORDER_TYPE.dinein].includes(order.delivery_type) ? "-" : (order.courierInfo && order.courierInfo.courierName) || "-"}</div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_PROVIDER")}</div>
                            <div className={'provider'}>
                                {order.courierInfo && order.courierInfo.provider ? <Avatar
                                    avatar={getExternalCycleLogoByProvider(order.courierInfo.provider)}
                                    title={getExternalCourierProviderNameByProvider(order.courierInfo.provider)}
                                /> : '-'}
                            </div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_COURIER_PHONE_LABEL")}</div>
                            <div>
                                <span className={classNames({neverRtl: true })}>{(order.courierInfo && order.courierInfo.phone) || "-"}</span>
                                <PhoneIcon />
                            </div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_CREATION_DATE_TIME_LABEL")}</div>
                            <div>{getFormattedDate(order.creation_datetime, DATE_TIME_FORMAT_DAYTIME)}</div>
                        </div>
                        {order.delivery_strategy === DELIVERY_STRATEGY.concreteTime && (
                            <div className={classNames("dataItem")}>
                                <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_DATE_TIME_LABEL")}</div>
                                <div>{getFormattedDate(order.delivery_datetime, DATE_TIME_FORMAT_DAYTIME)}</div>
                            </div>
                        )}
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.DELIVERY_INFO_ACTUAL_DATE_TIME_LABEL")}</div>
                            <div>{getFormattedDate(order.actual_delivery_datetime, DATE_TIME_FORMAT_DAYTIME) || "-"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminOrderInfoClientAndDelivery;
