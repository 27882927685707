import React from "react";
import classNames from "classnames";
import "./style.scss";
import {SALES_TYPES} from "../../../utils/enums";
import {useTranslation} from "react-i18next";
import IconButton from "../IconButton";

const CustomerSalesTopTenHeader = ({ activeTab, setActiveTab, isDesc }) => {
    const {t} = useTranslation();
    return (
        <thead className={"customerSalesTopTenHeader"}>
            <tr>
                <td className={"label"}>{t("sales.TOP_10_ITEMS")}</td>
                {false && (<td onClick={() => setActiveTab(SALES_TYPES.items)}
                                className={classNames("tabItem", {active: activeTab === SALES_TYPES.items})}>
                    {activeTab === SALES_TYPES.items && (
                        <IconButton isIcon iconName={classNames(isDesc ? "sortDesc" : "sortAsc")}/>)}
                    {t("sales.ITEM")}
                </td>)}
                <td onClick={() => setActiveTab(SALES_TYPES.source)} className={classNames("tabItem", { active: activeTab === SALES_TYPES.source })}>
                    <div className={'tabBlock'}>
                        {activeTab === SALES_TYPES.source && (<IconButton isIcon iconName={classNames(isDesc ? "sortDesc" : "sortAsc")}/>)}
                        {t("sales.SOURCE")}
                    </div>
                </td>
                <td onClick={() => setActiveTab(SALES_TYPES.quantity)} className={classNames("tabItem", { active: activeTab === SALES_TYPES.quantity })}>
                    <div className={'tabBlock'}>
                        {activeTab === SALES_TYPES.quantity && (<IconButton isIcon iconName={classNames(isDesc ? "sortDesc" : "sortAsc")}/>)}
                        {t("sales.QUANTITY")}
                    </div>
                </td>
                <td onClick={() => setActiveTab(SALES_TYPES.income)} className={classNames("tabItem", { active: activeTab === SALES_TYPES.income })}>
                    <div className={'tabBlock'}>
                        {activeTab === SALES_TYPES.income && (<IconButton isIcon iconName={classNames(isDesc ? "sortDesc" : "sortAsc")}/>)}
                        {t("sales.INCOME")}
                    </div>
                </td>
            </tr>
        </thead>
    )
};

export default CustomerSalesTopTenHeader;