import React from "react";
import "./style.scss";
import {Avatar} from "../Avatar/Avatar";
import IconButton from "../IconButton";

export const UserItem = ({avatar, name, hasButton, onClick}) => {
    return (
        <div className={"userItem"}>
            <div className={"userData"}>
                <Avatar avatar={avatar}/>
                <span>{name}</span>
            </div>
            {hasButton && (
                <button onClick={onClick}>
                    <IconButton onClick={onClick} isIcon iconName={"iconPlus"}/>
                </button>
            )}
        </div>
    )
}