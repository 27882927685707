import React, { useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import "./index.scss";

import { ADMIN_ORDER_REFUND } from "../../../store/reducers/order/order-actions";
import { getInitModel } from "../../../services/initModels";
import { createAction } from "../../../utils/sagaHelper";
import {
    getReshipmentTypes,
    MODEL_TYPE,
    getRefoundChannelType,
    REFOUND_CHANNEL_TYPE, getRefoundBonusChannelType,
} from "../../../utils/enums";

import LayoutHeaderControls from "../LayoutHeaderControls";
import OnOffSwitch from "../OnOffSwitch";
import Dropdown from "../Dropdown";
import {currentUserIsDepotRole} from "../../../services/userModel.js";

const RefundOrder = ({ t, id, maxAmount, onClose, refundOrder, paymentId, bonusRefund }) => {
    const minAmount = 0.01;
    const [refundType, setRefundType] = useState(getReshipmentTypes(currentUserIsDepotRole())[0]);
    const [refundChannel, setRefundChannel] = useState(
        bonusRefund ? getRefoundBonusChannelType()[0]: getRefoundChannelType()[0]
    );
    const [data, setData] = useState(getInitModel(MODEL_TYPE.refundModel, { id, paymentId }));

    const handleOnSave = () => {
        return new Promise((resolve, reject)=> {
            const body = {...data, refund_channel: refundChannel.key, refund_type: refundType.key}
            refundOrder(body).then(()=> {
                resolve();
                onClose();
            }, error=> {
                reject(error);
                toast(error);
            });
        });
    }

    const handleOnChange = (prop, value) => {
        setData({ ...data, [prop]: value });
    }

    const handleOnAmountChange = e => {
        const num = e.target.value;
        handleOnChange("amount", Number(parseFloat(num).toFixed(2)));
    }

    const isDisabled = () => {
        if(refundChannel.key === REFOUND_CHANNEL_TYPE.creditCard){
            return !(minAmount <= parseFloat(data.amount) && parseFloat(data.amount) <= parseFloat(maxAmount));
        } else {
            return false;
        }
    }

    const handleOnSelectRefundType = val => {
        setRefundType(val);
        setData({ ...data, refund_type: val.key });
    }

    const handleOnSelectRefundChannel = val => {
        setRefundChannel(val);
        setData({...data, refund_channel: val.key, partial: val === REFOUND_CHANNEL_TYPE.loyalty ? false : data.partial });
    }

    return (
        <div className={classNames("refundOrder")}>
            <div className={classNames("formControls")}>
                <div className={classNames("formControl")}>
                    <div className={classNames("title")}>{t("admin-orders.REFUND_TYPE_REASON")}</div>
                    <Dropdown value={(refundType && refundType.value) || ""} 
                        values={getReshipmentTypes(currentUserIsDepotRole())}
                        onSelect={handleOnSelectRefundType}
                    />
                </div>
                <div className={classNames("formControl")}>
                    <div className={classNames("title")}>{t("admin-orders.RESTAURANT_REFOUND_CHANNEL")}</div>
                    <Dropdown value={(refundChannel && refundChannel.value) || ""}
                              values={bonusRefund
                                  ? getRefoundBonusChannelType()
                                  : getRefoundChannelType()
                              }
                              onSelect={handleOnSelectRefundChannel}
                    />
                </div>
                {refundChannel && refundChannel.key === REFOUND_CHANNEL_TYPE.creditCard && (
                    <div className={classNames("formControl")}>
                        <div className={classNames("title")}>{t("admin-orders.REFUND_ORDER_PARTIAL_LABEL")}</div>
                        <OnOffSwitch id={id + "partial"}
                            value={data.partial}
                            isBlueColor={true}
                            title={{
                                on: t("settings.settings.numericalSettings.YES"),
                                off: t("settings.settings.numericalSettings.NO")
                            }}
                            handleOnClick={() => handleOnChange("partial", !data.partial)}
                        />
                    </div>
                )}
                {refundChannel && refundChannel.key === REFOUND_CHANNEL_TYPE.creditCard && data.partial && (
                    <div className={classNames("formControl")}>
                        <div className={classNames("title")}>{t("admin-orders.REFUND_ORDER_MAX_AMOUNT_LABEL")} {maxAmount}</div>
                        {minAmount >= maxAmount && (<span className={classNames("error")}>{t("admin-orders.CANT_DO_REFUND")}</span>)}
                        <input className={classNames({disabled: isDisabled() || minAmount >= maxAmount})} type="number"
                               value={data.amount} step="0.1" onChange={handleOnAmountChange} />
                    </div>
                )}
                {refundChannel && refundChannel.key === REFOUND_CHANNEL_TYPE.loyalty && (
                    <div className={classNames("formControl")}>
                        <div className={classNames("title")}>{t("admin-orders.REFUND_ORDER_AMOUNT_LABEL")}</div>
                        <input type="number" value={data.amount} step="0.1" onChange={handleOnAmountChange} />
                    </div>
                )}
                <div className={classNames("formControl")}>
                    <div className={classNames("title")}>{t("admin-orders.REFUND_ORDER_NOTE_LABEL")}</div>
                    <textarea className={classNames("inputArea")} rows="5" value={data.notes}
                        onChange={e=> handleOnChange("notes", e.target.value)}
                    ></textarea>
                </div>
            </div>
            <LayoutHeaderControls
                onSave={handleOnSave}
                withConfirmDialog
                disabledSaveButton={isDisabled() ? isDisabled() && data.partial : false}
                onClose={onClose}
            />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        refundOrder: data=> dispatch(createAction(ADMIN_ORDER_REFUND, data))        
    };
};

export default  connect(null, mapDispatchToProps)(withTranslation()(RefundOrder));
