import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { connect } from "react-redux";
import {CUSTOMER_SERVICE_MODEL, TYPE_SECTION_MODEL} from "../../utils/enums";

const PrivateRoute = ({ component: Component, ...args }) => {
  return (
    <Route
      {...args}
      render={props =>
        args.isAuthenticated === true ? (
          <Component {...args} {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

class Pages extends Component {
  loginpage = () =>
    Loadable({
      loader: () => import("../../app/pages/loginpage"),
      loading: () => null,
      modules: ["loginpage"]
    });

  orderspage = () =>
    Loadable({
      loader: () => import("../../app/pages/orderspage"),
      loading: () => null,
      modules: ["orderspage"]
    });

  editorderpage = () =>
    Loadable({
      loader: () => import("../../app/pages/editorderpage"),
      loading: () => null,
      modules: ["editorderpage"]
    });

  neworderpage = () =>
    Loadable({
      loader: () => import("../../app/pages/neworderpage"),
      loading: () => null,
      modules: ["neworderpage"]
    });
    
  pendingorderspage = () =>
    Loadable({
      loader: () => import("../../app/pages/pendingorderspage"),
      loading: () => null,
      modules: ["pendingorderspage"]
    });
  restaurateurmenupage = () =>
    Loadable({
      loader: () => import("../../app/pages/restaurateurmenupage"),
      loading: () => null,
      modules: ["restaurateurmenupage"]
    });
  orderingmenupage = () =>
    Loadable({
      loader: () => import("../../app/pages/orderingmenupage"),
      loading: () => null,
      modules: ["orderingmenupage"]
    });
  createorder = () =>
    Loadable({
      loader: () => import("../../app/pages/createorderpage"),
      loading: () => null,
      modules: ["restaurateurmenupage"]
    });
  infopage = () =>
    Loadable({
      loader: () => import("../../app/pages/infopage"),
      loading: () => null,
      modules: ["infopage"]
    });
  squareauthpage = () =>
    Loadable({
      loader: () => import("../../app/pages/squareauthpage"),
      loading: () => null,
      modules: ["squareauthpage"]
    });
  settingspage = () =>
    Loadable({
      loader: () => import("../../app/pages/settingspage"),
      loading: () => null,
      modules: ["settingspage"]
    });
    editcustomerorderpage = () =>
      Loadable({
        loader: () => import("../../app/pages/editcustomerorderpage"),
        loading: () => null,
         modules: ["editcustomerorder"]
    });
    adminorderspage = () =>
      Loadable({
        loader: () => import("../../app/pages/adminorderspage"),
        loading: () => null,
        modules: ["adminorderspage"]
    });

  render() {
    const { isAuthenticated, ...otherProps } = this.props;
    return (
      <Switch>
        <PrivateRoute
          component={this.pendingorderspage()}
          exact
          path="/"
          isAuthenticated={isAuthenticated}
          {...otherProps}
        />
        <PrivateRoute
          component={this.orderspage()}
          path="/orders"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.editorderpage()}
          path="/edit-order"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.editcustomerorderpage()}
          path="/edit-customer-order"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.neworderpage()}
          exact
          path="/new-order"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          component={this.restaurateurmenupage()}
          path="/menu"
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute path="/settings/orderingmenu" isAuthenticated={isAuthenticated}
          component={this.orderingmenupage()}
        />
        <PrivateRoute path="/info" isAuthenticated={isAuthenticated}
          component={this.infopage()}
        />
        {Object.keys(TYPE_SECTION_MODEL).map(key => (
          <PrivateRoute key={`PrivateRoute-${key}`} path={TYPE_SECTION_MODEL[key]} isAuthenticated={isAuthenticated}
            component={this.settingspage()}
          />
        ))}
        <PrivateRoute
          component={this.createorder()}
          exact
          path="/create-order"
          isAuthenticated={isAuthenticated}
        />
          {Object.keys(CUSTOMER_SERVICE_MODEL).map(key => (
              <PrivateRoute
                  key={`PrivateRoute-${key}`}
                  path={CUSTOMER_SERVICE_MODEL[key]}
                  isAuthenticated={isAuthenticated}
                  component={this.adminorderspage()}
              />
          ))}
        <Route path="/square/oauth" component={this.squareauthpage()} />
        <Route exact path="/login" component={this.loginpage()} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authentication.isAuthenticated
});

export default connect(mapStateToProps)(Pages);
