import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import classNames from "classnames";
import "./index.scss";

import {CUSTOMER_SERVICE_MODEL} from "../../../utils/enums";
import { DATE_FORMAT, getFormattedDate } from "../../../utils/convertTime";

import RateItem from "../RateItem";
import {currentUserIsDepotRole} from "../../../services/userModel.js";

const ReviewItem = ({ t, history, value }) => {
    const handleOnCustomerClick = () => {
        if(value.customer_id && !currentUserIsDepotRole()) {
            history.push(`${CUSTOMER_SERVICE_MODEL.customers}?userId=${value.customer_id}`);
        }
    }

    const handleOnOrderClick = () => {
        if(value.id) history.push(`${CUSTOMER_SERVICE_MODEL.order}?searchStr=${value.id}`);
    }

    return (
        <div className={classNames("reviewItem")}>
            <div className={classNames("title")}>
                <div className={classNames("reviewDataItem")}>
                    <div className={classNames("titleItem", "client", "link")} onClick={handleOnCustomerClick}>
                        {value.customer_name}
                    </div>
                    <div className={classNames("titleItem", "top")}>
                        {value.depot && (<div className={classNames("titleItem")}>{value.depot}</div>)}
                        <div className={classNames("titleItem", "link", "order")} onClick={handleOnOrderClick}>
                            {value.sequence_id}
                        </div>
                        <div className={classNames("titleItem", "date", "top")}>{getFormattedDate(value.creation_datetime, DATE_FORMAT)}</div>
                    </div>
                </div>
                <div className={classNames("reviewDataItem")}>
                    <div className={classNames("titleItem", "rate")}>
                        <span className={classNames("title")}>{t("reviews.REVIEW_FILTER_RATE_PRODUCT")}</span>
                        <RateItem rate={value.rating} value={value.rating} isInfo={true} />
                    </div>
                    <div className={classNames("titleItem", "rate")}>
                        <span className={classNames("title")}>{t("reviews.REVIEW_FILTER_RATE_DELIVERY")}</span>
                        <RateItem rate={value.delivery_rating} value={value.delivery_rating} isInfo={true} />
                    </div>
                    {value.depot && (<div className={classNames("titleItem", "depot")}>{value.depot}</div>)}
                    <div className={classNames("titleItem", "link", "order")} onClick={handleOnOrderClick}>
                        {t("admin-orders.ORDER_INFO_NUMBER_LABEL")} {value.sequence_id}
                    </div>
                    <div className={classNames("titleItem", "date")}>{getFormattedDate(value.creation_datetime, DATE_FORMAT)}</div>
                </div>
            </div>
            <div className={classNames("data")}>
                {value.review}
            </div>
        </div>
    );
}

export default withRouter(withTranslation()(ReviewItem));