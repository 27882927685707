import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import { ACCOUNT_ORDER_STATUS, getAccountOrderStatusTitle } from "../../../utils/enums";
import { defaultPromiseResolve } from "../../../utils/objects-util";

import IconButton from "../IconButton";

const CustomerOrderStatus = ({ t, value }) => {
    const title = getAccountOrderStatusTitle(value);
    return (
        <div className={classNames("customerOrderStatus")}>
            <div className={classNames("title")}>{title}</div>
            {value === ACCOUNT_ORDER_STATUS.finished && (
                <IconButton isIcon={true} iconName="applyFin" tooltipText={title} 
                    onClick={defaultPromiseResolve} 
                />
            )}
            {value === ACCOUNT_ORDER_STATUS.canceled && (
                <IconButton isIcon={true} iconName="canceled" tooltipText={title} 
                    onClick={defaultPromiseResolve} 
                />
            )}
        </div>
    );
}

export default withTranslation()(CustomerOrderStatus);