import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import {COURIER_STATE, SHIFT_TYPE, WAITER_TYPES} from "../../../utils/enums";
import {shiftCourierErrorMessage, shiftCourierTooltipText} from "../../../utils/constants";
import {createAction} from "../../../utils/sagaHelper";

import {
    AVAILABLE_CONTROL_CENTERS,
    CANCEL_SHIFT,
    END_SHIFT,
    FINISH_SHIFT,
    GOAWAY_SHIFT,
    START_SHIFT_BACKUP,
} from '../../../store/reducers/userModel/userModel-actions';

import Waiter from "../Waiter/Waiter";
import ShiftCourierControlPopup from "../ShiftCourierControlPopup";

const ShiftCourierControl = ({
    t,
    type, courier,
    startShiftBackUpAction,
    endShiftAction,
    fleet,
    cancelShiftAction,
    finishShiftAction,
    goAwayShiftAction,
    getAvailableControlCenters,
    selectedControlCenter,
    availableControlCenters,
    parentControlCenter
}) => {
    const [controlCenterId, setControlCenterId] = useState(null);
    const [selectedFleet, setSelectedFleet] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        if(controlCenterId !== parentControlCenter) {
            setControlCenterId(parentControlCenter);
        }
        // eslint-disable-next-line
    }, [parentControlCenter]);

    const handleOnClick = () => {
        const courierId = courier && courier.userId;
        const typesForCheckControlcenter = [COURIER_STATE.startShift];
        getAvailableControlCenters(courierId)

        if(typesForCheckControlcenter.includes(type)) {
            setPopupOpen(true);
            return Promise.resolve();
        }

        return handleOnCourierShift(courierId, type);
    };

    const handleOnCourierShift = (courierId, courierState, data) => {
        if(courierId) {
            switch (courierState) {
                case COURIER_STATE.startBackup:
                    return startShiftBackUpAction({
                        courierId,
                        controlCenterId: controlCenterId || selectedControlCenter,
                        vehicleId: selectedFleet,
                        shiftType: SHIFT_TYPE.Backup
                    })
                case COURIER_STATE.endBackup:
                    return endShiftAction({ courierId })
                case COURIER_STATE.startShift:
                    return startShiftBackUpAction({
                        courierId,
                        controlCenterId: controlCenterId || selectedControlCenter,
                        vehicleId: selectedFleet,
                        shiftType: SHIFT_TYPE.Normal,
                        ...data,
                    })
                case COURIER_STATE.endShift:
                    return endShiftAction({ courierId })
                case COURIER_STATE.cancelShift:
                    return cancelShiftAction({ courierId })
                case COURIER_STATE.finishShift:
                    return finishShiftAction({ courierId })
                case COURIER_STATE.goAwayShift:
                    return goAwayShiftAction({ courierId })
                default:
                    return Promise.resolve();
            }
        } else {
            Promise.reject();
        }
    }

    const hanldeOnPopupClose = (data) => {
        const courierId = courier && courier.userId;

        return new Promise((resolve, reject)=> {
            if(courierId) {
                handleOnCourierShift(courierId, type, data).then(()=> {
                    setPopupOpen(false);
                    resolve();
                }, ()=> reject());
            } else {
                reject();
            }
        });
    }

    const getErrorMessage = () => {
        return (shiftCourierErrorMessage[type] && t(shiftCourierErrorMessage[type])) || "";
    }

    const getTooltipText = () => {
        return (shiftCourierTooltipText[type] && t(shiftCourierTooltipText[type])) || "";
    }

    const getUseClass = () => {
        const classStyles = {
            [COURIER_STATE.startShift]: "start-shift",
            [COURIER_STATE.startBackup]: "start-backup-shift",
            [COURIER_STATE.endShift]: "end-shift",
            [COURIER_STATE.endBackup]: "end-backup-shift",
            [COURIER_STATE.cancelShift]: "cancel",
            [COURIER_STATE.finishShift]: "finish",
            [COURIER_STATE.goAwayShift]: "goaway"
        };

        return classStyles[type] || "";
    }

    return (
        <div className={classNames("shiftCourierControl")}>
            <Waiter type={WAITER_TYPES.span}
                tooltipText={getTooltipText()}
                useClass={getUseClass()}
                errorMessage={getErrorMessage()}
                stopPropagation={true}
                handleOnClick={handleOnClick}
            />
            {popupOpen && (
                <ShiftCourierControlPopup
                    popupOpen={popupOpen}
                    courier={courier}
                    fleet={fleet}
                    setSelectedFleet={setSelectedFleet}
                    selectedControlCenter={selectedControlCenter}
                    hanldeOnPopupClose={hanldeOnPopupClose}
                    setControlCenterId={setControlCenterId}
                    controlCenterId={controlCenterId}
                    availableControlCenters={availableControlCenters}
                    onClose={() => setPopupOpen(false)}
                />
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        selectedControlCenter: state.restaurant.data.selectedControlCenter,
        availableControlCenters: state.restaurant.data.availableControlCenters,
        fleet: state.restaurant.data.fleet,
    };
};

const mapDispatchToProps = dispatch => ({
    startShiftBackUpAction: data => dispatch(createAction(START_SHIFT_BACKUP, data)),
    endShiftAction: data => dispatch(createAction(END_SHIFT, data)),
    getAvailableControlCenters: data => dispatch(createAction(AVAILABLE_CONTROL_CENTERS, data)),
    cancelShiftAction: data => dispatch(createAction(CANCEL_SHIFT, data)),
    finishShiftAction: data => dispatch(createAction(FINISH_SHIFT, data)),
    goAwayShiftAction: data => dispatch(createAction(GOAWAY_SHIFT, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShiftCourierControl));
