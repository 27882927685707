import React from 'react';
import DropdownMultiSelect from '../DropdownMultiSelect/index.js';
import './style.scss';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const SectionItemMultiselect = ({ optionKey, option, onOptionChange }) => {
  const rootState = useSelector(state => state);
  const items = (option.mappingLambda ? option.mappingLambda(rootState) : option.items) || [];

  return (
    <div className={'sectionItemMultiselect'}>
      <div className={classNames('title')}>{option.label || ''}</div>
      <DropdownMultiSelect
        values={items}
        selectedValues={option.selected || []}
        onApply={item => onOptionChange(optionKey, 'selected', item)}
        placeholderEmpty={option.label || ''}
        placeholderMulti={option.label || ''}
      />
    </div>
  );
};

export default SectionItemMultiselect;
