import MenuModel from './menuModel';

import {CUSTOMER_SERVICE_MODEL} from '../utils/enums';
import {
    currentUserIsControlCenterManagerRole,
    currentUserIsDepotRole,
    currentUserIsSupervisorRole,
    currentUserIsSupportOperatorRole
} from "../services/userModel.js";

export function createCustomerServiceMenuSettings() {
    return currentUserIsDepotRole()
        ? [
            new MenuModel({
                pathname: CUSTOMER_SERVICE_MODEL.order,
                title: 'admin-orders.ADMIN_ORDERS_ORDER_LABEL',
                icon: 'adminOrders',
                active: location => getIsActive(location, CUSTOMER_SERVICE_MODEL.order),
            }),
            new MenuModel({
                pathname: CUSTOMER_SERVICE_MODEL.reviews,
                title: 'reviews.LABEL',
                icon: 'reviews',
                active: location => getIsActive(location, CUSTOMER_SERVICE_MODEL.reviews),
                isShow: () => currentUserIsSupportOperatorRole()
            }),
            new MenuModel({
                pathname: CUSTOMER_SERVICE_MODEL.sales,
                title: 'sales.LABEL',
                icon: 'sales',
                active: location => getIsActive(location, CUSTOMER_SERVICE_MODEL.sales),
                isShow: () => currentUserIsSupervisorRole() || currentUserIsSupportOperatorRole(),
            }),
        ] : [
            new MenuModel({
                pathname: CUSTOMER_SERVICE_MODEL.order,
                title: 'admin-orders.ADMIN_ORDERS_ORDER_LABEL',
                icon: 'adminOrders',
                active: location => getIsActive(location, CUSTOMER_SERVICE_MODEL.order),
            }),
            new MenuModel({
                pathname: CUSTOMER_SERVICE_MODEL.reviews,
                title: 'reviews.LABEL',
                icon: 'reviews',
                active: location => getIsActive(location, CUSTOMER_SERVICE_MODEL.reviews),
            }),
            new MenuModel({
                pathname: CUSTOMER_SERVICE_MODEL.customers,
                title: 'customers.LABEL',
                icon: 'customers',
                active: location => getIsActive(location, CUSTOMER_SERVICE_MODEL.customers),
            }),
            new MenuModel({
                pathname: CUSTOMER_SERVICE_MODEL.sales,
                title: 'sales.LABEL',
                icon: 'sales',
                active: location => getIsActive(location, CUSTOMER_SERVICE_MODEL.sales),
            }),
            new MenuModel({
                pathname: CUSTOMER_SERVICE_MODEL.supportChat,
                title: 'support-chat.LABEL',
                icon: 'supportChat',
                isShow: () => currentUserIsSupervisorRole() || currentUserIsControlCenterManagerRole(),
                active: location => getIsActive(location, CUSTOMER_SERVICE_MODEL.supportChat),
            }),
        ];
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2]
    );
}
