import React from 'react';
import './style.scss';
import {useTranslation} from "react-i18next";
import {
    getWeekPeriod,
    prepareMonth,
    preparePreviousMonth,
    prepareToday,
    prepareYear,
    prepareYesterday
} from "../../../utils/convertTime.js";
import classnames from "classnames";
import moment from "moment";
import IconButton from "../IconButton/IconButton.jsx";
const DateLinks = ({onClick, startOption, endOption}) => {
    const {t} = useTranslation();

    const getDateComponent = (title, {from, to}) => {
        const isSelected = moment(from).isSame(startOption.selected) && moment(to).isSame(endOption.selected);
        const isNotTodaySelected = moment(from).isSame(prepareToday().from) && moment(to).isSame(prepareToday().to);
        return (
            <div
                onClick={() => onClick(isSelected ? prepareToday().from : from, isSelected ? prepareToday().to : to)}
                className={classnames('link', {selected: isSelected})}
            >
                <span>{title}</span>
                {isSelected && !isNotTodaySelected && (
                    <IconButton isIcon iconName={'iconDelet'}/>
                )}
            </div>
        )
    }

    return (
        <div className={'dateLinks'}>
            <span className={'title'}>{t("pending-orders.FILTER_TYPE_BYDATE")}</span>
            <div className={'dateLinksItems'}>
                {getDateComponent(t('shifts.TODAY_LINK'), prepareToday())}
                {getDateComponent(t('shifts.YESTERDAY_LINK'), prepareYesterday())}
                {getDateComponent(t('shifts.WEEK_LINK'), getWeekPeriod())}
                {getDateComponent(t('shifts.MONTH_LINK'), prepareMonth())}
                {getDateComponent(t('shifts.PREVIOUS_MONTH_LINK'), preparePreviousMonth())}
                {getDateComponent(t('shifts.YEAR_LINK'), prepareYear())}
            </div>
        </div>
    )
}

export default DateLinks;