import React, {useCallback, useEffect, useState} from 'react';
import classnames from 'classnames';
import "./style.scss";
import {getCurrencyIcons} from "../../../../utils/enums.js";
import {useTranslation} from "react-i18next";
import {getProductPrice} from "../../../../api/order-requests.js";
import {CounterButton} from "../../CounterButton/CounterButton.jsx";
import {isRTL} from "../../../../services/userModel.js";

const ProductAddDish = ({product, multiSelect, onChange, currency, withButtonsCounter, useClass, allProductsCount, currentIndex}) => {
    const handleOnAdd = (data, operation) => {
        let suboptions = [];
        if(product.min || multiSelect || !product.limit_suboptions_by_max) {
            suboptions = product.suboptions.map(el => (
                el.id === data.id
                    ? {...el, quantity: (el.quantity ? el.quantity : 0)  + operation}
                    : {...el}
            ));
        } else {
            suboptions = product.suboptions.map(el => (
                el.id === data.id
                    ? {...el, quantity: (el.quantity ? el.quantity : 0)  + operation}
                    : {...el, quantity: 0}
            ))
        }

        const suboptionQuantity = suboptions.reduce((acc, cv) => acc + (cv.quantity ? cv.quantity : 0), 0) || 0;

        let maxIsZero = false;
        if(product.max === 0){
            maxIsZero = suboptions.every(el => el.quantity ? el.quantity <= 1 : true);
        }

        if(suboptionQuantity <= product.max || maxIsZero || !product.limit_suboptions_by_max) {
            onChange({...product, suboptions});
        }
    }
    const handleOnRemove = (data, operation) => {
        const suboptions = product.suboptions.map(el => (
            el.id === data.id
                ? {...el, quantity: (el.quantity ? el.quantity : 0)  + operation}
                : {...el}
        ))
        const suboptionQuantity = suboptions.reduce((acc, cv) => acc + (cv.quantity ? cv.quantity : 0), 0);
        if(suboptionQuantity >= 0) {
            onChange({...product, suboptions: suboptions});
        }
    }
    const handleOnSelect = (data, operation) => {
        const suboptions = product.suboptions.map(el => (
            el.id === data.id
                ? {...el, quantity: 1}
                : {...el, quantity: 0}
        ));
        onChange({...product, suboptions: suboptions});
    }

    return (
        <div className={classnames("productcard-add-dish", useClass)}>
            <div className={classnames("header")}>
                {product.name}
                {(!!product.max || !!product.min) && (!!product.min && product.max > 0)
                    && ` ${product.min ? '(min ' + product.min + ' - ' : '('}${product.max ? 'max ' +  product.max + ')' : ')'}`
                }
            </div>
            <div className={"steps"}>
                {allProductsCount > 0 && Array(allProductsCount)
                    .fill(0)
                    .map((_, index) => (
                        <>
                            <div className={classnames("step", {active: index === currentIndex})}/>
                            <div className={"step-line"}/>
                        </>
                    ))}

            </div>
            <div className={classnames("oprions", {radioButtons: product.min === 1 && product.max === 1})}>
                {product.suboptions
                    .filter(subopt => subopt.enabled)
                    .map(subopt => (
                    (product.min === 1 && product.max === 1)
                        ? (<RadioButton option={subopt}
                                        currency={currency}
                                        key={"subpotion" + subopt.id}
                                        onSelect={handleOnSelect}
                        />)
                        : (<CheckBox  option={subopt}
                                      onClickAdd={handleOnAdd}
                                      onClickRemove={handleOnRemove}
                                      showButton={withButtonsCounter}
                                      currency={currency}
                                      key={"subpotion" + subopt.id}
                        />)
                ))}
            </div>
        </div>
    )
}

const RadioButton = ({currency, option, onSelect}) => {
    const handleOnChange = (e) => {
        e.preventDefault();
        onSelect(option, !!option.quantity ? -1 : 1);
    }
    return (
        <label className={classnames("radio-button")} onClick={handleOnChange}>
            <div className={classnames("input")}>
                <input type="radio" checked={!!option.quantity} onChange={handleOnChange}/>
                <span className={classnames("text")}>{option.name}</span>
            </div>
            {!!option.price && (<span className={classnames("price")}>+{getCurrencyIcons(currency)}{option.price}</span>)}
        </label>
    )
};

const CheckBox = ({option, currency, showButton, onClickAdd, onClickRemove}) => {
    const handleOnChange = () => {
        !!option.quantity ? onClickRemove(option, -option.quantity) : onClickAdd(option, +1);
    }
    const handleOnAdd = () => {
        onClickAdd(option, +1);
    }
    const handleOnRemove = () => {
        if(option.quantity -1 >= 0) {
            onClickRemove(option, -1);
        }
    }

    return (
        <div className={classnames("checkbox", {mainColor: showButton && option.quantity})}>
            <label className={classnames("input")}>
                <input type="checkbox" checked={!!option.quantity} onChange={handleOnChange}/>
                <span className={classnames("text")}>{option.name} <span className={classnames("option-description")}>{option.description}</span></span>
            </label>
            {showButton && (<CounterButton quantity={option.quantity} onClickAdd={handleOnAdd}  onClickRemove={handleOnRemove}/>)}
            {!!option.price ? (<div className={classnames("price")}>+{getCurrencyIcons(currency)}{option.price}</div>) : (<div/>)}
        </div>
    )
};

export const checkRespectToAndEnabledSuboptions = (product, data) => {
    const enabledAllSuboptions = product.suboptions.filter(el => el.enabled);
    if(product.enabled && enabledAllSuboptions.length) {
        const subopt = data
            .flatMap(el => el.suboptions)
            .filter(el => el.enabled);
        const find = subopt.find(el => el.id === product.respect_to);
        return find ? !!find.quantity : true;
    }

    return false;
}

const HALF_OPTION = {
    all: 0,
    left: 1,
    right: 2
}

export const CreateProduct = ({onClose, productEdit, handleOnAddToCart, editMode, product, currency, business_id, emitProduct}) => {
    const {t} = useTranslation();

    const allProducts = (product && product.extras.flatMap(extra => extra.options)) || [];
    const [products, setProducts] = useState([]);
    const [halfProducts, setHalfProducts] = useState([]);
    const [isHalf, setIsHalf] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);


    const [isLoading, setIsLoading] = useState(false);
    const createProductIngredients = data => {
        if(data.length){
            const subopt = data.map((el, i) => ({enabled: true, name: el.name, quantity: el.quantity === 0 ? 0 : 1, id: i})) || [];
            return {
                id: "Ingredients",
                name: t("create-order.product.INGREDIENTS"),
                min: 0,
                max: data.length,
                suboptions: subopt,
                enabled: true,
            }
        } else {
            return {};
        }
    }
    const [ingredients, setIngredients] = useState(createProductIngredients((product && product.ingredients) || []));
    const [quantity, setQuantity] = useState(productEdit && editMode ? productEdit.quantity : 1);
    const [comments, setComments] = useState(productEdit && editMode ? productEdit.comment : "");
    const [fullPrice, setFullPrice] = useState(editMode && productEdit ? productEdit.full_price : 0);

    const [error, setError] = useState(null);

    const productsMap = products
        .filter(el => checkRespectToAndEnabledSuboptions(el, [...halfProducts, ...products]));

    const halfProductsMap = halfProducts
        .filter(el => checkRespectToAndEnabledSuboptions(el, [...halfProducts, ...products]));

    const hasIngredients = !!Object.keys(ingredients).length;
    const countOfAllProducts =
        productsMap.length + halfProductsMap.length + (hasIngredients ? 1 : 0) + (!!halfProducts.length ? 1 : 0);

    useEffect(() => {
        if(editMode) {
            mergeProduct();
        } else {
            setProducts(allProducts.filter(el => !el.with_half_option));
            setHalfProducts(allProducts.filter(el => el.with_half_option));
        }

    // eslint-disable-next-line
    }, [product]);

    const mergeProduct = () => {
        const isWithHalf = productEdit.options
            ? productEdit.options
                .filter(el => el.with_half_option)
                .flatMap(el => el.suboptions)
                .some(el => el.half_option >= 1)
            : false;
        setIsHalf(isWithHalf);

        const withHalf = allProducts.filter(el => el.with_half_option);
        const withoutHalf = allProducts.filter(el => !el.with_half_option);
        setProducts(withoutHalf.map(el => {
            const find = productEdit.options && productEdit.options.find(edit => edit.id === el.id);
            if(find) {
                find.suboptions = el.suboptions.map(sub => {
                    const findSub = find.suboptions.find(subel => subel.id === sub.id);
                    return findSub ? {...sub, ...findSub} : sub;
                })
            }
            return find ? {...el, ...find} : el;
        }));

        if(isWithHalf) {
            const suboptions = productEdit.options
                .filter(el => el.with_half_option)
                .flatMap(el => el.suboptions);

            const leftSides = suboptions.filter(el => el.half_option === HALF_OPTION.left);
            const rightSides = suboptions.filter(el => el.half_option === HALF_OPTION.right);


            const fullSides = withHalf.flatMap(el => {
                return [
                    {...el, name: t("create-order.product.ONE_SIDE", {name: el.name}), halfOption: 1, suboptions: el.suboptions.map(sub => {
                            const find = leftSides.find(ls => ls.id === sub.id);
                            return find ? find : sub
                        })},
                    {...el, name: t("create-order.product.ANOTHER_SIDE", {name: el.name}), halfOption: 2, suboptions: el.suboptions.map(sub => {
                            const find = rightSides.find(ls => ls.id === sub.id);
                            return find ? find : sub
                        })}
                ]
            });

            setTimeout(() => {
                setHalfProducts(fullSides);
            })

        } else {
            const fullProducts = withHalf.map(el => {
                const find = productEdit.options && productEdit.options.find(edit => edit.id === el.id);
                if(find) {
                    find.suboptions = el.suboptions.map(sub => {
                        const findSub = find.suboptions.find(subel => subel.id === sub.id);
                        return findSub ? {...sub, ...findSub} : sub;
                    })
                }
                return find ? {...el, ...find} : el;
            });

            setTimeout(() => {
                setHalfProducts(fullProducts);
            })
        }

        const subopt = ingredients.suboptions
            ? ingredients.suboptions.map(el => productEdit.ingredients.includes(el.name) ? {...el, quantity: 0} : {...el, quantity: 1})
            : [];

        setIngredients({...ingredients, suboptions: subopt});
    }

    useEffect(() => {
        const withHalf = allProducts.filter(el => el.with_half_option);
        if (isHalf) {
            setHalfProducts(p => withHalf.flatMap(el => [
                {...el, name: t("create-order.product.ONE_SIDE", {name: el.name}), halfOption: HALF_OPTION.left},
                {...el, name: t("create-order.product.ANOTHER_SIDE", {name: el.name}), halfOption: HALF_OPTION.right}
            ]))
        } else {
            setHalfProducts(p => withHalf);
        }

        // eslint-disable-next-line
    }, [isHalf])

    const changeQuantityOfIngredients = option => {
        const newproducts = products.map(el => (
            el.id === option.id
                ? option
                : el
        ))
        setProducts(newproducts);
    }
    const changeQuantityOfIngredientsHalf = option => {
        const newproducts = halfProducts.map(el => (
            el.id === option.id && el.halfOption === option.halfOption
                ? option
                : el
        ))
        setHalfProducts(newproducts);
    }
    const changeIngredients = data => {
        setIngredients(data);
    }
    const handleOnChangeMessage = value => {
        setComments(value);
    }
    const handleOnAddProduct = () => {
        setQuantity(quantity +1);
    }
    const handleOnRemoveProduct = () => {
        if(editMode && quantity -1 >= 0) {
            setQuantity(quantity -1);
        } else if (!editMode && quantity -1 > 0) {
            setQuantity(quantity -1);
        }
    }
    const isValid = useCallback(() => {
        const isValidProducts = products
            .filter(el => checkRespectToAndEnabledSuboptions(el, [...products, ...halfProducts]))
            .every(prod => !checkValidProduct(prod));
        const isValidHalf = halfProducts
            .filter(el => checkRespectToAndEnabledSuboptions(el, [...products, ...halfProducts]))
            .every(prod => !checkValidProduct(prod));

        return isValidHalf && isValidProducts && quantity > 0;
    }, [quantity, products, halfProducts])

    const checkError = useCallback(() => {
        let newError = "";
        const products = [...productsMap, null, ...halfProductsMap];
        products.forEach((prod, index) => {
            if(index+1 + (hasIngredients ? 1 : 0) === currentIndex && prod) {
                const condition = checkValidProduct(prod);
                if(condition) {
                    newError = t("create-order.product.MINIMUM_OPTION", {minCount: prod.name.toLowerCase()});
                }
            }
        });

        setError(newError);
    }, [currentIndex, halfProductsMap, productsMap, t, hasIngredients]);

    useEffect(() => {
        if(currentIndex <= countOfAllProducts) {
            checkError();
        }
    }, [currentIndex, countOfAllProducts, checkError]);

    const checkValidProduct = prod => {
        const allQuantity = prod.suboptions.reduce((acc, cv) => cv.quantity ? (acc = cv.quantity + acc) : acc, 0);

        const limitByMax = prod.limit_suboptions_by_max
            ? true
            : (prod.min === 1 && prod.max === 1);
        const condition = limitByMax
            ? prod.min !== 0 && !((prod.min <= allQuantity) && (allQuantity <= prod.max))
            : !(prod.min <= allQuantity) && prod.min !== 0;

        return condition;
    }

    const handleEmitProduct = useCallback((data, isValid) => {
        emitProduct && emitProduct(data, isValid);
    }, [emitProduct]);

    const getPrice = useCallback(() => {
        // checkError([...products, ...halfProducts]);
        if(!!product) {
            setIsLoading(true);
            const data = {
                business_id: business_id,
                product: {
                    id: product.id,
                    quantity: quantity,
                    price: product.price,
                    full_price: product.full_price,
                    comment: comments,
                    ingredients: ingredients.suboptions ? ingredients.suboptions.filter(el => !el.quantity).map(el => el.name) : [],
                    options: [
                        ...products
                            .filter(el => checkRespectToAndEnabledSuboptions(el, [...products, ...halfProducts]))
                            .map(el => ({
                                id: el.id,
                                name: el.name,
                                suboptions: el.suboptions.filter(sub => !!sub.quantity)
                                    .map(sub => ({
                                        id: sub.id,
                                        price: sub.price,
                                        quantity: sub.quantity,
                                        half_option: HALF_OPTION.all,
                                        name: sub.name
                                    }))
                            })).filter(el => !!el.suboptions.length),
                        ...halfProducts
                            .filter(el => checkRespectToAndEnabledSuboptions(el, [...products, ...halfProducts]))
                            .map(el => ({
                                id: el.id,
                                name: el.name,
                                suboptions: el.suboptions
                                    .filter(sub => !!sub.quantity)
                                    .map(sub => ({
                                        id: sub.id,
                                        price: sub.price,
                                        quantity: sub.quantity,
                                        half_option: el.halfOption,
                                        name: sub.name
                                    }))
                            })).filter(el => !!el.suboptions.length)
                    ]
                }
            }

            getProductPrice(data).then(res => {
                if(res.status === 200) {
                    setFullPrice(res.data.full_price);
                    handleEmitProduct({...data, full_price: res.data.full_price}, isHalf);
                }
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [t, products, business_id, comments, ingredients, product, quantity, handleEmitProduct, halfProducts, isHalf]);

    useEffect(() => {
        getPrice();
    }, [getPrice]);

    const getHalfAllPortion = (isHalf) => {
        return {
            id: "half_options",
            name: t("create-order.product.TOPPINGS"),
            min: 1,
            max: 1,
            enabled: true,
            suboptions: [
                {quantity:!isHalf, enabled: true, name: t("create-order.product.FOR_WHOLE"), id: "whole"},
                {quantity:isHalf, enabled: true, name: t("create-order.product.HALFHALF"), id: "half"}
            ]
        }
    }

    const changeTopping = (data) => {
        setIsHalf(!!data.suboptions.find(el => el.id === "half").quantity);

        const withHalf = allProducts.filter(el => el.with_half_option);
        if (isHalf) {
            setHalfProducts(p => withHalf.flatMap(el => [
                {...el, name: t("create-order.product.ONDE_SIDE", {name: el.name}), halfOption: HALF_OPTION.left},
                {...el, name: t("create-order.product.ANOTHER_SIDE", {name: el.name}), halfOption: HALF_OPTION.right}
            ]))
        } else {
            setHalfProducts(p => withHalf);
        }
    }

    return (
        <div className={classnames("modal-add-dish", {rtl: isRTL()})}>
            <div className={classnames("add-dish-layout")}>
                <div className={classnames("restaurant-modal-add-dish")}>
                    <div className={"close"} onClick={onClose}>+</div>
                    <div className={classnames("image-product")}>
                        <img src={product.images} alt=""/>
                    </div>

                    <div className={classnames("stinky-header")}>
                    <span className={classnames("head")}>
                        <span className={classnames("header-name")}>{product && product.name}</span>
                        {product && (
                            <div className={classnames("price")}>
                                {!!product.price_before_discount && (<span className={classnames("price-through")}>{getCurrencyIcons(currency)}{product.price_before_discount}</span>)}&nbsp;&nbsp;
                                <span className={classnames({"price-blue": !product.price_before_discount, "price-red": !!product.price_before_discount})}>{getCurrencyIcons(currency)}{product.price}</span>
                            </div>
                        )}
                        </span>
                    </div>
                    {product && product.description && (<div className={"description"}>{product.description}</div>)}
                    {error && <div className={"warning"}>{error}</div>}
                    {hasIngredients && currentIndex === 0 && (<ProductAddDish
                        allProductsCount={countOfAllProducts}
                        currentIndex={currentIndex}
                        onChange={changeIngredients}
                        currency={currency}
                        product={ingredients}
                        multiSelect
                        useClass={"checkbox-with-icon"}
                    />)}


                    {productsMap
                        .filter((_, index) => currentIndex === index +1 + (hasIngredients ? 1 : 0))
                        .map(prod => (
                            <ProductAddDish
                                currentIndex={currentIndex}
                                allProductsCount={countOfAllProducts}
                                onChange={changeQuantityOfIngredients}
                                key={"add-dish-products" + prod.id}
                                currency={currency}
                                product={prod}
                                withButtonsCounter={prod.max >= 2}
                                multiSelect={prod.limit_suboptions_by_max ? true : !(prod.min === 0 && prod.max === 1)}
                                useClass={((!prod.min && prod.max === 1) || (!prod.min && !prod.max && !prod.limit_suboptions_by_max)) && "checkbox-with-icon"}
                            />
                        ))}


                    {!!halfProducts.length && productsMap.length + 1 + (hasIngredients ? 1 : 0) === currentIndex && (
                        <ProductAddDish
                            currentIndex={currentIndex}
                            allProductsCount={countOfAllProducts}
                            onChange={changeTopping}
                            key={"need-half-option"}
                            currency={currency}
                            product={getHalfAllPortion(isHalf)}
                            useClass={"checkbox-with-icon"}
                        />
                    )}

                    {halfProductsMap
                        .filter((_, index) => currentIndex === index + 2 + productsMap.length + (hasIngredients ? 1 : 0))
                        .map((prod, i) => (
                            <ProductAddDish
                                currentIndex={currentIndex}
                                allProductsCount={countOfAllProducts}
                                onChange={changeQuantityOfIngredientsHalf}
                                key={i + "-add-dish-products-" + prod.id}
                                currency={currency}
                                product={prod}
                                withButtonsCounter={prod.max >= 2}
                                useClass={!prod.min && prod.max === 1 && "checkbox-with-icon"}
                            />
                        ))}

                    {currentIndex === countOfAllProducts && halfProducts && products && (
                        <div className={"selected-product-options"}>
                            {([...halfProductsMap, ...productsMap]).map(option => (
                                <span key={`option-${option.name}`}>
                                    {option.name}:&nbsp;{(option.suboptions || []).filter(s => s.quantity).map(s => s.name + (s.quantity > 1 ? (" x" + s.quantity) : "") + ", ")}
                                </span>
                            ))}
                        </div>
                    )}

                    {product && product.allow_comments && currentIndex === countOfAllProducts && (<div className={classnames("comments")}>
                        <span>{t("create-order.product.COMMENTS")}</span>
                        <input type="text" placeholder={t("create-order.product.COMMENTS")} onChange={e => handleOnChangeMessage(e.target.value)} value={comments}/>
                    </div>)}

                </div>
                <div className={classnames("full-price", {tabsControl: currentIndex !== countOfAllProducts && countOfAllProducts !== 0, noOptions: !countOfAllProducts})}>
                    {currentIndex === countOfAllProducts && (
                        <>
                            {countOfAllProducts !== 0 && (
                                <button className={"btn denied backToAddition"} onClick={() => currentIndex !== 0 && setCurrentIndex(currentIndex-1)}>{t("basic.BACK_BUTTON")}</button>
                            )}
                            <CounterButton disabled={isLoading} onClickRemove={handleOnRemoveProduct} onClickAdd={handleOnAddProduct} quantity={quantity} />
                            <div onClick={handleOnAddToCart} className={classnames("add-to-order", {isValid: (quantity === 0 && editMode) || isValid(), disable: isLoading})}>
                                <span className={classnames("label")}>
                                    {
                                        editMode && quantity > 0
                                            ? t("create-order.product.SAVE")
                                            : editMode && quantity === 0
                                                ? t("create-order.product.REMOVE")
                                                : t("create-order.product.ADD_TO_CART")
                                    }
                                </span>
                                <span className={classnames("price-total")}>
                                    {
                                        isLoading
                                            ? t("create-order.product.LOADING")
                                            : (quantity !== 0) && `${getCurrencyIcons(currency)}${fullPrice}`
                                    }
                                </span>
                            </div>
                        </>
                    )}
                    {currentIndex !== countOfAllProducts && (
                        <>
                            <button className={"btn denied"} onClick={() => currentIndex !== 0 && setCurrentIndex(currentIndex-1)}>{t("basic.BACK_BUTTON")}</button>
                            <button className={"btn resolve"} disabled={!!error} onClick={() => setCurrentIndex(currentIndex+1)}>{t("basic.PAGINATION_NEXT")}</button>
                        </>
                    )}

                </div>
            </div>

        </div>
    )
}