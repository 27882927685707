import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";
import { getFormatRelativeTime } from "../../../utils/convertTime";

const TimeValidControl = ({ t, value /*sec*/, onFinish }) => {    
    const [currentValue, setCurrentValue] = useState(0);
    const [baseValue, setBaseValue] = useState(0);
    const fullWidth = 140;

    const updateCurrentValue = useCallback(() => {
        if(currentValue) {
            setCurrentValue(currentValue - 1);
        } else {
            onFinish && onFinish();
        }              
    }, [currentValue, onFinish]);

    useEffect(()=> {
        if(value !== baseValue) {
            setCurrentValue(value);
            setBaseValue(value);
        }

        const currentValueInterval = setInterval(updateCurrentValue, 1000);
        return () => {
            clearInterval(currentValueInterval);
        };
    }, [value, baseValue, updateCurrentValue]);

    return (
        <div className={classNames("timeValidControl")}>
            <div className={classNames("title")}>
                {t("basic.VALID_FOR_LABEL")}:&nbsp;{getFormatRelativeTime(currentValue * 1000, null, true)}
            </div>
            <div className={classNames("timeLine")}>
                {!!currentValue && (
                    <div className={classNames("timeLineFull")} style={{ width: ((fullWidth/value)*currentValue) }}></div>
                )}
            </div>
        </div>
    );
}

export default withTranslation()(TimeValidControl);