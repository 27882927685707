import {call, put, select, takeEvery} from 'redux-saga/effects';
import {createAction, createSaga, ErrorData} from '../../utils/sagaHelper.js';
import {actionSetSectionItemsLoading} from '../reducers/sectionsWrapperData/sectionsWrapperData-actions.js';
import {
  approveReconciliationsPeriod,
  deleteReconciliationsConfiguration,
  deleteReconciliationsPeriods, exportReconciliationsConfigurations,
  exportReconciliationsPeriod,
  exportReconciliationsPeriodTableInvoice,
  exportReconciliationsPeriodTableXls,
  fetchReconciliationsConfigurationDepots, fetchReconciliationsConfigurationDepotsWithoutTerritory,
  fetchReconciliationsConfigurations,
  fetchReconciliationsPeriodById,
  fetchReconciliationsPeriods,
  getMergedConfiguration, importReconciliationsConfigurations,
  invoiceReconciliationsPeriod,
  postReconciliationsConfiguration,
  postReconciliationsPeriods,
  recalculateReconciliationsPeriod,
  updateReconciliationsConfiguration,
  updateReconciliationsPeriod,
  xlsReconciliationsPeriod,
} from '../../api/reconciliation-requests.js';
import {getErrorMessage, requestResultIsOk} from '../../utils/request-util.js';
import {saveAs} from 'file-saver';
import {
  actionAddReconciliationsConfigurations,
  actionAddReconciliationsPeriods,
  actionLoadReconciliationsConfigurations,
  actionLoadReconciliationsPeriod,
  actionLoadReconciliationsPeriods,
  actionUpdateReconciliationsConfigurations,
  actionUpdateReconciliationsConfigurationsDepots,
  actionUpdateReconciliationsConfigurationsMERGE,
  RECONCILIATIONS_CONFIGURATIONS_ADD,
  RECONCILIATIONS_CONFIGURATIONS_DELETE,
  RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET,
  RECONCILIATIONS_CONFIGURATIONS_EDIT, RECONCILIATIONS_CONFIGURATIONS_EXPORT,
  RECONCILIATIONS_CONFIGURATIONS_GET, RECONCILIATIONS_CONFIGURATIONS_IMPORT,
  RECONCILIATIONS_CONFIGURATIONS_PARENT_MERGED_GET, RECONCILIATIONS_CONFIGURATIONS_WITHOUT_TERRITORY_DEPOTS_GET,
  RECONCILIATIONS_PERIOD_GET,
  RECONCILIATIONS_PERIODS_ADD,
  RECONCILIATIONS_PERIODS_APPROVE,
  RECONCILIATIONS_PERIODS_DELETE,
  RECONCILIATIONS_PERIODS_EDIT,
  RECONCILIATIONS_PERIODS_EXPORT,
  RECONCILIATIONS_PERIODS_GET,
  RECONCILIATIONS_PERIODS_INVOICE,
  RECONCILIATIONS_PERIODS_RECALCULATE,
  RECONCILIATIONS_PERIODS_RELOAD,
  RECONCILIATIONS_PERIODS_TABLE_INVOICE_EXPORT,
  RECONCILIATIONS_PERIODS_TABLE_XLS_EXPORT,
  RECONCILIATIONS_PERIODS_XLS,
} from '../reducers/reconciliationModel/reconciliationModel-actions.js';
import {objectKeysToUpperLowerCase} from '../../utils/objects-util.js';
import {getHistoryLocationParams} from '../../utils/routesHelper.js';
import {getInitModel} from '../../services/initModels.js';
import {MODEL_TYPE} from '../../utils/enums.js';
import {reconciliationsSettingsAddModel} from '../../models/reconciliationsSettingsTemplateModel.js';
import {getReconciliationSettings} from "../selectors.js";
import countDecimals from "../../utils/getCountDecimal.js";
import i18n from "../../i18n.js";
import {getTerritoriesEntity} from "../../services/restaurant.js";

function* exportReconciliationsConfigurationsSaga(action) {
  yield* createSaga(function*() {
    const requestResult = yield call(exportReconciliationsConfigurations);
    if (requestResultIsOk(requestResult)) {
      const c = requestResult.headers["content-disposition"];
      let fileName = '';
      try {
        fileName = decodeURIComponent(c.split(";")[1].trim().split("=")[1].replace(new RegExp('"', "g"), "")) || decodeURIComponent(escape(atob(c.split('?')[3])));
      } catch (e) {
        fileName = `Reconciliations.xlsx`;
      }
      const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const contentType = requestResult.headers["content-type"] || octetStreamMime;
      const blob = new Blob([requestResult.data], { type: contentType });

      saveAs(blob, fileName);
    } else {
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action.type);
}

function* importReconciliationsConfigurationsSaga(action) {
  yield* createSaga(function*() {
    yield put(actionSetSectionItemsLoading(true));
    const file = action.data?.file
    if(!file) return
    const requestResult = yield call(importReconciliationsConfigurations, file);
    if (requestResultIsOk(requestResult)) {
      yield call(getReconciliationsConfigurationsSaga, {});
      yield put(actionSetSectionItemsLoading(false));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action?.type);
}

export function* getReconciliationsConfigurationsSaga(action) {
  yield* createSaga(function*() {
    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(fetchReconciliationsConfigurations);

    if (requestResultIsOk(requestResult)) {
      let data = objectKeysToUpperLowerCase(requestResult.data);

      const config = data.find(c => c.id === getHistoryLocationParams(window.location.search).id);
      if(!config?.parentId) {
        yield put(actionUpdateReconciliationsConfigurationsMERGE(null));
      }

      if(!getTerritoriesEntity().length) {
        yield put(createAction(RECONCILIATIONS_CONFIGURATIONS_WITHOUT_TERRITORY_DEPOTS_GET));
      }

      data = data.map((setting) => ({
        ...setting,
            fees: Object.entries(setting.fees).reduce((acc, [key, value]) => {
              if(value === 0){
                acc[key] = 0;
              } else {
                acc[key] = value ? +value * 100 : null;
                acc[key] = (acc[key] && countDecimals(acc[key]) > 4) ? acc[key].toFixed(5) : acc[key];
              }
          return  acc;
        }, {})
      }));

      yield put(actionLoadReconciliationsConfigurations(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* addReconciliationsConfigurationSaga(action) {
  yield* createSaga(function*() {

    yield put(actionSetSectionItemsLoading(true));

    const state = yield select(getReconciliationSettings);
    let allConfigurations = [];


    if(state){
      allConfigurations = [...state];
    }

    let configurationModel = getInitModel(MODEL_TYPE.reconciliationsSettingsTemplateModel, action.data);
    configurationModel = {
      ...configurationModel,
      fees: Object.entries(configurationModel.fees).reduce((acc, [key, value]) => {
        acc[key] = value ? (+value / 100) : null;
        if(acc[key] && countDecimals(acc[key]) > 7){
          acc[key] = +acc[key].toFixed(8)
        }
        return acc;
      }, {})
    };
    configurationModel = reconciliationsSettingsAddModel(configurationModel, allConfigurations);

    if(configurationModel?.depotIds) {
      const uniqueIds = new Set(configurationModel.depotIds);

      const uniqueIdArray = Array.from(uniqueIds);
      if(uniqueIdArray) {
        configurationModel.depotIds = uniqueIdArray;
      }
    }

    const { isDirty, refreshedValue, ...newData } = configurationModel;

    const requestResult = yield call(postReconciliationsConfiguration, newData);

    if (requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      yield put(actionAddReconciliationsConfigurations(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      // return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* editReconciliationsConfigurationSaga(action) {
  yield* createSaga(function*() {
    const { id: configurationId } = getHistoryLocationParams(window.location.search);
    yield put(actionSetSectionItemsLoading(true));
    let configurationModel = getInitModel(MODEL_TYPE.reconciliationsSettingsTemplateModel, action.data);


    configurationModel = {
      ...configurationModel,
      fees: Object.entries(configurationModel.fees).reduce((acc, [key, value]) => {
        acc[key] = value ? (+value / 100) : null;

        if(acc[key] && countDecimals(acc[key]) > 7){
          acc[key] = +acc[key].toFixed(8)
        }

        return acc;
      }, {})
    };

    if(configurationModel?.depotIds) {
      const uniqueIds = new Set(configurationModel.depotIds);

      const uniqueIdArray = Array.from(uniqueIds);
      if(uniqueIdArray) {
        configurationModel.depotIds = uniqueIdArray;
      }

    }

    const { isDirty, refreshedValue, ...newData } = configurationModel;

    const requestResult = yield call(updateReconciliationsConfiguration, {
      data: newData,
      configurationId,
    });


    if (requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      yield put(actionUpdateReconciliationsConfigurations(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}
export function* fetchReconciliationsConfigurationDepotsSaga(action) {
  yield* createSaga(function*() {
    const territoryId = action.data;
    if(typeof territoryId !== "string"){
      return;
    }
    yield put(actionSetSectionItemsLoading(true));

    const requestResult = yield call(fetchReconciliationsConfigurationDepots, territoryId);

    if (requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      data.depots.sort((a, b) => {
        return a.depotName.localeCompare(b.depotName, i18n.language ? i18n.language: 'en');
      });

      yield put(actionUpdateReconciliationsConfigurationsDepots(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* fetchReconciliationsConfigurationDepotsWithoutTerritorySaga(action) {
  yield* createSaga(function*() {
    yield put(actionSetSectionItemsLoading(true));

    const requestResult = yield call(fetchReconciliationsConfigurationDepotsWithoutTerritory);

    if (requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      data.depots.sort((a, b) => {
        return a.depotName.localeCompare(b.depotName, i18n.language ? i18n.language: 'en');
      });

      yield put(actionUpdateReconciliationsConfigurationsDepots(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* getMergedConfigurationSaga(action) {
  yield* createSaga(function*() {
    const parentId = action.data;
    const {id} = getHistoryLocationParams(window.location.search);

    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(getMergedConfiguration, id, parentId);
    let newConfig = null;

    if(requestResult && requestResult.data) {
      newConfig = {
        ...requestResult.data,
        Fees: Object.entries(requestResult.data.Fees).reduce((acc, [key, value]) => {
          if(value === 0){
            acc[key] = 0;
          } else {
            acc[key] = value ? +value * 100 : null;
            acc[key] = (acc[key] && countDecimals(acc[key]) > 4) ? acc[key].toFixed(5) : acc[key];
          }
          return  acc;
        }, {})
      }
    }

    if (requestResultIsOk(requestResult) && newConfig) {
      const data = objectKeysToUpperLowerCase(newConfig);

      yield put(actionUpdateReconciliationsConfigurationsMERGE(data));
    } else {
      yield put(actionUpdateReconciliationsConfigurationsMERGE(null));
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}


export function* editReconciliationsPeriodSaga(action) {
  yield* createSaga(function*() {
    const { id: periodId } = getHistoryLocationParams(window.location.search);
    const data = action?.data?.stats
    yield put(actionSetSectionItemsLoading(true));
    const offsets = {UpdateOffsets: []};
    if(data && data.length > 0){
      data.forEach((e) => {
        offsets.UpdateOffsets.push({StatId: e.territoryId ? e.territoryId : e.id, OffsetValue: +e.offset, isTerritory: !!e.territoryId})
      });
    }

    const requestResult = yield call(updateReconciliationsPeriod, {
      data: offsets,
      id: periodId,
    });

    const requestRecalculateResult = yield call(recalculateReconciliationsPeriod, {periodRewriteSourceDataModel: true, periodId});


    if (requestResultIsOk(requestResult) && requestResultIsOk(requestRecalculateResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);

      data.stats.sort((a, b) => {
        if (a.depotName === null) return 1;
        if (b.depotName === null) return -1;
        return a.depotName.localeCompare(b.depotName, i18n.language ? i18n.language: 'en');
      });

      data.territoryStats.sort((a, b) => {
        if (a.territoryStats === null) return 1;
        if (b.territoryStats === null) return -1;
        return a.territoryName.localeCompare(b.territoryName, i18n.language ? i18n.language: 'en');
      });

      yield put(actionLoadReconciliationsPeriod(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* deleteReconciliationsConfigurationSaga(action) {
  yield* createSaga(function*() {
    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(deleteReconciliationsConfiguration, action.data.id);

    if (requestResultIsOk(requestResult)) {
      yield call(getReconciliationsConfigurationsSaga, {});
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* deleteReconciliationsPeriodsSaga(action) {
  yield* createSaga(function*() {
    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(deleteReconciliationsPeriods, action.data.id);

    if (requestResultIsOk(requestResult)) {
      yield call(getReconciliationsPeriodsSaga, {});
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* getReconciliationsPeriodsSaga(action) {
  yield* createSaga(function*() {
    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(fetchReconciliationsPeriods);
    if (requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      yield put(actionLoadReconciliationsPeriods(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* getReconciliationsPeriodSaga(action) {
  yield* createSaga(function*() {
    const { id } = getHistoryLocationParams(window.location.search);
    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(fetchReconciliationsPeriodById, id);
    if (requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);

      data.stats.sort((a, b) => {
        if (a.depotName === null) return 1;
        if (b.depotName === null) return -1;
        return a.depotName.localeCompare(b.depotName, i18n.language ? i18n.language: 'en');
      });

      data.territoryStats.sort((a, b) => {
        if (a.territoryStats === null) return 1;
        if (b.territoryStats === null) return -1;
        return a.territoryName.localeCompare(b.territoryName, i18n.language ? i18n.language: 'en');
      });

      yield put(actionLoadReconciliationsPeriod(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* addReconciliationsPeriodsSaga(action) {
  yield* createSaga(function*() {
    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(postReconciliationsPeriods, action.data);

    if (requestResultIsOk(requestResult)) {
      const data = objectKeysToUpperLowerCase(requestResult.data);
      yield put(actionAddReconciliationsPeriods(data));
      yield put({ type: RECONCILIATIONS_PERIODS_GET });
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }

    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* xlsReconciliationsPeriodSaga(action) {
  yield* createSaga(function*() {
    let idPeriod = action.data;
    if(!idPeriod){
      const { id } = getHistoryLocationParams(window.location.search);
      idPeriod = id;
    }
    // yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(xlsReconciliationsPeriod, idPeriod);

    if (requestResult && requestResult.status === 200 && requestResult.data) {
      const c = requestResult.headers["content-disposition"];
      let fileName = decodeURIComponent(c.split(";")[1].trim().split("=")[1].replace(new RegExp('"', "g"), ""));
      const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const contentType = requestResult.headers["content-type"] || octetStreamMime;
      const blob = new Blob([requestResult.data], { type: contentType });

      saveAs(blob, fileName);
      // yield put(actionSetSectionItemsLoading(false));
      return {data: null};
    } else {
      // yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action.type);
}

export function* invoiceReconciliationsPeriodSaga(action) {
  yield* createSaga(function*() {
    let idPeriod = action.data;
    if(typeof idPeriod !== "string"){
      const { id } = getHistoryLocationParams(window.location.search);
      idPeriod = id;
    }
    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(invoiceReconciliationsPeriod, idPeriod);

    if (requestResult && requestResult.status === 200 && requestResult.data) {
      const c = requestResult.headers["content-disposition"];
      let fileName = decodeURIComponent(c.split(";")[1].trim().split("=")[1].replace(new RegExp('"', "g"), ""));
      const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const contentType = requestResult.headers["content-type"] || octetStreamMime;
      const blob = new Blob([requestResult.data], { type: contentType });

      saveAs(blob, fileName);
      yield put(actionSetSectionItemsLoading(false));
      return {data: null};
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action.type);
}

export function* recalculateReconciliationsPeriodSaga(action) {
  yield* createSaga(function*() {
    const idPeriod = action.data.id;

    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(recalculateReconciliationsPeriod, {periodRewriteSourceDataModel: true, periodId: idPeriod});

    if (requestResult && requestResult.status === 200 && requestResult.data) {
      const data = objectKeysToUpperLowerCase(requestResult.data);

      data.stats.sort((a, b) => {
        if (a.depotName === null) return 1;
        if (b.depotName === null) return -1;
        return a.depotName.localeCompare(b.depotName, i18n.language ? i18n.language: 'en');
      });

      data.territoryStats.sort((a, b) => {
        if (a.territoryName === null) return 1;
        if (b.territoryName === null) return -1;
        return a.territoryName.localeCompare(b.territoryName, i18n.language ? i18n.language: 'en');
      });

      yield put(actionLoadReconciliationsPeriod(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* reloadReconciliationsPeriodSaga(action) {
  yield* createSaga(function*() {
    const idPeriod = action.data.id;

    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(recalculateReconciliationsPeriod, {periodRewriteSourceDataModel: false, periodId: idPeriod});

    if (requestResult && requestResult.status === 200 && requestResult.data) {
      const data = objectKeysToUpperLowerCase(requestResult.data);

      data.stats.sort((a, b) => {
        if (a.depotName === null) return 1;
        if (b.depotName === null) return -1;
        return a.depotName.localeCompare(b.depotName, i18n.language ? i18n.language: 'en');
      });

      data.territoryStats.sort((a, b) => {
        if (a.territoryName === null) return 1;
        if (b.territoryName === null) return -1;
        return a.territoryName.localeCompare(b.territoryName, i18n.language ? i18n.language: 'en');
      });

      yield put(actionLoadReconciliationsPeriod(data));
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* approveRecalculateReconciliationsPeriodSaga(action) {
  yield* createSaga(function*() {
    const idPeriod = action?.data?.id;

    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(approveReconciliationsPeriod, idPeriod);

    if (requestResult && requestResult.status === 200 && requestResult.data) {
      const requestPeriodResult = yield call(fetchReconciliationsPeriodById, idPeriod);
      if (requestResultIsOk(requestPeriodResult)) {
        const data = objectKeysToUpperLowerCase(requestPeriodResult.data);
        yield put(actionLoadReconciliationsPeriod(data));
      } else {
        yield put(actionSetSectionItemsLoading(false));
        return new ErrorData(getErrorMessage(requestResult));
      }
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
    yield put(actionSetSectionItemsLoading(false));
  }, action.type);
}

export function* exportReconciliationsPeriodSaga(action) {
  yield* createSaga(function*() {
    let idPeriod = action?.data?.id;
    if(!idPeriod){
      const { id } = getHistoryLocationParams(window.location.search);
      idPeriod = id;
    }
    // yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(exportReconciliationsPeriod, idPeriod);

    if (requestResult && requestResult.status === 200 && requestResult.data) {
      const c = requestResult.headers["content-disposition"];
      let fileName = decodeURIComponent(c.split(";")[1].trim().split("=")[1].replace(new RegExp('"', "g"), ""));
      const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const contentType = requestResult.headers["content-type"] || octetStreamMime;
      const blob = new Blob([requestResult.data], { type: contentType });

      saveAs(blob, fileName);
      // yield put(actionSetSectionItemsLoading(false));
      return {data: null};
    } else {
      // yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action.type);
}

export function* xlsExportReconciliationsPeriodTableSaga(action) {
  yield* createSaga(function*() {
    const { id: periodId } = getHistoryLocationParams(window.location.search);
    let depotId = action.data.depotId;
    let actorType = action.data.isDepot;
    let type = !actorType ? 'territory': 'depot';

    // yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(exportReconciliationsPeriodTableXls, periodId, type, depotId);

    if (requestResult && requestResult.status === 200 && requestResult.data) {
      const c = requestResult.headers["content-disposition"];
      let fileName = decodeURIComponent(c.split(";")[1].trim().split("=")[1].replace(new RegExp('"', "g"), ""));
      const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const contentType = requestResult.headers["content-type"] || octetStreamMime;
      const blob = new Blob([requestResult.data], { type: contentType });

      saveAs(blob, fileName);
      // yield put(actionSetSectionItemsLoading(false));
      return {data: null};
    } else {
      // yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action.type);
}
export function* invoiceExportReconciliationsPeriodTableSaga(action) {
  yield* createSaga(function*() {
    const { id: periodId } = getHistoryLocationParams(window.location.search);
    let entityId = action.data.depotId;
    let actorType = action.data.isDepot;
    let type = !actorType ? 'territory': 'depot';

    yield put(actionSetSectionItemsLoading(true));
    const requestResult = yield call(exportReconciliationsPeriodTableInvoice, periodId, type, entityId);

    if (requestResult && requestResult.status === 200 && requestResult.data) {
      const c = requestResult.headers["content-disposition"];
      let fileName = decodeURIComponent(c.split(";")[1].trim().split("=")[1].replace(new RegExp('"', "g"), ""));
      const octetStreamMime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const contentType = requestResult.headers["content-type"] || octetStreamMime;
      const blob = new Blob([requestResult.data], { type: contentType });

      saveAs(blob, fileName);
      yield put(actionSetSectionItemsLoading(false));
      return {data: null};
    } else {
      yield put(actionSetSectionItemsLoading(false));
      return new ErrorData(getErrorMessage(requestResult));
    }
  }, action.type);
}


export default function*() {
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_EXPORT, exportReconciliationsConfigurationsSaga);
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_IMPORT, importReconciliationsConfigurationsSaga);
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_GET, getReconciliationsConfigurationsSaga);
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_PARENT_MERGED_GET, getMergedConfigurationSaga);
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_ADD, addReconciliationsConfigurationSaga);
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_EDIT, editReconciliationsConfigurationSaga);
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_DEPOTS_GET, fetchReconciliationsConfigurationDepotsSaga);
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_WITHOUT_TERRITORY_DEPOTS_GET, fetchReconciliationsConfigurationDepotsWithoutTerritorySaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_DELETE, deleteReconciliationsPeriodsSaga);
  yield takeEvery(RECONCILIATIONS_CONFIGURATIONS_DELETE, deleteReconciliationsConfigurationSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_GET, getReconciliationsPeriodsSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_XLS, xlsReconciliationsPeriodSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_INVOICE, invoiceReconciliationsPeriodSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_TABLE_INVOICE_EXPORT, invoiceExportReconciliationsPeriodTableSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_TABLE_XLS_EXPORT, xlsExportReconciliationsPeriodTableSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_EXPORT, exportReconciliationsPeriodSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_APPROVE, approveRecalculateReconciliationsPeriodSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_RECALCULATE, recalculateReconciliationsPeriodSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_RELOAD, reloadReconciliationsPeriodSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_EDIT, editReconciliationsPeriodSaga);
  yield takeEvery(RECONCILIATIONS_PERIODS_ADD, addReconciliationsPeriodsSaga);
  yield takeEvery(RECONCILIATIONS_PERIOD_GET, getReconciliationsPeriodSaga);
}
