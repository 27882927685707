import classNames from 'classnames';
import { getFullName, isRTL } from '../../../services/userModel.js';
import { getConvertedCloudUrl } from '../../../utils/image-util.js';
import defaultAvatar from '../../../assets/images/avatar.png';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const CourierShiftsFilterItem = ({ user, users, onUserClick }) => {
  if (!user) return null;
  const userFromState = users.find(item => item.userId === user.userId);
  const courier = userFromState || user;

  return (
    <div className={classNames('managerItem', { rtl: isRTL() })} onClick={() => onUserClick && onUserClick(courier)}>
      <div className='leftSide'>
        <img src={getConvertedCloudUrl(courier.avatarUrl) || defaultAvatar} alt={`img-${courier.userId}`} />
      </div>
      <div className='centerSide overflowHiddenText'>
        <div style={{ width: '88%' }}>
          <div className='title overflowText'>{getFullName(courier)}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    users: state.userModel.data || [],
  };
};

export default connect(mapStateToProps)(withTranslation()(CourierShiftsFilterItem));
