import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './index.scss';

import {
  CUSTOMER_ADD_BONUS_CARD, CUSTOMER_BLOCK_USER, CUSTOMER_BLOCK_USER_STATUS, CUSTOMER_DELETE_VOUCHER,
  CUSTOMER_ORDERS_GET, CUSTOMER_UPDATE_COMMENT, CUSTOMER_UPDATE_LOYALTY_LEVEL,
  CUSTOMER_WALLET_INFO_GET,
  CUSTOMER_WALLET_OPERATION,
} from '../../../store/reducers/customer/customer-actions';

import { createAction } from '../../../utils/sagaHelper';

import CustomerDataHeader from '../CustomerDataHeader';
import CustomerDataItems from '../CustomerDataItems';
import CustomerBonusesRefund from '../CustomerBonusesRefund';
import CustomersBonusCard from '../CustomersBonusCard/index.js';
import { CUSTOMER_WALLET_OPERATION_TYPE } from '../../../utils/constants.js';
import CustomerBonusCardsList from '../CustomerBonusCardsList/index.js';
import CustomersBlockUser from "../CustomerBlockUser/index.js";

const CustomersData = ({ customerId, getCustomerOrders, onChangeComment, getCustomerWalletInfo, getCustomerBlockUserStatus, customerWalletOperation, onDeleteVoucher, customerAddBonusCard, customerUpdateLoyaltyLevel, customerBlockUser }) => {
  const [filter, setFilter] = useState({ rating: null, deliveryRating: null });
  const [popupData, setPopupData] = useState({ type: null, isOpen: false });
  const [bonusCards, setBonusCards] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const handleOnSetFilter = val => {
    setFilter({ ...filter, ...val });
  };

  const handleOnOpenBonus = type => {
    setPopupData({ type, isOpen: true });
  };

  const handleOnBonusClose = () => {
    setPopupData({ type: null, isOpen: false });
  };

  const onDelete = (cardId) => {
    return onDeleteVoucher({ cardId, customerId });
  }

  const handleOnBonusSave = data => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    customerWalletOperation({ type: popupData.type, customerId, data }).then(() => {
      handleOnBonusClose();
      getCustomerWalletInfo({ customerId });
      setIsFetching(false);
    }).catch(e => setIsFetching(false));
  };

  const handleOnBonusCardSave = data => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    customerAddBonusCard({ customerId, data }).then(() => {
      handleOnBonusClose();
      getCustomerWalletInfo({ customerId });
      setIsFetching(false);
    }).catch(e => setIsFetching(false));
  }

  const handleOnUpdateLoyaltyLevel = (data) => {
    customerUpdateLoyaltyLevel({ customerId, loyaltyLevel: data.key });
  }

  const handleOnBlockUser = (user) => {
    if(user?.blockInfo?.isBlocked) {
      customerBlockUser({customerId, isBlocked: user?.blockInfo?.isBlocked});
    } else {
      setPopupData({ type: CUSTOMER_WALLET_OPERATION_TYPE.block, isOpen: true });
    }
  }

  const handleOnUpdateMessage = (notes) => {
    onChangeComment({ customerId, notes });
  }

  const handleOnSaveBlockUser = (notes) => {
    customerBlockUser({customerId, notes, isBlocked: false});
    setPopupData({type: null, isOpen: false });
  }

  useEffect(() => {
    setIsFetching(true);
    getCustomerOrders({ ...filter, customerId }).then(() => {
      setIsFetching(false);
    });
  }, [customerId, filter, getCustomerOrders, ]);

  useEffect(() => {
    getCustomerWalletInfo({ customerId }).then(data => {
      setBonusCards(data?.bonusCards || [])
    });
    getCustomerBlockUserStatus({ customerId });
  }, [customerId, isFetching, getCustomerWalletInfo, getCustomerBlockUserStatus]);

  const getPageComponent = () => {
    switch (popupData.type) {
      case CUSTOMER_WALLET_OPERATION_TYPE.bonusCard:
        return <CustomersBonusCard onClose={handleOnBonusClose} onSave={handleOnBonusCardSave} />;
      case CUSTOMER_WALLET_OPERATION_TYPE.bonusCardsList:
        return <CustomerBonusCardsList onDelete={onDelete} customerId={customerId} bonusCards={bonusCards} onClose={handleOnBonusClose} />
      case CUSTOMER_WALLET_OPERATION_TYPE.block:
        return <CustomersBlockUser onClose={() => setPopupData({type: null, isOpen: false})} onSave={handleOnSaveBlockUser} />;
      default:
        return <CustomerBonusesRefund writeOff={popupData.type === CUSTOMER_WALLET_OPERATION_TYPE.spend} onClose={handleOnBonusClose} onSave={handleOnBonusSave} />;
    }
  };

  return (
    <div className={classNames('customersData')}>
      {customerId && (
        <CustomerDataHeader
          filter={filter}
          isFetching={isFetching}
          customerId={customerId}
          onSetFilter={handleOnSetFilter}
          onOpenBonus={handleOnOpenBonus}
          onUpdateLoyaltyLevel={handleOnUpdateLoyaltyLevel}
          onBlockUser={handleOnBlockUser}
          onUpdateStaffComment={handleOnUpdateMessage}
        />
      )}
      {isFetching && (
          <div className={classNames("loaderContainer")}>
            <div className={classNames("loader")} />
          </div>
      )}
      {customerId && <CustomerDataItems customerId={customerId} onBlockUser={handleOnBlockUser} onOpenBonus={handleOnOpenBonus} />}
      <div className={classNames('layoutContainer', { open: popupData.isOpen, isList: CUSTOMER_WALLET_OPERATION_TYPE.bonusCardsList })}>
        {popupData.isOpen && getPageComponent()}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getCustomerOrders: data => dispatch(createAction(CUSTOMER_ORDERS_GET, data)),
    getCustomerWalletInfo: data => dispatch(createAction(CUSTOMER_WALLET_INFO_GET, data)),
    getCustomerBlockUserStatus: data => dispatch(createAction(CUSTOMER_BLOCK_USER_STATUS, data)),
    customerWalletOperation: data => dispatch(createAction(CUSTOMER_WALLET_OPERATION, data)),
    customerAddBonusCard: data => dispatch(createAction(CUSTOMER_ADD_BONUS_CARD, data)),
    customerUpdateLoyaltyLevel: data => dispatch(createAction(CUSTOMER_UPDATE_LOYALTY_LEVEL, data)),
    customerBlockUser: data => dispatch(createAction(CUSTOMER_BLOCK_USER, data)),
    onChangeComment: data => dispatch(createAction(CUSTOMER_UPDATE_COMMENT, data)),
    onDeleteVoucher: data => dispatch(createAction(CUSTOMER_DELETE_VOUCHER, data)),
  };
};

export default connect(null, mapDispatchToProps)(CustomersData);
