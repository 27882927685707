import { TYPE_MENU } from '../utils/enums';

import { createHeaderMenu } from '../models/headerMenu';
import { createSettingsMenu } from '../models/settingsMenu';
import { createOrderingMenu } from '../models/orderingMenu';
import { createDepotMenu } from '../models/depotMenu';
import { createMarketplaceSettingsMenu } from '../models/marketplaceSettingsMenu';
import { createMenuTemplateMenu } from '../models/menuTemplateMenu';
import { createOrderingMenuMenuTemplate } from '../models/orderingMenuMenuTemplate';
import { createShiftsMenu } from '../models/shiftsMenu';
import { createMessageMenuSettings } from '../models/menuMessageSettings';
import {createLoyaltyMenu} from '../models/createLoyaltyMenu.js';
import { createDeliveryMenu } from '../models/createDeliveryMenu.js';
import { createAppBuilderMenu } from '../models/createAppBuilderMenu.js';
import {createReconciliationMenu} from "../models/reconciliationMenu.js";
import {createCustomerServiceMenuSettings} from "../models/menuCustomerService.js";
import {createMenuGeoSettings} from "../models/menuGeoSettings.js";
import {createMenuCouriermanagementSettings} from "../models/menuCourierManagement.js";
import {createMenuIntegrationsSettings} from "../models/menuItegrationsSettings.js";
import {
  createMenuOperationsManagementSettings
} from "../models/menuOperationsManagement.js";

export function createMenuModel(typeMenu) {
  switch (typeMenu) {
    case TYPE_MENU.header:
      return createHeaderMenu();
    case TYPE_MENU.settings:
      return createSettingsMenu();
    case TYPE_MENU.shifts:
      return createShiftsMenu();
    case TYPE_MENU.orderingMenu:
      return createOrderingMenu();
    case TYPE_MENU.loyaltyMenu:
      return createLoyaltyMenu();
    case TYPE_MENU.deliveryMenu:
      return createDeliveryMenu();
    case TYPE_MENU.appBuilderMenu:
      return createAppBuilderMenu();
    case TYPE_MENU.orderingMenuMenuTemplate:
      return createOrderingMenuMenuTemplate();
    case TYPE_MENU.depotSettings:
      return createDepotMenu();
    case TYPE_MENU.menuTemplate:
      return createMenuTemplateMenu();
    case TYPE_MENU.marketplaceSettings:
      return createMarketplaceSettingsMenu();
    case TYPE_MENU.reconciliations:
      return createReconciliationMenu();
    case TYPE_MENU.messageSettings:
      return createMessageMenuSettings();
    case TYPE_MENU.customerService:
      return createCustomerServiceMenuSettings();
    case TYPE_MENU.geoSettings:
      return createMenuGeoSettings();
    case TYPE_MENU.courierManagement:
      return createMenuCouriermanagementSettings();
    case TYPE_MENU.integrations:
      return createMenuIntegrationsSettings();
    case TYPE_MENU.operationsManagement:
      return createMenuOperationsManagementSettings();
    default:
      return [];
  }
}
