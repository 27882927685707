import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import CheckBox from "../CheckBox";

const GroupCheckbox = ({ t, title, items, onChange }) => {
    return (
        <div className={classNames("groupCheckbox")}>            
            <div className={classNames("itemsContainer")}>
                {items.map((item, itemIndex)=> (
                    <div key={`groupCheckboxItems-${itemIndex}`} className={classNames("items")}>
                        {title && (<div className={classNames("title")}>{t(item.title)}</div>)}
                        {item.items.map((groupCheckbox, groupCheckboxIndex)=> (
                            <CheckBox key={`groupCheckbox-${groupCheckboxIndex}`}
                                label={t(groupCheckbox.label)}
                                value={groupCheckbox.selected}
                                onChange={value => onChange(items.map((m, i)=> i === itemIndex 
                                    ? { ...m, items: m.items.map((chBox, chBoxIndex)=> chBoxIndex === groupCheckboxIndex ? ({ ...chBox, selected: value }) : chBox)}  
                                    : m
                                ))}
                            />
                        ))}
                    </div>                    
                ))}
            </div>
        </div>
    );
}

export default withTranslation()(GroupCheckbox);