import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CouriersShiftPopup from '../../components/CouriersShiftPopup';

const mapStateToProps = state => {
  return {
    selectedControlCenter: state.restaurant?.data?.selectedControlCenter,
    orders: state.order.data.orders || [],
    pickUps: state.order.data.pickUps || [],
    activeControlCenter: state.restaurant?.data?.activeControlCenter?.controlCenterId
  };
};
export default connect(mapStateToProps)(withTranslation()(CouriersShiftPopup));
