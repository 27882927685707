import React from "react";
import classNames from "classnames";
import "./index.scss";
import { getTranslateTitle } from '../../../utils/objects-util.js';

export default ({ value, handleOnClick, id, isBlueColor, isRedActiveColor, title, isReadonly }) => {

  const titleOff = getTranslateTitle(title.off)
  const titleOn = getTranslateTitle(title.on)

  const getLabel = () => {
    return !value ? (title ? titleOff : "OFF") : title ? titleOn : "ON";
  };

  const lableText = getLabel();

  return (
    <div className={classNames("onoffswitchControl", { isLongTitle: lableText.length > 4 })}>
      <div className="autoMode">
        <div className="onoffswitch">
          <input
            type="checkbox"
            name="onoffswitch"
            className="onoffswitch-checkbox"
            id={id || "myonoffswitch"}
            checked={value}
            onChange={handleOnClick}
            readOnly={isReadonly}
          />
          <label className="onoffswitch-label" htmlFor={id || "myonoffswitch"}>
            <span
              className={classNames("onoffswitch-inner", {
                isBlue: isBlueColor,
                isRedActiveColor: isRedActiveColor
              })}
            />
            <span className="onoffswitch-switch" />
            <span className={classNames("switchLabel", { active: value })}>
              {lableText}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};
