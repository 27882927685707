import React, { useState } from 'react';
import classNames from 'classnames';
import CouriersShiftPopup from '../../containers/CouriersShiftPopupContainer/index.js';
import { getFullName } from '../../../services/userModel.js';
import ColumnTitleWithIcon from '../ColumnTitleWithIcon/index.js';
import { POPUP_ITEM_TYPE, USER_FILTER_ROLES } from '../../../utils/enums.js';
import { useLocation } from 'react-router';
import { getHistoryLocationParams } from '../../../utils/routesHelper.js';

const UsersPopup = ({
  column,
  item,
  error,
  onPropChange,
  itemType,
  roles,
  option,
  optionKey,
  onOptionChange,
  controlCenterOption,
  isClearUser = false,
}) => {
  const location = useLocation();
  const { id: controlCenterId } = getHistoryLocationParams(location.search)

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [userName, setUserName] = useState('');

  const handleUserClick = user => {
    setUserName(getFullName(user));
    setIsOpenPopup(false);

    if (optionKey) {
      onOptionChange(optionKey, 'selected', user.userId);
    } else {
      onPropChange({ userId: user.userId });
    }
  };

  const clearUser = () => {
    setUserName('');
    setIsOpenPopup(false);

    if (optionKey) {
      onOptionChange(optionKey, 'selected', null);
    } else {
      onPropChange({ userId: null });
    }
  };

  const getRoleByItemType = () => {
    //should always return array
    switch (itemType || column.popupItemType) {
      case POPUP_ITEM_TYPE.managerItem:
        return [USER_FILTER_ROLES.deliveryManager];
      default:
        return null;
    }
  };

  return (
    <div className={classNames('currentShiftCourierEdit')}>
      <div key={`sectionItemText-${column?.accessor}`} className={classNames('sectionItemText', column?.inlineType)}>
        {item && <ColumnTitleWithIcon item={item} column={column} onPropChange={onPropChange} />}
        {option && <div className={classNames('title')}>{option?.label || ''}</div>}
        <form
          autoComplete='off'
          className={classNames('inputItem', { isShowClearIcon: isClearUser || option?.isClearUser })}
        >
          <input
            onClick={() => setIsOpenPopup(true)}
            type='text'
            className={classNames('overflowHiddenText', { hasError: column?.hasError && column?.hasError(error) })}
            placeholder={column?.placeholder || ''}
            value={userName || ''}
            readOnly={true}
          />
          <div className={classNames('clearUser', { isShow: isClearUser || option?.isClearUser })} onClick={clearUser}>
            x
          </div>
        </form>
      </div>
      {isOpenPopup && (
        <CouriersShiftPopup
          itemType={itemType || column.popupItemType}
          roles={roles || getRoleByItemType()}
          onUserClick={handleUserClick}
          controlCenterFilter={controlCenterOption?.selected || controlCenterId}
          isOpen={isOpenPopup}
          onClose={() => setIsOpenPopup(false)}
        />
      )}
    </div>
  );
};
export default UsersPopup;
