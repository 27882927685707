import {callServer} from "../utils/request-util";
import environment from "environment";
import {getDefaultAdminOrdersSearchFilter} from "../services/order";

export function fetchActiveOrders() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/activeorders",
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.supervisor
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchCycle() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle",
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.supervisor
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function associateOrder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/associateOrder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function associateAssistance(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "assistance/associateOrder",
      method: "POST",
      data,
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.supervisor
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchAssistance() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "assistance",
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.supervisor
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function changeOrderState(id, state) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "orders/changeState",
      method: "POST",
      data: { Id: id, State: state },
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot,
        environment.USER_ROLES.supportOperator
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function courierReorder(orderId, courierId, index) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/reorder",
      method: "POST",
      data: {
        OrderId: orderId,
        CourierId: courierId,
        Index: index
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function groupReorder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "groups/reorder",
      method: "POST",
      data,
      roles: [environment.USER_ROLES.deliveryManager],
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function associateOrders(courierId, orders) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/associateOrders/" + courierId,
      method: "POST",
      data: {
        OrderId: orders
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function disbandGroup(groupId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "groups/disbandGroup/" + groupId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function exchangeOrders(fromCourierId, toCourierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/exchange",
      method: "POST",
      data: {
        FromCourierId: fromCourierId,
        ToCourierId: toCourierId
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function associateGroup(groupId, courierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "cycle/associateGroup",
      method: "POST",
      data: {
        GroupId: groupId,
        CourierId: courierId
      },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deleteOrder(orderId, token) {
  return new Promise((resolve, reject) => {
    callServer({
      headers: {
        Authorization: token
      },
      url: environment.backendAPI + "orders/" + orderId,
      method: "DELETE",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function editOrder(order, isTakeAway) {
  if (isTakeAway) {
    delete order.location;
    delete order.address;
  }

  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "orders",
      method: "PUT",
      data: order,
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function createNewOrder(order, isTakeAway) {
  if (isTakeAway) {
    delete order.location;
    delete order.address;
    delete order.Location;
    delete order.Address;
  }

  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "orders",
      method: "POST",
      data: order,
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then( response => resolve(response), error => reject(error.response));
  });
}

export function unlockGroup(groupId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "groups/unlock/" + groupId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function lockGroup(groupId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "groups/lock/" + groupId,
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function createNewOrderWithPlace(order) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "orders/place",
      method: "POST",
      data: order,
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      }
    );
  });
}
export function getGEOLocation(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "geolocation",
      method: "POST",
      data: data,
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getGEOLocationStr(address) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "geolocation/str",
      method: "POST",
      data: {
        Address: address
      },
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function fetchAdressesByPhone(phone) {
  return new Promise((resolve, reject)=> {
    callServer({
      url: environment.backendAPI + "geolocation/phone",
      method: "POST",
      data: { Phone: phone },
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function fetchAdressesByName(name) {
  return new Promise((resolve, reject)=> {
    callServer({
      url: environment.backendAPI + "geolocation/consumers",
      method: "POST",
      data: { Phone: name },
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function setNewOrderCashPaid(data, id, paymentId) {
  return new Promise((resolve, reject)=> {
    callServer({
      url: environment.backendAPI + `consumerOrder/getPayment/${id}/${paymentId}`,
      method: "POST",
      data: data,
      roles: [
        environment.USER_ROLES.deliveryManager,environment.USER_ROLES.depot]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function callPrintAction(data, id) {
  return new Promise((resolve, reject)=> {
    callServer({
      url: environment.backendAPI + `consumerOrder/print/${id}`,
      method: "POST",
      data: data,
      roles: [
        environment.USER_ROLES.deliveryManager,environment.USER_ROLES.depot]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function setNewOrderManagerCashPaid(data, id, paymentId) {
  return new Promise((resolve, reject)=> {
    callServer({
      url: environment.backendAPI + `orders/getPayment/${id}/${paymentId}`,
      method: "POST",
      data: {...data, SchemaType: 1},
      roles: [
        environment.USER_ROLES.deliveryManager,environment.USER_ROLES.depot]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function setOrderNote(data, id) {
  return new Promise((resolve, reject)=> {
    callServer({
      url: environment.backendAPI + `consumerOrder/serviceNotes/${id}`,
      method: "POST",
      data: {ServiceNotes: data},
      roles: [environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function setOrderManagerNote(data, id) {
  return new Promise((resolve, reject)=> {
    callServer({
      url: environment.backendAPI + `orders/serviceNotes`,
      method: "POST",
      data: {Id: id, ServiceNotes: data, SchemaType: 1},
      roles: [environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function getAddressHistory(phoneNumber) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "query/consumerAddress/" + phoneNumber,
      method: "GET",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getPlace(placeId, city, street) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "geolocation/place",
      method: "POST",
      data: {
        PlaceId: placeId,
        City: city,
        Street: street
      },
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function deleteAssistanceOrder(orderId, token) {
  const setting = token
    ? {
        headers: {
          Authorization: token
        },
        url: environment.backendAPI + "assistance/" + orderId,
        method: "DELETE",
        roles: [environment.USER_ROLES.deliveryManager]
      }
    : {
        url: environment.backendAPI + "assistance/" + orderId,
        method: "DELETE",
        roles: [environment.USER_ROLES.deliveryManager]
      };

  return new Promise((resolve, reject) => {
    callServer(setting).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function approveAssistanceOrder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "assistance",
      method: "POST",
      data: data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function fetchReceipts(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "receipts/" + id,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getAdvancedInfo(orderId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "receipts/raw/" + orderId,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function getDateOrders(date, territoryId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "query/dateoders",
      method: "POST",
      data: { Date: date, TerritoryId: territoryId },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getLastOrders(hours) {
  if (isNaN(hours)) {
    hours = 24;
  }

  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "query/lastorders/" + hours,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function reloadOrder(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "orders/rework/" + id,
      method: "POST",
      roles: [
        environment.USER_ROLES.deliveryManager,
        environment.USER_ROLES.depot
      ]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function OrdersCsv(start, end, depotId, customerVersion, controlCenterId, includeMarketPlaceOrders, territoryId, recalculatePriceForBusiness,includePartnerOrders,basedOnTargetDate) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/orders",
      method: "POST",
      responseType: "blob",
      data: {
        StartDate: start,
        EndDate: end,
        DepotId: depotId,
        CustomerVersion: customerVersion,
        ControlCenterId: controlCenterId,
        IncludeMarketPlaceOrders: includeMarketPlaceOrders,
        TerritoryId: territoryId,
        RecalculatePriceForBusiness: recalculatePriceForBusiness,
        BasedOnTargetDate: basedOnTargetDate
      },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function AccountOrdersCsv(start, end, depotId, customerVersion, controlCenterId, includeMarketPlaceOrders, territoryId, recalculatePriceForBusiness,includePartnerOrders,basedOnTargetDate) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/accountorders",
      method: "POST",
      responseType: "blob",
      data: {
        StartDate: start,
        EndDate: end,
        DepotId: depotId,
        CustomerVersion: customerVersion,
        ControlCenterId: controlCenterId,
        TerritoryId: territoryId,
        RecalculatePriceForBusiness: recalculatePriceForBusiness,
        IncludePartnerOrders : includePartnerOrders,
        BasedOnTargetDate : basedOnTargetDate
      }
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function setOrderDeliveredBy(orderId, newCourierId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "history/updateOrderDeliveredBy/" + orderId,
      method: "POST",
      data: { NewCourierId: newCourierId },
      roles: [environment.USER_ROLES.deliveryManager]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}


export function fetchConsumerActiveOrders() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerActiveOrders",
      method: "GET",
      roles: [environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchConsumerOrders() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder",
      method: "GET",
      roles: [environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function addConsumerOrder(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function cancelConsumerOrder(orderId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/cancel/" + orderId,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchPendingPreOrders() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerPreOrder",
      method: "GET",
      roles: [environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchAdminOrders(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/order",
      method: "POST",
      data: data || getDefaultAdminOrdersSearchFilter(),
      roles: [environment.USER_ROLES.supportOperator, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchConsumerInfo(consumerId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/consumer/"+ consumerId,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchCourierInfo(orderId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/courierInfo/" + orderId,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchCancelerInfo(orderId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/cancelerInfo/" + orderId,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchBusinessInfo(businessId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/business/"+ businessId,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchAdminOrderById(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/order/"+ id,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchConsumerCardsById(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/cards/"+ id,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function reshipmentOrder(orderId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/reshipment/"+ orderId,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getProductPrice(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/productPrice",
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot, environment.USER_ROLES.supportOperator],
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getOrderPrice(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/orderPrice",
      method: "POST",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot, environment.USER_ROLES.supportOperator],
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getOrderTimeSlots(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/preOrderSchedule",
      method: "POST",
      // roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot, environment.USER_ROLES.supportOperator],
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function importTemplateBatchOrder(file){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "markeplaceBulkOrder/create",
      method: "POST",
      data: file,
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function exportTemplateBatchOrder(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "markeplaceBulkOrder/exportTemplate",
      method: "GET",
      responseType: "blob",
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function updateBatchOrderRow(templateId, data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}`,
      method: "PUT",
      data,
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function deleteBatchOrderRow(templateId, rowId){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}/${rowId}`,
      method: "DELETE",
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function createBatchOrderRow(templateId, data){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}`,
      method: "POST",
      data,
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function updateBatchOrderComment(templateId, comment){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}/comment`,
      method: "PUT",
      data: { Comment, comment},
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function getTemplateBatchDrafts(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "markeplaceBulkOrder",
      method: "GET",
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function deleteTemplateBatchDraft(templateId){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}`,
      method: "DELETE",
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function getTemplateBatchDraftById(templateId){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}`,
      method: "GET",
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function getTemplateBatchOrderPrice(templateId, body){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}/orderPrice`,
      method: "POST",
      data: body
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function updateDeliveryBusinessBatchOrder(templateId, consumerId, body){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}/orderPrice/${consumerId}`,
      method: "POST",
      data: body
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function processBatchOrder(templateId){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}/process`,
      method: "POST",
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function submitBatchOrder(templateId, body){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + `markeplaceBulkOrder/${templateId}/submitPayment`,
      method: "POST",
      data: body
    }).then(response=> resolve(response), error => reject(error.response));
  });
}

export function refundOrder(orderId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/refund/"+ orderId,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function cancelAdminOrder(orderId, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/cancel/"+ orderId,
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function deliveryAdminOrder(orderId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/delivered/"+ orderId,
      method: "POST"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getOrderPaymentIntent(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/paymentIntent",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getOrderSetupIntent(Territory) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/setupIntent",
      method: "POST",
      data: {Territory},
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function stripeVerifyCardOrder(body) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/processCard",
      method: "POST",
      data: body,
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setCreatedOrders(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/orders",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getPaymentIntent(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/paymentIntent",
      method: "POST",
      data
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getCallCenterMenu(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/menu/" + id,
      method: "GET",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function setCaptureRemainder(id, data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/capture/" + id,
      method: "POST",
      data,
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getAdminOrderPengingCount() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "callCenter/count",
      method: "GET",
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchOrderMenu(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/" + id,
      method: "GET",
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot],
      skipRetry: true
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function updateServiceNotes(id, serviceNotes) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "orders/updateservicenotes",
      method: "POST",
      data: { Id: id, ServiceNotes: serviceNotes },
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchConsumerOrderMenu() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenuMerged",
      method: "GET",
      roles: [environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function consumerOrderAccept(orderId, value) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/accept/" + orderId,
      method: "POST",
      data: {
        ReadinessTime: value
      },
      roles: [environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function consumerOrderAcknowledge(orderId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/acknowledge_preorder/" + orderId,
      method: "POST",
      roles: [environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function consumerOrderReject(orderId, value) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/reject/" + orderId,
      method: "POST",
      data: {
        Reason: value
      },
      roles: [environment.USER_ROLES.depot]
    }).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error.response);
      }
    );
  });
}

export function consumerOrderDelaying(orderId, value) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/late/" + orderId,
      method: "POST",
      data: { ReadinessTime: value },
      roles: [environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function consumerOrderCooking(orderId, value) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/cooking/" + orderId,
      method: "POST",
      data: { ReadinessTime: value },
      roles: [environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function consumerOrderRepack(orderId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/repack/" + orderId,
      method: "POST",
      roles: [environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function consumerOrderReady(orderId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/ready/" + orderId,
      method: "POST",
      roles: [environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function consumerOrderPickUp(orderId) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/pickUp/" + orderId,
      method: "POST",
      roles: [environment.USER_ROLES.depot]
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchConsumerOrderReadinessTimes() {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "restaurant/readinessTimes",
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function fetchConsumerOrderReadinessTimesAccept(id) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/readinessTimes/" + id,
      method: "GET"
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function consumerOrderSearch(filter){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "consumerOrder/search",
      method: "POST",
      roles: [environment.USER_ROLES.depot],
      data: {
        Search: filter.search || null,
        Start: filter.search ? null : filter.start,
        End: filter.search ? null : filter.end
      }
    }).then(response => resolve(response), error => reject(error.response));
  });

}

export function getDeliverPriceByAddress(address){
  return new Promise((resolve, reject) => {
    resolve({ status: 200, data: 0 });
  });
}

export function getMergedBusinessMenu(){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenuMerged",
      method: "GET",
      roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager],
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getMergedBusinessMenuById(id){
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "businessMenuMerged/" + id,
      method: "GET",
      roles: [environment.USER_ROLES.depot, environment.USER_ROLES.deliveryManager, environment.USER_ROLES.supportOperator],
    }).then(response => resolve(response), error => reject(error.response));
  });
}

export function getReviewsReport(data) {
  return new Promise((resolve, reject) => {
    callServer({
      url: environment.backendAPI + "report/reviews",
      method: "POST",
      responseType: "blob",
      data,
      roles: [environment.USER_ROLES.deliveryManager, environment.USER_ROLES.depot]
    }).then(response=> resolve(response), error => reject(error.response));
  });
}
