import React from "react";
import {useTranslation} from "react-i18next";
import IconButton from "../IconButton";
import classnames from "classnames";
import {getCurrencyIcons} from "../../../utils/enums";

export const ProductCard = ({product, currency, onClick, showQuantity}) => {
    const {t} = useTranslation();

    const getSpicy = () => {
        let arr = [];
        for(let i = 0; i < product.spicy; i++){
            arr.push(
                <IconButton key={`${i}-spicy`} isIcon iconName={"spicy"}/>
            )
        }

        return arr;
    }
    const getVegan = () => {
        let count = 0;
        product.vegan && count++;
        product.vegetarian && count++;

        let arr = [];
        for(let i = 0; i < count; i++){
            arr.push(
                <IconButton key={`${i}-spicy`} isWhite isIcon iconName={"vegan"}/>
            )
        }
        return arr;
    }

    return (
        <div className={classnames("restaurant-product-card")} onClick={onClick}>
            <div className={classnames("image")}>
                <img src={product.images} alt={product.name}/>
                {/*<ImageCloudinary settings={product.image_settings || {}} image={product.images || ""}/>*/}
            </div>
            <div className={classnames("main-data")}>
                <div className={classnames("top")}>
                    <span className={classnames("title")}>{product.name}</span>
                    <span className={classnames("description")}>{product.description}</span>
                </div>

                <div className={classnames("bottom")}>
                    <div className={classnames("icons")}>
                        {!!product.spicy && (
                            <span className={classnames("spicy-block")}>
                                <span>{t("create-order.product.SPICY")}</span>
                                {getSpicy()}
                            </span>)
                        }
                        {product.gluten_free && (
                            <span className={classnames("noGluten-block")}>
                                <span>{t("create-order.product.GLUTEN_FREE")}</span>
                                <IconButton isWhite isIcon iconName={"noGluten"}/>
                            </span>)
                        }
                        {!!getVegan().length && (
                            <span className={classnames("vegan-block")}>
                                <span>{t("create-order.product.VEGAN")}</span>
                                {getVegan()}
                            </span>
                        )}
                    </div>
                    {!showQuantity && !!product.price && (<span className={classnames("price")}>
                        {!!product.price_before_discount && (<span className={classnames("price-through")}>{getCurrencyIcons(currency)}{product.price_before_discount}</span>)}&nbsp;&nbsp;
                        <span className={classnames({"price-blue": !product.price_before_discount, "price-red": !!product.price_before_discount})}>{getCurrencyIcons(currency)}{product.price}</span>
                    </span>)}

                    {showQuantity && !!product.full_price && (<span className={classnames("price")}>
                        <span className={classnames("price-blue")}>
                            <span className={"quantity"}>x{product.quantity}</span> {getCurrencyIcons(currency)}{product.full_price}
                        </span>
                    </span>)}
                </div>

            </div>
        </div>
    )
}