import React from "react";
import {useTranslation, withTranslation} from "react-i18next";
import { withRouter } from "react-router";
import classNames from "classnames";
import "./index.scss";

import { getDepotByBusinessId, getOrderMenuProductItems } from '../../../services/restaurant';
import {
    ORDERING_MENU_TYPE_SECTION_MODEL,
    RESHIPMENT_TYPE_VALUE,
    getCurrencyIcons,
    OFFER_DISCOUNT_TARGET,
    ORDER_DISCOUNT_TYPE,
    BASIC_PATH, SOURCE_TYPE,
} from "../../../utils/enums";
import { DATE_TIME_FORMAT_DAYTIME, getFormattedDate } from "../../../utils/convertTime";
import { defaultPromiseResolve } from "../../../utils/objects-util";

import IconButton from '../IconButton';
import AdminOrderInfoFooter from '../AdminOrderInfoFooter';
import { getConvertedCloudUrl } from '../../../utils/image-util';
import OrderMenuOption from '../OrderMenuOption';
import AdminOrderInfoPayments from "../AdminOrderInfoPayments";
import AdminOrderInfoStatus from "../AdminOrderInfoStatus";
import AdminOrderInfoRestaurant from "../AdminOrderInfoRestaurant/index.js";
import AdminOrderInfoClientAndDelivery from "../AdminOrderInfoClientAndDelivery/index.js";
import PendingOrdersInfoData from "../PendingOrdersInfoData/index.js";
import AdminOrderInfoGift from "../AdminOrderInfoGift/index.js";

const Product = ({t, product, order, currency}) => {
    return (
        <div className={classNames("dataItem product")}>
            {product.image && (
                <div className={classNames("item")}>
                    <img src={getConvertedCloudUrl(product.image, 85, true)} alt="img" />
                </div>
            )}
            <div className={classNames("item")}>
                {product?.name} ({product?.price} {getCurrencyIcons(currency)})
            </div>
            <div className={classNames("item", "count")}>
                x{product.quantity}
            </div>
            <div className={classNames("item", "options")}>
                <div className={classNames("options")}>
                    {product.options.map((option, optionIndex)=> (
                        <OrderMenuOption key={`option-${optionIndex}`} option={option} order={order}/>
                    ))} 
                </div>                                                                       
            </div>
            {product.ingredientsNames?.length > 0 && (<div className={classNames("item", "options")}>
                <div className={classNames("ingredients")}>
                    {product.ingredientsNames.map((ingredient, ingredientIndex)=> (
                        <div key={"ingredient-" + ingredientIndex} className={classNames("ingredientContainer")}>      
                            <IconButton isIcon={true} iconName={"iconDelet"} onClick={defaultPromiseResolve} />
                            <span className={classNames("ingredientText")}>{ingredient || t("pending-orders.PENDING_ORDER_EMPTY_INGREDIENT_LABEL")}&nbsp;</span>
                        </div>
                    ))}
                </div>                              
            </div>)}
            <div className={classNames("item", "currency")}>
                {`${product.total} ${getCurrencyIcons(currency)}`}
            </div>
        </div>
    )
}

const DiscountItem = ({discount, currency}) => {
    const {t} = useTranslation();
    const getName = () => {
        if(discount.discount_type === ORDER_DISCOUNT_TYPE.bonus) {
            return t("pending-orders.PENDING_ORDER_INFO_SUMMARY_BONUS");
        }
        if(discount.offer_type === 2) {
            return t("pending-orders.PENDING_ORDER_INFO_SUMMARY_COUPON");
        }
        return t("pending-orders.PENDING_ORDER_INFO_SUMMARY_DISCOUNT");
    }
    return (
        <tr key={discount.offer_id}>
            <td className={classNames("title")}>
                {getName()}
            </td>
            <td className={classNames("summary")}>
                <div>
                    {`${(discount.discount && `-`) || ""}${discount.discount} ${getCurrencyIcons(currency)}`}
                </div>
                <div>{discount.offer_name}</div>
            </td>
        </tr>
    )
}

const AdminOrderInfo = ({
    t, history,
    order, isHidePagination, territories,
    onClickPrevious, onClickNext, setEditOrder, onRepeatOrder, onReshipment, onRefund, openRefundsById, onCancelOrder, onSearchId, onCapture
}) => {
    const handleOnDepotClick = () => {
       return new Promise(resolve=> {
            resolve();
            const depot = getDepotByBusinessId(order.business_id);
            history.push({
                pathname: ORDERING_MENU_TYPE_SECTION_MODEL.orderingBusinessMenu,
                search: `?id=${depot.id}&businessId=${(depot.business_id || depot.businessId)}`,
                state: { 
                    pathFrom: BASIC_PATH.adminOrders
                }
            });
       });
    }

    const handleOnSearchId = () => {
        return new Promise(resolve => {
            onSearchId();
            resolve();
        })
    }

    return (
        <div className={classNames("adminOrderInfo")}>
            <div className={classNames("adminOrderInfoContainer")}>

                <AdminOrderInfoStatus territories={territories} order={order}/>

                {order.gift_option && (
                    <AdminOrderInfoGift giftOption={order.gift_option}/>
                )}

                <AdminOrderInfoPayments
                    paymentBalance={order.payment_balance}
                    payments={order.payments}
                    currency={order.currency}
                    onRefund={onRefund}
                    refunds={order.refunds}
                    openRefundsById={openRefundsById}
                    status={order.status}
                    onCapture={onCapture}
                    source={order.order_source}
                    disableCapture={![SOURCE_TYPE.app, SOURCE_TYPE.admin].includes(order.order_source)}
                />

                <AdminOrderInfoRestaurant businessInfo={order.businessInfo || {}} handleOnDepotClick={handleOnDepotClick}/>

                <AdminOrderInfoClientAndDelivery order={order} handleOnSearchId={handleOnSearchId}/>

                {order.reshipments && order.reshipments.length > 0 && (
                    <div className={classNames("adminOrderInfoItem", "noMarginTop", "active")}>
                        <div className={classNames("title")}>{t("admin-orders.RESHIPMENT_INFO_LABEL")}</div>
                        <div className={classNames("data", "items")}>
                            {(order.reshipments || []).map((reshipment, reshipmentIndex)=> {
                                const reshipmentProducts = getOrderMenuProductItems(reshipment.products, order.businessInfo);
                                return (
                                    <>
                                    <div key={`reshipment-${reshipmentIndex}`} className={classNames("data", "items")}>
                                        <div className={classNames("dataContainer")}>
                                            <div className={classNames("dataItem")}>
                                                <div className={classNames("title")}>{t("admin-orders.RESHIPMENT_INFO_DATE_LABEL")}</div>
                                                <div>{getFormattedDate(reshipment.date, DATE_TIME_FORMAT_DAYTIME)}</div>
                                            </div>
                                            <div className={classNames("dataItem")}>
                                                <div className={classNames("title")}>{t("admin-orders.RESHIPMENT_INFO_NOTES_LABEL")}</div>
                                                <div>{reshipment.notes || "-"}</div>
                                            </div>
                                            <div className={classNames("dataItem")}>
                                                <div className={classNames("title")}>{t("admin-orders.RESHIPMENT_TYPE_REASON")}</div>
                                                <div>{t(RESHIPMENT_TYPE_VALUE[reshipment.reshipment_type])}</div>
                                            </div>
                                        </div>
                                        <div className={classNames("dataContainer", "full", "reshipmentProducts")}>
                                            <div className={classNames("title")}>{t("admin-orders.RESHIPMENT_INFO_PRODUCTS_LABEL")}</div>
                                            {reshipmentProducts.map((product, productIndex)=> (
                                                <Product key={`dataContainerItem-${productIndex}`} t={t} product={product} currency={order.currency} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classNames("title", "total", "reshipmentProductTotal")}>
                                        <div>{t("admin-orders.ORDER_SUMMARY_TOTAL_LABEL")}</div>
                                        <div>{`${reshipment.full_price} ${getCurrencyIcons(order.currency)}`}</div>
                                    </div>
                                    </>
                                );
                            })}

                        </div>
                    </div>
                )}

                <div className={classNames("adminOrderInfoItem", "noMarginTop", "orderInfoProducts")}>
                    <div className={classNames("title")}>{t("admin-orders.ORDER_DETAILS_INFO_LABEL")}</div>
                    <div className={classNames("data", "full")}>
                        <PendingOrdersInfoData order={order} item={order}/>
                    </div>
                </div>

                <div className={classNames("adminOrderInfoItem", "summary")}>
                    {!!order.cutlery && (
                        <div className={"cutlery"}>
                            <div className={classNames("title")}>
                                {t("pending-orders.PENDING_ORDER_INFO_CUTLERY")}
                            </div>
                            <div className={classNames("summary")}>
                                {order.cutlery}
                            </div>
                        </div>
                    )}
                    <div className={classNames("title")}>{t("admin-orders.ORDER_SUMMARY_LABEL")}</div>
                    <div className={classNames("data")}>
                        <table>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td className={classNames("title")}>{t("admin-orders.ORDER_SUMMARY_AMOUNT_LABEL")}</td>
                                    <td className={classNames("summary")}>
                                        {order.full_price}&nbsp;{getCurrencyIcons(order.currency)}
                                    </td>
                                </tr>
                                {!!order.discounts && order.discounts
                                    .filter(d => d.discount_target === OFFER_DISCOUNT_TARGET.products && d.discount_type === ORDER_DISCOUNT_TYPE.common)
                                    .map((discount) => <DiscountItem key={discount.id} discount={discount} currency={order.currency} />)
                                }
                                {!!order.topup_to_minimum && (
                                    <tr>
                                        <td className={classNames("title")}>{t("admin-orders.ORDER_SUMMARY_TOPUP_TO_MINIMUM")}</td>
                                        <td className={classNames("summary")}>{`${order.topup_to_minimum} ${getCurrencyIcons(order.currency)}`}</td>
                                    </tr>
                                )}
								 {!!order.deliveryPrice && (
                                    <tr>
                                        <td className={classNames("title")}>{t("admin-orders.ORDER_SUMMARY_DELIVERY_FEE_LABEL")}</td>
                                        <td className={classNames("summary")}>{`
                                            ${order.deliveryPrice && Number(order.deliveryPrice.toFixed(2))} 
                                            ${getCurrencyIcons(order.currency)}
                                        `}</td>
                                    </tr>
                                )}
                                {!!order.service_fee && (
                                    <tr>
                                        <td className={classNames("title")}>{t("admin-orders.ORDER_SUMMARY_SERVICE_FEE_LABEL")}</td>
                                        <td className={classNames("summary")}>{`${order.service_fee} ${getCurrencyIcons(order.currency)}`}</td>
                                    </tr>
                                )}
                                {!!order.driver_tip && (
                                    <tr>
                                        <td className={classNames("title")}>{t("admin-orders.ORDER_SUMMARY_DRIVER_TIP_LABEL")}</td>
                                        <td className={classNames("summary")}>{`${order.driver_tip} ${getCurrencyIcons(order.currency)}`}</td>
                                    </tr>
                                )}
                                {!!order.discounts && order.discounts
                                    .filter(d => d.discount_target === OFFER_DISCOUNT_TARGET.delivery)
                                    .map((discount) => <DiscountItem key={discount.id} discount={discount} currency={order.currency} />)
                                }
                                {!!order.discounts && order.discounts
                                    .filter(d => d.discount_target === OFFER_DISCOUNT_TARGET.products && d.discount_type === ORDER_DISCOUNT_TYPE.bonus)
                                    .map((discount) => <DiscountItem key={discount.id} discount={discount} currency={order.currency} />)
                                }
								{!!order.captured_bonus && (
                                    <tr>
                                        <td className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_PAYMENTS_CARD_CAPTURED_BONUS")}</td>
                                        <td className={classNames("summary")}>{`${order.captured_bonus} ${getCurrencyIcons(order.currency)}`}</td>
                                    </tr>
                                )}
                                {!!order.weight_adjustment_price_buffer && (
                                    <tr>
                                        <td className={classNames("title adjusmentWeight")}>
                                            {t("uilib.summary.WEIGHT_ADJUSTMENT")}
                                            <IconButton isIcon iconName={'question'} tooltipText={t("uilib.summary.WEIGHT_ADJUSTMENT_TOOLTIP")}/>
                                        </td>
                                        <td className={classNames("summary")}>{`${order.weight_adjustment_price_buffer} ${getCurrencyIcons(order.currency)}`}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title", "total")}>
                                <div>{t("admin-orders.ORDER_SUMMARY_TOTAL_LABEL")}</div>
                                <div>{`${order.price} ${getCurrencyIcons(order.currency)}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AdminOrderInfoFooter order={order}
                isHidePagination={isHidePagination}
                onClickPrevious={onClickPrevious}
                onClickNext={onClickNext}
                onReshipment={onReshipment}
                onRefund={onRefund}
                onCancelOrder={onCancelOrder}
                setEditOrder={setEditOrder}
                onRepeatOrder={onRepeatOrder}
            />
        </div>
    );
}

export default withRouter(withTranslation()(AdminOrderInfo));
