import SectionModel from "../../models/sectionModel.js";
import {
    FLEET_SETTINGS,
    FLEET_SETTINGS_GET,
    FLEET_SETTINGS_TELEMETRY_BLINK,
    FLEET_SETTINGS_TELEMETRY_GET,
    FLEET_SETTINGS_UNMOUNT,
} from "../../store/reducers/restaurant/restaurant-actions.js";
import {
    CONTROL_TYPE,
    MARKETPLACE_SETTINGS_MODEL,
    MODEL_CONTROL_TYPE,
    MODEL_TYPE,
    TYPE_ACTION,
    TYPE_SECTION_MODEL
} from "../../utils/enums.js";
import FilterOptionsModel from "../../models/filterOptionsModel.js";
import {createControlModel} from "../controlModelFactory.js";
import {getFleetProducerType, getFleetVehicleType} from "../restaurant.js";
import {createBreadcrumbModel} from "../createBreadcrumbModel.js";
import {FleetProducerType} from "../../app/components/FleetProducerType/FleetProducerType.js";
import {FleetVehicleType} from "../../app/components/FleetVehicleType/FleetVehicleType.js";
import {getInitModel} from "../initModels.js";
import i18n from "../../i18n.js";
import {getFleetFilter} from "../filter.js";
import {getHistoryLocationParams} from "../../utils/routesHelper.js";

export function createFleetSectionModel() {
    return new SectionModel({
        filterOptions: createFleetSectionModelFilter(),
        columns: [
            {
                title: "fleet.TABLE_PRODUCER",
                accessor: "producer",
                width: '150px',
                fixedWidth: '151px',
                component: fleet => FleetProducerType(fleet?.producer),
            },
            /*{
                title: "fleet.TABLE_SERIAL_NUMBER",
                accessor: "serialNumber",
                width: '130px',
                fixedWidth: '130px',
            },*/
            {
                title: "fleet.TABLE_PLATE_NUMBER",
                accessor: "plateNumber",
                width: '150px',
                fixedWidth: '151px',
            },
            {
                title: "fleet.TABLE_CAPACITY",
                accessor: "capacity",
                width: '150px',
                fixedWidth: '151px',
            },
            {
                title: "fleet.TABLE_TYPE",
                accessor: "vehicleType",
                width: '150px',
                fixedWidth: '151px',
                component: fleet => FleetVehicleType(fleet?.vehicleType),
            },
            {
                title: "fleet.TABLE_DESCRIPTION",
                accessor: "description",
                width: '301px',
                fixedWidth: '301px',
            },
            {
                title: 'fleet.ACTIONS',
                textAlign: 'center',
                width: '48px',
                headerWidth: '98px',
                fixedWidth: '48px',
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.fleetTelemetry,
                url: item=> MARKETPLACE_SETTINGS_MODEL.giftCardCategoryProductsEdit + "?id=" + item.id + "plateNumber=" + item.serialNumber,
                iconName: 'telemetry',
                isVisible: (item) => {
                    return item.producer === 1
                },
                tooltipText: i18n.t('fleet.TELEMETRY'),
            },
            {
                title: '',
                width: '48px',
                fixedWidth: '48px',
                hideInHeader: true,
                type: CONTROL_TYPE.link,
                link: TYPE_SECTION_MODEL.fleetEdit,
                iconName: 'editItem',
                tooltipText: i18n.t('basic.EDIT_BUTTON'),
            },
        ],
        actionName: FLEET_SETTINGS,
        actionLoad: FLEET_SETTINGS_GET,
        typeModel: TYPE_SECTION_MODEL.fleet,
        label: "fleet.TITLE_FLEET",
        key: 'vehicleId',
        breadcrumbs: [
            createBreadcrumbModel({label: "header.menu.SETTINGS_LABEL"}),
            createBreadcrumbModel({label: "settings.OPERATIONS_MANAGEMENT_LABEL"}),
        ],
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.add,
                url: TYPE_SECTION_MODEL.fleetNew
            }),
            /*createControlModel({
                type: MODEL_CONTROL_TYPE.filter
            }),*/
        ],
        mappingLambda: state => {
            const data = state.restaurant?.data?.fleet;

            return data ? data : [];
        }

    });
}

export function createFleetTelemetrySectionModel({...args}) {
    return new SectionModel({
        filterOptions: createFleetSectionModelFilter(),
        columns: [
            {
                id: 'groupVehicleTView',
                columns: [
                    {
                        title: 'fleet.TABLE_TYPE',
                        accessor: 'vehicleType',
                        isEditable: false,
                        iconName: (value) => {
                            const vehicles = getFleetVehicleType();
                            const findVehicle = vehicles.find((e) => e.key === value);

                            return findVehicle ? findVehicle?.icon : ''
                        },
                        type: CONTROL_TYPE.icon,
                    },
                    {
                        title: 'fleet.TABLE_PLATE_NUMBER',
                        accessor: 'plateNumber',
                        isEditable: false,
                        width: 'auto',
                        type: CONTROL_TYPE.text,
                    },

                ],
                isEditable: true,
                isInline: true,
                isFillContent: true,
                alignItems: 'flex-end',
                gap: '16px',
                type: CONTROL_TYPE.group,
            },
            {
                id: 'groupGeoTView',
                columns: [
                    {
                        title: 'fleet.SPEED',
                        accessor: 'speed',
                        isEditable: false,
                        type: CONTROL_TYPE.text,
                    },
                    {
                        title: 'fleet.BATTERY_LEVEL',
                        accessor: 'batteryLevel',
                        isEditable: false,
                        type: CONTROL_TYPE.text,
                    },

                ],
                isEditable: true,
                isInline: true,
                isFillContent: true,
                width: 500,
                alignItems: 'flex-end',
                gap: '16px',
                type: CONTROL_TYPE.group,
            },

            {
                id: 'groupBatteryView',
                columns: [
                    {
                        title: 'fleet.READY',
                        accessor: 'readyOn',
                        isEditable: false,
                        type: CONTROL_TYPE.text,
                    },
                    {
                        title: 'fleet.FALL_STATE',
                        accessor: 'fallState',
                        isEditable: false,
                        isNoClickableIcon: () => true,
                        isShowIconLabel: (item) => item.fallState !== 'false',
                        labelIconName: 'warn',
                        type: CONTROL_TYPE.text,
                    },
                    {
                        title: 'fleet.IGNITION',
                        accessor: 'ignitionOn',
                        isEditable: false,
                        type: CONTROL_TYPE.text,
                    },

                ],
                isEditable: true,
                isInline: true,
                isFillContent: true,
                alignItems: 'flex-end',
                width: 500,
                gap: '16px',
                type: CONTROL_TYPE.group,
            },
            {
                id: 'groupTotalView',
                columns: [
                    {
                        title: 'fleet.TOTAL_MILEAGE',
                        accessor: 'totalMileage',
                        isEditable: false,
                        type: CONTROL_TYPE.text,
                    },
                    {
                        title: 'fleet.VIBRATION_STATE',
                        accessor: 'vibrationState',
                        isNoClickableIcon: () => true,
                        isShowIconLabel: (item) => item.vibrationState !== 'false',
                        labelIconName: 'warn',
                        isEditable: false,
                        type: CONTROL_TYPE.text,
                    },

                ],
                isEditable: true,
                isInline: true,
                isFillContent: true,
                width: 500,
                alignItems: 'flex-end',
                gap: '16px',
                type: CONTROL_TYPE.group,
            },
            {
                id: 'groupTotalView',
                columns: [
                    {
                        title: 'fleet.LOCATION',
                        accessor: 'blink',
                        isEditable: false,
                        iconName: 'blink',
                        showPushMessageIcon: true,
                        type: CONTROL_TYPE.button,
                        label: "fleet.LOCATION",
                        isDisableConfirmDialog: true,
                        clickAction: FLEET_SETTINGS_TELEMETRY_BLINK,
                    },
                ],
                isEditable: true,
                isInline: true,
                isFillContent: true,
                width: 500,
                alignItems: 'flex-end',
                gap: '16px',
                type: CONTROL_TYPE.group,
            },
            {
                title: '',
                accessor: 'location',
                isEditable: false,
                isReadOnly: true,
                isFillContent: true,
                isHideSearch: true,
                type: CONTROL_TYPE.mapByLocation,
            }

        ],
        actionName: FLEET_SETTINGS,
        actionLoad: FLEET_SETTINGS_TELEMETRY_GET,
        typeModel: TYPE_SECTION_MODEL.fleetTelemetry,
        label: "",
        typeAction: args.typeAction,
        isHideControls: true,
        actionOnUnmount: FLEET_SETTINGS_UNMOUNT,
        parentUrl: TYPE_SECTION_MODEL.fleet,
        initNewItemModel: item =>
            getInitModel(MODEL_TYPE.fleetTelemetry, item),
        breadcrumbs: [
            createBreadcrumbModel({label: "fleet.SETTINGS"}),
            createBreadcrumbModel({label: "fleet.TITLE_FLEET"}),
            createBreadcrumbModel({label: "fleet.TELEMETRY"}),
            createBreadcrumbModel({label: "settings.OPERATIONS_MANAGEMENT_LABEL"}),
        ],
        getLabel: (item) => {
            return item?.plateNumber;
        },
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: () => true,
            }),
        ],
        mappingLambda: state => {
            const { id } = getHistoryLocationParams(window.location.search);
            let data = state.restaurant?.data?.fleetTelemetry;
            let dataFleet = state.restaurant?.data.fleet;

            if(data && data.lat && data.lng) {
                data = {...data, location: {latitude: data.lat, longitude: data.lng}}
            }

            if(data && dataFleet && dataFleet.length > 0) {
                const currentFleet = dataFleet.find((e) => e.vehicleId === id);
                if(currentFleet) {
                    data = {...data, vehicleData: currentFleet}
                }
            }


            return data ? data : {};
        }


    });
}

export function createFleetEditSectionModel({...args}) {
    return new SectionModel({
        filterOptions: new FilterOptionsModel(),
        columns: [
            {
                title: 'fleet.TABLE_PRODUCER',
                accessor: 'producer',
                isEditable: true,
                connect: state => {
                    const result = getFleetProducerType();
                    return {
                        defaultValue: result && result.length > 0 ? result[0] : {key: '', value: '-'},
                        values: result,
                    };
                },
                type: CONTROL_TYPE.select,
            },
            {
                title: 'fleet.TABLE_TYPE',
                accessor: 'vehicleType',
                isEditable: true,
                connect: () => ({
                    values: getFleetVehicleType(),
                }),
                type: CONTROL_TYPE.iconsSelector,
            },
            {
                title: 'fleet.TABLE_PLATE_NUMBER',
                accessor: 'plateNumber',
                isEditable: true,
                min: '-100',
                type: CONTROL_TYPE.text,
            },
            {
                title: 'fleet.TABLE_CAPACITY',
                accessor: 'capacity',
                isEditable: true,
                min: () => '-100',
                type: CONTROL_TYPE.number,
            },
            {
                title: 'fleet.TABLE_SERIAL_NUMBER',
                accessor: 'serialNumber',
                isEditable: true,
                min: '-100',
                type: CONTROL_TYPE.text,
            },

            {
                title: "fleet.TABLE_DESCRIPTION",
                isEditable: true,
                accessor: "description",
                type: CONTROL_TYPE.text,
            }
        ],
        typeAction: args.typeAction,
        key: 'vehicleId',
        actionName: FLEET_SETTINGS,
        parentUrl: TYPE_SECTION_MODEL.fleet,
        label: "",
        initNewItemModel: item =>
            getInitModel(MODEL_TYPE.fleet, item),
        breadcrumbs: [
            createBreadcrumbModel({label: "header.menu.SETTINGS_LABEL"}),
            createBreadcrumbModel({label: "fleet.TITLE_FLEET"}),
            createBreadcrumbModel({label: "settings.OPERATIONS_MANAGEMENT_LABEL"}),
        ],
        getLabel: (item) => {
            return item?.plateNumber;
        },
        typeModel:
            args.typeAction === TYPE_ACTION.edit
                ? TYPE_SECTION_MODEL.fleetEdit
                : TYPE_SECTION_MODEL.fleetNew,
        controls: [
            createControlModel({
                type: MODEL_CONTROL_TYPE.back,
                isShow: () => true,
            }),
        ],
        mappingLambda: state => {
            const data = state.restaurant?.data?.fleet;

            return data ? data : [];
        }
    });
}

function createFleetSectionModelFilter() {
    let filterOptionsModel = new FilterOptionsModel();

    filterOptionsModel.addOptions("search", CONTROL_TYPE.text, null, function (
        region
    ) {
        return getFleetFilter(region, this.selected);
    });

    return filterOptionsModel;

}
