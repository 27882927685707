import React from "react";
import classNames from "classnames";
import "./index.scss";

import Color from "../../../services/color";
import { getLastCharacters } from "../../../utils/objects-util";

const MapMarkerOrderNumberIcon = ({ value }) => {
    const { orderId, icon } = value || {};
    const color = (icon || {}).color
        ? Color.instance.getCodeByColorKey((icon || {}).color)
        : "#D9D9D9";
    const fontColor = Color.instance.getFontColorByBackground(color);
    return (
        <div className={classNames("mapMarkerOrderNumberIcon")} style={{background: color}}>
            <span className={classNames("mapMarkerOrderNumberIconTitle")} style={{color: fontColor}}>
                {getLastCharacters(orderId, 5)}
            </span>
            <span className={classNames("mapMarkerOrderNumberIconArrow")} style={{background: color}}></span>
        </div>
    );
}

export default MapMarkerOrderNumberIcon;