import classNames from "classnames";
import {Button} from "../Waiter/Button.jsx";
import DatePicker from "../DatePicker/DatePicker.jsx";
import {DATE_FORMAT} from "../../../utils/convertTime.js";
import Dropdown from "../Dropdown/index.js";
import {getGender, getGenders, TYPE_PATTERN} from "../../../utils/enums.js";
import React, {useState} from "react";
import {createAction} from "../../../utils/sagaHelper.js";
import {
    CHECK_USER_BY_PHONE,
    CREATE_CONSUMER_USER
} from "../../../store/reducers/editCustomerOrder/editCustomerOrder-action.js";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import './index.scss';
import {toast} from "react-toastify";
import {getPattern} from "../../../utils/objects-util.js";

const initUserData = {
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    birth: '',
    language: '',
    termsAndConditions: false,
    marketingContent: false,
    id: '',
}

const AddNewCustomerForm = ({onCreateUser}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [openUserForm, setOpenUserForm] = useState(false);
    const [userData, setUserData] = useState({...initUserData});

    const handleOnEdit = (key, value) => {
        setUserData({...userData, [key]: value});
    }

    const checkPhone = async () => {
        try {
            const users = await dispatch(createAction(CHECK_USER_BY_PHONE, userData.phone.replace(/[\s()-]/g, '')));
            if(!users.length) {
                setOpenUserForm(true);
                return;
            }
            toast.info(t("edit-customer-order.aboutClient.USER_EXIST"));
        } catch (e) {
            toast.error("Error");
        }
    }

    const createUser = async () => {
        try {
            const user = await dispatch(createAction(CREATE_CONSUMER_USER, userData));
            onCreateUser({
                phone: user.phone,
                id: user.consumerId,
                lastName: user.lastName,
                firstName: user.firstName,
                email: user.email,
                gender: user.gender,
                birth: user.birthdate,
                serviceNotes: user.serviceNotes,
            });
            setOpenUserForm(false);
            setUserData({...initUserData});
        } catch (e) {
            toast.error("Error");
        }
    }

    const onClear = () => {
        setUserData({...initUserData, phone: userData.phone});
        setOpenUserForm(false);
    }

    const isEmailWarning = () => {
        if(!userData.email) {
            return false;
        }
        return !(new RegExp(getPattern(TYPE_PATTERN.email)).test(userData.email));
    }

    const isDisableCreate = () => {
        return !userData.lastName.trim().length || !userData.firstName.trim().length || !userData.termsAndConditions || isEmailWarning();
    }

    return (
        <div className={'addNewCustomerForm'}>
            <div className={'formControls'}>
                <div className={classNames('formControl')}>
                    <div className={classNames('formControlItem')}>
                        <div className={classNames('title')}>{t('edit-customer-order.aboutClient.PHONE')}</div>
                        <form autoComplete='off' className={classNames('inputItem')}>
                            <input
                                disabled={openUserForm}
                                type='text'
                                value={userData.phone}
                                onChange={e => handleOnEdit('phone', e.target.value)}
                            />
                        </form>
                    </div>

                    <div className={classNames('formControlItem')}>
                        {!openUserForm && (
                            <Button spanText={t('edit-customer-order.aboutClient.CHECK')} useClass={'btn primary'} handleOnClick={checkPhone} />
                        )}
                    </div>
                </div>

                {openUserForm && (
                    <>
                        <div className={classNames('formControl')}>
                            <div className={classNames('formControlItem')}>
                                <div className={classNames('title')}>{t('edit-customer-order.aboutClient.FIRST_NAME')}</div>
                                <form autoComplete='off' className={classNames('inputItem')}>
                                    <input
                                        type='text'
                                        value={userData.firstName}
                                        onChange={e => handleOnEdit('firstName', e.target.value)}
                                    />
                                </form>
                            </div>

                            <div className={classNames('formControlItem')}>
                                <div className={classNames('title')}>{t('edit-customer-order.aboutClient.LAST_NAME')}</div>
                                <form autoComplete='off' className={classNames('inputItem')}>
                                    <input
                                        type='text'
                                        value={userData.lastName}
                                        onChange={e => handleOnEdit('lastName', e.target.value)}
                                    />
                                </form>
                            </div>
                        </div>

                        <div className={classNames('formControl')}>
                            <div className={classNames('formControlItem')}>
                                <div className={classNames('title')}>{t('edit-customer-order.aboutClient.EMAIL')}</div>
                                <form autoComplete='off' className={classNames('inputItem')}>
                                    <input
                                        type='text'
                                        value={userData.email}
                                        onChange={e => handleOnEdit('email', e.target.value)}
                                    />
                                </form>
                            </div>

                            <div className={classNames('formControlItem')}>
                                <div className={classNames('title')}>{t('edit-customer-order.aboutClient.DATE_OF_BIRTH')}</div>
                                <form autoComplete='off' className={classNames('inputItem')}>
                                    <DatePicker
                                        format={DATE_FORMAT}
                                        value={userData.birth || null}
                                        onSave={date => handleOnEdit('birth', date)}
                                    />
                                </form>
                            </div>
                        </div>

                        <div className={classNames('formControl')}>
                            <div className={classNames('formControlItem')}>
                                <div className={classNames('title')}>{t('edit-customer-order.aboutClient.GENDER')}</div>
                                <form autoComplete='off' className={classNames('inputItem')}>
                                    <Dropdown
                                        value={userData.gender || userData.gender === 0 ? getGender(userData.gender) : ''}
                                        values={getGenders()}
                                        onSelect={v => handleOnEdit('gender', v.key)}
                                    />
                                </form>
                            </div>

                            <div className={classNames('formControlItem')}>

                            </div>
                        </div>

                        <div className={classNames('formControl')}>
                            <div className={classNames('formControlItem')}>
                                <div className={'checkbox'} onClick={() => handleOnEdit('termsAndConditions', !userData.termsAndConditions)}>
                                    <span>{t('edit-customer-order.aboutClient.TERMS')}</span>
                                    <div className={'checkboxIcon'}>
                                        {userData.termsAndConditions && (<span>&#10003;</span>)}
                                    </div>
                                </div>
                            </div>

                            <div className={classNames('formControlItem')}>
                                <div className={'checkbox'} onClick={() => handleOnEdit('marketingContent', !userData.marketingContent)}>
                                    <span>{t('edit-customer-order.aboutClient.MARKETING')}</span>
                                    <div className={'checkboxIcon'}>
                                        {userData.marketingContent && (<span>&#10003;</span>)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={classNames('formControl')}>
                            <div className={classNames('formControlItem')}>
                                <Button
                                    spanText={t('edit-customer-order.aboutClient.CLEAR_DATA')}
                                    useClass={'btn secondary'}
                                    handleOnClick={onClear}
                                />
                                <Button
                                    spanText={t('edit-customer-order.aboutClient.CREATE_USER')}
                                    useClass={'btn primary'}
                                    handleOnClick={createUser}
                                    disabled={isDisableCreate()}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default AddNewCustomerForm;