import React from "react";
import classnames from "classnames";
import "./style.scss";
import IconButton from "../../IconButton";

const Warning = ({message}) => {
    return (
        <div className={classnames("warning")}>
            <IconButton isIcon iconName={"warningCircle"}/>
            <span className={classnames("warning-message")}>{message}</span>
        </div>
    )
}

export default Warning;
