import React, {useCallback, useEffect, useState} from 'react';
import classnames from 'classnames';
import './style.scss';
import Modal from 'react-modal';
import {useTranslation} from "react-i18next";
import {DebounceInput} from "react-debounce-input";
import {isRTL} from "../../../../services/userModel";
import {getProductPrice} from "../../../../api/order-requests";
import {getCurrencyIcons} from "../../../../utils/enums";
import CounterButton from "../CounterButton/CounterButton";
import IconButton from "../../IconButton";
import Warning from "../Warning/Warning";
import ProductAddDish from "../ProductAddDish/ProductAddDish";

const HALF_OPTION = {
    all: 0,
    left: 1,
    right: 2
}

const getMergedProductData = ({allProducts, productEdit, ingredients}) => {
    let forSetHalfProduct = [];
    const withHalf = allProducts.filter(el => el.with_half_option);
    const withoutHalf = allProducts.filter(el => !el.with_half_option);
    const forSetProducts = withoutHalf.map(el => {
        const find = productEdit.options && productEdit.options.find(edit => edit.id === el.id);
        if(find) {
            find.suboptions = el.suboptions.map(sub => {
                const findSub = find.suboptions.find(subel => subel.id === sub.id);
                return findSub ? {...sub, ...findSub} : sub;
            })
        }
        return find ? {...el, ...find} : el;
    });

    const isWithHalf = productEdit.options
        ? productEdit.options
            .filter(el => el.with_half_option)
            .flatMap(el => el.suboptions)
            .some(el => el.half_option >= 1)
        : false;


    if(isWithHalf) {
        const suboptions = productEdit.options
            .filter(el => el.with_half_option)
            .flatMap(el => el.suboptions);

        const leftSides = suboptions.filter(el => el.half_option === HALF_OPTION.left);
        const rightSides = suboptions.filter(el => el.half_option === HALF_OPTION.right);

        const fullSides = withHalf.flatMap(el => {
            return [
                {...el, halfOption: HALF_OPTION.left, suboptions: el.suboptions.map(sub => {
                        const find = leftSides.find(ls => ls.id === sub.id);
                        return find ? find : sub
                    })},
                {...el, halfOption: HALF_OPTION.right, suboptions: el.suboptions.map(sub => {
                        const find = rightSides.find(ls => ls.id === sub.id);
                        return find ? find : sub
                    })}
            ]
        });
        forSetHalfProduct = fullSides;

    } else {
        const fullProducts = withHalf.map(el => {
            const find = productEdit.options && productEdit.options.find(edit => edit.id === el.id);
            if(find) {
                find.suboptions = el.suboptions.map(sub => {
                    const findSub = find.suboptions.find(subel => subel.id === sub.id);
                    return findSub ? {...sub, ...findSub} : sub;
                })
            }
            return find ? {...el, ...find} : el;
        });
        forSetHalfProduct = fullProducts;
    }

    const subopt = ingredients.suboptions
        ? ingredients.suboptions.map(el => productEdit.ingredients.includes(el.name) ? {...el, quantity: 0} : {...el, quantity: 1})
        : [];

    return {
        isWithHalf: isWithHalf,
        forSetProducts: forSetProducts,
        ingredients: {...ingredients, suboptions: subopt},
        forSetHalfProduct: forSetHalfProduct,
    }
}

const checkRespectTo = (product, data) => {
    const enabledAllSuboptions = product.suboptions.filter(el => el.enabled);
    if(product.enabled && enabledAllSuboptions.length) {
        const subopt = data
            .flatMap(el => el.suboptions)
            .filter(el => el.enabled);
        const find = subopt.find(el => el.id === product.respect_to);
        return find ? !!find.quantity : true;
    }

    return false;
}

const RestaurantAddDish = ({giftMode, isOpen, productEdit, handleOnAddToCart, onClose, editMode, product, currency, business_id, emitProduct}) => {
    const {t} = useTranslation();
    const [init, setInit] = useState(true);
    const [mergedInit, setMergedInit] = useState(true);

    const allProducts = (product && product.extras.flatMap(extra => extra.options)) || [];
    const [products, setProducts] = useState([]);
    const [halfProducts, setHalfProducts] = useState([]);
    const [isHalf, setIsHalf] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [shuffle, setShuffle] = useState(false);
    const [disableNext, setDisableNext] = useState(false);

    const createProductIngredients = data => {
        if(!!data.length){
            const subopt = data.map((el, i) => ({enabled: true, max: 1, name: el.name, quantity: el.quantity === 0 ? 0 : 1, id: i})) || [];
            return {
                id: "Ingredients",
                name: t("create-order.product.INGREDIENTS"),
                min: 0,
                max: data.length,
                suboptions: subopt,
                enabled: true
            }
        } else {
            return {};
        }
    }
    const [ingredients, setIngredients] = useState(createProductIngredients((product && product.ingredients) || []));
    const [nameOnBox, setNameOnBox] = useState(editMode && productEdit ? productEdit.name_on_box : "");
    const [quantity, setQuantity] = useState(productEdit && editMode ? productEdit.quantity : 1);
    const [comments, setComments] = useState(productEdit && editMode ? productEdit.comment : "");
    const [fullPrice, setFullPrice] = useState(editMode && productEdit ? productEdit.full_price : 0);

    const [error, setError] = useState(null);

    const productsMap = products
        .filter(el => checkRespectTo(el, [...halfProducts, ...products]));

    const halfProductsMap = halfProducts
        .filter(el => checkRespectTo(el, [...halfProducts, ...products]));


    const hasIngredients = !!Object.keys(ingredients).length;
    const countOfAllProducts =
        productsMap.length + halfProductsMap.length + (hasIngredients ? 1 : 0) + (!!halfProducts.length ? 1 : 0) + (product && product.allow_comments ? 1 : 0);
    const countOfAllSteps = countOfAllProducts + 1;

    useEffect(() => {
        if(editMode) {
            setCurrentIndex(countOfAllProducts);
        }
    }, [editMode, countOfAllProducts]);

    const [currentIndex, setCurrentIndex] = useState(0);

    const checkError = useCallback(() => {
        let newError = "";
        const products = [...productsMap, null, ...halfProductsMap];
        products.forEach((prod, index) => {
            if(index+1 + (hasIngredients ? 0 : -1) === currentIndex && prod) {
                const condition = checkValidProduct(prod);
                if(condition) {
                    newError = t("create-order.product.MINIMUM_OPTION", {minCount: prod.name?.toLowerCase()});
                }
            }
        });
        setError(newError);
    }, [currentIndex, halfProductsMap, productsMap, hasIngredients, t])

    useEffect(() => {
        if(currentIndex <= countOfAllProducts) {
            checkError();
        }
    }, [currentIndex, countOfAllProducts, checkError]);

    useEffect(() => {
        if(editMode) {
            const data = getMergedProductData({
                allProducts: allProducts,
                productEdit: productEdit,
                ingredients: ingredients
            });
            if(data.isWithHalf !== isHalf) {
                setIsHalf(data.isWithHalf);
            }
            setIngredients(data.ingredients);
            setProducts(data.forSetProducts);
            setHalfProducts(data.forSetHalfProduct);
            setTimeout(() => {
                setMergedInit(false);
                // getPrice();
            }, 0);
        } else {
            const productsData = allProducts.filter(el => !el.with_half_option);
            if(productsData.length) {
                setProducts(productsData);
            }
            const halfData = allProducts.filter(el => el.with_half_option);
            if(halfData.length) {
                setHalfProducts(halfData);
            }
        }

        // eslint-disable-next-line
    }, []);

    const changeQuantityOfIngredients = option => {
        const newproducts = products.map(el => (
            el.id === option.id
                ? option
                : el
        ))
        setProducts(newproducts);
    }
    const changeQuantityOfIngredientsHalf = option => {
        const newproducts = halfProducts.map(el => (
            el.id === option.id && el.halfOption === option.halfOption
                ? option
                : el
        ))
        setHalfProducts(newproducts);
    }
    const changeIngredients = data => {
        setIngredients(data);
    }
    const handleOnChangeMessage = value => {
        setComments(value);
    }
    const handleOnChangeNameOnBox = (value) => {
        setNameOnBox(value);
    }
    const handleOnAddProduct = () => {
        if(product.max && product.max === quantity) {
            return;
        }
        setQuantity(quantity +1);
    }
    const handleOnRemoveProduct = () => {
        if(editMode && quantity -1 >= 0) {
            setQuantity(quantity -1);
        } else if (!editMode && quantity -1 > 0) {
            setQuantity(quantity -1);
        }
    }
    const isValid = useCallback(() => {
        const isValidProducts = products
            .filter(el => checkRespectTo(el, [...products, ...halfProducts]))
            .every(prod => !checkValidProduct(prod));
        const isValidHalf = halfProducts
            .filter(el => checkRespectTo(el, [...products, ...halfProducts]))
            .every(prod => !checkValidProduct(prod));

        return isValidHalf && isValidProducts && quantity > 0;
    }, [quantity, products, halfProducts])

    const checkValidProduct = prod => {
        const allQuantity = prod.suboptions.reduce((acc, cv) => cv.quantity ? (acc = cv.quantity + acc) : acc, 0);

        const limitByMax = prod.limit_suboptions_by_max
            ? true
            : (prod.min === 1 && prod.max === 1);
        const condition = limitByMax
            ? prod.min !== 0 && !((prod.min <= allQuantity) && (allQuantity <= prod.max))
            : !(prod.min <= allQuantity) && prod.min !== 0;

        return condition;
    }

    const handleEmitProduct = useCallback((data, isValid) => {
        emitProduct && emitProduct(data, isValid);
    }, [emitProduct]);
    const getPrice = useCallback(() => {
        checkError([...halfProducts, ...products]);

        if(!!product) {
            setIsLoading(true);
            const data = {
                business_id: business_id,
                product: {
                    id: product.id,
                    quantity: quantity,
                    price: product.price,
                    full_price: product.full_price,
                    name_on_box: nameOnBox,
                    comment: comments,
                    ingredients: ingredients.suboptions ? ingredients.suboptions.filter(el => !el.quantity).map(el => el.name) : [],
                    options: [
                        ...products
                            .filter(el => checkRespectTo(el, [...products, ...halfProducts]))
                            .map(el => ({
                                id: el.id,
                                with_half_option: el.with_half_option,
                                name: el.name,
                                suboptions: el.suboptions
                                    .filter(sub => !!sub.quantity)
                                    .map(sub => ({
                                        id: sub.id,
                                        price: sub.price,
                                        quantity: sub.quantity,
                                        name: sub.name,
                                        half_option: HALF_OPTION.all,
                                    }))
                            })).filter(el => !!el.suboptions.length),
                        ...halfProducts
                            .filter(el => checkRespectTo(el, [...products, ...halfProducts]))
                            .map(el => ({
                                id: el.id,
                                with_half_option: el.with_half_option,
                                name: el.name,
                                suboptions: el.suboptions
                                    .filter(sub => !!sub.quantity)
                                    .map(sub => ({
                                        id: sub.id,
                                        price: sub.price,
                                        quantity: sub.quantity,
                                        name: sub.name,
                                        half_option: el.halfOption
                                    }))
                            })).filter(el => !!el.suboptions.length)
                    ]
                }
            }

            getProductPrice(data).then(res => {
                if(res.status === 200) {
                    setFullPrice(res.data.full_price);
                    handleEmitProduct({...data, full_price: res.data.full_price}, isHalf);
                }
                setIsLoading(false);
                setDisableNext(false);
            }).catch(() => {
                setShuffle(true);
                setTimeout(() => {
                    setShuffle(false);
                }, 1000);
                setDisableNext(true);
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [t, products, business_id, ingredients, product, quantity, halfProducts, isHalf, comments, nameOnBox]);

    useEffect(() => {
        if(editMode) {
            if(!mergedInit) {
                getPrice();
            }
        } else {
            getPrice();
        }
        // eslint-disable-next-line
    }, [quantity, product, mergedInit, nameOnBox, comments]);

    useEffect(() => {
        const productQuantity = products.some(pr => pr.suboptions.some(sub => sub.quantity));
        if(editMode) {
            if(!mergedInit) {
                getPrice();
            }
        } else {
            if(productQuantity || !init) {
                getPrice();
                setInit(false);
            }
        }
        // eslint-disable-next-line
    }, [products]);

    useEffect(() => {
        const halfQuantity = halfProducts.some(pr => pr.suboptions.some(sub => sub.quantity));
        if(editMode) {
            if(!mergedInit) {
                getPrice();
            }
        } else {
            if(halfQuantity || !init) {
                getPrice();
                setInit(false);
            }
        }
        // eslint-disable-next-line
    }, [halfProducts])

    const getHalfAllPortion = (isHalf) => {
        return {
            id: "half_options",
            name: t("create-order.product.TOPPINGS"),
            min: 1,
            max: 1,
            suboptions: [
                {quantity:!isHalf, name: t("create-order.product.FOR_WHOLE"), id: "whole"},
                {quantity:isHalf, name: t("create-order.product.HALFHALF"), id: "half"}
            ]
        }
    }

    const changeTopping = (data) => {
        const newIsHalf = !!data.suboptions.find(el => el.id === "half").quantity;
        setIsHalf(newIsHalf)
        const withHalf = allProducts.filter(el => el.with_half_option);
        if (newIsHalf) {
            setHalfProducts(p => withHalf.flatMap(el => [
                {...el, name: t("create-order.product.ONE_SIDE", {name: el.name}), halfOption: HALF_OPTION.left},
                {...el, name: t("create-order.product.ANOTHER_SIDE", {name: el.name}), halfOption: HALF_OPTION.right}
            ]))
        } else {
            setHalfProducts(p => withHalf);
        }
    }

    const goToStep = option => {
        const options = [...productsMap];
        if(hasIngredients) {
            options.unshift(ingredients);
        }

        if(halfProducts.length) {
            options.push(getHalfAllPortion(isHalf));
        }

        options.push(...halfProductsMap);

        const findIndex = options.findIndex((opt) => {
            return option.halfOption
                ? opt.id === option.id && opt.halfOption === option.halfOption
                : opt.id === option.id
        });

        setCurrentIndex(findIndex);
    }

    return (
        <Modal isOpen={isOpen} className={classnames("modal-add-dish", {rtl: isRTL()})}>
            <div className={classnames("add-dish-layout")}>
                <div className={classnames("close")} onClick={onClose}>
                    <IconButton isIcon iconName={"deleteX"}/>
                </div>
                <div className={classnames("restaurant-modal-add-dish")}>

                    <div className={classnames("image-product")}>
                        {giftMode ? (
                            <div className={classnames("gift-card-image")}>
                                <IconButton isIcon iconName={"loyality"}/>
                            </div>
                        ) : (
                            product && product.images && (<img src={product.images}
                                alt={product.name}
                                image={product.images || ""}
                                settings={product.image_settings}
                            />)
                        )}

                    </div>

                    <div className={classnames("stinky-header")}>
                    <span className={classnames("head")}>
                        <span className={classnames("header-name")}>{product && product.name}</span>
                        {product && (
                            <div className={classnames("price")}>
                                {!!product.price_before_discount && (<span className={classnames("price-through")}>{getCurrencyIcons(currency)}{product.price_before_discount}</span>)}&nbsp;&nbsp;
                                <span className={classnames({"price-blue": !product.price_before_discount, "price-red": !!product.price_before_discount})}>{getCurrencyIcons(currency)}{product.price}</span>
                            </div>
                        )}
                        </span>
                    </div>
                    {product && product.description && (<p className={"product-description"}>{product.description}</p>)}
                    {!!error && (<Warning message={error}/>)}
                    {hasIngredients && currentIndex === 0 && (<ProductAddDish
                        allProductsCount={countOfAllSteps}
                        currentIndex={currentIndex}
                        onChange={changeIngredients}
                        currency={currency}
                        product={ingredients}
                        useClass={"checkbox-with-icon"}
                    />)}

                    {productsMap
                        .filter((_, index) => currentIndex === index +1 + (hasIngredients ? 0 : -1))
                        .map(prod => (
                            <ProductAddDish onChange={changeQuantityOfIngredients}
                                            allProductsCount={countOfAllSteps}
                                            currentIndex={currentIndex}
                                            key={"add-dish-products" + prod.id}
                                            currency={currency}
                                            product={prod}
                                            withButtonsCounter={prod.max >= 2}
                                            useClass={((!prod.min && prod.max === 1) || (!prod.min && !prod.max && !prod.limit_suboptions_by_max)) && "checkbox-with-icon"}
                            />
                        ))}


                    {!!halfProducts.length && productsMap.length + 1 + (hasIngredients ? 0 : -1) === currentIndex && (
                        <ProductAddDish onChange={changeTopping}
                                        allProductsCount={countOfAllSteps}
                                        currentIndex={currentIndex}
                                        key={"need-half-option"}
                                        currency={currency}
                                        product={getHalfAllPortion(isHalf)}
                                        useClass={"checkbox-with-icon"}
                        />
                    )}

                    {halfProducts
                        .filter((_, index) => currentIndex === index + 2 + productsMap.length + (hasIngredients ? 0 : -1))
                        .map((prod, i) => (
                            <ProductAddDish onChange={changeQuantityOfIngredientsHalf}
                                            allProductsCount={countOfAllSteps}
                                            currentIndex={currentIndex}
                                            key={i + "-add-dish-products-" + prod.id}
                                            currency={currency}
                                            product={prod}
                                            withButtonsCounter={prod.max >= 2}
                                            useClass={!prod.min && prod.max === 1 && "checkbox-with-icon"}
                            />
                        ))}

                    {countOfAllProducts !== 0 && currentIndex === countOfAllProducts && halfProducts && products && (
                        <div className={"selected-product-options"}>
                            <div className={"selected-product-options-header"}>{t("create-order.product.SUMMARY")}</div>
                            <div className={"steps"}>
                                {Array(countOfAllSteps)
                                    .fill(0)
                                    .map((_, index) => (
                                        <React.Fragment key={`step-${index}`}>
                                            <div className={classnames("step", {active: index === currentIndex})}/>
                                            <div className={"step-line"}/>
                                        </React.Fragment>
                                    ))}

                            </div>
                            <div className={"summary-options"}>
                                {([ingredients, ...productsMap, halfProductsMap.length ? getHalfAllPortion(isHalf) : {}, ...halfProductsMap]).map((option, i) => {
                                    const options = (option.suboptions || [])
                                        .filter(s => s.quantity)
                                        .map(s => (
                                            <span key={`${s.name}-${s.id}`}>{s.name} {s.quantity > 1 ? ('x' + s.quantity) : ''} {s.price ? ('(+' + getCurrencyIcons(currency) + s.price + ')') : ''} <br/></span>
                                        ))
                                    if(!option.name) {
                                        return null;
                                    }

                                    return (
                                        <React.Fragment key={`${option.name}-${option.id}-${i}`}>
                                            <span className={"summary-name"}>{option.name}:</span>
                                            <span className={"summary-option"}>{options.length ? options : '-'}</span>
                                            <button className={"summary-button"} onClick={() => goToStep(option)}>{t("create-order.product.CHANGE")}</button>
                                        </React.Fragment>
                                    )
                                })}
                                {product && product.allow_comments && (
                                    <>
                                        <span className={"summary-name"}>{t("create-order.product.COMMENTS")}:</span>
                                        <span className={"summary-option"}>{nameOnBox || "-"} | {comments || "-"}</span>
                                        <button className={"summary-button"} onClick={() => setCurrentIndex(currentIndex-1)}>{t("create-order.product.CHANGE")}</button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {product && product.allow_comments && currentIndex === countOfAllProducts-1 && (<div className={"comments-block"}>
                        <div className={"selected-product-options-header"}>{t("create-order.product.COMMENTS")}</div>
                        <div className={"steps"}>
                            {Array(countOfAllSteps)
                                .fill(0)
                                .map((_, index) => (
                                    <React.Fragment key={`${index}-step`}>
                                        <div className={classnames("step", {active: index === currentIndex})}/>
                                        <div className={"step-line"}/>
                                    </React.Fragment>
                                ))}

                        </div>

                        <div className={classnames("description")}>
                            <span>{t("create-order.product.BOX_NAME")}</span>
                            <DebounceInput
                                debounceTimeout={500}
                                onChange={e => handleOnChangeNameOnBox(e.target.value)}
                                value={nameOnBox}
                            />
                        </div>

                        <div className={classnames("comments")}>
                            <span>{t("create-order.product.COMMENTS")}</span>
                            <DebounceInput
                                debounceTimeout={500}
                                onChange={e => handleOnChangeMessage(e.target.value)}
                                value={comments}
                            />
                        </div>
                    </div>)}

                </div>
                <div className={classnames("full-price", {tabsControl: currentIndex !== countOfAllProducts && countOfAllProducts !== 0})}>
                    {currentIndex === countOfAllProducts && (
                        <>
                            <CounterButton onClickRemove={handleOnRemoveProduct} disableAdd={q => product.max === q && product.max} onClickAdd={handleOnAddProduct} quantity={quantity} />
                            <div onClick={handleOnAddToCart} className={classnames("add-to-order", {disabled: isLoading || disableNext, isValid: (quantity === 0 && editMode && !isLoading) || isValid()})}>
                        <span className={classnames("label")}>
                            {
                                editMode && quantity > 0
                                    ? t("create-order.product.SAVE")
                                    : editMode && quantity === 0
                                        ? t("create-order.product.REMOVE")
                                        : <p>{t("create-order.product.ADD_TO_CART")}</p>
                            }
                        </span>
                                <span className={classnames("price-total")}>
                            {
                                isLoading
                                    ? (<div>loader</div>)
                                    : (quantity !== 0) && getCurrencyIcons(currency) + fullPrice
                            }
                        </span>
                            </div>
                        </>
                    )}
                    {currentIndex !== countOfAllProducts && (
                        <>
                            <button className={"btn denied"}
                                    disabled={currentIndex === 0}
                                    onClick={() => currentIndex !== 0 && setCurrentIndex(currentIndex-1)}
                            >{t("basic.BACK_BUTTON")}</button>
                            <button className={classnames("btn resolve", {shuffle})} disabled={!!error || isLoading || disableNext} onClick={() => setCurrentIndex(currentIndex+1)}>
                                {isLoading
                                    ? (<div>loader</div>)
                                    : (
                                        `${t("create-order.product.NEXT")} ${getCurrencyIcons(currency)}${fullPrice}`
                                    )}
                            </button>
                        </>
                    )}
                </div>
            </div>

        </Modal>
    )
}

export default RestaurantAddDish;
