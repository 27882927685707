export const getUserModel = state => state.userModel.data  || [];
export const getOrder = state => state.order;
export const getActiveStateCycles = state => state.activeStateCycles.data;
export const getAllOrders = state => (state.order.data.orders || []).concat(state.order.data.pickUps || []);
export const getControlCenter = state => state.restaurant.data.controlCenters || [];
export const getSelectedControlCenter = state => state.restaurant.data.selectedControlCenter || '';
export const getOrders = state => state.order.data.orders || [];
export const getPendingOrders = state => state.order.data.activeOrders;
export const getAdminOrders = state => state.order.data.adminOrders;
export const getPickups = state => state.order.data.pickUps || [];
export const getHistoryOrders = state => state.order.data.historyOrders || [];
export const getGroups = state => state.order.data.groups || [];
export const getRestaurant = state => state.restaurant.data;
export const getSelectedControlCenterMulti = state => state?.restaurant?.data?.selectedControlCenterMulti || [];
export const getRestaurantDepots = state => state.restaurant.data.depots || [];
export const getConfigurationRegions = state => state.restaurant.data.configuration.regions || [];
export const getRestaurantBusinessMenu = state => state.restaurant.data.businessMenu;
export const getAutentification = state => state.authentication;
export const getMarkers = state => {
    return Array.from(state.marker.dataMap, ([key, value]) => ({...value }));
};
export const getOrderEdit = state => state.orderEdit.data;
export const getSectionModel = state => state.sectionModel;
export const getNotifications = state => state.notifications.data.notifications || [];
export const getExternalCycles = state => state.externalCycles.data || [];
export const getProvidersAll = state => state.restaurant?.data?.providers || [];
export const getMenuTemplates = state => (state.menuTemplate.data || {}).templates || [];
export const getReconciliationSettings = state => state.reconciliations.data.configurations || [];
export const getBilling = state => state.billing;
export const getIsSectionsWrapperLoading = state => state.sectionsWrapperData.isLoading
