import React from "react";
import classNames from "classnames";

import { TYPE_CHART, CHART_MODEL } from "../utils/enums";

import {
  createControlCentersAverageDeliveryTimeChartModel,
  createControlCentersChartModel,
  createControlCentersTableChartModel, createDeepLinkStatsChartModel, createDepotTableChartModel,
  createOrdersAverageChartModel,
  createOrdersAveragePerHoursChartModel,
  createReportAverageDeliveryTimeChartModel,
  createReportAverageDeliveryTimeItemsChartModel,
  createReportControlCenterAverageDeliveryTimeChartModel,
  createReportDayAverageDeliveryTimeChartModel,
  createReportDeliveryTimeItemsChartModel, createSalesChart
} from "./chartModels/controlCentersChartModel";
import { createInProcessOrdersChartModel } from "./chartModels/inProcessOrdersChart";
import moment from "moment";
import {DATE_FORMAT_DMY2_FULL} from "../utils/convertTime.js";
import {defaultChartColors} from "../utils/constants.js";

export function createChartModel(type, ...args) {
  switch (type) {
    case CHART_MODEL.controlCenters:
      return createChartModelByType(createControlCentersChartModel(...args));
    case CHART_MODEL.controlCentersTable:
      return createChartModelByType(createControlCentersTableChartModel(...args));
    case CHART_MODEL.depotTableData:
      return createChartModelByType(createDepotTableChartModel(...args));
    case CHART_MODEL.inProcessOrders:
      return createChartModelByType(createInProcessOrdersChartModel(...args));
    case CHART_MODEL.ordersAverage:
      return createChartModelByType(createOrdersAverageChartModel(...args));
    case CHART_MODEL.ordersAveragePerHours:
      return createChartModelByType(createOrdersAveragePerHoursChartModel(...args));
    case CHART_MODEL.reportDeliveryTimeItems:    
      return createChartModelByType(createReportDeliveryTimeItemsChartModel(...args));
    case CHART_MODEL.reportControlCenterAverageDeliveryTime:
      return createChartModelByType(createReportControlCenterAverageDeliveryTimeChartModel(...args));
    case CHART_MODEL.reportAverageDeliveryTimeItems:
      return createChartModelByType(createReportAverageDeliveryTimeItemsChartModel(...args));
    case CHART_MODEL.reportAverageDeliveryTime:
      return createChartModelByType(createReportAverageDeliveryTimeChartModel(...args)); 
    case CHART_MODEL.reportDayAverageDeliveryTime:
      return createChartModelByType(createReportDayAverageDeliveryTimeChartModel(...args));
    case CHART_MODEL.controlCentersAverageDeliveryTime:
      return createChartModelByType(createControlCentersAverageDeliveryTimeChartModel(...args));
    case CHART_MODEL.sales:
      return createChartModelByType(createSalesChart(...args));
    case CHART_MODEL.deepLinkStats:
      return createChartModelByType(createDeepLinkStatsChartModel(...args));
    default:
      return null;
  }
}

export function generateComboChartData(data) {
  const { headers, columns } = data;
  return [headers, ...columns];
}

export function generateTableChartData(data) {
  const { headers, columns } = data;

  return [
    [...headers.map(header => header)],
    ...columns.map(column =>
      column.map((val, index) => (index ? { v: val } : val))
    )
  ];
}

export function generatePieChartData(data) {
  return generateComboChartData(data);
}

export function generateColumnChartData(data) {
  const { headers, columns } = data;

  if (!columns.length) return [["", { role: "annotation" }]];

  return [headers, ...columns];
}

export function generateGaugeChartData(data) {
  const { columns } = data;

  return [["Label", "Value"], columns];
}

export function generateStackedColumnChartData(data) {
  const { headers, columns } = data;

  return [headers, ...columns];
}

export function generateAreaChartData(data) {
  const { headers, columns } = data;

  return [headers, ...columns];
}

export function generateLineChartData(data) {
  const { headers, columns } = data;

  return [headers, ...columns];
}

function createChartModelByType(model) {
  if (!model) return null;
  let chartModel = {
    emptyLabel: model.emptyLabel,
    loader: <div className={classNames("loader")}></div>,
    graphID: model.typeModel,
    chartType: model.chartType
  };

  switch (model.chartType) {
    case TYPE_CHART.comboChart:
      chartModel = {
        ...chartModel,
        data: model.data && generateComboChartData(model.data),
        options: {
          title: model.title,
          vAxis: { title: model.titleX },
          hAxis: { title: model.titleY },
          seriesType: "bars",
          ...model.options,
        },
        height: model.height,
        updateChartData: function(data) {
          this.data = data && generateComboChartData(data);
        }
      };
      break;
    case TYPE_CHART.pieChart:
      chartModel = {
        ...chartModel,
        data: model.data && generatePieChartData(model.data),
        options: {
          title: model.title,
          ...model.options,
        },
        height: model.height,
        updateChartData: function(data) {
          this.data = data && generatePieChartData(data);
        }
      };
      break;
    case TYPE_CHART.table:
      chartModel = {
        ...chartModel,
        options: {
          ...chartModel.options,
        },
        data: model.data && generateTableChartData(model.data),
        updateChartData: function(data) {
          this.data = data && generateTableChartData(data);
        }
      };
      break;
    case TYPE_CHART.columnChart:
      chartModel = {
        ...chartModel,
        data: model.data && generateColumnChartData(model.data),
        options: {
          title: model.title,
          bar: { groupWidth: "80%" },
          legend: { position: "none" },
        },
        height: model.height,
        updateChartData: function(data) {
          this.data = data && generateColumnChartData(data);
        }
      };
      break;
    case TYPE_CHART.line:
      chartModel = {
        ...chartModel,
        data: model.data && generateLineChartData(model.data),
        options: {
          title: model.title,
          bar: { groupWidth: "80%" },
          legend: { position: "none" },
          vAxis: {
            format: "Mm/dd/yy HH:mm",
          }
        },
        height: model.height,
      };
      break;
    case TYPE_CHART.gauge:
      chartModel = {
        ...chartModel,
        data: model.data && generateGaugeChartData(model.data),
        options: {
          ...model.options,
          title: model.title,
        },
        height: model.height,
        updateChartData: function(data) {
          this.data = data && generateGaugeChartData(data);
        }
      };
      break;
    case TYPE_CHART.barChartStacked:
      chartModel = {
        ...chartModel,
        data: model.data && generateStackedColumnChartData(model.data),
        options: {
          title: model.title,
          isStacked: true,
          chartArea: { width: '70%' },
          vAxis: { title: model.titleX },
          hAxis: { title: model.titleY },
          ...model.options,
        },
        height: model.height,
        updateChartData: function(data) {
          this.data = data && generateStackedColumnChartData(data);
        }
      };

      chartModel.chartType = TYPE_CHART.columnChart;
      break;
    case TYPE_CHART.areaChart:
      chartModel = {
        ...chartModel,
        data: model.data && generateAreaChartData(model.data),
        options: {
          chartArea: { width: '90%', height: '70%' },
          legend: { position: "none" },
        },
        height: model.height,
        updateChartData: function(data) {
          this.data = data && generateAreaChartData(data);
        }
      };
      break;
    default:
      break;
  }
 
  return chartModel;
}

export const getApexCartDefaulOption = (length) => {
  return {
    colors: defaultChartColors,
    type: "line",
    dataLabels: {
      enabled: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    markers: {
      size: [6].concat(
          Array(length-1 > 0 ? length-1 : 0).fill(6)
      ),
      defaultChartColors
    },
    xaxis: {
      tickAmount: "dataPoints",
      type: "datetime",
      tickPlacement: "on",
      axisBorder: {
        color: "rgba(255,255,255,0.2)"
      },
      labels: {
        formatter: function (value) {
          const d = new Date(value);
          const M = d.getMonth();
          const D = d.getDate();
          return `${D}/${M +1}`;
        }
      },
    },
    yaxis: {
      labels: {
        formatter: value => parseFloat(value?.toFixed(2) || 0)
      }
    },
    options: {
      chart: {
        height: 350,
        type: "line"
      },
    },
    legend: {
      showForSingleSeries: true,
      show: true,
      position: 'bottom'
    },
    stroke: {
      show: true,
      width: 5,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: (dateLabel) => {
          return moment(dateLabel).format(DATE_FORMAT_DMY2_FULL);
        },
      },
    }
  };
}
export const getApexPieChartDefaultOption = () => ({
  colors: defaultChartColors,
  chart: {
    width: 380,
    type: 'pie',
  },
  dataLabels: {
    enabled: true,
    formatter: function (val, opt) {
      return parseFloat(opt.w.config.series[opt.seriesIndex]?.toFixed(2) || 0);
    },
  },
  stroke: {
    show: false,
  },
  legend: {
    position: 'bottom',
    show: true,
    showForSingleSeries: true
  },
})