import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import { CONTROL_STYLE_TYPE, CONTROL_TYPE, POPUP_ITEM_TYPE, WAITER_TYPES } from '../../../utils/enums';

import CheckBox from '../CheckBox';
import Slider from '../Slider';
import GroupCheckbox from '../GroupCheckbox';
import Waiter from '../Waiter/Waiter';
import OrdersFilterDropDownControl from '../OrdersFilterDropDownControl/OrdersFilterDropDownControl';
import IconButton from '../IconButton';
import UsersPopup from '../ControlCentersManagersList/index.js';
import { FILTER_OPTION_TYPE } from '../../../models/filterOptionsModel.js';
import SectionItemMultiselect from "../SectionItemMultiselect/index.js";
import DatePicker from "../DatePicker/DatePicker.jsx";
import {DATE_FORMAT} from "../../../utils/convertTime.js";
import DateLinks from "../DateLinks/DateLinks.jsx";

const OrdersFilter = ({ t, filterOptionsModel, onChangeOption, onClose }) => {
  const [edited, setEdited] = useState(false);
  const [options, setOptions] = useState(filterOptionsModel.options);

  const handleOnOptionChange = (key, prop, value, isMultiple) => {
    const option = filterOptionsModel.options[key] || {};

    if(isMultiple) {
      const newOptions = {
        ...filterOptionsModel.options
      };

      (value || []).forEach(val=> {
        if(newOptions[val.key]) {
          newOptions[val.key][val.prop] = val.value;
        }
      });

      setOptions(newOptions);
      if(onChangeOption) {
        onChangeOption(key, prop, value, isMultiple);
      }
    } else {
      if (option.type === CONTROL_TYPE.link && option.editFunc) {
        option.editFunc(handleOnOptionChange);
      } else {
        if (onChangeOption) {
          onChangeOption(key, prop, value);
          setOptions({
            ...filterOptionsModel.options,
            [[key][prop]]: value
          });
        }
      }
    }

    setEdited(true);
  };

  const handleOnSetDateLink = (from, to, options) => {
    handleOnOptionChange(options.startKey, "selected", from);
    handleOnOptionChange(options.endKey, "selected", to);
  }

  const handleOnSaveFilter = () => {
    return new Promise(resolve=> {
      resolve();
      handleOnClose();
    });
  }

  const handleOnClose = () => {
    onClose && onClose(null, null, options);
    setEdited(false);
  }

  const resetSelectOnCheckbox = (option) => {
    return option.selected.map(s => ({
      ...s,
      items: s.items.map(item => ({
        ...item,
        selected: false,
      }))
    }))
  }

  const deselectAll = () => {
    for(let key in filterOptionsModel.options) {
      if(![CONTROL_TYPE.dateLink, CONTROL_TYPE.link, CONTROL_TYPE.groupCheckbox].includes(filterOptionsModel.options[key].type)) {
        handleOnOptionChange(key, "selected", null);
      }
      if(CONTROL_TYPE.groupCheckbox === filterOptionsModel.options[key].type) {
        handleOnOptionChange(key, "selected", resetSelectOnCheckbox(filterOptionsModel.options[key]));
      }
    }
  }

  useEffect(() => {
    setOptions(filterOptionsModel.options);
  }, [filterOptionsModel]);
  
  return (
    <div className={classNames("ordersFilterContainer")}>
      <div className={classNames("ordersFilterHeader")}>
        <IconButton iconName={"crossClose"} isIcon onClick={handleOnClose}/>
      </div>
      <div className={classNames("ordersFilterContent")}>
        <div className={"filtersLabel"}>
          <span>{t("orders.FILTERS_LABEL")}:</span>
          <div className={'deselectAll'} onClick={deselectAll}>{t("orders.DESELECT_ALL")}</div>
        </div>
        {filterOptionsModel.options &&
          Object.keys(filterOptionsModel.options).filter(key=> options[key]).map(key => (
            <div key={key}
              className={classNames("filterOption", { inline: options[key].typeControlStyle === CONTROL_STYLE_TYPE.inline })}
            >
              {options[key].type === CONTROL_TYPE.date && (
                  <div className={classNames("filterDate")}>
                    <div className={classNames("title")}>
                      {options[key].label ? t(options[key].label) : ""}
                    </div>
                    <DatePicker
                      format={options[key].format}
                      calendarPosition={'left'}
                      updateValueByParent
                      showTimeControl={options[key].format !== DATE_FORMAT}
                      value={options[key].selected ? new Date(options[key].selected).getTime() : null}
                      onSave={date => handleOnOptionChange(key, "selected", date)}
                    />
                  </div>
                )}
              {options[key].type === CONTROL_TYPE.checkbox && (
                <CheckBox
                  label={t(options[key].label)}
                  value={options[key].selected}
                  onChange={value => handleOnOptionChange(key, "selected", value)}
                />
              )}
              {options[key].type === CONTROL_TYPE.label && (
                <div className={classNames("groupLabel")}>{options[key].label}</div>
              )}
              {options[key].type === CONTROL_TYPE.groupCheckbox && (
                <GroupCheckbox key={key} title={options[key].label} items={options[key].selected} 
                  onChange={val=> handleOnOptionChange(key, "selected", val)} 
                />
              )}
              {options[key].type === CONTROL_TYPE.select && (options[key].isShow ? options[key].isShow() : true) && (
                <OrdersFilterDropDownControl optionKey={key} option={options[key]} onOptionChange={handleOnOptionChange} />
              )}
              {options[key].type === CONTROL_TYPE.multiselect && (options[key].isShow ? options[key].isShow() : true) && (
                  <SectionItemMultiselect optionKey={key} option={options[key]} onOptionChange={handleOnOptionChange}/>
              )}
              {options[key].type === CONTROL_TYPE.slider && (
                  <div className={classNames("sliderOption")}>
                    <Slider
                      reverse={!options[key].sliderOptions.rightToLeft}
                      min={options[key].sliderOptions.floor}
                      max={options[key].sliderOptions.ceil}
                      value={options[key].selected}
                      title={options[key].label}
                      onChange={value => handleOnOptionChange(key, "selected", value)}
                    />
                  </div>
                )}
              {options[key].type === CONTROL_TYPE.usersPopup && (
                <UsersPopup
                  optionKey={key}
                  controlCenterOption={options[FILTER_OPTION_TYPE.controlCenter]}
                  option={options[key]}
                  onOptionChange={handleOnOptionChange}
                  itemType={POPUP_ITEM_TYPE.shiftsFilterItem}
                />
              )}
              {options[key].type === CONTROL_TYPE.link && (
                  <div className={classNames("link")} onClick={() => handleOnOptionChange(key)}>
                    {options[key].label || ""}
                  </div>
                )}
              {options[key].type === CONTROL_TYPE.dateLink && (
                  <DateLinks
                      startOption={options[options[key].startKey]}
                      endOption={options[options[key].endKey]}
                      onClick={(from, to) => handleOnSetDateLink(from, to, options[key])}
                  />
              )}
            </div>
          ))}
      </div>
      <div className={classNames("ordersFilterFooter")}>
        <Waiter type={WAITER_TYPES.button}
          useClass={classNames("btn", "denied")}
          spanText={t("basic.CLOSE_BUTTON")}
          stopPropagation={true} 
          handleOnClick={handleOnClose}
        />
        <Waiter type={WAITER_TYPES.button}
          useClass={classNames("btn", "confirm")}
          spanText={t("basic.APPLY_BUTTON")}
          stopPropagation={true}
          disabled={!edited}
          handleOnClick={handleOnSaveFilter}
        />
      </div>
    </div>
  );
};

export default withTranslation()(OrdersFilter)
