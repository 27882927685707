import { LOCATION_CHANGE, push } from "connected-react-router";
import { put, takeEvery, select } from "redux-saga/effects";

import { createSettingsSectionModel } from "../../services/sectionModelFactory";

import { createSaga } from "../../utils/sagaHelper";
import { getSeparatedValue } from "../../utils/objects-util";
import {
  ORDERING_MENU_TYPE_SECTION_MODEL,
  TYPE_SECTION_MODEL,
  ROLES_MANAGER_TYPE,
  SECTION_MODEL_FACTORY_TYPE,
  TYPE_SECTION_DEPOT_MODEL,
  MARKETPLACE_SETTINGS_MODEL,
  ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL,
  TYPE_SECTION_MENUTEMPLATE_MODEL, TYPE_SECTION_MESSAGE_MODEL,
} from "../../utils/enums";
import { getHistoryLocationParams } from "../../utils/routesHelper";

import RoleManager from "../../services/roleManage";
import InfoMessageService from "../../services/infoMessage";
import { currentUserIsDepotRole } from "../../services/userModel";

import Store from "../store";
import { getSectionModel } from "../selectors";
import {
  actionUpdateSectionModel,
  SECTION_MODEL_GET,
  SECTION_MODEL_INIT,
  SECTION_MODEL_REFRESH
} from "../reducers/routing/routing-actions";
import { actionSetEmptyInfoMessage } from "../reducers/emptyInfo/emptyInfo-actions";
import { excludedUrls } from "../../utils/constants";

function* routeSaga(action) {
  yield routeModelsSaga(action);
}

function* routeModelsSaga(action) {
  yield* createSaga(function*() {
    const { location, isRefresh } = action.payload;
    const check = InfoMessageService.instance.checkAction(location.pathname);

    if (check) {
      yield put(actionSetEmptyInfoMessage({ message: check.message }));

      if (check.skipCheckPathname !== location.pathname) {
        Store.history.push(check.pathname);
      }
      return;
    }

    if (isRefresh) {
      yield put({ type: SECTION_MODEL_INIT });
    }

    if (
      location.pathname === "/" &&
      !RoleManager.instance.isAuthorized(ROLES_MANAGER_TYPE.menu, "/")
    ) {
      Store.history.push(RoleManager.instance.getFirstAuthorizedLink());
    }

    if (location.pathname && location.pathname.includes("/settings")) {
      const pathSplit = location.pathname.split("/");
      const settingsAutorized = RoleManager.instance.isAuthorized(
        ROLES_MANAGER_TYPE.menu,
        "/settings"
      );
      const pathnameAutorized = RoleManager.instance.isAuthorized(
        ROLES_MANAGER_TYPE.menu,
        location.pathname
      );
      const pathSplitAutorized =
        pathSplit.length > 2
          ? RoleManager.instance.isAuthorized(
              ROLES_MANAGER_TYPE.menu,
              `/${pathSplit[1]}/${pathSplit[2]}`
            )
          : true;

      if (!settingsAutorized || !pathnameAutorized) {
        const keys = Object.keys(TYPE_SECTION_MODEL);
        const first = keys.find(key =>
          RoleManager.instance.isAuthorized(
            ROLES_MANAGER_TYPE.menu,
            TYPE_SECTION_MODEL[key]
          )
        );

        if (first) {
          Store.history.push(first);
        }
        return;
      } else if (!pathSplitAutorized) {
        Store.history.push(RoleManager.instance.getFirstAuthorizedLink());
        return;
      }
    } else {
      if (
        location.pathname !== "/" &&
        !excludedUrls.includes(location.pathname) &&
        !currentUserIsDepotRole() &&
        !RoleManager.instance.isAuthorized(
          ROLES_MANAGER_TYPE.menu,
          location.pathname
        )
      ) {
        yield put(push("/"));
        return;
      }
    }

    if (location.pathname && location.pathname.includes("/shifts")) {
      yield shiftsRouteSaga(action.payload);
      return;
    }
    if (location.pathname && location.pathname.includes("/reconciliations")) {
      yield reconciliationsRouteSaga(action.payload);
      return;
    }

    if (location.pathname && location.pathname.includes("/adminorders")) {
      yield customerServiceRouteSaga(action.payload);
      return;
    }

    if (location.pathname === "/orders") {
      yield ordersRouteSaga(action.payload);
    }

    if (location.pathname === "/menu") {
      yield restaurateurMenuRouteSaga(action.payload);
    }

    if (
      Object.keys(TYPE_SECTION_MODEL).some(
        key => TYPE_SECTION_MODEL[key] === location.pathname
      )
    ) {
      yield settingsRouteSaga({
        ...action.payload,
        model: "TYPE_SECTION_MODEL"
      });
    }

    if (
      Object.keys(ORDERING_MENU_TYPE_SECTION_MODEL).some(
        key => ORDERING_MENU_TYPE_SECTION_MODEL[key] === location.pathname
      )
    ) {
      yield orderingMenuRouteSaga(action.payload);
    }

    if (
      Object.keys(TYPE_SECTION_DEPOT_MODEL).some(
        key => TYPE_SECTION_DEPOT_MODEL[key] === location.pathname
      )
    ) {
      yield settingsDepotRouteSaga(action.payload);
    }

    if (
      Object.keys(MARKETPLACE_SETTINGS_MODEL).some(
        key => MARKETPLACE_SETTINGS_MODEL[key] === location.pathname
      )
    ) {
      yield settingsRouteSaga({
        ...action.payload,
        model: "MARKETPLACE_SETTINGS_MODEL"
      });
    }

    if (
        Object.keys(ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL).some(
            key => ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL[key] === location.pathname
        )
    ) {
      yield orderingMenuMenuTemplateRouteSaga(action.payload);
    }

    if (
        Object.keys(TYPE_SECTION_MENUTEMPLATE_MODEL).some(
            key => TYPE_SECTION_MENUTEMPLATE_MODEL[key] === location.pathname
        )
    ) {
      yield marketplaceMenuTemplateRouteSaga(action.payload);
    }
    if (
        Object.keys(TYPE_SECTION_MESSAGE_MODEL).some(
            key => TYPE_SECTION_MESSAGE_MODEL[key] === location.pathname
        )
    ) {
      yield marketplaceMessageSettingsRouteSaga(action.payload);
    }

  }, action.type);
}

function* marketplaceMenuTemplateRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const groupSectionItem = getSeparatedValue(pathname, "/", 4);
    const { id, parentId } = getHistoryLocationParams(search);

    let newModel =
        sectionModel.sectionModels[pathname].model ||
        createSettingsSectionModel({ typeModel: pathname, params: search });
    if (
        sectionModel.sectionModels[pathname].model &&
        newModel.controls &&
        newModel.controls.length
    ) {
      newModel.controls = newModel.controls.map(control => {
        const controlUrl =
            (control.url && control.url.split("?parentId")) || [];
        return controlUrl.length > 1
            ? { ...control, url: controlUrl[0] + "?parentId=" + id }
            : control;
      });
    }

    yield put(
        actionUpdateSectionModel({
          prevModelType: {
            ...sectionModel.prevModelType,
            MARKETPLACE_SETTINGS_MODEL: pathname
          },
          currentModelType: pathname,
          sectionModels: {
            ...sectionModel.sectionModels,
            [pathname]: { id: id || parentId, model: newModel }
          },
          groupSections: {
            ...sectionModel.groupSections,
            [groupSectionItem]: pathname + search
          }
        })
    );
  } catch (e) {
    console.log(e);
  }
}

function* marketplaceMessageSettingsRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const groupSectionItem = getSeparatedValue(pathname, "/", 4);
    const { id, parentId } = getHistoryLocationParams(search);

    let newModel =
        sectionModel.sectionModels[pathname].model ||
        createSettingsSectionModel({ typeModel: pathname, params: search });
    if (
        sectionModel.sectionModels[pathname].model &&
        newModel.controls &&
        newModel.controls.length
    ) {
      newModel.controls = newModel.controls.map(control => {
        const controlUrl =
            (control.url && control.url.split("?parentId")) || [];
        return controlUrl.length > 1
            ? { ...control, url: controlUrl[0] + "?parentId=" + id }
            : control;
      });
    }

    yield put(
        actionUpdateSectionModel({
          prevModelType: {
            ...sectionModel.prevModelType,
            TYPE_SECTION_MESSAGE_MODEL: pathname
          },
          currentModelType: pathname,
          sectionModels: {
            ...sectionModel.sectionModels,
            [pathname]: { id: id || parentId, model: newModel }
          },
          groupSections: {
            ...sectionModel.groupSections,
            [groupSectionItem]: pathname + search
          }
        })
    );
  } catch (e) {
    console.log(e);
  }
}

function* settingsDepotRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const groupSectionItem = getSeparatedValue(pathname, "/", 4);
    const { id, parentId } = getHistoryLocationParams(search);

    let newModel =
      sectionModel.sectionModels[pathname].model ||
      createSettingsSectionModel({ typeModel: pathname, params: search });
    if (
      sectionModel.sectionModels[pathname].model &&
      newModel.controls &&
      newModel.controls.length
    ) {
      newModel.controls = newModel.controls.map(control => {
        const controlUrl =
          (control.url && control.url.split("?parentId")) || [];
        return controlUrl.length > 1
          ? { ...control, url: controlUrl[0] + "?parentId=" + id }
          : control;
      });
    }

    yield put(
      actionUpdateSectionModel({
        prevModelType: {
          ...sectionModel.prevModelType,
          TYPE_SECTION_MODEL: pathname
        },
        currentModelType: pathname,
        sectionModels: {
          ...sectionModel.sectionModels,
          [pathname]: { id: id || parentId, model: newModel }
        },
        groupSections: {
          ...sectionModel.groupSections,
          [groupSectionItem]: pathname + search
        }
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* settingsRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const groupSectionItem = getSeparatedValue(pathname, "/", 3);
    const { id, parentId } = getHistoryLocationParams(search);

    const isForceUpdateColumns = [
      TYPE_SECTION_MODEL.depotsCategoriesEdit
    ].includes(pathname);

    let newModel =
      sectionModel.sectionModels[pathname].model ||
      createSettingsSectionModel({ typeModel: pathname, params: search });
    if (
      sectionModel.sectionModels[pathname].model &&
      newModel.controls &&
      newModel.controls.length
    ) {
      newModel.controls = newModel.controls.map(control => {
        const controlUrl =
          (control.url && control.url.split("?parentId")) || [];
        return controlUrl.length > 1
          ? { ...control, url: controlUrl[0] + "?parentId=" + id }
          : control;
      });
    }

    if (isForceUpdateColumns) {
      const updModel = createSettingsSectionModel({
        typeModel: pathname,
        params: search
      })["columns"];
      newModel.columns = updModel;
    }

    yield put(
      actionUpdateSectionModel({
        prevModelType: {
          ...sectionModel.prevModelType,
          [action.model]: pathname
        },
        currentModelType: pathname,
        sectionModels: {
          ...sectionModel.sectionModels,
          [pathname]: { id: id || parentId, model: newModel }
        },
        groupSections: {
          ...sectionModel.groupSections,
          [groupSectionItem]: pathname + search
        }
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* ordersRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);

    yield put(
      actionUpdateSectionModel({
        sectionModels: {
          ...sectionModel.sectionModels,
          [pathname]: {
            id: search,
            model:
              sectionModel.sectionModels[pathname].model ||
              createSettingsSectionModel({
                typeModel: pathname,
                params: search
              })
          }
        }
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* restaurateurMenuRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const { id, parentId } = getHistoryLocationParams(search);
    const model =
      (sectionModel && sectionModel.sectionModels[pathname].model) ||
      createSettingsSectionModel({ typeModel: pathname, params: search });

    yield put(
      actionUpdateSectionModel({
        prevModelType: sectionModel.prevModelType,
        currentModelType: pathname,
        sectionModels: {
          ...sectionModel.sectionModels,
          [pathname]: {
            id: id || parentId,
            model: model
          }
        }
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* shiftsRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const { parentId, id, subId, subToId } = getHistoryLocationParams(search);
    let sectionModels = { ...sectionModel.sectionModels };
    let groupSections = { ...sectionModel.groupSections };
    const prevExistedModel = (sectionModels[pathname] || {}).model || null;
    const newSectionModel = createSettingsSectionModel({
      typeModel: pathname,
      params: search
    });

    if(prevExistedModel?.skipUpdateFilterOptions) {
      newSectionModel.filterOptions = prevExistedModel.filterOptions;
    }

    sectionModels = {
      ...sectionModels,
      [pathname]: {
        id: subToId || subId || id || parentId,
        model: newSectionModel
      }
    };

    yield put(
        actionUpdateSectionModel({
          prevModelType: {
            ...sectionModel.prevModelType,
            SHIFTS_MODEL: pathname
          },
          currentModelType: pathname,
          sectionModels,
          groupSections
        })
    );
  } catch (e) {
    console.log(e);
  }
}

function* reconciliationsRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const { parentId, id, subId, subToId } = getHistoryLocationParams(search);
    let sectionModels = { ...sectionModel.sectionModels };
    let groupSections = { ...sectionModel.groupSections };

    sectionModels = {
      ...sectionModels,
      [pathname]: {
        id: subToId || subId || id || parentId,
        model: createSettingsSectionModel({
          typeModel: pathname,
          params: search
        })
      }
    };

    yield put(
        actionUpdateSectionModel({
          prevModelType: {
            ...sectionModel.prevModelType,
            RECONCILIATIONS_MODEL: pathname
          },
          currentModelType: pathname,
          sectionModels,
          groupSections
        })
    );
  } catch (e) {
    console.log(e);
  }
}


function* customerServiceRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const { parentId, id, subId, subToId } = getHistoryLocationParams(search);
    let sectionModels = { ...sectionModel.sectionModels };
    let groupSections = { ...sectionModel.groupSections };

    sectionModels = {
      ...sectionModels,
      [pathname]: {
        id: subToId || subId || id || parentId,
        model: createSettingsSectionModel({
          typeModel: pathname,
          params: search
        })
      }
    };

    yield put(
        actionUpdateSectionModel({
          prevModelType: {
            ...sectionModel.prevModelType,
            CUSTOMER_SERVICE_MODEL: pathname
          },
          currentModelType: pathname,
          sectionModels,
          groupSections
        })
    );
  } catch (e) {
    console.log(e);
  }
}


function* orderingMenuMenuTemplateRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const { parentId, id, subId, subToId } = getHistoryLocationParams(search);
    let sectionModels = { ...sectionModel.sectionModels };
    let groupSections = { ...sectionModel.groupSections };

    Object.keys(groupSections).forEach(key => {
      if (key.includes("/orderingmenu")) {
        groupSections[key] = key + "?id=" + (parentId || id);
      }
    });

    sectionModels = {
      ...sectionModels,
      [ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: {
        ...sectionModels[ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit],
        id: parentId || id,
        model: createSettingsSectionModel({
          typeModel: ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL.orderingMenuEdit,
          params: "?id=" + (parentId || id)
        })
      },
      [pathname]: {
        id: subToId || subId || id || parentId,
        model: createSettingsSectionModel({
          typeModel: pathname,
          params: search
        })
      }
    };

    yield put(
        actionUpdateSectionModel({
          prevModelType: {
            ...sectionModel.prevModelType,
            ORDERING_MARKETPLACE_MENU_TYPE_SECTION_MODEL: pathname
          },
          currentModelType: pathname,
          sectionModels,
          groupSections
        })
    );
  } catch (e) {
    console.log(e);
  }
}


function* orderingMenuRouteSaga(action) {
  try {
    const { pathname, search } = action.location;
    const sectionModel = yield select(getSectionModel);
    const { parentId, id, subId, subToId } = getHistoryLocationParams(search);
    let sectionModels = { ...sectionModel.sectionModels };
    let groupSections = { ...sectionModel.groupSections };

    Object.keys(groupSections).forEach(key => {
      if (key.includes("/orderingmenu")) {
        groupSections[key] = key + "?id=" + (parentId || id);
      }
    });

    sectionModels = {
      ...sectionModels,
      [ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit]: {
        ...sectionModels[ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit],
        id: parentId || id,
        model: createSettingsSectionModel({
          typeModel: ORDERING_MENU_TYPE_SECTION_MODEL.orderingMenuEdit,
          params: "?id=" + (parentId || id)
        })
      },
      [pathname]: {
        id: subToId || subId || id || parentId,
        model: createSettingsSectionModel({
          typeModel: pathname,
          params: search
        })
      }
    };

    yield put(
      actionUpdateSectionModel({
        prevModelType: {
          ...sectionModel.prevModelType,
          ORDERING_MENU_TYPE_SECTION_MODEL: pathname
        },
        currentModelType: pathname,
        sectionModels,
        groupSections
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* getSectionModelSaga(action) {
  yield* createSaga(function*() {
    const { link, editItem, type } = action.data;
    let sectionModel = yield select(getSectionModel);
    const pathname = link.split("?")[0];
    const search = link.split("?")[1];
    const groupSectionItem = getSeparatedValue(pathname, "/", 3);
    const { parentId, id, subId, subToId } = getHistoryLocationParams(search);
    let createSectionModel;
    switch (type) {
      case SECTION_MODEL_FACTORY_TYPE.settings:
      case SECTION_MODEL_FACTORY_TYPE.orderingMenu:
      case SECTION_MODEL_FACTORY_TYPE.marketplaceSettings:
      case SECTION_MODEL_FACTORY_TYPE.marketplaceOrderingMenu:
        createSectionModel = createSettingsSectionModel;
        break;
      default:
        break;
    }
    if (createSectionModel) {
      let newModel =
        sectionModel.sectionModels[pathname].model ||
        createSectionModel({ typeModel: pathname, params: search });
      yield put(
        actionUpdateSectionModel({
          layoutModelType: pathname,
          sectionModels: {
            ...sectionModel.sectionModels,
            [pathname]: {
              id: (editItem || {}).id || subToId || subId || id || parentId,
              model: newModel
            }
          },
          groupSections: {
            ...sectionModel.groupSections,
            [groupSectionItem]: pathname + search
          }
        })
      );

      sectionModel = yield select(getSectionModel);
      return sectionModel.sectionModels[pathname];
    } else {
      return null;
    }
  }, action.type);
}

export default function*() {
  yield takeEvery(LOCATION_CHANGE, routeSaga);
  yield takeEvery(SECTION_MODEL_REFRESH, routeModelsSaga);
  yield takeEvery(SECTION_MODEL_GET, getSectionModelSaga);
}
