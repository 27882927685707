import React from 'react';
import "./index.scss";
import i18n from 'i18next';
import { isRTL } from '../../../services/userModel.js';

const SectionInfoTitle = ({ sectionModel, styles, infoTitleRef }) => {
  if(!sectionModel?.label) return null

  const label = sectionModel.label
  const translateInfoTitle = i18n.exists(label + '_INFO_TITLE') ? i18n.t(label + '_INFO_TITLE') : ''

  if(!translateInfoTitle) return null

  return (
    <div className="infoTitleWrapper" style={styles} ref={infoTitleRef}>
      <p style={{textAlign: isRTL() ? 'right' : ''}}>{translateInfoTitle}</p>
    </div>
  );
};

export default SectionInfoTitle;