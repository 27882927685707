import {COURIER_CONFIRMATION_OPTION} from "../utils/enums.js";

export const getCourierConfirmationSettingsModel = (data = {}) => {
    const courierConfirmationOptions = [];
    for(const key in COURIER_CONFIRMATION_OPTION) {
        if(data[key]) {
            courierConfirmationOptions.push(COURIER_CONFIRMATION_OPTION[key]);
        }
    }

    return {
        CourierConfirmationOptions: courierConfirmationOptions,
        CourierWaitingApproveTimeout: data.courierWaitingApproveTimeout ?? 0,
        RejectCycleOnCourierApproveTimeout: data.rejectCycleOnCourierApproveTimeout ?? false,
    };
}

export const getCourierConfirmationSettingsServerModel = (data = {}) => {
    const courierConfirmationOptions = {};
    for(const key in COURIER_CONFIRMATION_OPTION) {
        if(data.courierConfirmationOptions.includes(COURIER_CONFIRMATION_OPTION[key])) {
            courierConfirmationOptions[key] = true;
        } else {
            courierConfirmationOptions[key] = false;
        }
    }

    return {
        ...courierConfirmationOptions,
        courierWaitingApproveTimeout: data.courierWaitingApproveTimeout ?? 0,
        rejectCycleOnCourierApproveTimeout: data.rejectCycleOnCourierApproveTimeout ?? false,
    };
}