import React from "react";
import Chart from "react-apexcharts";

const ApexChart = ({data}) => {
    return <Chart
        options={data.options}
        height={350}
        series={data.series}
        type={data.type || data.options.type}
    />
}
export default ApexChart;