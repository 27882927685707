import React, { useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import CustomersListItem from "../CustomersListItem";

const CustomersListItems = ({ customerId, items, onSelect }) => {
    useEffect(()=> {
        if(items.length && !customerId) {
            onSelect && onSelect(items[0].consumerId);
        }
    }, [items, customerId, onSelect]);

    return (
        <div className={classNames("customersListItems")}>
            {(items || []).map((item, i)=> (
                <CustomersListItem key={`customersListItem-${i}`} 
                    value={item} 
                    active={customerId === item.consumerId} 
                    onSelect={onSelect}
                />
            ))}
        </div>
    );
}

const mapStateToProps = state => ({
    items: state.customer.customers || []
});

export default connect(mapStateToProps)(CustomersListItems);