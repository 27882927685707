import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { CUSTOMER_REVIEWS_GET } from "../../../store/reducers/customer/customer-actions";

import GlobalFilterDataService from "../../../services/globalFilterDataService";
import { createAction } from "../../../utils/sagaHelper";
import { GLOBAL_FILTER_TYPE } from "../../../utils/enums";

import CustomerReviewsHeader from "../CustomerReviewsHeader";
import CustomerReviewsFilter from "../CustomerReviewsFilter";
import CustomerReviewsData from "../CustomerReviewsData";

const CustomerReviews = ({ getCustomerReviews }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState(GlobalFilterDataService.instance.getFilter(GLOBAL_FILTER_TYPE.reviews));

    const handleOnSetFilter = val => {
        GlobalFilterDataService.instance.setFilter(GLOBAL_FILTER_TYPE.reviews, { ...filter, ...val })
        setFilter({ ...filter, ...val });
    }

    useEffect(()=> {
        setIsLoading(true);
        getCustomerReviews(filter)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    }, [filter, getCustomerReviews]);

    return (
        <div className={classNames("customerReviews")}>
            <CustomerReviewsHeader filter={filter} onSetFilter={handleOnSetFilter} />
            <CustomerReviewsFilter filter={filter} onSetFilter={handleOnSetFilter} />
            <CustomerReviewsData isLoading={isLoading} />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        getCustomerReviews: data=> dispatch(createAction(CUSTOMER_REVIEWS_GET, data))
    };
};

export default connect(null, mapDispatchToProps)(CustomerReviews);