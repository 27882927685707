import ChartModel from "../../models/chartModel";
import { TYPE_CHART, CHART_MODEL } from "../../utils/enums";
import { defaultControlCenterColors } from "../../utils/constants";

export function createControlCentersChartModel(data) {
  return new ChartModel({
    typeModel: CHART_MODEL.controlCenters,
    chartType: TYPE_CHART.comboChart,
    title: "",
    data: data,
    titleX: "Orders",
    titleY: "Control center",
    height: "200px",
    options: {
      chartArea: { left: 45 }
    }
  });
}

export function createControlCentersTableChartModel(data) {
  return new ChartModel({
    typeModel: CHART_MODEL.controlCentersTable,
    chartType: TYPE_CHART.table,
    title: "",
    data: data,
    height: "200px"
  });
}

export function createDepotTableChartModel(data) {
    return new ChartModel({
        typeModel: CHART_MODEL.depotTableData,
        chartType: TYPE_CHART.table,
        title: "",
        data: data,
        height: "200px"
    });
}

export function createOrdersAverageChartModel(data) {
  return new ChartModel({
    typeModel: CHART_MODEL.ordersAverage,
    chartType: TYPE_CHART.columnChart,
    title: "",
    data: data,
    height: "200px"
  });
}

export function createOrdersAveragePerHoursChartModel(data) {
  return new ChartModel({
    typeModel: CHART_MODEL.ordersAveragePerHours,
    chartType: TYPE_CHART.gauge,
    title: "reports.SCORE",
    data: data,
    options: {
      redFrom: 0,
      redTo: 1,
      yellowFrom: 1,
      yellowTo: 2,
      minorTicks: 2,
      max: 5
    },
    height: "200px"
  });
}

export function createReportDeliveryTimeItemsChartModel(data) {
  return (data || {}).isPeriod
    ? new ChartModel({
        data,
        titleX: "Orders",
        titleY: "Date",
        height: "300px",
        options: { 
          title: "",
          chartArea: { left: 50 }, 
          colors: data.colors
        },
        typeModel: CHART_MODEL.reportDeliveryTimeItems,
        chartType: TYPE_CHART.barChartStacked
    })
    : new ChartModel({
        typeModel: CHART_MODEL.reportDeliveryTimeItems,
        chartType: TYPE_CHART.comboChart,
        title: "",
        data,
        titleX: "Orders",
        titleY: "Control center",
        height: "300px",
        options: { chartArea: { left: 50 }, colors: defaultControlCenterColors }
    });
}

export function createReportControlCenterAverageDeliveryTimeChartModel(data) {
  return (data || {}).isPeriod
    ? new ChartModel({
      data,
      titleX: "Orders",
      titleY: "Control center",
      height: "300px",
      options: { 
        title: "",
        chartArea: { left: 50 }, 
        colors: data.colors
      },
      typeModel: CHART_MODEL.reportDeliveryTimeItems,
      chartType: TYPE_CHART.barChartStacked
    })
    : new ChartModel({
      typeModel: CHART_MODEL.reportControlCenterAverageDeliveryTime,
      chartType: TYPE_CHART.comboChart,
      title: "",
      data,
      titleX: "Orders",
      titleY: "Control center",
      height: "300px",
      options: {
        chartArea: { left: 70 },
        colors: defaultControlCenterColors
      }
    });
}

export function createReportAverageDeliveryTimeItemsChartModel(data) {
  return new ChartModel({
    typeModel: CHART_MODEL.reportAverageDeliveryTimeItems,
    chartType: TYPE_CHART.areaChart,
    title: "",
    data: data,
    titleX: "",
    titleY: "",
    height: "300px"
  });
}

export function createReportAverageDeliveryTimeChartModel(data) {
  return new ChartModel({
    typeModel: CHART_MODEL.reportAverageDeliveryTime,
    chartType: TYPE_CHART.areaChart,
    title: "",
    data: data,
    titleX: "",
    titleY: "",
    height: "300px"
  });
}

export function createReportDayAverageDeliveryTimeChartModel(data) {
  return new ChartModel({
    typeModel: CHART_MODEL.reportDayAverageDeliveryTime,
    chartType: TYPE_CHART.comboChart,
    title: "",
    data: data,
    titleX: "Orders",
    titleY: "Control center",
    height: "200px",
    options: {
      chartArea: { left: 25 },
      colors: defaultControlCenterColors
    }
  });
}

export function createControlCentersAverageDeliveryTimeChartModel(data) {
    return new ChartModel({
        typeModel: CHART_MODEL.controlCentersAverageDeliveryTime,
        chartType: TYPE_CHART.columnChart,
        data: data,
        height: "200px",
        options: {
            colors: defaultControlCenterColors,
        }
    })
}

export function createSalesChart(data) {
    return new ChartModel({
        typeModel: CHART_MODEL.sales,
        chartType: TYPE_CHART.line,
        data: data,
        height: "400px",
        options: {
            colors: defaultControlCenterColors,
        }
    })
}

export function createDeepLinkStatsChartModel(data) {
    return new ChartModel({
        typeModel: CHART_MODEL.deepLinkStats,
        chartType: TYPE_CHART.columnChart,
        data: data,
        height: "200px",
        options: {
            colors: defaultControlCenterColors,
        }
    })
}