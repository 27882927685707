import React, {useCallback, useEffect, useState} from 'react';
import './index.scss';
import classNames from 'classnames';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getRestaurant} from "../../../store/selectors";
import {getReshipmentTypes, WAITER_TYPES} from "../../../utils/enums";
import Dropdown from "../Dropdown";
import Waiter from "../Waiter/Waiter";
import LayoutHeaderControls from "../LayoutHeaderControls/LayoutHeaderControls";
import {CreateOrderMenu} from "../CreateOrderMenu/CreateOrderMenu";
import { ListSelectedProducts } from '../CreateOrderMenu/ListSelectedProducts.jsx';

const AddDish = ({fromEditMode, value, defOrder, quantityFromZero, withReshipments, headerLabelEditing, onClose, onSave}) => {
    const {t} = useTranslation();
    const restaurant = useSelector(getRestaurant);

    const [showRestaurantMenu, setShowRestaurantMenu] = useState(!fromEditMode);
    const [products, setProducts] = useState([]);
    const [reshipmentType, setReshipmentType] = useState(getReshipmentTypes()[0]);
    const [notes, setNotes] = useState("");

    useEffect(() => {
        if(defOrder && defOrder.products) {
            setProducts(() => defOrder.products.map(formatedProduct));
        }

        // eslint-disable-next-line
    }, []);

    const handleOnSaveData = () => {
        const newOrder = {
            order: defOrder,
            products: products.map(p => ({...getProduct(p), ...p.product})),
            notes: notes,
            reshipment_type: reshipmentType.key,
        };
        onSave && onSave(newOrder);
    }

    const formatedProduct = (product) => {
        return {
            full_price: product.full_price,
            quantity: product.quantity,
            product: product,
        }
    };

    const updateProductList = useCallback(newList => {
        setProducts(newList);
    }, [])

    const getProduct = prod => {
        const allProducts = restaurant && restaurant.businessMenu && restaurant.businessMenu.categories
          .flatMap(allCategory => allCategory)
          .flatMap(cat => cat.products);

        if(!allProducts) {
            return prod;
        }

        const found = allProducts.find(el => el.id === prod.product.id);
        return {
            ...found,
            quantity: prod.product.quantity,
            comment: prod.product.comment,
            name_on_box: prod.product.name_on_box,
            full_price: prod.full_price
        };
    }

    const handleOnAddProduct = (prod, index) => {
        if(prod[0].product) {
            const data = {
                ...prod[0],
                ...prod[0].product,
                full_price: prod[0].full_price,
            }
            setProducts(products => ([...products, data]));
        }
    }

    const handleOnCloseMenu = () => {
        setShowRestaurantMenu(false);
    }

    return (
        <div className={classNames("addDish")}>
            {headerLabelEditing && (<div className={"addDishHeader"}>{headerLabelEditing}</div>)}
            {!showRestaurantMenu && (
                <div className={"controls"}>
                    {withReshipments && (
                        <div className={"controlItem"}>
                            <span>{t("admin-orders.RESHIPMENT_TYPE_REASON")}</span>
                            <Dropdown
                                values={getReshipmentTypes()}
                                value={reshipmentType.value}
                                onSelect={(item) => setReshipmentType(item)}
                            />
                        </div>
                    )}

                    <div className={"controlItem"}>
                        <span>{t("admin-orders.REFUNDS_INFO_NOTES_LABEL")}</span>
                        <textarea value={notes} onChange={({target}) => setNotes(target.value)}></textarea>
                    </div>
                </div>
            )}

            {!showRestaurantMenu && products && (
                <ListSelectedProducts
                  getProduct={getProduct}
                  products={products}
                  currency={value.currency}
                  businessId={defOrder.business_id}
                  updateProductList={updateProductList}
                />
            )}

            {!showRestaurantMenu && (
                <Waiter
                    type={WAITER_TYPES.span}
                    spanText={t("basic.ADD_BUTTON")}
                    handleOnClick={() => setShowRestaurantMenu(true)}
                    useClass={"btn confirm addButton"}
                />
            )}

            {showRestaurantMenu && (
                <CreateOrderMenu
                    currency={defOrder.currency}
                    cart={[]}
                    getProduct={getProduct}
                    onAddProduct={handleOnAddProduct}
                    businessId={defOrder.businessId}
                />
            )}

            {showRestaurantMenu && (
                <div className={"orderingLayoutMenu"}>
                    <div className={"back"}>
                        <Waiter
                            type={WAITER_TYPES.span}
                            spanText={t("basic.BACK_BUTTON")}
                            handleOnClick={handleOnCloseMenu}
                            useClass={"btn confirm backButton"}
                        />
                    </div>
                </div>
            )}

            {!showRestaurantMenu && (
                <div className={"orderingLayoutMain"}>
                    <LayoutHeaderControls onSave={handleOnSaveData} onClose={onClose} />
                </div>
            )}
        </div>
    )
}


export default AddDish;
