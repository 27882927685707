import React, {useCallback, useEffect, useState} from "react";
import "./style.scss";
import classnames from "classnames";
import {useSelector} from "react-redux";
import {getRestaurant} from "../../../store/selectors";
import {UserItem} from "./UserItem";
import IconButton from "../IconButton";
import {useTranslation} from "react-i18next";
import {getUsersFilteredByDepotId} from "../../../services/filter";

const TAB_TYPES = {
  courier: "courier",
  business: "business",
  consumers: "consumers",
};

export const SelectorUsersForChat = ({
    disableCourier,
    disableBusiness,
    disableConsumers,
    selectedUsersList,
    canChooseOneUser,
    onChange,
}) => {
    const {t} = useTranslation();
    const {depots} = useSelector(getRestaurant);
    const couriers = useSelector(state => getUsersFilteredByDepotId(state.userModel.data));

    const [search, setSearch] = useState("");
    const [selectedTab, setSelectedTab] = useState(TAB_TYPES.courier);
    const [selectedUsers, setSelectedUsers] = useState(selectedUsersList || []);

    useEffect(() => {
        if(disableCourier) {
            setSelectedTab(TAB_TYPES.business);
        } else if(disableBusiness) {
            setSelectedTab(TAB_TYPES.consumers);
        } else if (disableConsumers) {
            setSelectedTab(TAB_TYPES.courier);
        }
    }, [disableConsumers, disableCourier, disableBusiness])

    const saveSelectedUser = (user, saveAs, saveFrom) => {
        const selectedUser = { [saveAs]: user[saveFrom], name: user.name || user.firstName || user.userName || "" };
        if(canChooseOneUser) {
            setSelectedUsers([selectedUser]);
        } else {
            const find = selectedUsers.find(user => user[saveAs]);
            if(!!find) {
                setSelectedUsers(selectedUsers.map(el => el[saveAs] ? selectedUser : el))
            } else {
                setSelectedUsers([...selectedUsers, selectedUser]);
            }
        }
    }
    const updateUsers = useCallback(() => {
        onChange(selectedUsers);
    }, [onChange, selectedUsers]);
    useEffect(() => {
        updateUsers();
    }, [selectedUsers, updateUsers]);


    const isSelectedUser = (user, checkFrom, idKey = "id") => {
        return selectedUsers.some(u => u[checkFrom] === user[idKey]);
    }

    const removeUser = (user) => {
        let key = "depotId";
        if(user.courierId) {
            key = "courierId";
        }
        setSelectedUsers(selectedUsers.filter(u => u[key] !== (user.courierId || user.depotId)));
    }

    const filterBySearch = (user) => {
        return (user.name || user.firstName || user.userName || "")?.toLowerCase()?.includes(search?.toLowerCase().trim());
    }

    return (
        <div className={"selectorUsersForChat"}>
            {!!selectedUsers.length && (
                <div className={"selectedUsers"}>
                    {selectedUsers.map(user => (
                        <div className={"selectedUserItem"} key={`saved-users-${user.id}`}>
                            <IconButton isIcon iconName={"iconPlus"} onClick={() => removeUser(user)}/>
                            {user.name}
                        </div>
                    ))}
                </div>
            )}

            <div className={"tabsRole"}>
                <div className={classnames("tabItem",
                    {disable: disableCourier, active: selectedTab === TAB_TYPES.courier}
                )}
                onClick={() => !disableCourier && setSelectedTab(TAB_TYPES.courier)}
                >{t("support-chat.chatSource.COURIER")}</div>
                <div className={classnames("tabItem",
                    {disable: disableBusiness, active: selectedTab === TAB_TYPES.business}
                )}
                onClick={() => !disableBusiness && setSelectedTab(TAB_TYPES.business)}
                >{t("support-chat.chatSource.BUSINESS")}</div>
                <div className={classnames("tabItem",
                    {disable: disableConsumers, active: selectedTab === TAB_TYPES.consumers}
                )}
                onClick={() => !disableConsumers && setSelectedTab(TAB_TYPES.consumers)}
                >{t("support-chat.chatSource.CONSUMER")}</div>
            </div>

            <input
                placeholder={"Search"}
                type="text"
                className={"search"}
                value={search}
                onChange={({target}) => setSearch(target.value)}
            />

            <div className={"itemsContainer"}>
                {selectedTab === TAB_TYPES.business && (
                    depots && !!depots.filter(filterBySearch).length ? depots.filter(filterBySearch).map(depot =>
                        <UserItem
                            key={`depot-list-${depot.id}`}
                            name={depot.name}
                            avatar={depot.avatarUrl}
                            hasButton={!isSelectedUser(depot, "depotId")}
                            onClick={() => saveSelectedUser(depot, "depotId", "id")}
                        />
                    ) : <div className={"emptyList"}>{t("support-chat.LIST_IS_EMPTY")}</div>
                )}

                {selectedTab === TAB_TYPES.consumers && (
                    <div className={"emptyList"}>{t("support-chat.LIST_IS_EMPTY")}</div>
                )}

                {selectedTab === TAB_TYPES.courier && (
                    couriers && couriers.filter(filterBySearch).length ? couriers.filter(filterBySearch).map(courier => (
                            <UserItem
                                key={`courier-list-${courier.userId}`}
                                name={courier.firstName}
                                avatar={courier.avatarUrl}
                                hasButton={!isSelectedUser(courier, "courierId", "userId")}
                                onClick={() => saveSelectedUser(courier, "courierId", "userId")}
                            />
                        ))
                        : <div className={"emptyList"}>{t("support-chat.LIST_IS_EMPTY")}</div>
                )}
            </div>
        </div>
    )
}