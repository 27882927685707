import MenuModel from "./menuModel.js";
import {TYPE_SECTION_MODEL} from "../utils/enums.js";

export function createMenuIntegrationsSettings() {
    return [
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.providers,
            title: "settings.courierSettings.LABEL_PROVIDERS",
            active: location => getIsActive(location, TYPE_SECTION_MODEL.providers)
        }),
        new MenuModel({
            pathname: TYPE_SECTION_MODEL.telegramSettings,
            title: "settings.telegramSettings.LABEL",
            active: location => getIsActive(location, TYPE_SECTION_MODEL.telegramSettings)
        }),
    ]
}

function getIsActive(location, type) {
    const splitCurrentLocation = location.pathname.split('/');
    const splitType = type.split('/');
    return (
        splitCurrentLocation[1] === splitType[1] &&
        splitCurrentLocation[2] === splitType[2] &&
        splitCurrentLocation[3] === splitType[3]
    );
}
