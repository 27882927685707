import React from "react";
import { withTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import classNames from "classnames";
import "./index.scss";

import { getReviewsReport } from "../../../api/order-requests";
import { getErrorMessage, requestResultIsOk } from "../../../utils/request-util";
import { fileFromRequestResult } from "../../../utils/objects-util";

import IconButton from "../IconButton";
import CustomerReviewSearchPeriod from "../CustomerReviewSearchPeriod";

const CustomerReviewsHeader = ({ t, filter, onSetFilter }) => {
    const handleOnReportSave = () => {
        return new Promise((resolve, reject)=> {
            getReviewsReport(filter).then(requestResult=> {
                if(requestResultIsOk(requestResult)) {
                    const dataBlob = fileFromRequestResult(requestResult);
                    if (dataBlob && dataBlob.file && dataBlob.fileName) {
                        saveAs(new Blob([dataBlob.file], { type: dataBlob.contentType }), dataBlob.fileName);
                        resolve();
                    } else {
                        reject("Can't convert file"); 
                    }
                } else {
                    reject(getErrorMessage(requestResult)); 
                }
            }, err=> reject(err));
        });
    }

    return (
        <div className={classNames("customerReviewsHeader")}>
            <div className={classNames("title")}>
                {t("reviews.LABEL")}
            </div>
            <div className={classNames("controls")}>
                <div className={classNames("control")}>
                    <CustomerReviewSearchPeriod filter={filter} onSetFilter={onSetFilter} />
                </div>
                <div className={classNames("control")}>
                    <div className={classNames("reportButton")}>
                        <IconButton isIcon={true} iconName="excelIcon"
                            tooltipText={t("pending-orders.PENDING_ORDERS_REPORT_SAVE_TOOLTIP")}
                            onClick={handleOnReportSave}
                        /> 
                    </div> 
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(CustomerReviewsHeader);