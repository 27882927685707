import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import {WAITER_TYPES} from "../../../utils/enums";

import Waiter from "../Waiter/Waiter";
import ConfirmDialog from "../../containers/ConfirmDialogContainer";

const LayoutHeaderControls = ({ t, withConfirmDialog, isHideSave, disabledSaveButton, closeIcon, cancelSpanText, onClose, onSave }) => {
    const [isShowConfirm, setShowConfirm] = useState(false);

    const handleOnCloseConfirm = () => {
        return new Promise(resolve => {
            setShowConfirm(false);
            resolve();
        })
    }

    const handleOnOpenConfirm = () => {
        return new Promise(resolve => {
            setShowConfirm(true);
            resolve();
        })
    }

    return (
        <div className={classNames("layoutHeaderControls")}>
            {withConfirmDialog ? (
                <>
                    {!isHideSave && (
                        <Waiter type={WAITER_TYPES.button}
                                useClass="btn confirm"
                                stopPropagation={true}
                                spanText={t("basic.SAVE_BUTTON")}
                                handleOnClick={handleOnOpenConfirm}
                                disabled={disabledSaveButton}
                        />
                    )}
                    {isShowConfirm && (
                        <ConfirmDialog
                            useWrapperClass={classNames("confirmDialog")}
                            useBtnConfirmClass={classNames("btn confirm btnConfirmDialog")}
                            handleOnConfirm={onSave}
                            handleOnCancel={handleOnCloseConfirm}
                        />
                    )}
                </>
            ) : (
                !isHideSave && (
                    <Waiter type={WAITER_TYPES.button}
                            useClass="btn confirm"
                            stopPropagation={true}
                            spanText={t("basic.SAVE_BUTTON")}
                            handleOnClick={onSave}
                            disabled={disabledSaveButton}
                    />
                )
            )}
            {closeIcon ? <Waiter type={WAITER_TYPES.buttonSvg}
                                 useClass={closeIcon}
                                 stopPropagation={true}
                                 spanText={cancelSpanText ? cancelSpanText : t("basic.CANCEL_BUTTON")}
                                 handleOnClick={onClose}
            /> : <Waiter type={WAITER_TYPES.button}
                                   useClass="btn denied"
                                   stopPropagation={true}
                                   spanText={t("basic.CANCEL_BUTTON")}
                                   handleOnClick={onClose}
            />}

        </div>
    );
}

export default withTranslation()(LayoutHeaderControls);
