import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import { connect } from 'react-redux';
import IconButton from '../IconButton/IconButton.jsx';
import FormInfoDataItem from '../FormInfoDataItem/index.js';
import { getFormattedDate, DATE_TIME_FORMAT } from '../../../utils/convertTime.js';
import { getCurrencyIcons } from '../../../utils/enums.js';
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog.jsx";
import {fixedNumber} from "../../../utils/math-util.js";

const BonusCardItem = ({ t, bonusCardsItem, onDelete }) => {
  const { name, bonus, expiration, checkoutLimitPercent, currency } = bonusCardsItem;
  const currencyValue = currency ? currency + ' ' + getCurrencyIcons(currency) : 0;
  const [isShowConfirmDelete, setShowConfirmDelete] = useState(false);

  return (
    <div className={classNames('bonusCardItem')}>
      <div className={classNames('bonusCardItemHeader')}>
        <FormInfoDataItem title={t('customers.BONUS_NAME')} value={name} isInline={true} />
          <div className={'deleteVoucher'} onClick={() => setShowConfirmDelete(true)}>{t('basic.DELETE_BUTTON')}</div>
          {isShowConfirmDelete && (
              <ConfirmDialog
                  labelText={t("basic.LABEL_CONFIRM_MODAL")}
                  handleOnConfirm={onDelete}
                  handleOnCancel={() => setShowConfirmDelete(false)}
              />
          )}
      </div>
      <div className={classNames('bonusCardItemContent')}>
        <FormInfoDataItem title={t('customers.BONUS')} value={bonus || 0} />
        <FormInfoDataItem title={t('customers.BONUS_PERCENT')} value={fixedNumber(checkoutLimitPercent * 100, 2) + '%'} />
        <FormInfoDataItem title={t('customers.BONUS_CURRENCY')} value={currencyValue} />
        <FormInfoDataItem isNeverRtl title={t('customers.BONUS_EXPIRATION_DATE')} value={getFormattedDate(expiration, DATE_TIME_FORMAT) || 0} />
      </div>
    </div>
  );
};

const CustomerBonusCardsList = ({ t, onClose, items, customerId, onDelete }) => {
  const [bonusCardsList, setBonusCardsList] = useState([]);

  useEffect(() => {
    if (!customerId || !items.length) return;

    const user = items.find(user => user.consumerId === customerId);
    setBonusCardsList(user?.wallet?.bonusCards || []);
  }, [items, customerId]);

  const handleOnClose = () =>
    new Promise(resolve => {
      resolve();
      onClose && onClose();
    });

  return (
    <>
      <div className={classNames('closIconWrapper')}>
        <IconButton iconName={'crossClose'} isIcon onClick={handleOnClose} />
      </div>
      <div className={classNames('customerBonusCardsList')}>
        {!!bonusCardsList?.length &&
          bonusCardsList.map(card => <BonusCardItem key={`${card?.id} + ${card?.name}`} t={t} bonusCardsItem={card} onDelete={() => onDelete(card.id)} />)}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  items: state.customer.customers || [],
});

export default connect(mapStateToProps)(withTranslation()(CustomerBonusCardsList));
