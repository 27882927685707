import classNames from 'classnames';
import { getFullName, isRTL } from '../../../services/userModel.js';
import { getConvertedCloudUrl } from '../../../utils/image-util.js';
import defaultAvatar from '../../../assets/images/avatar.png';
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

const PopupManagerItem = ({ user, users, controlCenters, onUserClick }) => {
  if (!user) return null;
  const userFromState = users.find(item => item.userId === user.userId);
  const courier = userFromState || user;

  const userControlCenter = controlCenters.find(cc => cc?.userModel === courier?.userId);

  return (
    <div
      className={classNames('managerItem', { rtl: isRTL(), isDisabled: !!userControlCenter })}
      onClick={() => !userControlCenter && onUserClick(courier)}
    >
      <div className='leftSide'>
        <img src={getConvertedCloudUrl(courier.avatarUrl) || defaultAvatar} alt={`img-${courier.userId}`} />
      </div>
      <div className='centerSide overflowHiddenText'>
        <div style={{ width: '88%' }}>
          <div className='title overflowText'>{getFullName(courier)}</div>
        </div>
      </div>
      <div className='rightSide'>
        {userControlCenter && <div className='title overflowText'>{userControlCenter?.name}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    users: state.userModel.data || [],
    controlCenters: state.restaurant.data.controlCenters || [],
  };
};

export default connect(mapStateToProps)(withTranslation()(PopupManagerItem));
