import React, {useCallback, useState} from "react";
import "./style.scss";
import {useSelector} from "react-redux";
import {ProductCard} from "./ProductCard";
import SearchBox from "../SearchBox";
import {CounterButton} from "../CounterButton/CounterButton";
import {useTranslation} from "react-i18next";
import {getProductPrice} from "../../../api/order-requests";
import {getCurrencyIcons} from "../../../utils/enums";
import RestaurantAddDish from "./RestaurantAddDish";

export const CreateOrderMenu = ({onAddProduct, cart, getProduct, currency}) => {
    const {t} = useTranslation();
    const {businessMenu} = useSelector(state => state.restaurant.data);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [constructedProduct, setConstructedProduct] = useState(null);
    const [products, setProducts] = useState(cart || []);
    const [search, setSearch] = useState("");
    const [disabledAdd, setDisabledAdd] = useState(false);

    const [index, setIndex] = useState(null);
    const [isEdit, setIsEdit] = useState(null);

    const selectProduct = (product, isEdit, index) => {
        setIndex(index);
        setIsEdit(isEdit);
        setSelectedProduct(product);
    }

    const addToCart = useCallback(() => {
        if(isEdit) {
            let newProducts = [];
            if(constructedProduct.product.quantity) {
                newProducts = products.map((el, i) => i === index ? constructedProduct : el);
            } else {
                newProducts = products.filter((el, i) => i !== index);
            }
            setProducts(newProducts);
            onAddProduct(newProducts);
            setIsEdit(null);
            setIndex(null);
            setSelectedProduct(null);
        } else {
            const newProducts = [...products, constructedProduct];
            onAddProduct(newProducts);
            setProducts(newProducts);
            setSelectedProduct(null);
        }

    }, [products, constructedProduct, index, isEdit, onAddProduct]);

    const saveProduct = useCallback((product) => {
        setConstructedProduct(product)
    }, [])

    const onClose = () => {
        setIsEdit(null);
        setIndex(null);
        setSelectedProduct(null);
    }

    const onClickAddFromCart = async (index, quantity) => {
        const newProduct = products.find((el, i) => i === index);
        if(newProduct) {
            newProduct.product.quantity = quantity;
            setDisabledAdd(true);
            const {data} = await getProductPrice(newProduct);
            newProduct.product = data.product;
            newProduct.full_price = data.full_price;
            setDisabledAdd(false);
        }
        const newProducts = products.map((el, i) => i === index ? {...el, product: {...el.product, quantity}} : el);
        setProducts(newProducts);
        onAddProduct(newProducts);
    }

    const onClickRemoveFromCart = async (index, quantity) => {
        let newProducts;
        if(quantity === 0) {
            newProducts = products.filter((p,i) => i !== index);
        } else {
            const newProduct = products.find((el, i) => i === index);
            if(newProduct) {
                newProduct.product.quantity = quantity;
                setDisabledAdd(true);
                const {data} = await getProductPrice(newProduct);
                newProduct.product = data.product;
                newProduct.full_price = data.full_price;
                setDisabledAdd(false);
            }
            newProducts = products.map((el, i) => i === index ? {...el, product: {...el.product, quantity}} : el);
        }

        setProducts(newProducts);
        onAddProduct(newProducts);
    }

    const getCartProducts = (product, i) => {
        const fullProduct = getProduct(product);
        const currProduct = (product || {}).product || {};

        const onAdd = () => {
            onClickAddFromCart(i, fullProduct.quantity +1);
        }
        const onRemove = () => {
            onClickRemoveFromCart(i, fullProduct.quantity -1);
        }

        return (
            <div className={"cartProduct"} key={`${currProduct.id || "cartProduct"}` + i}>
                <div className="head">
                    <div onClick={() => selectProduct(fullProduct, true, i)}
                         className="title"
                    >
                        {fullProduct.name}
                    </div>
                    <CounterButton
                        disabled={disabledAdd}
                        onClickAdd={onAdd}
                        onClickRemove={onRemove}
                        quantity={fullProduct.quantity}
                    />
                    <div className={"price"}>{getCurrencyIcons(businessMenu.currency || currency)}{fullProduct.full_price}</div>
                </div>
                <div className="options">
                    {(currProduct.options || []).map(option => (
                        <span key={`option-${option.name}`}>
                            {option.name}:&nbsp;{(option.suboptions || []).filter(s => s.quantity).map(s => s.name + " x" + s.quantity + ", ")}
                        </span>
                    ))}
                </div>
            </div>
        )
    }

    const getTotalPrice = () => {
        return getCurrencyIcons(businessMenu.currency || currency) + "" + products.reduce((acc, cv) => acc + (cv.full_price || 0), 0);
    }

    const strollToCategory = (name) => {
        const category = document.getElementById(name);
        const container = document.getElementById("products-container");
        container.scrollBy({
            top: category.getBoundingClientRect().top - 200,
            left: 0,
            behavior: "smooth"
        });

    }

    return (
        <div className={"menuSection"}>
            <SearchBox placeholder={t("create-order.order.PLACEHOLDER")} useValue={search} onChange={setSearch} debounce={500}/>
            <div className={"restaurant-menu"}>

                <div className={"menu"}>
                    <div className={"categories"}>
                        {businessMenu && businessMenu.categories.map(c => (
                            <div key={c.name} className={"category"} onClick={() => strollToCategory(c.name)}>{c.name}</div>
                        ))}
                    </div>
                    <div className={"products"} id={"products-container"}>
                        {businessMenu && businessMenu.categories
                            .map(c => (
                                <div key={`category-${c.name}`}>
                                    <div className={"catName"} id={c.name}>{c.name}</div>
                                    {c.products
                                        .filter(p => p.name?.toLowerCase()?.includes(search.trim()?.toLowerCase()))
                                        .map(p => (
                                            <ProductCard key={p.id} product={p} onClick={() => selectProduct(p, false)} currency={businessMenu.currency || currency}/>
                                        ))}
                                </div>
                            ))
                        }
                    </div>

                </div>
                <div className={"cart"}>
                    <div className={"summary"}>{t("create-order.order.ORDER_SUMMARY")}</div>
                    {(products || []).map(getCartProducts)}
                    {products && !products.length && (
                        <div className={"emptyCart"}>{t("create-order.order.EMPTY_CART")}</div>
                    )}
                    {!!(products || []).length && (
                        <div className={"total"}>Total <div className="price">{getTotalPrice()}</div></div>
                    )}
                </div>
            </div>

            {!!selectedProduct && (
                <RestaurantAddDish
                    isOpen={!!selectedProduct}
                    onClose={onClose}
                    business_id={businessMenu.id}
                    product={selectedProduct}
                    productEdit={isEdit ? products[index].product : null}
                    editMode={isEdit}
                    currency={businessMenu.currency || currency}
                    handleOnAddToCart={addToCart}
                    emitProduct={saveProduct}
                />
            )}
        </div>
    )
}