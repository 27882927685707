import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import LayoutHeaderControls from '../LayoutHeaderControls';
import SectionInfoTitle from "../SectionInfoTitle/index.js";

const CustomersBlockUser = ({ t, onClose, onSave }) => {
    const [data, setData] = useState({
        notes: '',
    });

    const handleOnSave = () => {
        return new Promise(resolve => {
            resolve();
            onSave && onSave(data.notes);
        });
    };

    const handleOnClose = () =>
        new Promise(resolve => {
            resolve();
            onClose && onClose();
        });

    const handleOnEdit = (prop, val) => {
        setData({ ...data, [prop]: val });
    };

    const isDisabledSave = () => {
        return (
            !data?.notes?.length
        )
    }

    return (
        <div className={classNames('customersBlockUser')}>
            <div className={classNames('formControls')}>
                <SectionInfoTitle sectionModel={{label: "admin-orders.BLOCK_CUSTOMER"}}/>
                <div className={classNames('formControl')}>
                    <div className={classNames('formControlItem')}>
                        <div className={classNames('title')}>{t('admin-orders.BLOCK_NOTES')}</div>
                        <form autoComplete='off' className={classNames('inputItem required')}>
                            <input
                                type={'text'}
                                required={true}
                                value={data?.notes || ''}
                                onChange={e => handleOnEdit('notes', e.target.value)}
                            />
                        </form>
                    </div>
                </div>
            </div>
            <LayoutHeaderControls
                disabledSaveButton={isDisabledSave()}
                onSave={handleOnSave}
                onClose={handleOnClose}
            />
        </div>
    );
};

export default withTranslation()(CustomersBlockUser);
