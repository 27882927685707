import React, {useState} from "react";
import "./style.scss";
import CustomerSalesTopTenHeader from "../CustomerSalesTopTenHeader";
import {SALES_TYPES} from "../../../utils/enums";
import CustomerSalesTopTenList from "../CustomerSalesTopTenList";
import {sortByPropNumber} from "../../../services/filter";

const CustomerSalesTopTen = ({ statistics, menu }) => {
    const [activeTab, setActiveTab] = useState(SALES_TYPES.items);
    const [isDesc, setIsDesc] = useState(true);

    const handleOnSetTab = (tab) => {
        if(tab === activeTab) {
            setIsDesc(!isDesc);
        } else {
            setActiveTab(tab);
            setIsDesc(true);
        }
    }

    const getSortedData = () => {
        const filterProp = activeTab === SALES_TYPES.quantity
            ? "count"
            : activeTab === SALES_TYPES.income
                ? "total"
                : activeTab === SALES_TYPES.source
                    ? "order_source"
                    : null;
        return sortByPropNumber(statistics || [], filterProp, isDesc);
    }

    return (
        <div className={"customerSalesTopTen"}>
            <table>
                <CustomerSalesTopTenHeader activeTab={activeTab} setActiveTab={handleOnSetTab} isDesc={isDesc} />
                <CustomerSalesTopTenList statistics={getSortedData()} menu={menu} />
            </table>
        </div>
    )
};

export default CustomerSalesTopTen;