import React from "react";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import "./index.scss";

import {getCurrencyIcons, HALF_OPTION} from "../../../utils/enums";
import {isRTL} from "../../../services/userModel.js";

const OrderMenuOption = ({ t, key, option, order }) => {
    const suboptions = (option && option.suboptions) || [];
    const isExistHalfs = suboptions.find(f=> f.halfOption > 0);
    const suboptionsGroups = suboptions
        .reduce((acc, suboption)=> {
            const index = acc.map(m=> m.id).indexOf(suboption.halfOption);

            if(index !== -1) {
                acc[index].values.push(suboption);
            } else {
                acc.push({
                    id: suboption.halfOption,
                    values: [suboption]
                });
            }

            return acc;
        }, [])?.sort((a, b)=> a.id - b.id);

    return (
        <div key={key} className={classNames("orderMenuOption")}>
            <div className={classNames("title")}>{(option && option.name) || t("pending-orders.PENDING_ORDER_EMPTY_OPTION_LABEL")}:&nbsp;</div>
            {suboptionsGroups.map(group=> (
                <div key={`group-${group.id}`} className={classNames("group")}>
                    {isExistHalfs && (<span className={classNames("groupTitle")}>{HALF_OPTION[group.id]}:&nbsp;</span>)}
                    <div className={classNames("suboptions")}>
                        {group.values.map((suboption, index)=> (
                            <div key={`suboption-${index}`} className={classNames("suboption")}>
                                <span>&nbsp;{(suboption && suboption.name) || t("pending-orders.PENDING_ORDER_EMPTY_OPTION_LABEL")}</span>
                                <span className={classNames("currencyPending")}>
                                    {suboption.quantity > 1 && (<span className={classNames("quantity")}>&nbsp;x{suboption.quantity}</span>)}
									{suboption.full_price > 0 && <span className={classNames("currency")}>
                                        {isRTL() ? <span className={classNames('currencyRtl')}>
                                                <span>+{suboption.full_price}</span>
                                                <span>{(order && getCurrencyIcons(order.currency))}</span>
                                            </span> :
                                            <>&nbsp;+{suboption.full_price}{(order && getCurrencyIcons(order.currency))}</>}
                                    </span>}
                                </span>
                                <span className={classNames("demitt")}>, </span>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default withTranslation()(OrderMenuOption);
