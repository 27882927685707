import React, {useCallback, useEffect, useState} from "react";
import "./style.scss";
import CustomerSalesChartHeader from "../CustomerSalesChartHeader";
import CustomerSalesEvents from "../CustomerSalesEvents";
import {
    EVENT_OBJECT,
    getCurrencyIcons, orderChannelNames, PENDING_ORDER_TYPE,
    SALES_TYPES
} from "../../../utils/enums";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import ApexChart from "../ApexChart";
import {getApexCartDefaulOption, getApexPieChartDefaultOption} from "../../../services/chartModelFactory";
import {getOrderSource} from "../../../utils/order.js";
import classnames from "classnames";
import {fixedNumber} from "../../../utils/math-util.js";

const CHART_TYPE = {
    line: 'line',
    pie: 'pie'
}

const getDataByOrderSource = (data = []) => {
    const orderSources = data.reduce((acc, cv) => {
        const objBySource = acc.find(p => p.sourceId === cv.order_source_id && p.source === cv.order_source);
        if(objBySource) {
            objBySource.items = objBySource.items.concat(cv);
        } else {
            acc.push({
                sourceId: cv.order_source_id,
                source: cv.order_source,
                items: [cv],
            })
        }
        return acc;
    }, []);

    const arrayIds = Array.from(new Set(data.map(item => item.id)));

    if(!arrayIds) {
        return orderSources;
    }

    return orderSources.map(data => {
        // make every data.items same size
        const copyMap = {};
        data.items.forEach(item =>  {
            if(copyMap[item.id]) {
                // same values can be duplicated with same id, source and sourceId
                copyMap[item.id] = copyMap[item.id].concat(item);
            } else {
                copyMap[item.id] = [item];
            }
        })

        return {
            ...data,
            items: arrayIds.map(id => {
                if(copyMap[id]) {
                    return copyMap[id].reduce((acc, cv) => {
                        return {
                            id,
                            total: cv.total + acc.total,
                            count: cv.count + acc.count,
                        }
                    }, {total: 0, count: 0})
                }
                return { id, total: 0, count: 0 };
            })
        }
    });
}

const CustomerSalesChart = ({ filter, events, setIsOpenSalesFilter, onSetFilter, title, data, isLoading, filterQuantity }) => {
    const {t} = useTranslation();
    const [activeTab, setActiveTab] = useState(SALES_TYPES.quantity);
    const [chartType, setChartType] = useState(CHART_TYPE.line);
    const [eventData, setEventData] = useState({});
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [filtersForOrderChannel, setFiltersForOrderChannel] = useState([]);

    useEffect(() => {
        const currency = [];
        (data || []).forEach(item => {
            if(!currency.includes(item.currency)) {
                currency.push(item.currency);
            }
        });

        setCurrency(currency);
    }, [data]);

    const getObjectOfEvents = useCallback(() => {
        const obj = {};

        events && events.forEach(item => {
            if(item.objectType) {
                if(obj[item.objectType]) {
                    obj[item.objectType].push(item);
                } else {
                    obj[item.objectType] = [item];
                }
            }
        });

        return obj;
    }, [events]);

    useEffect(() => {
        setEventData(getObjectOfEvents);
    }, [events, getObjectOfEvents]);

    const getEvents = () => {
        const items = [];
        for(let event in eventData) {
            const isSelected = selectedEvents.includes(event);
            items.push({ name: EVENT_OBJECT[event], quantity: eventData[event].length, selected: isSelected, id: event });
        }
        return items;
    }

    const handleOnClickEvent = event => {
        const isEventSelected = selectedEvents.includes(event);
        if(isEventSelected) {
            setSelectedEvents(events => events.filter(ev => ev !== event));
        } else {
            setSelectedEvents(events => [...events, event]);
        }
    }

    const handleOnClickSeries = (data) => {
        setFiltersForOrderChannel(data);
    }

    const getChartDataOrderChannel = () => {
        const orderSourceMap = {};
        data
            .filter(item => filtersForOrderChannel.length ? filtersForOrderChannel.some(filter => filter.sourceId === item.order_source_id && filter.source === item.order_source) : true)
            .forEach(item => {
                if(orderSourceMap[item.order_channel]) {
                    orderSourceMap[item.order_channel].push(item);
                } else {
                    orderSourceMap[item.order_channel] = [item];
                }
            })
        const series = [];
        const labels = [];
        Object.entries(orderSourceMap).forEach(([key, value]) => {
            labels.push(orderChannelNames()[key]);
            series.push(value.reduce((acc, cv) => fixedNumber(acc + (activeTab === SALES_TYPES.quantity ? cv.count : cv.total), 2), 0));
        })
        return {
            options: {
                ...getApexPieChartDefaultOption(),
                chart: {
                    ...getApexPieChartDefaultOption().chart,
                    events: { },
                },
                labels,
            },
            series: series,
            type: 'pie'
        }
    }

    const getChartDataDeliveryType = () => {
        const orderSourceMap = {};
        const deliveryLabel = {
            [PENDING_ORDER_TYPE.any]: t("dashboard.orders.UNKNOWN_ORDERS_MODE_LABEL"),
            [PENDING_ORDER_TYPE.delivery]: t("dashboard.orders.DELIVERY_ORDERS_MODE_LABEL"),
            [PENDING_ORDER_TYPE.takeAway]: t("dashboard.orders.TAKEAWAY_ORDERS_MODE_LABEL"),
            [PENDING_ORDER_TYPE.dinein]: t("dashboard.orders.DINEIN_ORDERS_MODE_LABEL"),
            [PENDING_ORDER_TYPE.virtual]: t("dashboard.orders.VIRTUAL_VENUE_ORDERS_TYPE_LABEL"),
        }
        data
            .filter(item => filtersForOrderChannel.length ? filtersForOrderChannel.some(filter => filter.sourceId === item.order_source_id && filter.source === item.order_source) : true)
            .forEach(item => {
                if(orderSourceMap[item.delivery_type]) {
                    orderSourceMap[item.delivery_type].push(item);
                } else {
                    orderSourceMap[item.delivery_type] = [item];
                }
            })
        const series = [];
        const labels = [];
        Object.entries(orderSourceMap).forEach(([key, value]) => {
            labels.push(deliveryLabel[key]);
            series.push(value.reduce((acc, cv) => fixedNumber(acc + (activeTab === SALES_TYPES.quantity ? cv.count : cv.total), 2), 0));

        })
        return {
            options: {
                ...getApexPieChartDefaultOption(),
                chart: {
                    ...getApexPieChartDefaultOption().chart,
                    events: { },
                },
                labels,
            },
            series: series,
            type: 'pie'
        }
    }

    const getChartData = () => {
        if(CHART_TYPE.line === chartType) {
            const dataBySource = getDataByOrderSource(data);
            const sourceCarts = [];
            dataBySource.forEach(value => {
                sourceCarts.push({
                    name: getOrderSource(value.source, value.sourceId),
                    data: value.items.map(item => ({
                        x: item.id,
                        y: activeTab === SALES_TYPES.quantity ? item.count : item.total,
                        id: item.id,
                    })),
                    type: "line"
                });
            })

            const barChart = [];

            const series = [].concat(sourceCarts).concat(barChart);
            return {
                options: {
                    ...getApexCartDefaulOption(series.length),
                    chart: {
                        ...getApexCartDefaulOption(series.length).chart,
                        events: {},
                    },
                },
                series: series,
                type: 'line'
            }
        }
        const dataBySource = getDataByOrderSource(data);
        const series = dataBySource.map(value => {
           return value.items.reduce((acc, cv) => fixedNumber(acc + (activeTab === SALES_TYPES.quantity ? cv.count : cv.total), 2), 0);
        });
        const labels = dataBySource.map(value => getOrderSource(value.source, value.sourceId));
        return {
            options: {
                ...getApexPieChartDefaultOption(),
                chart: {
                    ...getApexPieChartDefaultOption().chart,
                    events: {
                        dataPointSelection: (a, b, opts) => {
                            const selected = opts.selectedDataPoints[0];
                            const values = selected.map(index => ({
                                sourceId: dataBySource[index].sourceId,
                                source: dataBySource[index].source
                            }));
                            handleOnClickSeries(values);
                        },
                    }
                },
                labels,
            },
            series: series,
            type: 'pie'
        }
    }

    const getTotalValue = (key) => {
        return fixedNumber((data || []).reduce((acc, cv) => acc + (cv[key] || 0), 0), 2);
    }

    return (
        <div className={"customerSalesChart"}>
            {isLoading && (
                <div className={"loader-background"}>
                    <div className={"loader"} />
                </div>
            )}

            <CustomerSalesChartHeader
                setFilterOpen={setIsOpenSalesFilter}
                currency={currency}
                filter={filter}
                onSetFilter={onSetFilter}
                title={title}
                filterQuantity={filterQuantity}
            />

            <div className={"totalData"}>
                <div className={"totalItem"}>
                    <div className={"label"}>{t("sales.QUANTITY")}</div>
                    <div className={"total"}>{getTotalValue("count")}</div>
                </div>

                <div className={"totalItem"}>
                    <div className={"label"}>{t("sales.INCOME")}</div>
                    <div className={"total"}>{getTotalValue("total")} {getCurrencyIcons(currency[0] || "ILS")}</div>
                </div>

                <div className={"totalItem"}>
                    <div className={"label"}>{t("sales.TOTAL_CARD")}</div>
                    <div className={"total"}>{getTotalValue("total_card")} {getCurrencyIcons(currency[0] || "ILS")}</div>
                </div>

                <div className={"totalItem"}>
                    <div className={"label"}>{t("sales.TOTAL_CASH")}</div>
                    <div className={"total"}>{getTotalValue("total_cash")} {getCurrencyIcons(currency[0] || "ILS")}</div>
                </div>

                <div className={"totalItem"}>
                    <div className={"label"}>{t("sales.TOTAL_PAYMENT_BALANCE")}</div>
                    <div className={"total"}>{getTotalValue("total_payment_balance")} {getCurrencyIcons(currency[0] || "ILS")}</div>
                </div>
            </div>

            <div className={"headerTabs"}>
                <div className={"tabsContainer"}>
                    <div onClick={() => setActiveTab(SALES_TYPES.quantity)} className={classNames("tabItem", { active: activeTab === SALES_TYPES.quantity })}>{t("sales.QUANTITY")}</div>
                    <div onClick={() => setActiveTab(SALES_TYPES.income)} className={classNames("tabItem", { active: activeTab === SALES_TYPES.income })}>{t("sales.INCOME")}</div>
                </div>
                <div className={"tabsContainer"}>
                    <span className={"diagram"}>{t("sales.DIAGRAM")}:</span>
                    <div onClick={() => setChartType(CHART_TYPE.line)} className={classNames("tabItem", { active: chartType === CHART_TYPE.line })}>{t("sales.LINE_CHART")}</div>
                    <div onClick={() => setChartType(CHART_TYPE.pie)} className={classNames("tabItem", { active: chartType === CHART_TYPE.pie })}>{t("sales.PIE_CHART")}</div>
                </div>
            </div>

            <div className={classnames('chartBlock', {double: chartType === CHART_TYPE.pie})}>
                {chartType === CHART_TYPE.line && (
                    <ApexChart data={getChartData()} />
                )}

                {chartType === CHART_TYPE.pie && (
                    <>
                        <ApexChart data={getChartData()} />
                        <ApexChart data={getChartDataOrderChannel()} />
                    </>
                )}
            </div>
            {chartType === CHART_TYPE.pie && (
                <div className={'chartBlock'}>
                    <ApexChart data={getChartDataDeliveryType()} />
                </div>
            )}

            {!!getEvents().length && (
                <CustomerSalesEvents events={getEvents()} onClickEvent={handleOnClickEvent}/>
            )}
        </div>
    );
}

export default CustomerSalesChart;