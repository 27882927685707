import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { createAction } from "../../../utils/sagaHelper";
import { AUTHENTICATION } from "../../../store/reducers/authentication/authentication-actions";

import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";

const mapDispatchToProps = dispatch => {
  return {
    actionAuthentication: data => dispatch(createAction(AUTHENTICATION, data))
  };
};

export default connect(null, mapDispatchToProps)(withTranslation()(ConfirmDialog));
