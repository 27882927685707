import React from 'react';
import classNames from 'classnames';
import './index.scss';

import { isEmpty } from '../../../utils/objects-util';

import IconButton from '../IconButton';
import InlineTooltipText from '../InlineTooltipText';

const FormInfoDataItem = ({ title, value, icon, isInline = false, onClick, isNeverRtl = false, className, hideTooltip }) => {
  return (
    <div className={classNames('formInfoDataItem', className, { isInline: isInline })}>
      <div className={classNames('formInfoDataItemTitle')}>
        {`${title}${isInline ? ':' : ''}`}&nbsp;&nbsp;
        {icon && <IconButton isIcon={true} iconName={icon} />}
      </div>
      <div className={classNames('formInfoDataItemValue', { isClickable: !!onClick })}>
        {hideTooltip && value}
        {!hideTooltip && <InlineTooltipText isNeverRtl={isNeverRtl} onClick={onClick} value={isEmpty(value) ? '-' : value} />}
      </div>
    </div>
  );
};

export default FormInfoDataItem;
