import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import classNames from "classnames";
import "./index.scss";

import { getDepotName } from "../../../services/restaurant";
import { DATE_TIME_FORMAT_DAYTIME, getFormattedDate } from "../../../utils/convertTime";
import {CUSTOMER_SERVICE_MODEL, getCurrencyIcons, PAY_METHOD} from "../../../utils/enums";

import FormInfoDataItem from "../FormInfoDataItem";
import OrderTypeInfo from "../OrderTypeInfo";
import CustomerOrderStatus from "../CustomerOrderStatus";
import RateItem from "../RateItem";

const CustomerDataItem = ({ value, hideRestaurantName }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const depotName = getDepotName(value);
    const paymentCard = (value.payments || []).find(f=> f.paymethod_id === PAY_METHOD.creditCard) || {};
    const paymentCash = (value.payments || []).find(f=> f.paymethod_id === PAY_METHOD.cash) || {};
    const currency = getCurrencyIcons(value.currency);

    const handleOnOrderClick = () => {
        if(value.id) history.push(`${CUSTOMER_SERVICE_MODEL.order}?searchStr=${value.id}`);
    }

    return (
        <div className={classNames("customerDataItem")}>
            <div className={classNames("dataItem", "info")}>
                <div className={classNames("link")} onClick={handleOnOrderClick}>#{value.sequence_id}</div>
                <div className={classNames("dataItemInfo")}>
                    <OrderTypeInfo type={value.delivery_type} />
                    <CustomerOrderStatus value={value.status} />
                </div>
            </div>
            <div className={classNames("dataItem")}>
                <FormInfoDataItem title={t("pending-orders.PENDING_ORDER_INFO_CREATION_DATETIME")}
                    value={getFormattedDate(value.creation_datetime, DATE_TIME_FORMAT_DAYTIME)}
                />
                {!hideRestaurantName && (
                    <FormInfoDataItem title={t("customers.DELIVERED_FROM_LABEL")} value={depotName} />
                )}
                <FormInfoDataItem title={t("customers.PAYMENT_CASH")} value={`${paymentCash.price || 0} ${currency}`} icon="cash" />
                <FormInfoDataItem title={t("customers.PAYMENT_CARD")} value={`${paymentCard.price || 0} ${currency}`} icon="creditCard" />
                <FormInfoDataItem title={t("customers.TOTAL_LABEL")} value={`${value.price || 0} ${currency}`} />
            </div>
            <div className={classNames("dataItem", "rating")}>
                {!!value.rating && (
                    <div className={classNames("titleItem", "rate")}>
                        <span className={classNames("title")}>{t("reviews.REVIEW_FILTER_RATE_PRODUCT")}</span>
                        <RateItem rate={value.rating} value={value.rating} isInfo={true} />
                    </div>
                )}
                {!!value.delivery_rating && (
                    <div className={classNames("titleItem", "rate")}>
                        <span className={classNames("title")}>{t("reviews.REVIEW_FILTER_RATE_DELIVERY")}</span>
                        <RateItem rate={value.delivery_rating} value={value.delivery_rating} isInfo={true} />
                    </div>
                )}
            </div>
            {value.review && (<div className={classNames("dataItem")}>{value.review}</div>)}
        </div>
    );
}

export default CustomerDataItem;