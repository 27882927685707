export default class ChartModel {
  constructor({
    typeModel,
    chartType,
    title,
    data,
    isFiltered = false,
    filterColumnIndex,
    filterCategoryLabel,
    emptyLabel,
    options,
    height
  }) {
    this.typeModel = typeModel;
    this.chartType = chartType;
    this.title = title;
    this.data = data;
    this.isFiltered = isFiltered;
    this.filterColumnIndex = filterColumnIndex;
    this.filterCategoryLabel = filterCategoryLabel;
    this.emptyLabel = emptyLabel;
    this.options = options;
    this.height = height
  }
}
