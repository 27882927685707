import React, {useCallback, useState} from "react";
import "./style.scss";
import {ProductCard} from "./ProductCard";
import {useTranslation} from "react-i18next";
import { ModalPopup } from '../ModalPopup/ModalPopup.jsx';
import { CreateProduct } from './CreateProduct/CreateProduct.jsx';

export const ListSelectedProducts = ({getProduct, currency, products, updateProductList, businessId}) => {
    const {t} = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [index, setIndex] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [editingProduct, setEditingProduct] = useState(null);

    const selectProduct = (product, index) => {
        setIndex(index);
        setIsEdit(true);
        setSelectedProduct(getProduct(product));
    }

    const onClose = () => {
        setIsEdit(false);
        setIndex(null);
        setSelectedProduct(null);
    }

    const addToCart = () => {
        if(editingProduct.product.quantity) {
            updateProductList(products.map((p, i) => i === index ? editingProduct : p));
        } else {
            updateProductList(products.filter((p, i) => i !== index));
        }

        onClose();
    }

    const saveProduct = useCallback((prod) => {
        setEditingProduct(prod)
    }, []);


    return (
        <div>
            <div className="products">
                <div className={"head-title"}>{t("create-order.summary.SELECTED_PRODUCTS")}</div>
                {products && products.map((p, i) => (
                    <ProductCard
                      key={`productCard-${i}`}
                      onClick={() => selectProduct(p, i)}
                      product={getProduct(p)}
                      currency={currency}
                      showQuantity
                    />
                ))}
            </div>

            {!!selectedProduct && (<ModalPopup onClose={onClose} width={600} containerModalClass={"add-product-modal"} isOpen={!!selectedProduct} content={
                <CreateProduct
                  onClose={onClose}
                  business_id={businessId}
                  product={selectedProduct}
                  productEdit={isEdit ? products[index].product : null}
                  editMode={isEdit}
                  currency={currency}
                  handleOnAddToCart={addToCart}
                  emitProduct={saveProduct}
                />
            }></ModalPopup>)}

        </div>
    )
};