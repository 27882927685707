import React, {useEffect, useState} from "react";
import {connect} from 'react-redux'
import "./style.scss";
import CustomerSalesTopTen from "../CustomerSalesTopTen";
import CustomerSalesMenu from "../CustomerSalesMenu";
import CustomerSalesChart from "../CustomerSalesChart";
import GlobalFilterDataService from "../../../services/globalFilterDataService";
import {GLOBAL_FILTER_TYPE, PERIOD_TYPE} from "../../../utils/enums";
import {
    SALES_TYPE,
    defaultCustomerServiceSalesItem,
    DEFAULT_PERIOD_STATE,
    getDefaultReviewsFilter
} from "../../../utils/constants";
import {useDispatch} from "react-redux";
import {createAction} from "../../../utils/sagaHelper";
import {GET_SALES_STATISTICS, MENU_TEMPLATE_GET} from "../../../store/reducers/restaurant/restaurant-actions";
import {getMergedBusinessMenuById} from "../../../api/order-requests";
import {getPeriodByType} from "../../../utils/convertTime";
import {getFilteredItems, getQuantityFilters, salesFilterModel} from "../../../services/filter.js";
import classNames from "classnames";
import OrdersFilter from "../OrdersFilter/OrdersFilter.jsx";


const CustomerSales = ({getData}) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(GlobalFilterDataService.instance.getFilter(GLOBAL_FILTER_TYPE.sales));
    const [currentItems, setCurrentItems] = useState([defaultCustomerServiceSalesItem()]);
    const [statisticsDaily, setStatisticsDaily] = useState([]);
    const [statistics, setStatistics] = useState([]);
    // const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading]= useState(false);
    const [menuMap, setMenuMap] = useState({});
    const [salesFilterOptions, setSalesFilterOptions] = useState(salesFilterModel());
    const [isOpenSalesFilter, setIsOpenSalesFilter] = useState(false);

    useEffect(() => {
        dispatch(createAction(MENU_TEMPLATE_GET));
        const newValue = !filter.periodFilter[PERIOD_TYPE.today];
        handleOnSetFilter(newValue
            ? {
                ...filter,
                ...getPeriodByType(PERIOD_TYPE.today),
                periodFilter: PERIOD_TYPE.today
                    ? { ...DEFAULT_PERIOD_STATE, [PERIOD_TYPE.today]: newValue }
                    : DEFAULT_PERIOD_STATE
            }
            : { ...getDefaultReviewsFilter(), rating: filter.rating, deliveryRating: filter.deliveryRating }
        );

        // eslint-disable-next-line
    }, [dispatch]);

    const handleOnSetFilter = val => {
        const newFilter = { ...filter, ...val }
        GlobalFilterDataService.instance.setFilter(GLOBAL_FILTER_TYPE.sales, newFilter);
        setFilter(newFilter);
        updateStatistics(currentItems, newFilter);
    }

    const handleOnSelect = item => {
        let newCurrentItem = null;
        if(item.type === "business") {
            let newItem = currentItems.filter(o => o.type === "business");
            if(currentItems.some(o => o.id === item.id)) {
                newItem = newItem.filter(o => o.id !== item.id);
            } else {
                newItem = newItem.concat(item);
            }
            newCurrentItem = newItem;
        } else {
            newCurrentItem = [item];
        }

        if(!newCurrentItem.length) {
            newCurrentItem = [defaultCustomerServiceSalesItem()];
        }

        setCurrentItems(newCurrentItem);
        updateStatistics(newCurrentItem, filter);
    }
    const getMenu = async item =>  {
        const response = await getMergedBusinessMenuById(item.businessId);
        const menu = response.data;
        if(response.status === 200 && menu) {
            return {
                id: item.id,
                name: item.name || menu.name,
                type: item.type,
                businessId: item.businessId,
                index: 1,
                items: menu.categories.map(cat => ({
                    id: cat.id,
                    name: cat.name,
                    index: 2,
                    items: cat.products.map(prod => ({
                        id: prod.id,
                        name: prod.name,
                        type: SALES_TYPE.product,
                        index: 3,
                        businessId: item.businessId,
                    }))
                })).concat((menu.extras || []).map(extra => ({
                    id: extra.id,
                    name: extra.name,
                    index: 2,
                    items: (extra.options || []).map(option => ({
                        id: option.id,
                        name: option.name,
                        index: 3,
                        businessId: item.businessId,
                        items: (option.suboptions || []).map(sub => ({
                            id: sub.id,
                            name: sub.name,
                            type: SALES_TYPE.suboption,
                            index: 4,
                            businessId: item.businessId,
                        }))
                    }))
                })))
            }
        }
        return null;
    }

    const updateStatistics = async (item, filter) => {
        if(!item) {
            return;
        }

        setIsLoading(true);
        setStatistics([]);
        setStatisticsDaily([]);
        // setEvents([]);

        const needMenu = item.some(item => item.type === SALES_TYPE.business || item.type === SALES_TYPE.template);
        if(needMenu) {
            const businessToMenu = item.filter(o => !menuMap[o.businessId]);
            const menus = await Promise.allSettled(businessToMenu.map(getMenu));
            const newMenu = {...menuMap};
            menus.forEach(prom => {
                if(prom.status === "fulfilled" && prom.value) {
                    newMenu[prom.value.businessId] = prom.value;
                }
            })
            setMenuMap(newMenu);
        }

        const data = await getData({items: item, filter});

        setStatistics(data.statistics);
        setStatisticsDaily(data.statisticsDaily);
        // setEvents(data.events);
        setIsLoading(false);
    }

    const getName = () => {
        return currentItems.map(c => c.name).join(', ');
    }

    const getCurrentMenu = () => {
        return currentItems.map(item => menuMap[item.id] || menuMap[item.businessId]).filter(Boolean) || [];
    }

    const onSalesFilterChange = (key, prop, value) => {
        const filter = salesFilterOptions;
        if(filter.options[key]) {
            filter.options[key][prop] = value;
            setSalesFilterOptions(filter);
        }
    }

    const groupStatistics = () => {
        const map = getFilteredItems(statistics || [], salesFilterOptions).reduce((acc, cv) => {
            const key = `${cv.id}-${cv.order_source}`;
            const item = acc[key];
            if(item) {
                return {
                    ...acc,
                    [key]: {
                        ...item,
                        count: item.count + cv.count,
                        discount: item.count + cv.count,
                        items: item.count + cv.count,
                        products: item.products + cv.products,
                        service: item.service + cv.service,
                        tip: item.tip + cv.tip,
                        top_up: item.top_up + cv.top_up,
                        total: item.total + cv.total,
                        total_bit: item.total_bit + cv.total_bit,
                        total_card: item.total_card + cv.total_card,
                        total_cash: item.total_cash + cv.total_cash,
                        total_payment_balance: item.total_payment_balance + cv.total_payment_balance
                    },
                }
            } else {
                return {
                    ...acc,
                    [key]: cv,
                }
            }
        }, {});
        return Object.values(map);
    }

    return <div className={"customerSales"}>
        <CustomerSalesMenu onSelect={handleOnSelect} menu={{}} menus={getCurrentMenu()} />
        <div className={'midColumn'}>
            <CustomerSalesChart
                filterQuantity={getQuantityFilters(salesFilterOptions?.options || {})}
                // events={events}
                setIsOpenSalesFilter={setIsOpenSalesFilter}
                salesFilterOptions={salesFilterOptions}
                data={getFilteredItems(statisticsDaily, salesFilterOptions)}
                filter={filter}
                onSetFilter={handleOnSetFilter}
                title={getName()}
                isLoading={isLoading}
            />
            <div className={classNames("salesFilterWrapper", {
                isOpen: isOpenSalesFilter,
            })}>
                <OrdersFilter
                    filterOptionsModel={salesFilterOptions}
                    onChangeOption={onSalesFilterChange}
                    onClose={() => setIsOpenSalesFilter(false)}
                />
            </div>
        </div>
        <CustomerSalesTopTen statistics={groupStatistics()} menu={getCurrentMenu()}/>
    </div>
}

const mdtp = dispatch => ({
    getData: data => dispatch(createAction(GET_SALES_STATISTICS, data)),
})

export default connect(null, mdtp)(CustomerSales);