import {ADD, DELETE, EDIT, GET, REORDER, UPDATE} from '../common-actions';
import {MARKETPLACE_MESSAGE_DATA_GET} from '../order/order-actions';

export const GET_SALES_STATISTICS = "GET_SALES_STATISTICS";
export const LOAD_RESTAURANT = "LOAD_RESTAURANT_LOCATIONS";
export const LOAD_RESTAURANT_SUCCESS = LOAD_RESTAURANT + "_SUCCESS";
export const LOAD_RESTAURANT_FAIL = LOAD_RESTAURANT + "_FAIL";
export const LOAD_DEPOT_AVATARS = "LOAD_DEPOT_AVATARS";
export const SAVE_DEPOT_AVATAR = "SAVE_DEPOT_AVATAR";
export const SAVE_DEPOTS_AVATARS = "SAVE_DEPOTS_AVATARS";
export const PUSHER_RESTAURANT_STATE_UPDATE = "PUSHER_RESTAURANT_STATE_UPDATE";
export const RESTAURANT_UPDATE_CONTROL_CENTERS = "RESTAURANT_UPDATE_CONTROL_CENTERS";
export const RESTAURANT_UPDATE_AVAILABLE_CONTROL_CENTERS = "RESTAURANT_UPDATE_AVAILABLE_CONTROL_CENTERS";
export const RESTAURANT_CONTROL_CENTER_STATUS_GET = "RESTAURANT_CONTROL_CENTER_STATUS_GET";
export const RESTAURANT_CONTROL_CENTERS_STATUS_UPDATE = "RESTAURANT_CONTROL_CENTERS_STATUS_UPDATE";
export const SET_MENU_CREATE_ORDER = "SET_MENU_CREATE_ORDER";
export const RESTAURANT_SET_MODE = "RESTAURANT_SET_MODE";
export const RESTAURANT_UPDATE = "RESTAURANT_UPDATE";
export const SET_BUSINESS_MENU = "SET_BUSINESS_MENU";
export const REMOVE_BUSINESS_MENU = "REMOVE_BUSINESS_MENU";
export const SET_BUSINESS_PARENT_MENU = "SET_BUSINESS_PARENT_MENU";
export const SET_DEPOT_PROVIDERS = "SET_DEPOT_PROVIDERS";
export const SET_ORDERS_PROVIDER = "SET_ORDERS_PROVIDER";
export const SET_SHIFT_PROVIDERS = "SET_SHIFT_PROVIDERS";
export const DEPOT_NEW = "DEPOT_NEW";
export const DEPOT_EDIT = "DEPOT_EDIT";
export const DEPOT_DELETE = "DEPOT_DELETE";
export const SET_DEPOT_AVATAR = "SET_DEPOT_AVATAR";
export const DEPOT_OPERATION_MODE_SETTING_GET_ALL = "DEPOT_OPERATION_MODE_SETTING_GET_ALL";
export const DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE = "DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE";
export const DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_EDIT = DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE + EDIT;
export const DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_RESET = "DEPOT_OPERATION_MODE_SETTING_SET_ACTIVE_RESET";
export const DEPOT_INIT_DATA = "DEPOT_INIT_DATA";
export const DEPOT_INIT_DATA_GET = DEPOT_INIT_DATA + GET;
export const DEPOT_OPERATION_MODE_SETTING = "DEPOT_OPERATION_MODE_SETTING";
export const DEPOT_OPERATION_MODE_SETTING_GET = DEPOT_OPERATION_MODE_SETTING + GET;
export const DEPOT_OPERATION_MODE_SETTING_EDIT = DEPOT_OPERATION_MODE_SETTING + EDIT;
export const DEPOT_OPERATION_MODE_SETTING_ADD = DEPOT_OPERATION_MODE_SETTING + ADD;
export const DEPOT_OPERATION_MODE_SETTING_DELETE = DEPOT_OPERATION_MODE_SETTING + DELETE;
export const DEPOT_SETTING = "DEPOT_SETTING";
export const DEPOT_SETTING_ADD = DEPOT_SETTING + ADD;
export const DEPOT_SETTING_EDIT = DEPOT_SETTING + EDIT;
export const DEPOT_SETTING_DELETE = DEPOT_SETTING + DELETE;
export const DEPOTPAYMEWALLET_SETTING = "DEPOTPAYMEWALLET_SETTING";
export const DEPOTPAYMEWALLET_SETTING_ADD = DEPOTPAYMEWALLET_SETTING + ADD;
export const DEPOTPAYMECREDIT_SETTING = "DEPOTPAYMECREDIT_SETTING";
export const DEPOTPAYMECREDIT_SETTING_EDIT = DEPOTPAYMECREDIT_SETTING + EDIT;
export const DEPOT_BUSINESS_PRODUCT_DEEP_LINK = "DEPOT_BUSINESS_PRODUCT_DEEP_LINK";
export const MARKETPLACE_DEEP_LINK = "MARKETPLACE_DEEP_LINK";
export const MARKETPLACE_DEEP_LINK_CREATE = MARKETPLACE_DEEP_LINK + "_CREATE";
export const DEPOT_BUSINESS_PRODUCT_DEEP_LINK_CREATE = DEPOT_BUSINESS_PRODUCT_DEEP_LINK + "_CREATE";
export const DEPOT_BUSINESS_DEEP_LINK = "DEPOT_BUSINESS_DEEP_LINK";
export const DEPOT_BUSINESS_DEEP_LINK_CREATE = DEPOT_BUSINESS_DEEP_LINK + "_CREATE";
export const DEPOT_BUSINESS_DEEP_LINK_GET = DEPOT_BUSINESS_DEEP_LINK + GET;
export const DEPOT_BUSINESS_TRANSLATION = "TRANSLATION";
export const DEPOT_BUSINESS_TRANSLATION_GET = DEPOT_BUSINESS_TRANSLATION + GET;
export const DEPOT_BUSINESS_TRANSLATION_EDIT = DEPOT_BUSINESS_TRANSLATION + EDIT;
export const DEPOT_BUSINESS_TRANSLATION_EXPORT = DEPOT_BUSINESS_TRANSLATION + '_EXPORT';
export const DEPOT_BUSINESS_TRANSLATION_IMPORT = DEPOT_BUSINESS_TRANSLATION + '_IMPORT';
export const BUSINESS_CATEGORY = "BUSINESS_CATEGORY";
export const BUSINESS_CATEGORY_GET = BUSINESS_CATEGORY + GET;
export const BUSINESS_CATEGORY_ADD = BUSINESS_CATEGORY + ADD;
export const BUSINESS_CATEGORY_EDIT = BUSINESS_CATEGORY + EDIT;
export const BUSINESS_CATEGORY_DELETE = BUSINESS_CATEGORY + DELETE;
export const BUSINESS_CATEGORY_UPDATE = BUSINESS_CATEGORY + UPDATE;
export const CANCEL_REASON = "CANCEL_REASON";
export const CANCEL_REASON_EDIT = CANCEL_REASON + EDIT;
export const CANCEL_REASON_ADD = CANCEL_REASON + ADD;
export const CANCEL_REASON_DELETE = CANCEL_REASON + DELETE;
export const CANCEL_REASON_GET = CANCEL_REASON + GET;
export const LOAD_DEPOT_USER_BY_ID = "LOAD_DEPOT_USER_BY_ID";
export const DEPOTPAYMENT_STATE = "DEPOTPAYMENT_STATE";
export const DEPOTUSERS_SETTING = "DEPOTUSERS_SETTING";
export const DEPOTUSERS_SETTING_EDIT = DEPOTUSERS_SETTING + EDIT;
export const DEPOTUSERS_SETTING_ADD = DEPOTUSERS_SETTING + ADD;
export const DEPOTUSERS_SETTING_DELETE = DEPOTUSERS_SETTING + DELETE;
export const DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING = "DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING";
export const DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_EDIT = DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING + EDIT;
export const DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_ADD = DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING + ADD;
export const DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING_DELETE = DEPOT_DEFAULT_REGION_CONTROL_CENTER_SETTING + DELETE;
export const DEPOTPRICE_SETTING = "DEPOTPRICE_SETTING";
export const DEPOTPRICE_SETTING_EDIT = DEPOTPRICE_SETTING + EDIT;
export const DEPOTPRICE_SETTING_APPLY_TO = DEPOTPRICE_SETTING + "_APPLY_TO";
export const DEPOTPRICE_SETTING_APPLY_TO_ADD = DEPOTPRICE_SETTING + "_APPLY_TO" + ADD;
export const DEPOTMENU_SETTING = "DEPOTMENU_SETTING";
export const DEPOTMENU_SETTING_EDIT = DEPOTMENU_SETTING + EDIT;
export const DEPOTMENU_SETTING_CHECK = DEPOTMENU_SETTING + "_CHECK";
export const DEPOTMENU_GET = "DEPOTMENU_GET" + GET;
export const DEPOT_MENU_COPY = "DEPOT_MENU_COPY";
export const DEPOT_MENU_COPY_EDIT = DEPOT_MENU_COPY + EDIT;
export const ORDERING_MENU = "ORDERING_MENU";
export const ORDERING_MENU_EDIT = ORDERING_MENU + EDIT;
export const ORDERING_MENU_GET = ORDERING_MENU + "_GET";
export const BUSINESS_MENU = "BUSINESS_MENU";
export const BUSINESS_MENU_GET = BUSINESS_MENU + "_GET";
export const SETTINGS_OFFERS = "SETTINGS_OFFERS";
export const SETTINGS_OFFERS_ADD = SETTINGS_OFFERS + ADD;
export const SETTINGS_OFFERS_EDIT = SETTINGS_OFFERS + EDIT;
export const SETTINGS_OFFERS_DELETE = SETTINGS_OFFERS + DELETE;
export const SETTINGS_OFFERS_GET = SETTINGS_OFFERS + "_GET";
export const SETTINGS_DELIVERY_ZONES = "SETTINGS_DELIVERY_ZONES";
export const SETTINGS_DELIVERY_ZONES_ADD = SETTINGS_DELIVERY_ZONES + ADD;
export const SETTINGS_DELIVERY_ZONES_EDIT = SETTINGS_DELIVERY_ZONES + EDIT;
export const SETTINGS_DELIVERY_ZONES_DELETE = SETTINGS_DELIVERY_ZONES + DELETE;
export const SETTINGS_DELIVERY_ZONES_GET = SETTINGS_DELIVERY_ZONES + "_GET";
export const SETTINGS_DELIVERY_ZONES_LIST_UPDATE = SETTINGS_DELIVERY_ZONES + "_LIST_UPDATE";
export const ORDERING_MENU_DELIVERY_ZONES = "ORDERING_MENU_DELIVERY_ZONES";
export const ORDERING_MENU_DELIVERY_ZONES_GLOBAL = "ORDERING_MENU_DELIVERY_ZONES_GLOBAL";
export const ORDERING_MENU_DELIVERY_ZONES_GLOBAL_EDIT = ORDERING_MENU_DELIVERY_ZONES_GLOBAL + EDIT;
export const ORDERING_MENU_DELIVERY_ZONES_GLOBAL_EDIT_RESET = ORDERING_MENU_DELIVERY_ZONES_GLOBAL + EDIT + "_RESET";
export const ORDERING_MENU_DELIVERY_ZONES_ADD = ORDERING_MENU_DELIVERY_ZONES + ADD;
export const ORDERING_MENU_DELIVERY_ZONES_EDIT = ORDERING_MENU_DELIVERY_ZONES + EDIT;
export const ORDERING_MENU_DELIVERY_ZONES_DELETE = ORDERING_MENU_DELIVERY_ZONES + DELETE;
export const ORDERING_MENU_DELIVERY_ZONES_LIST_UPDATE = ORDERING_MENU_DELIVERY_ZONES + "_LIST_UPDATE";
export const ORDERING_MENU_DELIVERY_ZONES_UPDATE = ORDERING_MENU_DELIVERY_ZONES + "_UPDATE";
export const ORDERING_MENU_BUSINESS_SCHEDULE = "ORDERING_MENU_BUSINESS_SCHEDULE";
export const ORDERING_MENU_BUSINESS_SCHEDULE_EDIT = ORDERING_MENU_BUSINESS_SCHEDULE + EDIT;
export const ORDERING_MENU_BUSINESS_DISCOUNTS = "ORDERING_MENU_BUSINESS_DISCOUNTS";
export const ORDERING_MENU_BUSINESS_DISCOUNTS_ADD = ORDERING_MENU_BUSINESS_DISCOUNTS + ADD;
export const ORDERING_MENU_BUSINESS_DISCOUNTS_EDIT = ORDERING_MENU_BUSINESS_DISCOUNTS + EDIT;
export const ORDERING_MENU_BUSINESS_DISCOUNTS_DELETE = ORDERING_MENU_BUSINESS_DISCOUNTS + DELETE;
export const ORDERING_MENU_BUSINESS_INFO = "ORDERING_MENU_BUSINESS_INFO";
export const ORDERING_MENU_BUSINESS_INFO_EDIT = ORDERING_MENU_BUSINESS_INFO + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORIES = "ORDERING_MENU_BUSINESS_CATEGORIES";
export const ORDERING_MENU_BUSINESS_CATEGORIES_EDIT = ORDERING_MENU_BUSINESS_CATEGORIES + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORIES_ADD = ORDERING_MENU_BUSINESS_CATEGORIES + ADD;
export const ORDERING_MENU_BUSINESS_CATEGORIES_DELETE = ORDERING_MENU_BUSINESS_CATEGORIES + DELETE;
export const ORDERING_MENU_BUSINESS_CATEGORIES_UPDATE = ORDERING_MENU_BUSINESS_CATEGORIES + "_UPDATE";
export const ORDERING_MENU_BUSINESS_CATEGORIES_LIST_UPDATE = ORDERING_MENU_BUSINESS_CATEGORIES + "_LIST_UPDATE";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DRAG_UPDATE = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DRAG_UPDATE";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_UPDATE = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_UPDATE";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_UPDATE = ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT + "_UPDATE";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_ADD = ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS + ADD;
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_DELETE = ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS + DELETE;
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_AVATAR = ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS + "AVATAR";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_GET = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_GET";
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_EDIT = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT" + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_ADD = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT" + ADD;
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_DELETE = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT" + DELETE;
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_REORDER = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT" + REORDER;
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT_UPDATE = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_INGREDIENT" + UPDATE;
export const GET_ORDERING_MENU_BUSINESS_EXTRA = "GET_ORDERING_MENU_BUSINESS_EXTRA";
export const ORDERING_MENU_BUSINESS_EXTRA = "ORDERING_MENU_BUSINESS_EXTRA";
export const ORDERING_MENU_BUSINESS_EXTRA_EDIT = "ORDERING_MENU_BUSINESS_EXTRA"  + EDIT;
export const ORDERING_MENU_BUSINESS_EXTRA_ADD = "ORDERING_MENU_BUSINESS_EXTRA"  + ADD;
export const ORDERING_MENU_BUSINESS_EXTRA_DELETE = "ORDERING_MENU_BUSINESS_EXTRA" + DELETE;
export const ORDERING_MENU_BUSINESS_EXTRA_UPDATE  = "ORDERING_MENU_BUSINESS_EXTRA" + UPDATE;
export const ORDERING_MENU_BUSINESS_EXTRA_OPTIONS = "ORDERING_MENU_BUSINESS_EXTRA_OPTIONS";
export const ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_EDIT = "ORDERING_MENU_BUSINESS_EXTRA_OPTIONS"  + EDIT;
export const ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_ADD = "ORDERING_MENU_BUSINESS_EXTRA_OPTIONS"  + ADD;
export const ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_DELETE = "ORDERING_MENU_BUSINESS_EXTRA_OPTIONS" + DELETE;
export const ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_UPDATE  = "ORDERING_MENU_BUSINESS_EXTRA_OPTIONS" + UPDATE;
export const ORDERING_MENU_BUSINESS_EXTRA_OPTIONS_REORDER  = "ORDERING_MENU_BUSINESS_EXTRA_OPTIONS" + REORDER;
export const ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION = "ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION";
export const ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_EDIT = "ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION"  + EDIT;
export const ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_ADD = "ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION"  + ADD;
export const ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_DELETE = "ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION" + DELETE;
export const ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_REORDER = "ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION" + REORDER;
export const ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION_UPDATE  = "ORDERING_MENU_BUSINESS_EXTRA_SUBOPTION" + UPDATE;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS = "ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS";
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_ADD = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS + ADD;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DELETE = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS + DELETE;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_UPDATE = "ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_UPDATE";
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION = "ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION";
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_DEPOT = "ORDERING_MENU_BUSINESS_CATEGORY_DEPOT"
export const ORDERING_MENU_BUSINESS_CATEGORY_DEPOT_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_DEPOT + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_DEPOT = "ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_DEPOT"
export const ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_DEPOT_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_PRODUCT_DEPOT + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DEPOT = "ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DEPOT"
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DEPOT_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_DEPOT + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DEPOT = "ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DEPOT"
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DEPOT_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DEPOT + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DEPOT = "ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DEPOT";
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DEPOT_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DEPOT + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_ADD = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION + ADD;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_DELETE = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION + DELETE;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_REORDER = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION + REORDER;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION_UPDATE = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_OPTION + "_UPDATE";
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION = "ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION";
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_EDIT = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION + EDIT;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_ADD = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION + ADD;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_DELETE = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION + DELETE;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_REORDER = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION + REORDER;
export const ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION_UPDATE = ORDERING_MENU_BUSINESS_CATEGORY_EXTRAS_SUBOPTION + UPDATE;
export const ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA = "ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA";
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA = "TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA";
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION = "TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION";
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION = "TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION";
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_DELETE = TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION + DELETE;
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_EDIT = TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION + EDIT;
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_EDIT = TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION + EDIT;
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_DELETE = TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION + DELETE;
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_DELETE = TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA + DELETE;
export const TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_EDIT = TEMPLATE_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA + EDIT;
export const ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA_EDIT = ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_EXTRA + EDIT;
export const ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION = "ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION";
export const ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION_EDIT = ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_OPTION + EDIT;
export const ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION = "ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION";
export const ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION_EDIT = ORDERING_MENU_BUSINESS_PRODUCT_OVERRIDE_SUBOPTION + EDIT;
export const PARENT_MENU_OVERRIDE_DELETE = "PARENT_MENU_OVERRIDE_DELETE";
export const PARENT_MENU_OVERRIDE_SUBOPTION = "PARENT_MENU_OVERRIDE_SUBOPTION";
export const PARENT_MENU_OVERRIDE_SUBOPTION_EDIT = PARENT_MENU_OVERRIDE_SUBOPTION + EDIT;
export const PARENT_MENU_OVERRIDE_OPTION = "PARENT_MENU_OVERRIDE_OPTION";
export const PARENT_MENU_OVERRIDE_OPTION_EDIT = PARENT_MENU_OVERRIDE_OPTION + EDIT;
export const PARENT_MENU_OVERRIDE_EXTRA = "PARENT_MENU_OVERRIDE_EXTRA";
export const PARENT_MENU_OVERRIDE_EXTRA_EDIT = PARENT_MENU_OVERRIDE_EXTRA + EDIT;
export const PARENT_MENU_OVERRIDE_PRODUCT = "PARENT_MENU_OVERRIDE_PRODUCT";
export const PARENT_MENU_OVERRIDE_PRODUCT_EDIT = PARENT_MENU_OVERRIDE_PRODUCT + EDIT;
export const PARENT_MENU_OVERRIDE_CATEGORY = "PARENT_MENU_OVERRIDE_CATEGORY";
export const PARENT_MENU_OVERRIDE_CATEGORY_EDIT = PARENT_MENU_OVERRIDE_CATEGORY + EDIT;
export const ORDERING_MENU_BUSINESS_PRODUCT_DELETE_OVERRIDE = "ORDERING_MENU_BUSINESS_PRODUCT_DELETE_OVERRIDE";
export const ORDERING_MENU_BUSINESS_GALLERY = "ORDERING_MENU_BUSINESS_GALLERY";
export const ORDERING_MENU_BUSINESS_GALLERY_EDIT = ORDERING_MENU_BUSINESS_GALLERY + EDIT;
export const ORDERING_MENU_BUSINESS_GALLERY_DELETE = ORDERING_MENU_BUSINESS_GALLERY + DELETE;
export const ORDERING_MENU_BUSINESS_GALLERY_ADD = ORDERING_MENU_BUSINESS_GALLERY + ADD;
export const ORDERING_MENU_BUSINESS_GALLERY_REORDER = ORDERING_MENU_BUSINESS_GALLERY + REORDER;
export const SET_ORDERING_MENU_BUSINESS_INFO_AVATAR = "SET_ORDERING_MENU_BUSINESS_INFO_AVATAR";
export const SET_ORDERING_MENU_BUSINESS_INFO_HEADER_MINI = "SET_ORDERING_MENU_BUSINESS_INFO_HEADER_MINI";
export const GET_DEPOT_USERS = "GET_DEPOT_USERS";
export const GET_DEPOT_PRICE = "GET_DEPOT_PRICE";
export const SHIFTS_GET = "SHIFTS_GET";
export const SHIFTS_SAVE = "SHIFTS_SAVE";
export const UPDATE_SHIFTS = "UPDATE_SHIFTS";
export const SAVE_DEPOT_USERS = "SAVE_DEPOT_USERS";
export const UPDATE_DEPOT_USER = "UPDATE_DEPOT_USER";
export const ADD_DEPOT_USER = "ADD_DEPOT_USER";
export const DEPOTUSERS_SETTING_PASSWORD = "DEPOTUSERS_SETTING_PASSWORD";
export const DEPOTUSERS_SETTING_PASSWORD_EDIT =
  DEPOTUSERS_SETTING_PASSWORD + EDIT;
export const SHIPMENT_PRICE_CONFIGURATION = "SHIPMENT_PRICE_CONFIGURATION";
export const SHIPMENT_PRICE_CONFIGURATION_EDIT = SHIPMENT_PRICE_CONFIGURATION + EDIT;
export const SHIPMENT_PRICE_CONFIGURATION_GET = SHIPMENT_PRICE_CONFIGURATION + GET;
export const GENERAL_SETTING = "GENERAL_SETTING";
export const GENERAL_SETTING_EDIT = GENERAL_SETTING + EDIT;
export const GENERAL_SETTING_GET = GENERAL_SETTING + "_GET";
export const MESSAGE_GALLERY = "MESSAGE_GALLERY";
export const MESSAGE_GALLERY_GET = MESSAGE_GALLERY + GET;
export const MESSAGE_GALLERY_EDIT = MESSAGE_GALLERY + EDIT;
export const MESSAGE_GALLERY_DELETE = MESSAGE_GALLERY + DELETE;
export const MARKETPLACE_MENU_CLEAR_OVERRIDE = "MARKETPLACE_MENU_CLEAR_OVERRIDE";
export const MARKETPLACE_MESSAGE = "MARKETPLACE_MESSAGE";
export const MARKETPLACE_PAGES = "MARKETPLACE_PAGES";
export const MARKETPLACE_PAGES_ADD = MARKETPLACE_PAGES + ADD;
export const MARKETPLACE_PAGES_EDIT = MARKETPLACE_PAGES + EDIT;
export const MARKETPLACE_PAGES_DELETE = MARKETPLACE_PAGES + DELETE;
export const MARKETPLACE_MESSAGE_ADD = MARKETPLACE_MESSAGE + ADD;
export const MARKETPLACE_MESSAGE_EDIT = MARKETPLACE_MESSAGE + EDIT;
export const MARKETPLACE_MESSAGE_TOGGLE_ENABLED = "MARKETPLACE_MESSAGE_TOGGLE_ENABLED";
export const MARKETPLACE_PAGES_TOGGLE_ENABLED = "MARKETPLACE_PAGES_TOGGLE_ENABLED";
export const MARKETPLACE_MESSAGE_DELETE = MARKETPLACE_MESSAGE + DELETE;
export const ADD_MARKETPLACE_MESSAGE = ADD + MARKETPLACE_MESSAGE;
export const ADD_MARKETPLACE_PAGES = ADD + MARKETPLACE_PAGES;
export const UPDATE_MARKETPLACE_MESSAGE_GALLERY = "UPDATE_MARKETPLACE_MESSAGE_GALLERY";
export const UPDATE_MARKETPLACE_MESSAGE_GALLERY_ADD = UPDATE_MARKETPLACE_MESSAGE_GALLERY + ADD;
export const UPDATE_MARKETPLACE_MESSAGE = UPDATE + MARKETPLACE_MESSAGE;
export const DELETE_MARKETPlACE_MESSAGE = DELETE + MARKETPLACE_MESSAGE;
export const UPDATE_MARKETPLACE_PAGE = UPDATE + MARKETPLACE_PAGES;
export const DELETE_MARKETPLACE_PAGE = DELETE + MARKETPLACE_PAGES;
export const MARKETPLACE_MESSAGE_UPDATE = MARKETPLACE_MESSAGE + UPDATE;
export const MARKETPLACE_PAGES_UPDATE = MARKETPLACE_PAGES + UPDATE;
export const MARKETPLACE_SETTINGS = "MARKETPLACE_SETTINGS";
export const MARKETPLACE_SETTINGS_EDIT = MARKETPLACE_SETTINGS + EDIT;
export const TELEGRAM_SETTING = "TELEGRAM_SETTING";
export const TELEGRAM_SETTING_EDIT = TELEGRAM_SETTING + EDIT;
export const OPERATION_TIMING_SETTING = "PRESET_TIMING_SETTING";
export const OPERATION_TIMING_SETTING_EDIT = OPERATION_TIMING_SETTING + EDIT;
export const OPERATION_TIMING_SETTING_ADD = OPERATION_TIMING_SETTING + ADD;
export const OPERATION_TIMING_SETTING_DELETE = OPERATION_TIMING_SETTING + DELETE;
export const OPERATION_TIMING_SETTING_TOGGLE_DEFAULT_PROPERTY = "OPERATION_TIMING_SETTING_TOGGLE_DEFAULT_PROPERTY";
export const OPERATION_TIMING_SETTINGS_GET = OPERATION_TIMING_SETTING + GET;
export const PAYMENTS_CONFIGURATION_SETTING = "PAYMENTS_CONFIGURATION_SETTING";
export const PAYMENTS_CONFIGURATION_SETTING_EDIT = PAYMENTS_CONFIGURATION_SETTING + EDIT;
export const RESTAURANT_PAYME_WALLET_SETTING = "RESTAURANT_PAYME_WALLET_SETTING";
export const RESTAURANT_PAYME_WALLET_SETTING_ADD = RESTAURANT_PAYME_WALLET_SETTING + ADD;
export const RESTAURANT_PAYMENT_STATE = "RESTAURANT_PAYMENT_STATE";
export const REGION_SETTING = "REGION_SETTING";
export const REGION_SETTING_EDIT = REGION_SETTING + EDIT;
export const REGION_SETTING_ADD = REGION_SETTING + ADD;
export const REGION_SETTING_DELETE = REGION_SETTING + DELETE;
export const FLEET_SETTINGS = "FLEET_SETTINGS";
export const FLEET_SETTINGS_UNMOUNT = "FLEET_SETTINGS_UNMOUNT";
export const FLEET_SETTINGS_LOAD = "FLEET_SETTINGS_LOAD";
export const FLEET_SETTINGS_TELEMETRY_LOAD = "FLEET_SETTINGS_TELEMETRY_LOAD";
export const FLEET_SETTINGS_FLEET_VEHICLE_LOAD = "FLEET_SETTINGS_FLEET_VEHICLE_LOAD";
export const FLEET_SETTINGS_GET = FLEET_SETTINGS + GET;
export const FLEET_SETTINGS_TELEMETRY_GET = "FLEET_SETTINGS_TELEMETRY" + GET;
export const FLEET_SETTINGS_TELEMETRY_BLINK = "FLEET_SETTINGS_TELEMETRY_BLINK";
export const FLEET_SETTINGS_EDIT = FLEET_SETTINGS + EDIT;
export const FLEET_SETTINGS_ADD = FLEET_SETTINGS + ADD;
export const FLEET_SETTINGS_DELETE = FLEET_SETTINGS + DELETE;
export const PROVIDERS_SETTING = "PROVIDERS_SETTING";
export const PROVIDERS_SETTING_DETAILS_UNMOUNT = "PROVIDERS_SETTING_DETAILS_UNMOUNT";
export const PROVIDERS_SETTING_GET = PROVIDERS_SETTING + GET;
export const PROVIDERS_SETTING_DETAILS_GET = "PROVIDERS_SETTING_DETAILS" + GET;
export const PROVIDERS_SETTING_EDIT = PROVIDERS_SETTING + EDIT;
export const PROVIDERS_SETTING_ADD = PROVIDERS_SETTING + ADD;
export const PROVIDERS_SETTING_DELETE = PROVIDERS_SETTING + DELETE;
export const PROVIDERS_SETTING_LOAD = "PROVIDERS_SETTING_LOAD";
export const PROVIDERS_SETTING_LOAD_DETAILS = "PROVIDERS_SETTING_LOAD_DETAILS";
export const PROVIDERS_SETTING_UPDATE = "PROVIDERS_SETTING_UPDATE";
export const CLOUD_PRINTERS_SETTING = "CLOUD_PRINTERS_SETTING";
export const CLOUD_PRINTERS_SETTING_UPDATE = "CLOUD_PRINTERS_SETTING_UPDATE";
export const CLOUD_PRINTERS_SETTING_LOAD = "CLOUD_PRINTERS_SETTING_LOAD";
export const CLOUD_PRINTERS_SETTING_ADD = CLOUD_PRINTERS_SETTING + ADD;
export const CLOUD_PRINTERS_SETTING_LOAD_DETAILS = "CLOUD_PRINTERS_SETTING_LOAD_DETAILS";
export const CLOUD_PRINTERS_SETTING_EDIT = CLOUD_PRINTERS_SETTING + EDIT;
export const CLOUD_PRINTERS_SETTING_GET = CLOUD_PRINTERS_SETTING + GET;
export const CLOUD_PRINTERS_SETTING_DELETE = CLOUD_PRINTERS_SETTING + DELETE;
export const CLOUD_PRINTERS_SETTING_DETAILS_GET = "CLOUD_PRINTERS_SETTING_DETAILS" + GET;
export const CLOUD_PRINTERS_SETTING_DETAILS_UNMOUNT = "CLOUD_PRINTERS_SETTING_DETAILS_UNMOUNT";

export const SERVICE_CITIES_SETTING = "SERVICE_CITIES_SETTING";
export const SERVICE_CITIES_SETTING_TOGGLE = "SERVICE_CITIES_SETTING_TOGGLE";
export const SERVICE_CITIES_SETTING_ADD = SERVICE_CITIES_SETTING + ADD;
export const SERVICE_CITIES_SETTING_EDIT = SERVICE_CITIES_SETTING + EDIT;
export const SERVICE_CITIES_SETTING_GET = SERVICE_CITIES_SETTING + GET;
export const SERVICE_CITIES_SETTING_DELETE = SERVICE_CITIES_SETTING + DELETE;
export const SEARCH_SHORTCUT_SETTING = "SEARCH_SHORTCUT_SETTING";
export const SEARCH_SHORTCUT_SETTING_ADD = SEARCH_SHORTCUT_SETTING + ADD;
export const SEARCH_SHORTCUT_SETTING_DELETE = SEARCH_SHORTCUT_SETTING + DELETE;
export const NAMED_PLACES = "NAMED_PLACES";
export const NAMED_PLACES_GET = NAMED_PLACES + GET;
export const NAMED_PLACES_ADD = NAMED_PLACES + ADD;
export const NAMED_PLACES_EDIT = NAMED_PLACES + EDIT;
export const NAMED_PLACES_DELETE = NAMED_PLACES + DELETE;
export const GET_USER_SHIFT_HISTORY = "GET_USER_SHIFT_HISTORY";
export const GET_PROVIDER_SHIFT_HISTORY = "GET_PROVIDER_SHIFT_HISTORY";
export const CURRENT_SHIFT_USERS = "CURRENT_SHIFT_USERS";
export const SHIFT_PROVIDERS = "SHIFT_PROVIDERS";
export const SHIFT_PROVIDERS_GET = SHIFT_PROVIDERS + GET;
export const SHIFTS_HISTORY_SAVE = "SHIFTS_HISTORY_SAVE";
export const SETTINGS_CONTROL_CENTER = "SETTINGS_CONTROL_CENTER";
export const SETTINGS_CONTROL_CENTER_EDIT = SETTINGS_CONTROL_CENTER + EDIT;
export const SETTINGS_CONTROL_CENTER_GET = SETTINGS_CONTROL_CENTER + GET;
export const SETTINGS_CONTROL_CENTER_ACTIVATE =
  SETTINGS_CONTROL_CENTER + "_ACTIVATE";
export const SETTINGS_CONTROL_CENTER_ACTIVATE_EDIT =
  SETTINGS_CONTROL_CENTER_ACTIVATE + EDIT;
export const SETTINGS_CONTROL_CENTER_DEACTIVATE =
  SETTINGS_CONTROL_CENTER + "_DEACTIVATE";
export const SETTINGS_CONTROL_CENTER_DEACTIVATE_EDIT =
  SETTINGS_CONTROL_CENTER_DEACTIVATE + EDIT;
export const SETTINGS_CONTROL_CENTER_CHANGEUSER =
  SETTINGS_CONTROL_CENTER + "_CHANGEUSER";
export const SETTINGS_CONTROL_CENTER_CHANGEUSER_EDIT =
  SETTINGS_CONTROL_CENTER_CHANGEUSER + EDIT;
export const COPY_TELEGRAM_COMMAND = "COPY_TELEGRAM_COMMAND";
export const GET_ORDER_STAT = "GET_ORDER_STAT";
export const SET_ACTIVATE_MODEL = "SET_ACTIVATE_MODEL";
export const PUSHER_DEPOT_SERVICES_REFRESH = "PUSHER_DEPOT_SERVICES_REFRESH";
export const GIFT_CARD = "GIFT_CARD";
export const GIFT_CARD_GET = GIFT_CARD + "_GET";
export const GIFT_CARD_EDIT = GIFT_CARD + EDIT;
export const GIFT_CARD_PRODUCT = GIFT_CARD + "_PRUDUCT";
export const GIFT_CARD_PRODUCT_EDIT = GIFT_CARD_PRODUCT + EDIT;
export const GIFT_CARD_PRODUCT_ADD= GIFT_CARD_PRODUCT + ADD;
export const GIFT_CARD_PRODUCT_DELETE= GIFT_CARD_PRODUCT + DELETE;
export const GIFT_CARD_PRODUCT_UPDATE= GIFT_CARD_PRODUCT + "_UPDATE";
export const GIFT_CARD_PRODUCT_REORDER= GIFT_CARD_PRODUCT + "_REORDER";
export const GIFT_CARD_PRODUCT_UPDATE_AVATAR = GIFT_CARD_PRODUCT + "_UPDATE_AVATAR";
export const GIFT_CARD_BUSINESS_UPDATE_AVATAR = GIFT_CARD_PRODUCT + "_BUSINESS_UPDATE_AVATAR";
export const COURIER_DELIVERY_FEES_SETTING = "COURIER_DELIVERY_FEES_SETTING";
export const COURIER_DELIVERY_FEES_SETTING_GET = "COURIER_DELIVERY_FEES_SETTING_GET";
export const COURIER_DELIVERY_FEES_SETTING_EDIT = "COURIER_DELIVERY_FEES_SETTING" + EDIT;
export const REFERRAL_SETTINGS = "REFERRAL_SETTINGS";
export const LOYALTY_SETTINGS = "LOYALTY_SETTINGS";
export const LOYALTY_SETTINGS_GET = LOYALTY_SETTINGS + GET;
export const LOYALTY_SETTINGS_REGISTER_VOUCHER = "LOYALTY_SETTINGS_REGISTER_VOUCHER";
export const LOYALTY_SETTINGS_REGISTER_VOUCHER_EDIT = LOYALTY_SETTINGS_REGISTER_VOUCHER + EDIT;
export const LOYALTY_SETTINGS_CASHBACK = "LOYALTY_SETTINGS_CASHBACK";
export const LOYALTY_SETTINGS_CASHBACK_EDIT = LOYALTY_SETTINGS_CASHBACK + EDIT;
export const REFERRAL_SETTINGS_EDIT = REFERRAL_SETTINGS + EDIT;
export const REFERRAL_SETTINGS_GET = REFERRAL_SETTINGS + "_GET";
export const CURRENT_CONTROL_CENTER_UPDATE = "CURRENT_CONTROL_CENTER_UPDATE";
export const CONTROL_CENTER_REFRESH = "CONTROL_CENTER_REFRESH";
export const DRIVER_TIP = "DRIVER_TIP";
export const DRIVER_TIP_EDIT = DRIVER_TIP + EDIT;
export const DRIVER_TIP_GET = DRIVER_TIP + "_GET";
export const LIST_PROMOTION_SETTING = "LIST_PROMOTION_SETTING";
export const LIST_PROMOTION_SETTING_ADD = LIST_PROMOTION_SETTING + ADD;
export const LIST_PROMOTION_SETTING_EDIT = LIST_PROMOTION_SETTING + EDIT;
export const LIST_PROMOTION_SETTING_DELETE = LIST_PROMOTION_SETTING + DELETE;
export const LIST_PROMOTION_SETTING_GET = LIST_PROMOTION_SETTING + "_GET";
export const LIST_PROMOTION_SETTING_DRAG = LIST_PROMOTION_SETTING + "_DRAG";
export const LOYALTY_CAMPAIGNS = "LOYALTY_CAMPAIGNS";
export const LOYALTY_CAMPAIGNS_ADD = LOYALTY_CAMPAIGNS + ADD;
export const LOYALTY_CAMPAIGNS_DELETE = LOYALTY_CAMPAIGNS + DELETE;
export const LOYALTY_CAMPAIGNS_EDIT = LOYALTY_CAMPAIGNS + EDIT;
export const LOYALTY_CAMPAIGNS_GET = LOYALTY_CAMPAIGNS + "_GET";
export const LOYALTY_CAMPAIGNS_AUDIENCE_FILTER_COUNT_GET = LOYALTY_CAMPAIGNS + "_AUDIENCE_FILTER_COUNT_GET";
export const LOYALTY_CAMPAIGNS_TOGGLE_ENABLED = "LOYALTY_CAMPAIGNS_TOGGLE_ENABLED";
export const EXECUTE_COMPAIGN = "EXECUTE_COMPAING";
export const LOYALTY_CAMPAIGNS_EXECUTIONS_GET = LOYALTY_CAMPAIGNS + "_EXECUTIONS" + GET;
export const LOYALTY_CAMPAIGNS_EXECUTIONS_ADD = LOYALTY_CAMPAIGNS + "_EXECUTIONS" + ADD;
export const LOYALTY_CAMPAIGNS_EXECUTIONS_UPDATE_BY_ID = LOYALTY_CAMPAIGNS + "_EXECUTIONS_UPDATE_BY_ID";
export const SERVICE_FEE = "SERVICE_FEE";
export const SERVICE_FEE_EDIT = SERVICE_FEE + EDIT;
export const SERVICE_FEE_GET = SERVICE_FEE + "_GET";
export const MENU_TEMPLATE = "MENU_TEMPLATE";
export const MENU_TEMPLATE_GET = "MENU_TEMPLATE_GET";
export const MENU_TEMPLATE_DELETE = MENU_TEMPLATE + DELETE;
export const MENU_TEMPLATE_EDIT = MENU_TEMPLATE + EDIT;
export const MENU_TEMPLATE_ADD = MENU_TEMPLATE + ADD;
export const MENU_TEMPLATE_BUSINESS_CATEGORY_SETTING = "MENU_TEMPLATE_BUSINESS_CATEGORY_SETTING";
export const MENU_TEMPLATE_BUSINESS_CATEGORY_SETTING_EDIT = MENU_TEMPLATE_BUSINESS_CATEGORY_SETTING + EDIT;
export const BUSINESS_PARENT_MENU = "BUSINESS_PARENT_MENU"
export const BUSINESS_PARENT_MENU_GET = BUSINESS_PARENT_MENU + "_GET";
export const BUSINESS_PARENT_MENU_UPDATE = BUSINESS_PARENT_MENU + UPDATE;
export const CHECK_SQUAREUP_AUTHORIZED_REQUIRED = "CHECK_SQUAREUP_AUTHORIZED_REQUIRED";
export const GET_BUSINESS_BY_QUERY = "GET_BUSINESS_BY_QUERY";
export const RESTAURANT_DELIVERY_ZONE = "RESTAURANT_DELIVERY_ZONE";
export const RESTAURANT_DELIVERY_ZONE_UPDATE = RESTAURANT_DELIVERY_ZONE + UPDATE;
export const COURIER_SETTINGS = "COURIER_SETTINGS";
export const COURIER_SETTINGS_GET = COURIER_SETTINGS + "_GET";
export const COURIER_SETTINGS_EDIT = COURIER_SETTINGS + EDIT;
export const BUSINESS_APP_CONFIGURATION = "BUSINESS_APP_CONFIGURATION";
export const BUSINESS_APP_CONFIGURATION_GET = BUSINESS_APP_CONFIGURATION + GET;
export const BUSINESS_APP_CONFIGURATION_EDIT = BUSINESS_APP_CONFIGURATION + EDIT;

export function actionLoadRestaurant(payload) {
  return {
    type: LOAD_RESTAURANT,
    payload: {
      ...payload
    }
  };
}

export function actionLoadRestaurantSuccess(data) {
  return { type: LOAD_RESTAURANT_SUCCESS, data, additionalParams: { batched: true } };
}
export function actionLoadFleet(data) {
  return { type: FLEET_SETTINGS_LOAD, data };
}
export function actionLoadFleetTelemetry(data) {
  return { type: FLEET_SETTINGS_TELEMETRY_LOAD, data };
}
export function actionLoadFleetUpdate(data) {
  return { type: FLEET_SETTINGS_FLEET_VEHICLE_LOAD, data };
}

export function actionLoadRestaurantFail(error) {
  return {
    type: LOAD_RESTAURANT_FAIL,
    error: error
  };
}

export function actionLoadDepotAvatars(data) {
  return {
    type: LOAD_DEPOT_AVATARS,
    data: data
  };

}
export function actionLoadProvidersSetting(data) {
  return {
    type: PROVIDERS_SETTING_LOAD,
    data: data
  };
}
export function actionLoadProvidersSettingDetails(data) {
  return {
    type: PROVIDERS_SETTING_LOAD_DETAILS,
    data: data
  };
}
export function actionUpdateProvidersSetting(data) {
  return {
    type: PROVIDERS_SETTING_UPDATE,
    data: data
  };
}

export function actionCleanProvidersDetailsSetting(data) {
  return {
    type: PROVIDERS_SETTING_DETAILS_UNMOUNT,
    data: data
  };
}
export function actionLoadCloudPrintersSetting(data) {
  return {
    type: CLOUD_PRINTERS_SETTING_LOAD,
    data: data
  };
}
export function actionLoadCloudPrintersSettingDetails(data) {
  return {
    type: CLOUD_PRINTERS_SETTING_LOAD_DETAILS,
    data: data
  };
}

export function actionCleanCloudPrintersDetailsSetting(data) {
  return {
    type: CLOUD_PRINTERS_SETTING_DETAILS_UNMOUNT,
    data: data
  };
}
export function actionUpdateCloudPrintersSetting(data) {
  return {
    type: CLOUD_PRINTERS_SETTING_UPDATE,
    data: data
  };
}

export function actionDeleteCloudPrintersSetting(data) {
  return {
    type: CLOUD_PRINTERS_SETTING_DELETE,
    data: data
  };
}

export function actionGetControlCenterStatus(data) {
  return { type: RESTAURANT_CONTROL_CENTER_STATUS_GET, data };
}

export function actionSaveDepotAvatar(data) {
  return {
    type: SAVE_DEPOT_AVATAR,
    data: data
  };
}

export function actionSaveDepotsAvatars(data) {
  return {
    type: SAVE_DEPOTS_AVATARS,
    data: data
  };
}

export function actionGetRestaurantControlCenters() {
  return {
    type: SETTINGS_CONTROL_CENTER_GET
  }
}

export function actionUpdateRestaurantControlCenters(data) {
  return {
    type: RESTAURANT_UPDATE_CONTROL_CENTERS,
    data
  };
}

export function actionUpdateRestaurantAvailableControlCenters(data) {
  return {
    type: RESTAURANT_UPDATE_AVAILABLE_CONTROL_CENTERS,
    data
  };
}

export function actionRestaurantStateUpdate(data) {
  return {
    type: PUSHER_RESTAURANT_STATE_UPDATE,
    data
  };
}

export function actionRestaurantUpdate(data) {
  return {
    type: RESTAURANT_UPDATE,
    data
  };
}

export function actionDepotNew(data) {
  return {
    type: DEPOT_NEW,
    data
  };
}

export function actionDepotEdit(data) {
  return { type: DEPOT_EDIT, data };
}

export function actionDepotDelete(data) {
  return {
    type: DEPOT_DELETE,
    data
  };
}

export function actionSetDepotAvatar(data) {
  return {
    type: SET_DEPOT_AVATAR,
    data
  };
}

export function actionSetBusinessMenuAvatar(data) {
  return {
    type: SET_ORDERING_MENU_BUSINESS_INFO_AVATAR,
    data
  };
}

export function actionSetBusinessMenuHeaderMini(data) {
  return {
    type: SET_ORDERING_MENU_BUSINESS_INFO_HEADER_MINI,
    data
  };
}

export function actionSaveDepotUsers(data) {
  return {
    type: SAVE_DEPOT_USERS,
    data
  };
}

export function actionUpdateDepotUser(data) {
  return {
    type: UPDATE_DEPOT_USER,
    data
  };
}

export function actionAddDepotUser(data) {
  return {
    type: ADD_DEPOT_USER,
    data
  };
}

export function actionSaveShifts(data) {
  return {
    type: SHIFTS_SAVE,
    data
  };
}

export function actionUpdateShifts(data) {
  return {
    type: UPDATE_SHIFTS,
    data
  };
}

export function actionSaveShiftsHistory(data) {
  return {
    type: SHIFTS_HISTORY_SAVE,
    data
  };
}

export function actionSetDepotPaymentState(data) {
  return { type: DEPOTPAYMENT_STATE, data }
}

export function actionSetRestaurantPaymentState(data) {
  return { type: RESTAURANT_PAYMENT_STATE, data }
}


export function actionSetCategoryProductAvatar(data) {
  return {
    type: ORDERING_MENU_BUSINESS_CATEGORY_PRODUCTS_AVATAR,
    data
  };
}

export function actionSetGiftCardProductAvatar(data) {
  return {
    type: GIFT_CARD_PRODUCT_UPDATE_AVATAR,
    data
  };
}

export function actionSetGiftCardBusinessMenuAvatar(data) {
  return {
    type: GIFT_CARD_BUSINESS_UPDATE_AVATAR,
    data
  };
}

export function actionAddNewMarketplaceMessage(data) {
  return {
    type: ADD_MARKETPLACE_MESSAGE,
    data
  }
}

export function actionAddNewMarketplacePage(data) {
  return {
    type: ADD_MARKETPLACE_PAGES,
    data
  }
}

export function actionUpdateMarketplaceMessage(data) {
  return {
    type: UPDATE_MARKETPLACE_MESSAGE,
    data
  }
}

export function actionUpdateMarketplaceMessageGalley(data) {
  return {
    type: UPDATE_MARKETPLACE_MESSAGE_GALLERY,
    data
  }
}

export function actionUpdateMarketplaceMessageGalleyAdd(data) {
  return {
    type: UPDATE_MARKETPLACE_MESSAGE_GALLERY_ADD,
    data
  }
}

export function actionUpdateMarketplacePage(data) {
  return {
    type: UPDATE_MARKETPLACE_PAGE,
    data
  }
}

export function actionDeleteMarketplaceMessage(id) {
  return {
    type: DELETE_MARKETPlACE_MESSAGE,
    id
  }
}

export function actionDeleteMarketplacePage(id) {
  return {
    type: DELETE_MARKETPLACE_PAGE,
    id
  }
}

export function actionGetGiftCard() {
  return { type: GIFT_CARD_GET }
}

export function actionCurrentControlCenterUpdate(data) {
  return { type: CURRENT_CONTROL_CENTER_UPDATE, data };
}

export function actionGetListPromotion() {
  return { type: LIST_PROMOTION_SETTING_GET }
}

export function actionGetMessagesData() {
  return { type: MARKETPLACE_MESSAGE_DATA_GET }
}

export function actionGetSettingsDeliveryZones() {
  return { type: SETTINGS_DELIVERY_ZONES_GET }
}

export function actionControlCenterRefresh(data) {
  return { type: CONTROL_CENTER_REFRESH, data }
}

export function setBusinessMenu(data) {
  return { type: SET_BUSINESS_MENU, data }
}

export function removeBusinessMenu() {
  return { type: REMOVE_BUSINESS_MENU }
}

export function setParentBusinessMenu(data) {
  return { type: SET_BUSINESS_PARENT_MENU, data }
}

export function actionGetBusinessCategories() {
  return { type: BUSINESS_CATEGORY_GET };
}

export function actionGetOperationTiming() {
  return { type: OPERATION_TIMING_SETTINGS_GET };
}

export function actionRestaurantControlCentersStateUpdate(data) {
  return { type: RESTAURANT_CONTROL_CENTERS_STATUS_UPDATE, data };
}

export function setDepotProviders(data){
  return { type: SET_DEPOT_PROVIDERS, data }
}

export function setOrdersProvider(data){
  return { type: SET_ORDERS_PROVIDER, data }
}

export function setShiftProviders(data){
  return { type: SET_SHIFT_PROVIDERS, data }
}

export function actionUpdateRestaurantDeliveryZone(data) {
  return { type: RESTAURANT_DELIVERY_ZONE_UPDATE, data }
}

export function getDriverTipData(data) {
  return { type: DRIVER_TIP_GET, data }
}
