import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';

import { getFullName } from '../../../services/userModel';

import {DATE_FORMAT, DATE_FORMAT_DMY_SHORT, getFormattedDate} from '../../../utils/convertTime';
import { CUSTOMER_WALLET_OPERATION_TYPE } from '../../../utils/constants';

import FormInfoDataItem from '../FormInfoDataItem';
import CustomerReviewsFilter from '../CustomerReviewsFilter';
import moment from "moment";
import Dropdown from "../Dropdown/index.js";
import {LOYALTY_LEVEL, WAITER_TYPES} from "../../../utils/enums.js";
import Waiter from "../Waiter/Waiter.jsx";

const CustomerDataHeader = ({ customerId, onBlockUser, filter, items, onOpenBonus, onSetFilter, isFetching, onUpdateLoyaltyLevel, onUpdateStaffComment }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [bonusCardsCount, setBonusCardsCount] = useState(0);
  const [staffComment, setStaffComment] = useState('');

  useEffect(() => {
    const newValue = customerId && items?.find(f => f.consumerId === customerId);
    if ((!newValue || newValue?.consumerId === value?.consumerId) && isFetching) return;
    setBonusCardsCount(newValue?.wallet?.bonusCards?.length)

    if (!newValue?.orders) return;

    setValue(newValue);
    setStaffComment(newValue.serviceNotes || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, items, isFetching]);

  const handleOpenBonusCardsList = () => {
    onOpenBonus(CUSTOMER_WALLET_OPERATION_TYPE.bonusCardsList);
  };


  const handleOnChangeStaffComment = ({target}) => setStaffComment(target.value);

  return (
    <div className={classNames('customerDataHeader')}>
      <div className={classNames('customerDataHeaderItem', 'controls')}>
        <div className={classNames('link')}>{getFullName(value)}</div>
      </div>
      <div className={classNames('customerDataHeaderItem')}>
        {(value || {}).phone && (
          <FormInfoDataItem isNeverRtl={true} title={t('customers.PHONE_LABEL')} value={value?.phone} isInline={true} />
        )}
        <FormInfoDataItem title={t('customers.EMAIL_LABEL')} value={value?.email} isInline={true} />
        {value && value.birthdate && (
          <FormInfoDataItem title={t('customers.BDAY_LABEL')} value={moment(value.birthdate).format(DATE_FORMAT)} isInline={true} />
        )}
        {value && (
          <FormInfoDataItem hideTooltip title={t('customers.LOYALTY_LEVEL')} value={(
            <Dropdown
              values={Object.entries(LOYALTY_LEVEL).map(([key, value]) => ({key: parseInt(key), value}))}
              value={LOYALTY_LEVEL[value.loyaltyLevel ?? 0]}
              onSelect={onUpdateLoyaltyLevel}
            />
          )} isInline={true} />
        )}
      </div>
      {value?.blockInfo?.isBlocked && (
          <div className={'customerDataHeaderItem blockInfo'}>
            <FormInfoDataItem title={t('customers.BLOCK_STATUS')} value={t('customers.BLOCKED_USER')} isInline={true} />
            <FormInfoDataItem title={t('customers.BLOCK_NOTE')} value={value.blockInfo.note} isInline={true} />
            <div className={'buttonControl'}>
              <Waiter
                  type={WAITER_TYPES.span}
                  spanText={t("customers.UNBLOCK_USER")}
                  handleOnClick={() => onBlockUser(value)}
                  useClass={"btn confirm"}
              />
            </div>
          </div>
      )}
      <div className={classNames('customerDataHeaderItem', 'regInfo')}>
        <FormInfoDataItem title={t('customers.WALLET_LABEL')} value={value?.wallet?.bonus || 0} isInline={true} />
        <FormInfoDataItem
          title={t('customers.AVERAGE_BILL_LABEL')}
          value={Number(parseFloat(value?.averageOrderAmount || 0).toFixed(2))}
          isInline={true}
        />
        <FormInfoDataItem
          title={t('customers.REGISTRATION_LABEL')}
          isInline={true}
          value={getFormattedDate(value?.creationDateTime, DATE_FORMAT_DMY_SHORT) || 0}
        />
        <FormInfoDataItem
          title={t('customers.REG_THROUGH_LABEL')}
          value={value?.registrationSources.join(', ') || ''}
          isInline={true}
        />
      </div>
      <div className={classNames('customerDataHeaderItem', 'regInfo')}>
        <FormInfoDataItem title={t('customers.TOTAL_SPENT')} value={value?.totalAmountSpent || 0} isInline={true} />
        <FormInfoDataItem
          onClick={bonusCardsCount && handleOpenBonusCardsList}
          title={t('customers.VOUCHERS')}
          value={bonusCardsCount || 0}
          isInline={true}
        />
        <FormInfoDataItem
          title={t('customers.TOTAL_CARD_PAYMENTS')}
          value={value?.totalCardPayments || 0}
          isInline={true}
        />
        <FormInfoDataItem
          title={t('customers.TOTAL_CASH_PAYMENTS')}
          value={value?.totalCashPayments || 0}
          isInline={true}
        />
        <FormInfoDataItem title={t('customers.TOTAL_ORDERS')} value={value?.totalOrders || 0} isInline={true} />
      </div>
      <div className={classNames('customerDataHeaderItem', 'regInfo', 'staffComment')}>
        <FormInfoDataItem hideTooltip title={t('customers.STAFF_ONLY_COMMENT')} value={
          <input
              type="text"
              value={staffComment}
              onChange={handleOnChangeStaffComment}
          />
        } isInline={true} />
        <div className={'buttonControl'}>
          <Waiter
              type={WAITER_TYPES.span}
              spanText={t("customers.STAFF_ONLY_COMMENT_UPDATE")}
              handleOnClick={() => onUpdateStaffComment(staffComment)}
              useClass={"btn confirm"}
          />
        </div>
      </div>
      <CustomerReviewsFilter filter={filter} onSetFilter={onSetFilter} isInline={true} />
    </div>
  );
};

const mapStateToProps = state => ({
  items: state.customer.customers || [],
});

export default connect(mapStateToProps)(CustomerDataHeader);
