import classNames from "classnames";
import {
    ACCOUNT_ORDER_STATUS,
    EXTERNAL_COURIER_PROVIDER,
    getCurrencyIcons,
    PAY_METHOD,
    SOURCE_TYPE
} from "../../../utils/enums";
import React from "react";
import {useTranslation} from "react-i18next";
import IconButton from "../IconButton/IconButton";
import {defaultPromiseResolve} from "../../../utils/objects-util";
import './style.scss';

export default ({paymentBalance, payments, refunds, currency, openRefundsById, source, onRefund, hideRefunds, onCapture, status, hideCapture, disableCapture, showDiscardPayment, cancelPaymentIds, onDiscardPayment}) => {
    const {t} = useTranslation();
    const PaymentData = ({payment}) => {
        const { t } = useTranslation();
        const getSettings = () => {
            switch (payment.paymethod_id) {
                case PAY_METHOD.creditCard:
                    if(payment.provider === EXTERNAL_COURIER_PROVIDER.external) {
                        return { icon: "terminal", tooltip: "edit-customer-order.EXTERNAL_PROVIDER" };
                    }
                    return { icon: "creditCard", tooltip: "assistance.PAYMENT_CARD" };
                case PAY_METHOD.cash:
                    return { icon: "cash", tooltip: "assistance.PAYMENT_CASH" };
                case PAY_METHOD.bonus:
                    if(payment.reference_Id) {
                        return { icon: "voucher", tooltip: "assistance.PAYMENT_VOUCHER" };
                    }
                    return { icon: "bonus", tooltip: "assistance.PAYMENT_BONUS" };
                case PAY_METHOD.discount:
                    return { icon: "discount", tooltip: "assistance.PAYMENT_DISCOUNT" };
                default:
                    return {};
            }
        }

        const settings = getSettings();
        return <IconButton isIcon={true}
                           iconName={settings.icon}
                           tooltipText={t(settings.tooltip)}
                           onClick={defaultPromiseResolve}
        />
    }

    const isRefundActive = (payment) => {
        const isCard = payment.provider !== EXTERNAL_COURIER_PROVIDER.external && PAY_METHOD.creditCard === payment.paymethod_id && payment.refund_remainder > 0;
        const isExternal = payment.provider === EXTERNAL_COURIER_PROVIDER.external && PAY_METHOD.creditCard === payment.paymethod_id;
        const isBonus = payment.paymethod_id === PAY_METHOD.bonus && !payment.reference_Id;
        const isCash = PAY_METHOD.cash === payment.paymethod_id;
        return (isBonus || isCash || isCard || isExternal) && status >= ACCOUNT_ORDER_STATUS.accepted;
    };

    const isCaptureActive = (payment) => {
        return (
            payment.captured_price === 0
            && status !== ACCOUNT_ORDER_STATUS.pending
            && payment.paymethod_id === PAY_METHOD.creditCard
            && [SOURCE_TYPE.app, SOURCE_TYPE.admin].includes(source)
        );
    }

    const getRefundsQuantity = paymentId => {
        return (refunds || []).filter(r => r.payment_id === paymentId).length;
    }

    return (
        <div className={classNames("adminOrderInfoItem adminOrderInfoPayments")}>
            <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_PAYMENTS")}</div>
            {!!paymentBalance && (
                <div className={classNames("dataItemDif")}>
                    <div className={classNames("title")}>{t("uilib.summary.PAYMENT_BALANCE")}:</div>
                    <div>
                        {paymentBalance} {getCurrencyIcons(currency)}
                    </div>
                </div>
            )}
            {(payments || []).map((payment, index) => (
                <div key={`payment-${index}`} className={classNames("data")}>
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_TYPE")}</div>
                        <div>
                            <PaymentData key={payment.id} payment={payment}/>
                        </div>
                    </div>
                    <div className={classNames("dataItem")}>
                        <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_AMOUNT")}</div>
                        <div>{payment.price} {getCurrencyIcons(currency)}</div>
                    </div>
                    {payment.paymethod_id === PAY_METHOD.creditCard && payment.provider !== EXTERNAL_COURIER_PROVIDER.external && (
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_PAYMENTS_CARD_MASK")}</div>
                            <div className={classNames({neverRtl: true })}>{payment.card_mask}</div>
                        </div>
                    )}
                    {payment.paymethod_id === PAY_METHOD.creditCard && (
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_PAYMENTS_CARD_TYPE")}</div>
                            <div>{(payment.provider === EXTERNAL_COURIER_PROVIDER.external ? t("edit-customer-order.EXTERNAL_PROVIDER") : payment.card_type) || "-"}</div>
                        </div>
                    )}
                    {payment.paymethod_id === PAY_METHOD.creditCard && (
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_PAYMENTS_CARD_CAPTURED_PRICE")}</div>
                            <div>{payment.captured_price}</div>
                        </div>
                    )}
                    {payment.captured_bonus > 0 && (
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_PAYMENTS_CARD_CAPTURED_BONUS")}</div>
                            <div>{payment.captured_bonus}</div>
                        </div>
                    )}
                    {([PAY_METHOD.voucher].includes(payment.paymethod_id) || (payment.paymethod_id === PAY_METHOD.creditCard && payment.provider !== EXTERNAL_COURIER_PROVIDER.external)) && (
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.ADMIN_ORDER_INFO_REFUND_REMAINDER")}</div>
                            <div>{payment.refund_remainder}</div>
                        </div>
                    )}
                    {!hideRefunds && (
                        <div className={classNames("dataItem refundLink hasRefunds")}>
                            {!!getRefundsQuantity(payment.id) && (
                                <div onClick={() => openRefundsById(payment.id)}>
                                    {t("admin-orders.SEE_REFUNDS")}
                                    <span>{getRefundsQuantity(payment.id)}</span>
                                </div>
                            )}

                            <button className={classNames("btn", "confirm", "refundBtn")}
                                    disabled={!isRefundActive(payment)}
                                    onClick={() => onRefund(payment.id)}
                            >
                                {t("admin-orders.REFUND_BUTTON_LABEL")}
                            </button>
                        </div>
                    )}
                    {!hideCapture && (
                        <div className={classNames("dataItem capture")}>
                            <button className={classNames("btn", "confirm", "captureBtn")}
                                    disabled={!isCaptureActive(payment)}
                                    onClick={() => onCapture(payment.id)}
                            >
                                {t("admin-orders.CAPTURE_BUTTON_LABEL")}
                            </button>
                        </div>
                    )}
                    {showDiscardPayment && payment.paymethod_id === PAY_METHOD.cash &&  (
                        <div className={classNames("dataItem capture")}>
                            <button className={classNames("btn", "confirm", "cancelPayment", {canceled: cancelPaymentIds?.includes(payment.id)})}
                                    onClick={() => onDiscardPayment(payment.id)}
                            >
                                {cancelPaymentIds?.includes(payment.id) ? t('basic.ADD_BUTTON') : t('basic.CANCEL_BUTTON')}
                            </button>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}
