import environment from "environment";
import i18n from "../i18n";
import Store from "../store/store";
import * as Sentry from "@sentry/react";

import {
  ACCOUNT_ORDER_STATUS,
  CYCLE_STATE,
  DEFAULT_ORDERS_GROUPS,
  DELIVERY_STRATEGY,
  DIALOG_TYPE_CONFIRM,
  EXTERNAL_CYCLE_STATE,
  getAccountOrderDeliveryStrategyList,
  getAccountOrderStatusList,
  ICONS_MARKER_TYPE,
  INTEGRATION_TYPE,
  MODE_SWITCHER_TYPE,
  MODEL_TYPE,
  ORDER_PROCESS_STEP,
  ORDER_STATE,
  ORDER_STATE_COLOR,
  ORDER_TYPE,
  PENDING_ORDERS_FILTER_TYPE,
  SOURCE_TYPE,
  TYPE_MARKER
} from "../utils/enums";
import {getParsedServerModel, mergeArrays, objectKeysToUpperLowerCase, parseServerModel} from "../utils/objects-util";
import {
  defaultDate,
  getFormatRelativeTime,
  getISOTime,
  getRelativeStartEndTime,
  getStaticTime,
  prepareStartDate,
  relativeDeltaTimeNoJust, setStartTime
} from "../utils/convertTime";

import GoogleMapsService from "./googleMapsService";
import {getInitModel} from "./initModels";
import Color from "./color";
import ColorService from "./color";
import MarkerIcons from "./markericons";
import {calculateOrderPreviousTime, getCourierColor, getFullName, getSwitchedFullName, isRTL} from "./userModel";
import User from "./user";
import {getDepot, getDepotName, getRestaurantConfigPropValue, isDepotMode} from "./restaurant";
import {
  checkForControlCenter,
  courierFilter,
  courierQueueSort,
  defaultGroupFilter,
  externalCycleGroupsFilter,
  getActiveUsers,
  isPickupGroup,
  notReadyGroupFilter,
  readyGroupFilter,
  sortByDate,
  sortByOlderOrder,
  sortItemsByReadinessAndTargetDate,
  sortItemsDate
} from "./filter";
import {reverseGeoLocation} from "../api/restaurant-requests";
import {getAddressHistory, getGEOLocation, getGEOLocationStr, getPlace} from "../api/order-requests";
import Markers from "./markers";
import {getHistoryLocationParams} from "../utils/routesHelper";

const orderModelTypesDictionary = {
  address: MODEL_TYPE.address,
  location: MODEL_TYPE.location,
  payments: MODEL_TYPE.payments,
  order: MODEL_TYPE.order
};

export function getOrderServerModel(order) {
  return {
    Id: order.id,
    OrderId: order.orderId,
    DepotId: order.depotId,
    Date: getISOTime(order.date),
    State: order.state,
    FirstName: order.firstName,
    LastName: order.lastName,
    Address: order.type ? null : getParsedServerModel(order.address),
    Location: order.type ? null : getParsedServerModel(order.location),
    ConsumerPhone: order.consumerPhone,
    Notes: order.notes,
    Amount: order.amount,
    PaymentInfo: order.paymentInfo,
    IsCache: order.isCache,
    IsHighPriority: order.isHighPriority,
    IsExtraSize: order.isExtraSize,
    Currency: order.currency,
    Content: getParsedServerModel(order.content) || [],
    TargetTime: getISOTime(order.targetTime),
    DeliveryStrategy: order.deliveryStrategy ? "ConcreteTime" : "Asap",
    Type: order.type ? "TakeAway" : "Delivery",
    ReceiptIds: order.receiptIds,
    Payments: getParsedServerModel(order.payments),
    CookingTimeInMin: order.cookingTimeInMin,
    ReadinessTime: getISOTime(order.readinessTime)
  };
}

export function getAddressServerModel(address) {
  return {
    City: address.city,
    Country: address.country,
    Entrance: address.entrance,
    Notes: address.notes,
    Organization: address.organization,
    Postal: address.postal,
    State: address.state,
    Street: address.street,
    StreetNumber: address.streetNumber
  };
}

export function getPlaceServerModel(order) {
  const result = {
    OrderId: order.orderId || "",
    DepotId: order.depotId,
    PlaceId: order.location ? order.location.placeId : "",
    Date: getISOTime(order.date),
    TargetTime: getISOTime(order.targetTime),
    DeliveryStrategy: order.deliveryStrategy ? "ConcreteTime" : "Asap",
    City: order.address.city,
    Street: order.address.street,
    IsVip: order.isHighPriority || false,
    IsExtraSize: order.isExtraSize || false,
    Payments: order.payments,
    ReadinessTime: getISOTime(order.readinessTime),
    Currency: order.currency,
    ConsumerPhone: order.consumerPhone,
    Notes: order.notes
  };

  if (order.userName) {
    let arr = order.userName.trim().split(' ');
    result.LastName = arr.pop();
    result.FirstName = arr.join(' ');
  }

  return result;
}

export function getPlaceServerOrderModel(order) {
  const result = {
    OrderId: order.orderId || "",
    DepotId: order.depotId,
    PlaceId: order.location ? order.location.placeId : "",
    Date: getISOTime(order.date),
    TargetTime: getISOTime(order.targetTime),
    DeliveryStrategy: order.deliveryStrategy,
    City: order.address.city,
    Street: order.address.street,
    IsVip: order.isHighPriority || false,
    IsExtraSize: order.isExtraSize || false,
    Location: order.location || false,
    Payments: order.payments,
    ReadinessTime: getISOTime(order.readinessTime),
    Currency: order.currency,
    ConsumerPhone: order.consumerPhone,
    Notes: order.notes,
    Type: 0,
    Address: order.address,
    CookingTimeInMin: order.cookingTimeInMin,
    IsHighPriority: order.isHighPriority,

  };

  if (order.userName) {
    let arr = order.userName.trim().split(' ');
    result.LastName = arr.pop();
    result.FirstName = arr.join(' ');
  }

  return result;
}


export function getLocationServerModel(location) {
  if (!locationIsEmpty(location)) return location;
  return {
    Latitude: location.latitude,
    Longitude: location.longitude
  };
}

export function createAutocompletionRequest(countryCode, input) {
  return {
    componentRestrictions: {
      country: countryCode
    },
    input: input,
    types: ["geocode"]
  };
}

export function createLocationModel(latitude, longitude) {
  return {
    latitude: latitude || 0,
    longitude: longitude || 0,
    placeId: null,
    modelType: MODEL_TYPE.location
  };
}

export function getAddress(address) {
  if (!address) return "";
  if(typeof address === 'string' || address instanceof String)
    return address;

  let items = [];
  if (address.street) {
    items.push(address.street);
  }

  if (address.city) {
    items.push(address.city);
  }

  return items.join(", ");
}

export function getShortOrderId(order) {
  if(!order || !order.orderId) return "";
  return order.orderId.length > environment.orders.maxSymbolsInOrderName
    ? ("..." + order.orderId.substring(
      order.orderId.length - environment.orders.maxSymbolsInOrderName,
      order.orderId.length))
    : order.orderId;
}

export function getPlaceOrAddress(order) {
  if(!order || !order.address) return "";
  return order.address.organization || getMiniAddress(order);
}

export function getPickupPlaceOrAddress(order, depot) {
  if (!order.depotId) return "";

  if(depot) {
    const address = depot && depot.address && depot.address.address;
    return address.notes || getDepotAddress(address);
  } else {
    const state = Store.store.getState();
    const depot = ((state && state.restaurant && state.restaurant.data.depots) || [])
      .find(depot => depot.id === order.depotId);
    const address = depot && depot.address && depot.address.address;
    return address.notes || getDepotAddress(address);
  }
}

export function getMiniAddress(order) {
  if (order.type) return getName(order);
  let address = [];
  if (order.address && order.address.street) address.push(order.address.street);
  if (order.address && order.address.city) address.push(order.address.city);
  return address.length ? address.join(", ") : "--";
}

export function getOrderInfoAddress(order) {
  if (!order) return "";
  let address = [];

  if (order.city) address.push(order.city);
  if (order.address) address.push(order.address);
  if (order.flat) address.push(`${i18n.t("orders.FLAT_LABEL")}: ${order.flat}`);
  if (order.storey) address.push(`${i18n.t("orders.STOREY_LABEL")}: ${order.storey}`);

  return address.length ? address.join(", ") : "-";
}

export function getMiniOriginalAddress(order) {
  if (order.type) return i18n.t("orders.TAKEAWAY_GROUP_LABEL");
  if (!order.originalAddress) return "--";

  let address = [];
  if (order.originalAddress.street) address.push(order.originalAddress.street);
  if (order.originalAddress.city) address.push(order.originalAddress.city);
  return address.length ? address.join(", ") : "--";
}

export function getAppartmentNumber(order) {
  return (order.address && order.address.entrance) || "";
}

export function getName(order) {
  return (
    (order.firstName ? order.firstName + " " : "") +
    (order.lastName ? order.lastName : "")
  );
}

export function getUIName(order) {
  if (!order) return i18n.t("basic.ANONYMOUS_LABEL");
  return getName(order) || i18n.t("basic.ANONYMOUS_LABEL");
}

export function getNotes(order) {
  return order.address ? order.address.notes : "";
}

export function getOrganization(order) {
  return order.address ? order.address.organization : "";
}

export function getConvertedTargetTime(order, rtl = isRTL()) {
  return order.deliveryStrategy === DELIVERY_STRATEGY.concreteTime
    ? getStaticTime(order.targetTime, false, rtl)
    : null;
}

export function getFormattedDeliveryDuration(order) {
  if (order.state !== ORDER_STATE.delivered || !order.deliveryTime) {
    return "-:-";
  }

  return getRelativeStartEndTime(order.date, order.deliveryTime);
}

export function getDeliveredDate(order) {
  if (!order || !order.deliveryTime) return "";
  const date = new Date(order.deliveryTime);
  return order.deliveryTime && date.getTime() === new Date(defaultDate)
    ? ""
    : date;
}

export function initOrderModel(order, firstDepotId) {
  return getInitModel(MODEL_TYPE.order, {
    depotId: firstDepotId,
    ...parseServerModel(order, orderModelTypesDictionary, MODEL_TYPE.order)
  });
}

export function getDefaultAdminOrdersSearchFilter() {
  const search = getHistoryLocationParams((window.location || {}).search).searchStr || "";
  return {
    search,
    businessId: null,
    start: !search ? prepareStartDate(setStartTime(new Date())) : null,
    end: null,
    status: !search ? ACCOUNT_ORDER_STATUS.pending : null,
    deliveryStrategy: null,
    rating: null,
    deliveryRating: null,
    withRating: false,
    territory: null,
    // deviceType: null,
    // paymentMethod: null,
    // orderSource: null,
  };
}

export function extractOrders(source) {
  const activeUsers = getActiveUsers(source.users);
  const groupsOrders = extractGroupsOrders(source.orders, source.users);
  const preOrders = extractPreOrders(source.orders);
  const takeawayOrders = extractTakeawayOrders(source.orders);
  const cycleOrdersGroups = extractCycleOrders(source.cycles, activeUsers);
  const assistanceOrders = exctractAssistanceOrders(source.assistanceOrders);
  const userGroups = createUserGroups(cycleOrdersGroups.groups, activeUsers);
  const firstDepot = getDepot(source.restaurant && source.restaurant.depots, null, true);

  return {
    groups: groupsOrders.groups
      .concat(preOrders.groups)
      .concat(takeawayOrders.groups)
      .concat(cycleOrdersGroups.groups)
      .concat(userGroups)
      .concat(assistanceOrders.groups),
    orders: groupsOrders.orders
      .concat(preOrders.orders)
      .concat(takeawayOrders.orders)
      .concat(cycleOrdersGroups.orders)
      .concat(assistanceOrders.orders)
      .map(order => initOrderModel(order, firstDepot.id)),
    pickUps: cycleOrdersGroups.pickUps,
    activeOrders: ((source.activeOrders || [])
      .concat(source.consumerOrders || []))
      .concat(assistanceOrders.orders || [])
      ?.sort((a, b) => sortByDate(a, b, "creation_datetime"))
  };
}

export function extractExternalCycleOrders(externalCycles) {
  let orders = [];
  let markers = [];

  (externalCycles || []).forEach(externalCycle => {
    let externalCyclesOrders = externalCycle.orders || [];
    updateOrdersMarkerType(externalCycle, externalCyclesOrders);

    orders.push(...externalCyclesOrders.map((order, index)=> ({
      ...order,
      color: externalCycle.color,
      isExternalGroup: true,
      index
    })));

    markers.push(...externalCyclesOrders
      .filter(f=> f.type === ORDER_TYPE.delivery)
      .map(order=> Markers.instance.createExternalCycleMarkerOrder(order, externalCycle))
    );
  });

  return { orders, markers };
}

export function extractExternalCycleCourierMarkers(courierLocations, externalCyclesItems) {
  const externalCycles = (externalCyclesItems || []).map(externalCycle=> {
    const travelData = (courierLocations || []).find(courierLocation=> courierLocation.courierId === externalCycle.id);

    return {
      ...externalCycle,
      travelData: externalCycle.travelData
        ? { ...externalCycle.travelData, ...(travelData || {}) }
        : travelData
    };
  });

  return {
    externalCycles,
    markers: externalCycles.reduce((acc, externalCycle)=> {
      if(externalCycle.travelData) {
        acc.push(Markers.instance.createExternalCycleMarkerCourier(externalCycle));
      }

      return acc;
    }, [])
  };
}

export function getGroupColor(group, order) {
  if (!group) return ORDER_STATE_COLOR.white;

  return group &&
    isDefaultGroup(group.id) &&
    order.state === ORDER_STATE.ready &&
    User.instance.getCurrentUserConfigurationProp("courierColorScheme")
      ? ORDER_STATE_COLOR.blue
      : group.color;
}

export function getGroupCourierColor(user) {
  return getCourierColor(user);
}

export function isDefaultGroup(groupId) {
  return (
    groupId === DEFAULT_ORDERS_GROUPS.Unsorted ||
    groupId === DEFAULT_ORDERS_GROUPS.takeawayOrders ||
    groupId === DEFAULT_ORDERS_GROUPS.preOrders ||
    groupId === DEFAULT_ORDERS_GROUPS.assistance
  );
}

export function isNotDefaultOrderGroup(groupId) {
  return groupId && !Object.keys(DEFAULT_ORDERS_GROUPS).includes(groupId);
}

export function getGroupName(group) {
  if(!group) return "";
  if (isDefaultGroup(group.id)) return group.name;
  return i18n.t("dashboard.orders.ROUTE_LABEL");
}

export function getAllTimeGroup(group) {
  const routeEstimation =
    group.routeEstimation || getInitModel(MODEL_TYPE.routeEstimation);
  return relativeDeltaTimeNoJust(routeEstimation.totalTime);
}

export function getDeltaTime(group) {
  const routeEstimation =
    group.routeEstimation || getInitModel(MODEL_TYPE.routeEstimation);
  const delta = routeEstimation.totalTime - group.optimalData.totalTime;
  const convertedTime = relativeDeltaTimeNoJust(Math.abs(delta));
  return delta < 0 ? "- " + convertedTime : "+ " + convertedTime;
}

export function getDistance(group) {
  const routeEstimation =
    group.routeEstimation || getInitModel(MODEL_TYPE.routeEstimation);
  return routeEstimation.totalDistance;
}

export function getPath(group) {
  if(!group) return "";
  const state = Store.store.getState();
  const orders = []
      .concat(state.order.data.orders)
      .concat(state.order.data.pickUps)
      .filter(f => f.groupId === group.id)
      .sort((a, b) => a.index - b.index);
  const orderId = getShortOrderId(orders[0]);
  const isShowOrderId = (!isDepotMode || !User.instance.getCurrentUserConfigurationProp("isHideOrderId")) && orderId;

  let result = [];

  if (orders && orders.length) {
    if (orders.length > environment.orders.maxOrdersInTitle) {
      result.push(
        orders[0].type === ORDER_TYPE.delivery || isShowOrderId ? getShortOrderId(orders[0]) : getDepotName(orders[0])
      );
      result.push("...");
      result.push(
        orders[orders.length - 1].type === ORDER_TYPE.delivery || isShowOrderId ? getShortOrderId(orders[orders.length - 1]) : getDepotName(orders[orders.length - 1])
      );
    } else {
      orders.forEach(order => {
        result.push(
          order.type === ORDER_TYPE.delivery || isShowOrderId ? getShortOrderId(order) : getDepotName(order)
        );
      });
    }
  }

  return [...new Set(result)].join(" > ");
}

export function isLastGroupOrder(id, orders, courier) {
  if(!id || !(courier || {}).cycle || courier.cycle.state >= CYCLE_STATE.closed) return false;
  const cycleOrders = (courier.cycle.orders || [])
    .map((m, index)=> ({ ...m, groupId: courier.userId || courier.courierId, index }))
    .filter(f=> f.type !== ORDER_TYPE.pickUp && f.state < ORDER_STATE.delivered);
  const order = cycleOrders.find(f=> f.id === id) || (orders || []).find(f=> f.id === id);

  if(!order) return false;

  const courierOrders = cycleOrders.length
    ? cycleOrders
    : (orders || []).filter(f=> f.groupId === order.groupId && f.state < ORDER_STATE.delivered);
  return courierOrders.length === 1 || order.index === Math.max(...courierOrders?.sort((a, b)=> (a.index || 0) - (b.index || 0)).map(m=> m.index));
}

export function isLastExternalCycleOrder(order, orders, externalCycle) {
  if(!order || !Array.isArray(orders) || isDefaultGroup(order.groupId)) return false;

  if(!externalCycle || externalCycle.state !== EXTERNAL_CYCLE_STATE.deliveryStart) return false;
  const items = orders
    .filter(f=> f.groupId === order.groupId && f.state < ORDER_STATE.delivered)
    ?.sort((a, b)=> a.index - b.index);
  return order.index === Math.max(...items.map(m=> m.index));
}

export function showGroup(group) {
  return group && (group.alwaysShow || (!groupIsEmpty(group) && !group.hide));
}

export function getOrderTime(order, routeEstimation) {
  if(!order || !order.id) return "";
  if(routeEstimation) {
    const segment = ((routeEstimation && routeEstimation.segments) || [])
      .find(f => f.correspondingId === order.id);
    return relativeDeltaTimeNoJust(segment && segment.accumulatedTime);
  } else {
    const state = Store.store.getState();
    const group = ((state &&
      state.order &&
      state.order.data.groups) || []).find(f => f.id === order.groupId);
    const segment = ((group && group.routeEstimation && group.routeEstimation.segments) || [])
      .find(f => f.correspondingId === order.id);
    return relativeDeltaTimeNoJust(segment && segment.accumulatedTime);
  }
}

export function isEmptyEstimationData(group) {
  if (!group) return true;
  return (
    isEmptyRouteEstimation(group.travelData) &&
    isEmptyOptimalData(group.optimalData)
  );
}

export function isEmptyOptimalData(optimalData) {
  return !optimalData || (!optimalData.totalDistance && !optimalData.totalTime);
}

export function isEmptyRouteEstimation(travelData) {
  return (
    !travelData || !travelData.progress || !travelData.progress.segments.length
  );
}

export function isShowETAForOrder(order, group, user) {
  if (
    !group ||
    !user ||
    group.id === DEFAULT_ORDERS_GROUPS.preorders ||
    group.id === DEFAULT_ORDERS_GROUPS.takeaways ||
    order.state > ORDER_STATE.onroute
  )
    return false;
  return !(
    !user.travelData ||
    !user.travelData.progress ||
    !user.travelData.progress.segments
  );
}

export function getDistanceWithOptimal(group) {
  const num = group.routeEstimation.totalDistance - group.optimalData.totalDistance;
  const distance = parseFloat(num.toFixed(2));
  return distance < 0 ? distance : "+ " + distance;
}

export function getOrderArrivalEstimation(order, courier, orders) {
  if (
    !courier.travelData ||
    !courier.travelData.progress ||
    !courier.travelData.progress.segments
  )
    return "";

  const time = calculateOrderPreviousTime(
    order,
    courier.travelData.progress.segments,
    orders
  );
  return time > 0
    ? order.markerType === ICONS_MARKER_TYPE.point
      ? getFormatRelativeTime(time * 1000, undefined, true, isRTL())
      : getStaticTime(time * 1000 + new Date().getTime(), false, isRTL())
    : "";
}

export function getExternalCycleOrderArrivalEstimation(order, externalCycle, orders) {
  const travelData = ((externalCycle.travelData && externalCycle.travelData.progress) || externalCycle.routeEstimation) || {};
  const segment = (travelData.segments || []).find(f=> f.correspondingId === (order || {}).id) || {};
  const orderTimeToArrival = segment.timeToArrivalInSec;
  if (!travelData.segments || !order || !orderTimeToArrival)
    return "";

  const time = calculateOrderPreviousTime(order, travelData.segments, orders);
  return time > 0
    ? (order.markerType === ICONS_MARKER_TYPE.point
      ? getFormatRelativeTime(time * 1000, undefined, true, isRTL())
      : getStaticTime(time * 1000 + new Date().getTime(), false, isRTL()))
    : "";
}

export function getAPIType(order) {
  return order.groupId === DEFAULT_ORDERS_GROUPS.takeaways ||
    order.groupId === DEFAULT_ORDERS_GROUPS.preorders
    ? order.groupId
    : "orders";
}

export function getGroupOrders(orderModel, groupId, withPickups = true) {
  let orders = (orderModel.orders || []).filter(order => order.groupId === groupId);
  if (withPickups)
    orders = orders.concat(
      (orderModel.pickUps || []).filter(order => order.groupId === groupId)
    );
  return orders;
}

export function getGroupOrdersByControlCenter(orderModel, groupId, selectedControlCenterMulti, withPickups = true) {
  let orders = (orderModel.orders || []).filter(order => order.groupId === groupId && checkForControlCenter(order, selectedControlCenterMulti));
  if (withPickups)
    orders = orders.concat(
      (orderModel.pickUps || []).filter(order => order.groupId === groupId)
    );
  return orders;
}

export function getAllOrders(orderModel) {
  return orderModel.orders.concat(orderModel.pickUps);
}

export const getAddressLine = (item, returnArray) => {
  const address = [];
  if(item.city) address.push(item.city);
  if(item.address && typeof item.address === 'string') address.push(item.address);
  if(item.storey) address.push(`${item.storey} ${i18n.t("orders.STOREY_LABEL")}`);
  if(item.flat) address.push(`${item.flat} ${i18n.t("orders.FLAT_LABEL")}`);
  return returnArray ? address : address.join(", ");
}

export function getDepotAddress(depotAddress) {
  if(!depotAddress) return "";
  let items = [];
  if (depotAddress.street) items.push(depotAddress.street);
  if (depotAddress.city) items.push(depotAddress.city);
  return items.join(", ");
}

export function getDepotAddressById(depotId) {
  if(!depotId) return "";
  const state = Store.store.getState();
  const restaurant = state.restaurant.data;
  const depot = (restaurant.depots || []).find(d => d.id === depotId);
  if(depot && depot.address && depot.address.address) {
    return getDepotAddress(depot.address.address);
  } else {
    return "";
  }
}

export function getDepotAddressNotesById(depotId) {
  if(!depotId) return "";
  const state = Store.store.getState();
  const restaurant = state.restaurant.data;
  const depot = (restaurant.depots || []).find(d => d.id === depotId);
  return depot?.address?.address?.notes || "";
}

export function isHighlightItem(groupId) {
  return groupId === DEFAULT_ORDERS_GROUPS.assistance;
}

export function isAssistanceGroup(groupId) {
  return groupId === DEFAULT_ORDERS_GROUPS.assistance;
}

export function isShowTakeAwayBlock() {
  const state = Store.store.getState();
  const restaurant = state.restaurant.data;
  return restaurant && restaurant.configuration && restaurant.configuration.takeAwaySupported;
}

export function getGroupsBarItems(
  groups,
  orders,
  externalCycles,
  isShowGroups = true,
  orderMode,
  isSearchMode = false,
  isMultiSelectMode = false,
  isShowCourierGroupOrders = false,
  isShowExtraSizeGroup,
) {
  if (!groups && !orders) return [];

  if (orderMode === MODE_SWITCHER_TYPE.takeAway) {
    const items = sortItemsByReadinessAndTargetDate(
      getOrdersByGroups([groups.find(f => f.id === DEFAULT_ORDERS_GROUPS.takeawayOrders)], orders)
    );
    return isShowExtraSizeGroup ? items.filter(order => !order.isExtraSize) : items;
  }

  const activeGroups = groups
    .filter(group => group.alwaysShow ||
      (!group.hide && (orders || []).filter(f => f && f.groupId === group.id).length > 0)
    );

  let courierGroups = [];
  if (isShowGroups) {
    courierGroups = activeGroups
      .filter(group => courierFilter(group))
      ?.sort((g1, g2) => courierQueueSort(g1, g2));
  }

  let courierGroupOrders = [];
  if(isShowCourierGroupOrders) {
    const courGroups = groups.filter(group => courierFilter(group));
    courierGroupOrders = getOrdersByGroups(courGroups, orders, ORDER_STATE.onroute);
  }

  const readyGroups = activeGroups
    .filter(group => readyGroupFilter(group))
    ?.sort((g1, g2) => sortByOlderOrder(g1, g2));
  const notReadyGroups = activeGroups
    .filter(group => notReadyGroupFilter(group))
    ?.sort((g1, g2) => sortByOlderOrder(g1, g2));
  const defaultGroups = activeGroups.filter(group => !User.instance.getCurrentUserConfigurationProp("isHideTakeawayGroup")
      ? defaultGroupFilter(group)
      : defaultGroupFilter(group) && group.id !== DEFAULT_ORDERS_GROUPS.takeawayOrders
  ).filter(group=> isShowTakeAwayBlock() ? group : group.id !== DEFAULT_ORDERS_GROUPS.takeawayOrders);
  const defaultGroupsOrders = sortItemsByReadinessAndTargetDate(getOrdersByGroups(defaultGroups, orders));
  const externalCycleGroups = externalCycleGroupsFilter(externalCycles);

  if (isMultiSelectMode) {
    return defaultGroupsOrders.filter(f=> f.groupId !== DEFAULT_ORDERS_GROUPS.assistance);
  }

  const result = isSearchMode
    ? orders
    : externalCycleGroups
      .concat(getOrdersByGroups(readyGroups, orders))
      .concat(courierGroups)
      .concat(getOrdersByGroups(notReadyGroups, orders))
      .concat(defaultGroupsOrders.filter(f=> f.groupId !== DEFAULT_ORDERS_GROUPS.preOrders))
      .concat(courierGroupOrders)
      .concat(defaultGroupsOrders
        .filter(f=> f.groupId === DEFAULT_ORDERS_GROUPS.preOrders)
        ?.sort((a, b)=> sortByDate(a, b, User.instance.getCurrentUserConfigurationProp("sortOrdersBasedOnReadinessTime") ? "readinessTime" : "targetTime"))
      );

  if(orderMode === MODE_SWITCHER_TYPE.delivery) {
    return isShowExtraSizeGroup ? result.filter(order => !order.isExtraSize) : result;
  }

  if(orderMode === MODE_SWITCHER_TYPE.extraSize) {
    const items = result.concat(getOrdersByGroups([groups.find(f => f.id === DEFAULT_ORDERS_GROUPS.takeawayOrders)], orders));
    return isShowExtraSizeGroup ? items.filter(order => order.isExtraSize) : items;
  }
}

export function getDefaultGroup() {
  const state = Store.store.getState();
  return state.order.data.groups.find(
    f => f.id === DEFAULT_ORDERS_GROUPS.Unsorted
  );
}

export function equalsAddress(address1, address2) {
  const addressesExcludeKeys = [
    "entrance",
    "notes",
    "organization",
    "modelType",
    "placeId"
  ];
  for (var key in address1) {
    if (address1.hasOwnProperty(key) && addressesExcludeKeys.indexOf(key) < 0) {
      if ((address1[key] || "") !== (address2[key] || "")) return false;
    }
  }
  return true;
}

export function getLocationByLocationModel(
  locationModel,
  applySearchCoordinates,
  skipEmptyAddress
) {
  if (!locationModel) return Promise.reject();
  return getLocationByCoordinates(
    locationModel.latitude,
    locationModel.longitude,
    applySearchCoordinates,
    skipEmptyAddress
  );
}

export function getLocationByString(address, skipEmptyAddress) {
  return new Promise((resolve, reject) => {
    getGEOLocationStr(address).then(
      response => {
        const result = convertGeoResponse(response, skipEmptyAddress);
        resolve(result ? result : reject("No data"));
      },
      error => {
        console.log(error);
      }
    );
  });
}

export function getLocationByAddress(address) {
  return new Promise((resolve, reject) => {
    getGEOLocation(address).then(
      response => {
        const result = convertGeoResponse(response, true);
        if (result) {
          resolve(result);
        } else {
          reject("No data");
        }
      },
      error => {
        console.log(error);
      }
    );
  });
}

export function getTotalAmount(payments) {
  if (!payments || !payments.length) return 0;
  return payments.reduce((a, b) => a + b.amount * 1, 0);
}

export function processHistoryOrders(
  activeOrders,
  historyOrders,
  clearHistory,
  response
) {
  const data = objectKeysToUpperLowerCase(response.data) || [];
  const orders = clearHistory ? [] : historyOrders || [];
  const newHistoryOrders = data.reduce((accumulator, orderFromSource) => {
    const activeOrder = activeOrders.find(f => f.id === orderFromSource.id);
    const order = orders.find(f => f.id === orderFromSource.id);

    if (!activeOrder && !order) {
      const initOrder = getInitModel(MODEL_TYPE.order);
      accumulator.push({
        ...initOrder,
        ...orderFromSource
      });
    }

    return accumulator;
  }, []);

  return sortItemsDate(orders.concat(newHistoryOrders), "date", true) || [];
}

export function getGroupById(id) {
  const state = Store.store.getState();
  return state.order.data.groups.find(f => f.id === id);
}

export function getCurrentDndId() {
  const state = Store.store.getState();
  return state.orderEdit.data.dnd.id;
}

export function getLocationByCoordinates(
  latitude,
  longitude,
  applySearchCoordinates,
  skipEmptyAddress
) {
  return new Promise((resolve, reject) => {
    reverseGeoLocation(latitude, longitude).then(
      response => {
        const result = convertGeoResponse(response, skipEmptyAddress);
        if (!result) {
          reject("No data");
        } else {
          if (result.locatedAddress && applySearchCoordinates) {
            result.locatedAddress.location = { latitude, longitude };
          }
          resolve(result);
        }
      },
      error => {
        console.log(error);
        reject();
      }
    );
  });
}

export function getOrderColorByGroup(group, order) {
  return order && order.integrationType === INTEGRATION_TYPE.ordering
    ? "#ed552b"
    : ((group && group.color) ? Color.instance.getCodeByColorKey(group.color) : "");
}

function convertGeoResponse(response, skipEmptyAddress) {
  if (!response.data) return null;
  if (
    !response.data.LocatedAddress &&
    (!response.data.SuggestedAddresses ||
      !response.data.SuggestedAddresses.length)
  )
    return null;

  return getGeoAddressModel(response.data, null, skipEmptyAddress);
}

function getGeoAddressModel(source, destinationModel, skipEmptyAddress) {
  if (!destinationModel)
    destinationModel = { locatedAddress: null, suggestedAddresses: [] };
  source = objectKeysToUpperLowerCase(source);
  return {
    locatedAddress:
      source.hasOwnProperty("LocatedAddress") ||
      source.hasOwnProperty("locatedAddress")
        ? source["locatedAddress"]
        : destinationModel.locatedAddress,
    suggestedAddresses: source.hasOwnProperty("suggestedAddresses")
      ? source["suggestedAddresses"].reduce((accumulator, suggestedAddress) => {
          if (!skipEmptyAddress || getAddress(suggestedAddress.address)) {
            accumulator.push(suggestedAddress);
          }
          return accumulator;
        }, [])
      : destinationModel.suggestedAddresses
  };
}

function groupIsEmpty(group) {
  const state = Store.store.getState();
  const order = state.order.data.orders.find(
    f => f.groupId === group.id && !isPickupGroup(f)
  );

  return !order;
}

function extractPreOrders(source) {
  let key = DEFAULT_ORDERS_GROUPS.preOrders;
  let newGroup = getGroup(key);
  newGroup.alwaysShow = !User.instance.getCurrentUserConfigurationProp("isHidePreordersGroup");
  newGroup.hideMarkers = true;

  if (!source) return { groups: [newGroup], orders: [] };
  let orders = source[key] || [];

  if (orders.length) {
    orders = orders.filter(order=> order.type !== ORDER_TYPE.takeAway).map(order => ({
      ...order,
      groupId: DEFAULT_ORDERS_GROUPS.preOrders,
      isCourierGroup: false
    }));
  }

  return { groups: [newGroup], orders: orders };
}

function extractTakeawayOrders(source) {
  let newGroup = getGroup(DEFAULT_ORDERS_GROUPS.takeawayOrders);
  newGroup.alwaysShow = !User.instance.getCurrentUserConfigurationProp("isHideTakeawayGroup");
  newGroup.hideMarkers = true;

  if (!source) return { groups: [newGroup], orders: [] };

  let orders = source[DEFAULT_ORDERS_GROUPS.takeawayOrders] || [];
  let preOrdersTakeAway = (source[DEFAULT_ORDERS_GROUPS.preOrders] || []).filter(order=> order.type === ORDER_TYPE.takeAway);

  orders = orders.concat(preOrdersTakeAway);

  if (orders.length) {
    orders = orders.map(order => ({
      ...order,
      groupId: DEFAULT_ORDERS_GROUPS.takeawayOrders,
      isCourierGroup: false
    }));
  }

  return {
    groups: [newGroup],
    orders: orders
  };
}

function extractCycleOrders(cycles, users) {
  let groups = [];
  let orders = [];
  let pickUps = [];

  (cycles || []).forEach(cycle => {
    if (cycle.orders && cycle.orders.length > 0) {
      let user = users.find(user => user.userId === cycle.courierId);
      let newGroup = getGroup(DEFAULT_ORDERS_GROUPS.courierGroup, user);

      //Copy keys without orders
      Object.keys(cycle)
        .filter(f => f !== "orders")
        .forEach(key => { newGroup[key] = cycle[key]; });
      if(!newGroup.id && cycle.courierId) {
        newGroup.id = cycle.courierId;
      }
      groups.push(newGroup);

      //Fill orders
      let groupOrders = cycle.orders
        .map((order, index) => ({ ...order, index }))
        ?.sort((a, b) => a.index - b.index);
      updateOrdersMarkerType(newGroup, groupOrders);
      groupOrders.forEach(order => {
        order.groupId = newGroup.id || newGroup.courierId;
        if (order.type === ORDER_TYPE.pickUp) {
          pickUps.push(order);
        } else {
          orders.push(order);
        }
      });

      const extractCycleOrders = (cycle.orders &&
        cycle.orders.length &&
        cycle.orders.filter(o=> !orders.concat(pickUps).find(f=> f.id === o.id))) || [];
      if(extractCycleOrders.length) {
        Sentry.captureException("Error extractCycleOrders. Next ids: " + extractCycleOrders.map(m=> m.id).join(", "));
      }
    }
  });

  return { groups, orders, pickUps };
}

export function getCycleData(cycle, users, groups) {
  if(!cycle || !Array.isArray(cycle.orders)) return {};
  const user = (users || []).find(user => user.userId === cycle.courierId);
  const group = groups.find(g=> g.id === cycle.courierId) || getGroup(DEFAULT_ORDERS_GROUPS.courierGroup, user);
  const orders = cycle.orders.map((order, index)=> ({ ...order, index }));
  updateOrdersMarkerType(group, orders);
  return { group, orders };
}

function extractGroupsOrders(source, users) {
  let groups = [];
  let orders = [];
  let key = DEFAULT_ORDERS_GROUPS.groups;

  if (!source) return { groups, orders };
  (source[key] || []).forEach(group => {
    let user = users.find(f => f.userId === group.id);
    let newGroup = getGroup(null, user, group.id);

    Object.keys(group)
      .filter(f => f !== "orders")
      .forEach(key => {
        newGroup[key] = group[key];
      });
    groups.push(newGroup);

    orders = orders.concat(
      group.orders.map(order => ({
        ...order,
        groupId: newGroup.id,
        isCourierGroup: newGroup.isCourierGroup
      }))
    );
  });

  if(!groups.find(f=> f.id === DEFAULT_ORDERS_GROUPS.Unsorted)) {
    groups.push(getGroup(DEFAULT_ORDERS_GROUPS.Unsorted));
  }

  return { groups, orders };
}

function exctractAssistanceOrders(source) {
  let newGroup = getGroup(DEFAULT_ORDERS_GROUPS.assistance);
  newGroup.caching = false;
  newGroup.name = i18n.t("dashboard.orders.ASSISTANCE_LABEL");
  newGroup.hideMarkers = true;

  if (!source) return { groups: [newGroup], orders: [] };

  let orders = source || [];
  if (orders.length) {
    orders = orders.map(order => ({
      ...(order.parsedOrder ? { ...order, ...order.parsedOrder, ...order.parsedOrder.address, notes: order.parsedOrder.notes } : order),
      groupId: newGroup.id,
      isCourierGroup: newGroup.isCourierGroup,
      type: order.parsedOrder.takeAway ? 1 : 0,
      isAssistOrder: true,
      state: order.state,
    }));
  }

  return {
    groups: [newGroup],
    orders: orders
  };
}

function createUserGroups(groups, users) {
  let groupIds = groups.map(group => group.id);
  return users
    .filter(user => user && !groupIds.includes(user.userId) && (user.roles || {}).active)
    .map(user => getGroup(DEFAULT_ORDERS_GROUPS.courierGroup, user));
}

export function getGroup(groupType = null, user = null, groupId = null) {
  const group = {
    id: groupId
      ? groupId
      : groupType === DEFAULT_ORDERS_GROUPS.courierGroup
        ? (user || {}).userId
        : groupType,
    enableDragAndDrop: true,
    showPin: false,
    isLocked: false
  };

  if (
    groupType === DEFAULT_ORDERS_GROUPS.Unsorted ||
    groupId === DEFAULT_ORDERS_GROUPS.Unsorted
  ) {
    group.showFlags = true;
    group.alwaysShow = true;
    group.name = i18n.t("dashboard.orders.GROUP_UNSORTED");
  } else {
    group.showBages = false;
    group.showPath = true;
    group.showPin = true;
    group.showNotes = true;

    if (user) {
      group.color = user && user.color ? user.color : ColorService.instance.getColor();
      group.isCourierGroup = true;
      group.name = getSwitchedFullName(user);
      group.isLocked = user.isLocked;
    } else {
      group.color = ColorService.instance.getColor();
      group.name = i18n.t("dashboard.orders.GROUP_LABEL");
      group.showFlags = true;
    }
  }

  return group;
}

export function updateOrderData(
  sourceOrder,
  isPreorder,
  groupDestinationName,
  state
) {
  let source = initOrderModel({ ...sourceOrder });
  let order =
    state.data.orders && source.id
      ? state.data.orders.find(order => order && source && order.id === source.id)
      : null;
  let takeaway = source.type;

  if (
    !groupDestinationName ||
    groupDestinationName !== DEFAULT_ORDERS_GROUPS.assistance
  ) {
    if (isPreorder) {
      groupDestinationName = DEFAULT_ORDERS_GROUPS.preOrders;
    } else if (takeaway) {
      groupDestinationName = DEFAULT_ORDERS_GROUPS.takeawayOrders;
    } else if (!groupDestinationName) {
      groupDestinationName = DEFAULT_ORDERS_GROUPS.Unsorted;
    }
  }

  let groupDestination =
    state.data.groups &&
    state.data.groups.find(group => group.id === groupDestinationName);

  if (order) {
    order = { ...order, ...source };
  } else if (groupDestination) {
    order = { ...source };
    order.isCourierGroup = groupDestination.isCourierGroup;
    order.markerType = ICONS_MARKER_TYPE.empty;
  }

  if (groupDestination) {
    order.groupId = groupDestination.id;
    order.color = groupDestination.color;
  }

  return order;
}

export function getOrdersByGroups(groups, orders, orderState = ORDER_STATE.delivered) {
  if (!groups || !groups.length) return [];
  let groupIds = groups.map(m => m.id);
  return orders.filter(order => groupIds.includes(order.groupId) && order.state < orderState);
}

export function isGroupHide(state) {
  return !(state === CYCLE_STATE.started || state === CYCLE_STATE.away);
}

export function removeAddressNulls(address) {
  for (const key in address) {
    if (address.hasOwnProperty(key) && address[key] === null) address[key] = "";
  }
  return address;
}

export function dropPaymensZerro(payments) {
  if (!Array.isArray(payments)) return [];
  return payments.reduce((accumulator, payment) => {
    if (payment.amount && parseInt(payment.amount) !== 0)
      accumulator.push(payment);
    return accumulator;
  }, []);
}

export function getInitOrderModel() {
  return getInitModel(MODEL_TYPE.order);
}

export function getPaymentsServerModel(payment) {
  return payment;
}

export function isUniqueAddress(locatedAddresses, addressString) {
  const unifiedAddress = unifyAddress(addressString);
  return !locatedAddresses.some(locatedAddress => unifyAddress(getAddress(locatedAddress.address)) === unifiedAddress);
}

export function getRestaurantCityList(restaurant, isBase = false) {
  if (!restaurant || !restaurant.configuration || !restaurant.configuration.serviceCities)
    return [];
  return isBase
    ? restaurant.configuration.serviceCities
    : restaurant.configuration.serviceCities.map(city => ({ city: city }));
}

export function getFirstRestaurantCity() {
  const state = Store.store.getState();
  return state &&
    state.restaurant &&
    state.restaurant.data.configuration &&
    state.restaurant.data.configuration.serviceCities &&
    state.restaurant.data.configuration.serviceCities[0];
}

export function fillLocationFromPlace(location, address) {
  return new Promise((resolve, reject) => {
    let city = "";
    let street = "";
    let placeId = (location && location.placeId) || "";
    if (address) {
      city = address.city;
      street = address.street;
    }

    if (!city && !street && !placeId) reject("No data for place");

    getPlace(placeId, city, street).then(
      response => {
        const result = convertGeoResponse(response);
        if (!result || !result.locatedAddress) {
          reject("No data");
        } else {
          resolve(result.locatedAddress);
        }
      },
      response => {
        console.log(response);
        reject(response);
      }
    );
  });
}

export function getAddressSuggestions(address, city, countryCode, isByCitySearch, radiusLocation) {
  return new Promise((resolve, reject) => {
    const input = city ? city + ", " + address : address;
    GoogleMapsService.instance
      .getAutocompleteService()
      .then(autocompleteService => {
        let autocompleteRequest = {
          componentRestrictions: { country: countryCode },
          input,
          types: ["geocode"]
        };

        if(radiusLocation) {
          autocompleteRequest = { ...autocompleteRequest, ...radiusLocation };
        }

        autocompleteService.getPlacePredictions(autocompleteRequest, (response, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && response.length) {
            const cityLower = city ? city.toLowerCase() : "";

            const locatedAddresses = response.reduce((accumulator, place) => {
              if (!place.description.toLowerCase().includes(cityLower)) {
                return accumulator;
              }

              const addressArr = [];
              place.terms.some(term => {
                if (term.value.toLowerCase() === cityLower) return true;
                addressArr.push(term.value);
                return false;
              });

              let locatedAddress = {
                location: { ...createLocationModel(), placeId: place.place_id }
              };

              locatedAddress.address = isByCitySearch
                ? {
                  ...getInitModel(MODEL_TYPE.address),
                  city: (place.terms && place.terms.length > 0 && place.terms[0].value) || ""
                } : {
                  ...getInitModel(MODEL_TYPE.address),
                  city: city || (place.terms.length > 1 ? place.terms[place.terms.length - 2].value : ""),
                  street: addressArr.join(", ")
                }

              if (isUniqueAddress(accumulator, getAddress(locatedAddress.address)))
                accumulator.push(locatedAddress);
              return accumulator;
            }, []);

            if (locatedAddresses.length) {
              resolve(locatedAddresses);
            } else {
              reject("No addresses");
            }
          } else {
            reject(status);
          }
        });
      });
  });
}

export function locationIsEmpty(location, isSomeEmpty = false) {
  if (!location) return true;
  const latitude = location.latitude || location.lat;
  const longitude = location.longitude || location.lng;

  const validLatitude = typeof latitude === 'number' && !Number.isNaN(latitude);
  const validLongitude = typeof longitude === 'number' && !Number.isNaN(longitude);

  return isSomeEmpty
    ? (!validLatitude || !validLongitude)
    : (!validLatitude && !validLongitude);
}

export function getLatLngFromLocation(location, isGoogleLatLng) {
  const currLocation = locationIsEmpty(location)
    ? { lat: 0, lng: 0 }
    : {
      lat: location.latitude || location.lat || 0,
      lng: location.longitude || location.lng || 0
    };

  return isGoogleLatLng
    ? ((window && window.google && window.google.maps && new window.google.maps.LatLng(currLocation)) || currLocation)
    : currLocation;
}

export function isSameLocations(location1, location2) {
  if (!location1 && !location2) return true;
  if (!location1 || !location2) return false;

  return (
    location1.latitude === location2.latitude &&
    location1.longitude === location2.longitude
  );
}

export function getDeliveryDuration(order) {
  if (!order.deliveryTime || !order.date) return 0;
  const deliveryDate = new Date(order.deliveryTime);
  const date = new Date(order.date);
  return deliveryDate - date;
}

export function setPaymentOrder(order) {
  if(!order.payments) return order;

  order.payments = order.payments.filter((payment, index)=> (payment.isCache && payment.amount)
    ? true
    : ((index && order.payments[0].isCache && order.payments[0].amount) ? false : payment.amount)
  );

  order.amount = order.payments.map((a, b)=> a.amount + b.amount);
  return order;
}

export function getPreviousAddresses(phone, lastPhone) {
  const filteredPhone = phone.replace("+", "");

  return new Promise((resolve, reject) => {
    if (lastPhone === filteredPhone || !filteredPhone) {
      resolve({ lastPhone: lastPhone, previousAddresses: [] });
      return;
    }

    getAddressHistory(filteredPhone).then(response => {
        if (response.data && response.data.length) {
          const previousAddresses = response.data.reduce((accumulator, address) => {
              if (address.location) accumulator.push(address);
              return accumulator;
            }, []
          );

          resolve({ lastPhone: lastPhone, previousAddresses: previousAddresses });
        }
      },
      result => resolve({ lastPhone: lastPhone, previousAddresses: [] })
    );
  });
}

export function getAmountForType(order, isCache = false) {
  if(!order || !Array.isArray(order.payments)) return 0;
  return order.payments
    .reduce((sum, p) => (p.isCache === isCache && (sum + (p.amount || 0))) || sum, 0);
}

export function getReadyForGroupingOrdersCount(orders) {
  if (!orders || !orders.length)
    return 0;
  return orders.filter(order=> isDefaultGroup(order.groupId) && order.state === ORDER_STATE.ready).length;
}

function unifyAddress(addressString) {
  const addressExp = /,/g;
  return addressString.toLowerCase().trim().replace(addressExp, "");
}

export function updateOrdersMarkerType(group, orders) {
  let orderDispatched = 0;
  orders.forEach(order => {
    let markerType;
    if (order.type !== ORDER_TYPE.pickUp) {
      markerType = MarkerIcons.instance.convertStateToIcon(order.state, orderDispatched);
      if (order.state === ORDER_STATE.onroute) {
        orderDispatched++;
      }
    } else if (order.type === ORDER_TYPE.pickUp) {
      markerType = MarkerIcons.instance.convertPickUpStateToIcon(order.state, orderDispatched);
      if (order.state === ORDER_STATE.new) {
        orderDispatched++;
      }
    }

    order.markerType = markerType;
    order.color = group.color;
    order.groupId = group.id;
  });
}

export function getAccountOrderStatusByKey(key) {
  const status = getAccountOrderStatusList().find(f=> f.key === key) || {};
  return status["value"] || "";
}

export function getAccountOrderDeliveryStrategyByKey(key) {
  const strategy = getAccountOrderDeliveryStrategyList().find(f=> f.key === key) || {};
  return strategy["value"] || "";
}

export function getIsShowReadinessTime(order) {
  const array = [ORDER_STATE.new, ORDER_STATE.kitchen, ORDER_STATE.ready];
  return !getRestaurantConfigPropValue("hideReadinessTimeOnOrderCard")
    && order.readinessTime
    && array.includes(order.state)
}

export function getControlIcon(order) {
  switch (order.markerType) {
    case ICONS_MARKER_TYPE.empty:
      return "icon-empty";
    case ICONS_MARKER_TYPE.point:
      return "icon-point";
    case ICONS_MARKER_TYPE.checked:
      return "icon-checked";
    case ICONS_MARKER_TYPE.canseled:
      return order.type !== ORDER_TYPE.pickUp ? "icon-canseled" : "";
    default:
      return "";
  }
};

export function getOrderTitle(order) {
  const address = getAddress(order.address);
  if (!isDepotMode()) return (address ? address : `${order.orderId || ""} ${getFullName(order)}`);
  const depotName = getDepotName(order);

  return `${depotName ? depotName + " / " : ""}` +
    (address ? address : `${order.orderId || ""} ${getFullName(order)}`);
};

export function getPendingOrderCourier(order, users, externalCycles) {
  if(!order || !order.courier_id) return null;

  const user = (users || [])
    .find(f=> f.userId === order.courier_id) || {};
  const externalCycle = (externalCycles || [])
    .find(f=> f.id === order.courier_id);

  return {
    ...user,
    ...(externalCycle || {}),
    id: order.courier_id,
    userId: order.courier_id,
    color: (externalCycle  || {}).color || user.color || ColorService.instance.getColor(),
    travelData: (externalCycle || {}).travelData || user.travelData,
    isCourierGroup: true,
    isExternalGroup: !!externalCycle
  };
}

export function getPendingOrdersMapMarkers(orders, order, users, externalCycles) {
  let activeOrderMarker = null;
  let markers = [];
  const depotMarkers = Markers.instance.getRestaurantMarkers(null, true);
  const defaultGroup = getGroup(DEFAULT_ORDERS_GROUPS.Unsorted);

  if(Array.isArray(orders) && orders.length) {
    markers = orders.reduce((acc, currValue)=> {
      //check if not selected order
      if((!order || currValue.id !== order.id) &&
        !acc.find(f=> f.id === currValue.id)
      ) {
        const orderCourierGroup = getPendingOrderCourier(currValue, users, externalCycles);
        const orderMarker = Markers.instance.createMarker(TYPE_MARKER.order, currValue, orderCourierGroup || defaultGroup);

        //push order maker if exist
        if(orderMarker) {
          orderMarker.content = Markers.instance.getPendingOrderContent(currValue);
          acc.push(orderMarker);
        }

        if(orderCourierGroup) {
          const marker = orderCourierGroup.isExternalGroup
            ? Markers.instance.createExternalCycleMarkerCourier(orderCourierGroup)
            : Markers.instance.createMarker(TYPE_MARKER.courier, orderCourierGroup, false);
          //push courier maker if exist
          if(marker) {
            acc.push(marker);
          }
        }
      }

      return acc;
    }, []);
  }

  //Get active order markers
  const activeOrderCourierGroup = getPendingOrderCourier(order, users, externalCycles);
  activeOrderMarker = order && Markers.instance.createMarker(TYPE_MARKER.order, order, activeOrderCourierGroup || defaultGroup);
  if(activeOrderMarker) {
    activeOrderMarker.isLarge = () => true;
    activeOrderMarker.content = Markers.instance.getPendingOrderContent(order);
    activeOrderMarker.zIndex = 5;
  }

  if(activeOrderCourierGroup) {
    const marker = activeOrderCourierGroup.isExternalGroup
      ? Markers.instance.createExternalCycleMarkerCourier(activeOrderCourierGroup)
      : Markers.instance.createMarker(TYPE_MARKER.courier, activeOrderCourierGroup, false);

    //push courier maker if exist
    if(marker) {
      const index = markers.map(m=> m.id).indexOf(marker.id);
      if(index !== -1) {
        markers[index].zIndex = 5;
      } else {
        marker.zIndex = 5;
        markers.push(marker);
      }
    }
  }

  return { markers: markers.concat(depotMarkers), activeOrderMarker, activeOrderCourierGroup };
}

export function isShowPendingChooseCourierButton(filterType) {
  return [PENDING_ORDERS_FILTER_TYPE.ready, PENDING_ORDERS_FILTER_TYPE.cooking]
    .includes(filterType);
}

export function getPendingOrderDialogItems(type, data, cookingTime) {
  switch (type) {
    case DIALOG_TYPE_CONFIRM.delivered:
      return {
        type,
        items: [
            { key: 0, value: i18n.t("dashboard.couriersbar.CANCEL_BUTTON") },
            { key: 1, value: i18n.t("dashboard.couriersbar.SURE_BUTTON") }
        ]
      }
    case DIALOG_TYPE_CONFIRM.reject:
      return {
        type,
        items: [
            { key: 0, value: i18n.t("pending-orders.REASON_NO_INGREDIENTS") },
            { key: 1, value: i18n.t("pending-orders.REASON_NO_TIME") },
            { key: 2, value: i18n.t("pending-orders.REASON_OTHER_REASON") }
        ]
      }
    case DIALOG_TYPE_CONFIRM.accept:
    case DIALOG_TYPE_CONFIRM.delay:
      return {
        type,
        items: Array.isArray(data) && data.length
          ? data
          : [
            { key: 5, value: "5 min" },
            { key: 10, value: "10 min" },
            { key: 15, value: "15 min" },
            { key: 20, value: "20 min" }
          ]
      }
    case DIALOG_TYPE_CONFIRM.cooking:
      const items = Array.isArray(data) && data.length
        ? data
        : [
          { key: 5, value: "5 min" },
          { key: 10, value: "10 min" },
          { key: 15, value: "15 min" },
          { key: 20, value: "20 min" }
        ];
      const findCookingTime = items.find(el => el.key === cookingTime);
      if(findCookingTime) {
        findCookingTime.isCookingTime = true;
      } else {
        items.push({ key: findCookingTime, value: `${cookingTime} min`, isCookingTime: true });
      }

      return { type, items };
    default:
      break;
  }
}

export function isPendingOrdersChanged(currentItems, newItems) {
  return ((currentItems || []).length !== (newItems || []).length) ||
    (currentItems || []).some(currentItem=> {
      const item = (newItems || []).find(newItem=> newItem.id === currentItem.id);
      return !item || currentItem.status !== item.status || currentItem.courier_id !== item.courier_id;
    })
}

export function removeGroupFromPendingOrder(order, process_step = ORDER_PROCESS_STEP.waitingForCourierAssignment) {
  if(!order) return;
  return {
    ...order,
    isExternalGroup: false,
    isCourierGroup: false,
    groupId: null,
    courierId: null,
    courier_id: null,
    process_step
  }
}

export const isShowOrderSourceType = value => {
  return value && [
    SOURCE_TYPE.admin,
    SOURCE_TYPE.mishloha,
    SOURCE_TYPE.tenBis,
    SOURCE_TYPE.wolt
  ].includes(value);
}

export default {
  updateOrderState: (state, data) => {
    return {
      ...state,
      data: {
        ...state.data,
        orders: state.data.orders.map(order => order.id === data.id ? data : order),
        pickUps: state.data.pickUps.map(pickUp => pickUp.id === data.id ? data : pickUp),
      }
    };
  },
  newOrder: (state, data) => {
    if (User.instance.skipObjectWithControlCenter(data.order)) return state;

    let order = updateOrderData(
      data.order,
      data.preorder || data.isPreorder,
      data.groupId || data.associatedCycleId || data.order.groupId || DEFAULT_ORDERS_GROUPS.Unsorted,
      state
    );

    if (!order || order.type === ORDER_TYPE.pickUp || User.instance.skipObjectWithControlCenter(order)) return state;
    if(order.type === ORDER_TYPE.takeAway) {
      order.groupId = DEFAULT_ORDERS_GROUPS.takeawayOrders;
    }

    return {
      ...state,
      data: { ...state.data, orders: mergeArrays(state.data.orders, [order]) }
    };
  },
  updateOrder: (state, data) => {
    if (User.instance.skipObjectWithControlCenter(data.order)) return state;
    let searchOrder = state.data.orders.find(f => f.id === data.order.id);
    let group = searchOrder ? state.data.groups.find(f => f.id === searchOrder.groupId) : undefined;

    let order = updateOrderData(
      data.order,
      data.preorder,
      (group && (group.isCourierGroup || !isDefaultGroup(group.id))) ? group.id : null,
      state
    );

    if (!order) return state;

    if(order.type === ORDER_TYPE.takeAway) {
      order.groupId = DEFAULT_ORDERS_GROUPS.takeawayOrders;
    }

    group = state.data.groups.find(f => f.id === order.groupId);

    let groupOrders = [];
    const groupFlag = (group && (group.isCourierGroup || group.id === DEFAULT_ORDERS_GROUPS.takeawayOrders))
    if (groupFlag) {
      groupOrders = mergeArrays(state.data.orders.filter(f => f.groupId === group.id), [order]);
      updateOrdersMarkerType(group, groupOrders);
    }

    return {
      ...state,
      data: {
        ...state.data,
        orders: state.data.orders.map(m => {
          let updOrder = groupFlag ? groupOrders.find(f => f.id === m.id) : null;
          return updOrder ? { ...m, ...updOrder } : m.id === order.id ? { ...m, ...order } : m;
        }),
        activeOrders: state.data.activeOrders.map(m => {
          return m.id === data.order.id ? {...m, ...data.order} : m;
        })
      }
    };
  },
  cycleDispatched: (state, data) => {
    return {
      ...state,
      data: {
        ...state.data,
        orders: state.data.orders.map(order => order.groupId === data.courierId ? { ...order, state: ORDER_STATE.onroute } : order)
      }
    };
  },
  //event.PickUps - data about updated pick ups, is not in use currently
  cycleReorder: (state, data) => {
    if (!data || !data.orderIds || !data.orderIds.length) return state;

    let cycleGroup = data.groupId && state.data.groups.find(group => group.id === data.groupId);
    if (!cycleGroup) {
      if(data.groupId) {
        console.log(`Unknown cycle ${data.groupId}`);
        Sentry.captureException(`Unknown cycle ${data.groupId}`);
      }

      return state;
    }

    let groupData = {};
    Object.keys(data)
      .filter(key => ![ "courierId", "orderIds", "pickUps", "groupId", "courierId"].includes(key))
      .forEach(key => { groupData[key] = data[key]; });

    let newOrders = state.data.orders.filter(f => data.orderIds.includes(f.id));

    if (!newOrders) return state;

    newOrders = newOrders.map(order => {
      return { ...order, groupId: cycleGroup.id, index: data.orderIds.findIndex(f => f === order.id) };
    });

    updateOrdersMarkerType(cycleGroup, newOrders);

    return {
      ...state,
      data: {
        ...state.data,
        orders: state.data.orders.map(m => {
          let updOrder = newOrders.find(f => f.id === m.id);
          return updOrder ? { ...m, ...updOrder } : m;
        }),
        pickUps: state.data.pickUps
          .filter(f=> f.groupId !== cycleGroup.id)
          .concat((data.pickUps || state.data.pickUps.filter(f=> f.groupId === cycleGroup.id)).map(pickUp=> ({
            ...pickUp,
            groupId: cycleGroup.id,
            index: data.orderIds.findIndex(f => f === pickUp.id)
          }))),
        groups: state.data.groups.map(group => group.id === data.groupId ? { ...group, ...groupData } : group)
      }
    };
  },
  manualAssociateUpdate: (state, data) => {
    let associateGroup = state.data.groups.find(f => f.id === data.courierId || f.courierId === data.courierId);

    if (!associateGroup) return state;

    if (!associateGroup.color)
      associateGroup.color = ColorService.instance.getColor();

    return {
      ...state,
      data: {
        ...state.data,
        groups: state.data.groups.map(group => group.id === associateGroup.id ? { ...group, ...associateGroup } : group),
        orders: state.data.orders.map(order => {
          return order.id === data.orderId ? { ...order, isCourierGroup: true, groupId: associateGroup.id } : order;
        })
      }
    };
  }
};

