import React, { useEffect } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import "./index.scss";

import { rates, RATE_CONTROL_WIDTH_HIDE } from "../../../utils/constants";

import RateItem from "../RateItem";
import RateSelect from "../RateSelect";

const RateControl = ({ title, value, isShowRateSelect, isInline = false, onClick }) => {
    const handleOnSelect = rate => {
        return new Promise(resolve=> {
            onClick && onClick(value !== rate ? rate : null);
            resolve();
        });
    }

    useEffect(()=> {

    }, [value, isShowRateSelect])

    return (
        <div className={classNames("rateControl", { isSelect: isShowRateSelect, isInline: isInline })}>
            <div className={classNames("title")}>{title}</div>
            <div className={classNames("rates")}>
                {rates.map(rate=> (<RateItem key={`rate-${rate}`} rate={rate} value={value} onClick={handleOnSelect} />))}
            </div>
            <div className={classNames("ratesSelect")}>
                <RateSelect items={rates} value={value} onSelect={handleOnSelect} />
            </div>
        </div>
    );
}


const mapStateToProps = state => ({
    isShowRateSelect: window.innerWidth <= RATE_CONTROL_WIDTH_HIDE //Fix for update after resize
});
export default connect(mapStateToProps)(RateControl);