import React, {useState} from "react";
import "./style.scss";
import SearchBox from "../SearchBox";
import CustomerSalesMenuList from "../CustomerSalesMenuList";
import {useTranslation} from "react-i18next";

const CustomerSalesMenu = ({ onSelect, menu, menus }) => {
    const {t} = useTranslation();
    const [search, setSearch] = useState("");

    return <div className={"customerSalesMenu"}>
        <div className={"header"}>{t("sales.SALES_LIST")}</div>
        <SearchBox onChange={setSearch} useValue={search} placeholder={t("sales.SEARCH_PLACEHOLDER")} />

        <CustomerSalesMenuList
            filterString={search}
            onSelect={onSelect}
            menu={menu}
            menus={menus}
        />
    </div>
}

export default CustomerSalesMenu;