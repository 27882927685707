import React from "react";
import classNames from "classnames";
import {DATE_TIME_FORMAT_DAYTIME, getFormattedDate} from "../../../utils/convertTime";
import {getCurrencyIcons, getRefoundChannelType, RESHIPMENT_TYPE_VALUE} from "../../../utils/enums";
import {useTranslation} from "react-i18next";
import LayoutHeaderControls from "../LayoutHeaderControls/LayoutHeaderControls";

import "./index.scss";

export const RefundDetails = ({refunds, currency, onClose}) => {
    const {t} = useTranslation();
    return (
        <div className={classNames("refundsOrderDetails")}>
            <div className={classNames("adminOrderInfoItem")}>
                <div className={classNames("header")}>{t("admin-orders.REFUNDS_INFO_LABEL")}</div>
                {(refunds || []).map(refund=> (
                    <div className={classNames("data", "borderBottom")}>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.REFUNDS_INFO_DATE_LABEL")}</div>
                            <div>{getFormattedDate(refund.date, DATE_TIME_FORMAT_DAYTIME)}</div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.REFUND_TYPE_REASON")}</div>
                            <div>{t(RESHIPMENT_TYPE_VALUE[refund.refund_type])}</div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.REFUNDS_INFO_PAYME_TRANSACTION_AUTH_NUMBER_LABEL")}</div>
                            <div>{refund.payme_transaction_auth_number || "-"}</div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.RESTAURANT_REFOUND_CHANNEL")}</div>
                            <div>{(getRefoundChannelType().find(type => type.key === refund.refund_channel) || {}).value || "-"}</div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.REFUNDS_INFO_AMOUNT_LABEL")}</div>
                            <div>{refund.refund_amount || "-"}&nbsp;{refund.refund_amount && getCurrencyIcons(currency)}</div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.REFUNDS_INFO_NOTES_LABEL")}</div>
                            <div>{refund.notes || "-"}</div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.REFUNDS_INFO_INVOICE_URL_LABEL")}</div>
                            <div>{
                                refund.invoice_url
                                    ? <a href={refund.invoice_url} rel="noopener noreferrer"  target={'_blank'}>{refund.invoice_url}</a>
                                    : "-"
                            }</div>
                        </div>
                        <div className={classNames("dataItem")}>
                            <div className={classNames("title")}>{t("admin-orders.REFUNDS_INFO_USER_LABEL")}</div>
                            <div>{refund.user}</div>
                        </div>
                    </div>
                ))}
            </div>
            <LayoutHeaderControls
                isHideSave
                onClose={onClose}
            />
        </div>
    )
}