import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import './index.scss';
import {isRTL} from "../../../services/userModel.js";

const FullScreenConfirmDialog = ({
                                     handleOnCancel,
                                     labelText,
                                     useWrapperClass,
                                     useBtnConfirmClass,
                                     showCloseButton,
                                     useBtnCancelClass,
                                     data,
                                     handleOnConfirm,
                                     minWidth,
                                     t,
                                     description,
                                     hideConfirm,
                                 }) => {
    const modalRoot = document.getElementById('modal-root-confirm');

    if (!modalRoot) return null;

    return ReactDOM.createPortal(
        <div className={classNames('fullScreenConfirmDialogWrapper', useWrapperClass || '', {rtl: isRTL()})}>
            <div className={classNames('confirmDialogWrapper')} style={minWidth ? { minWidth: minWidth } : {}}>

                {showCloseButton && <div className={classNames('confirmDialogWrapperClose')}
                                         onClick={handleOnCancel}>{t('basic.CLOSE')}</div>}
                {labelText && <span className={classNames('labelText')}>{labelText}</span>}
                {description && <span className={classNames('description')}>{description}</span>}
                <div className={classNames('controlsWrapper')}>
                    {!hideConfirm && (
                        <button className={classNames('fullScreenBtn btnConfirm', useBtnConfirmClass || '')}
                                onClick={() => handleOnConfirm(data)}>
                            {t('basic.PROCEED_CONFIRM_MODAL')}
                        </button>
                    )}
                    <button className={classNames('fullScreenBtn btnCancel', useBtnCancelClass || '')}
                            onClick={handleOnCancel}>
                        {t('basic.GO_BACK_CONFIRM_MODAL')}
                    </button>
                </div>
            </div>
        </div>,
        modalRoot
    );
};

export default FullScreenConfirmDialog;
