import React from 'react';
import './style.scss';
import classNames from "classnames";
import IconButton from "../IconButton/IconButton.jsx";
import {useTranslation} from "react-i18next";

const PhoneIcon = () => (
    <div className={classNames("phoneIconLogo")}>
        <div className={classNames("phoneIcon")}></div>
    </div>
);

const AdminOrderInfoRestaurant = ({businessInfo, handleOnDepotClick}) => {
    const {t} = useTranslation();
    return (
        <div className={classNames("adminOrderInfoItem adminOrderInfoRestaurant")}>
            <div className={classNames("title")}>{t("admin-orders.RESTAURANT_INFO_LABEL")}</div>
            <div className={classNames("data")}>
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("admin-orders.RESTAURANT_NAME_LABEL")}</div>
                    <div>{businessInfo.name || "-"}</div>
                </div>
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>&nbsp;</div>
                    <div>
                        <IconButton isIcon={true}
                                    iconName={"depotMenu"}
                                    tooltipText={t("settings.orderingmenu.MENU_BUSINESS_MENU")}
                                    onClick={handleOnDepotClick}
                        />
                    </div>
                </div>
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("admin-orders.ORDER_INFO_ADDRESS_LABEL")}</div>
                    <div>{businessInfo.address || "-"}</div>
                </div>
                <div className={classNames("dataItem")}>
                    <div className={classNames("title")}>{t("admin-orders.ORDER_INFO_PHONE_LABEL")}</div>
                    <div>
                        <span className={classNames({neverRtl: true })}>{businessInfo.phone || "-"}</span>
                        <PhoneIcon /></div>
                </div>
            </div>
        </div>
    )
}

export default AdminOrderInfoRestaurant;
