import React from 'react';
import classnames from 'classnames';
import './style.scss';
import IconButton from "../../IconButton";

const CounterButton = ({onClickAdd, onClickRemove, disableAdd, quantity = 0}) => {
    const handleOnAdd = e => {
        onClickAdd && onClickAdd();
    }
    const handleOnRemove = e => {
        onClickRemove && onClickRemove()
    }
    return (
        <div className={classnames("counterButton")}>
            <div className={classnames("minus")} onClick={handleOnRemove}><IconButton isIcon iconName={"iconMinus"}/></div>
            <div className={classnames("quantity")}>{quantity}</div>
            <div className={classnames("plus", {disable: disableAdd && disableAdd(quantity)})} onClick={handleOnAdd}> <IconButton isIcon iconName={"iconPlus"}/></div>
        </div>
    )
}

export default CounterButton;
